import React, { FC, memo } from 'react';
import classnames from 'classnames';
import './arrow.scss';

export enum Direction {
    toLeft = 'toLeft',
    toRight = 'toRight',
    toTop = 'toTop',
    toBottom = 'toBottom',
    toTopLeft = 'toTopLeft',
    toTopRight = 'toTopRight',
    toBottomLeft = 'toBottomLeft',
    toBottomRight = 'toBottomRight',
}

interface Props {
    direction: Direction;
    color?: string;
    className?: string;
}

const Arrow: FC<Props> = memo(({ direction, color, className }) => {
    const cnSpan = classnames(
        {
            arrow: true,
            'arrow-top': direction === Direction.toTop,
            'arrow-top-left': direction === Direction.toTopLeft,
            'arrow-top-right': direction === Direction.toTopRight,
            'arrow-bottom': direction === Direction.toBottom,
            'arrow-bottom-left': direction === Direction.toBottomLeft,
            'arrow-bottom-right': direction === Direction.toBottomRight,
            'arrow-left': direction === Direction.toLeft,
            'arrow-right': direction === Direction.toRight,
        },
        className,
    );

    const svgStyle = { fill: color };

    return (
        <span className={cnSpan}>
            <svg style={svgStyle} viewBox="0 0 100 100">
                <polygon points="10,90, 50,10, 90,90" />
            </svg>
        </span>
    );
});

export default Arrow;
