import header from './header';
import community from './community';
import statistics from './statistics';
import posts from './posts';

export default {
    header,
    community,
    statistics,
    posts,
};
