export default {
    metrics: {
        community: `Community`,
        engagement: `Engagement rate`,
        engagementRate: `Engagement rate`,
        likes: 'Likes',
        dislikes: 'Dislikes',
        comments: 'Comments',
        shares: 'Shares',
        views: 'Views',
        plays: 'Plays',
    },
    legend: `Averages calculated over the last month`,
    waiting: `Your stats are being processed, they will be available within 24h`,
    delete: 'Delete this account',
    expiredInstagram: {
        label: 'The connection with Instagram has expired!',
        button: 'Log back in',
        hover: {
            statsShared: 'Reconnect quickly so that you can continue to enjoy the application and also continue to share your statistics with your partner brands.',
            statsNotShared: 'Reconnect so you can continue to enjoy the application.',
        },
    },
};
