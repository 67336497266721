export default {
    title: `Complétion de votre profil`,
    subtitle: `Gagnez en visibilité en complétant au maximum votre profil`,
    personalInfo: {
        title: `Complétez toutes vos informations personnelles`,
        subtitle: `(nom, prénom, genre, date de naissance, ville, nationalité, téléphone)`,
    },
    moreInfo: {
        title: `Dites-nous en plus sur vous`,
        subtitle: `(thèmes d’influence, site, bio, description)`,
    },
    instagramAccount: {
        title: `Ajoutez votre compte business ou créateur Instagram`,
    },
    otherNetworks: {
        title: `Ajoutez au moins un autre réseau`,
    },
    full: {
        title: `Félicitations! `,
        subtitle: `Votre profil est complet et sa visibilité est maximale sur notre plateforme`,
    },
};
