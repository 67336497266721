import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as SOCIAL_STATS_CARD from 'constants/socialStatsCard';
import { AnyAction } from 'redux';

export interface SocialNetwork {
    url: string;
    error: boolean;
    errorType: string;
    modal?: {
        display: boolean;
    };
    loading?: boolean;
}

export interface SocialStatsCardState {
    facebook: SocialNetwork;
    instagram: SocialNetwork;
    twitter: SocialNetwork;
    youtube: SocialNetwork;
    tiktok: SocialNetwork;
    snapchat: SocialNetwork;
    pinterest: SocialNetwork;
    twentyone_buttons: SocialNetwork;
    twitch: SocialNetwork;
    website: SocialNetwork;
    blog: SocialNetwork;
    other_networks: SocialNetwork;
}

const getDefaultSocialStatsCardValues = () => ({
    url: '',
    error: false,
    errorType: '',
    modal: {
        display: false,
    },
});

const initialState: SocialStatsCardState = {
    facebook: getDefaultSocialStatsCardValues(),
    instagram: getDefaultSocialStatsCardValues(),
    twitter: getDefaultSocialStatsCardValues(),
    youtube: getDefaultSocialStatsCardValues(),
    tiktok: getDefaultSocialStatsCardValues(),
    snapchat: getDefaultSocialStatsCardValues(),
    pinterest: getDefaultSocialStatsCardValues(),
    twentyone_buttons: getDefaultSocialStatsCardValues(),
    twitch: getDefaultSocialStatsCardValues(),
    website: getDefaultSocialStatsCardValues(),
    blog: getDefaultSocialStatsCardValues(),
    other_networks: getDefaultSocialStatsCardValues(),
};

const reducers = {
    [SOCIAL_STATS_CARD.MODAL_OPEN]: (
        state: SocialStatsCardState,
        { payload: { type } }: AnyAction,
    ): SocialStatsCardState =>
        update(state, {
            [type]: {
                $merge: {
                    modal: { display: true },
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_CLOSE]: (
        state: SocialStatsCardState,
        { payload: { type } }: AnyAction,
    ): SocialStatsCardState =>
        update(state, {
            [type]: {
                $merge: {
                    modal: { display: false },
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_ON_CONNECT]: (state: SocialStatsCardState, { payload }: AnyAction): SocialStatsCardState =>
        update(state, {
            [payload.label]: {
                $merge: {
                    modal: { display: false },
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_ON_SEND]: (state: SocialStatsCardState, { payload }: AnyAction): SocialStatsCardState =>
        update(state, {
            [payload.type]: {
                $merge: {
                    url: payload.url,
                    error: false,
                    errorType: '',
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_ON_EDIT]: (state: SocialStatsCardState, { payload }: AnyAction): SocialStatsCardState =>
        Object.keys(payload).reduce((acc, type) => {
            if (acc[type]) {
                acc[type] = {
                    ...acc[type],
                    url: payload[type].url,
                    error: payload[type].error,
                    errorType: payload[type].errorType,
                };
            }
            return acc;
        }, state),
    [SOCIAL_STATS_CARD.MODAL_INFO_SNA_ERROR]: (
        state: SocialStatsCardState,
        { payload: { error } }: AnyAction,
    ): SocialStatsCardState =>
        update(state, {
            [error.type]: {
                $merge: {
                    error: true,
                    errorType: error.errorType,
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_FETCH_INFO_SNA_FAILURE]: (
        state: SocialStatsCardState,
        { payload: { error } }: AnyAction,
    ): SocialStatsCardState =>
        update(state, {
            [error.type]: {
                $merge: {
                    error: true,
                    errorType: error.errorType,
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_POST_SNA_SUCCESS]: (
        state: SocialStatsCardState,
        { payload }: AnyAction,
    ): SocialStatsCardState =>
        update(state, {
            [payload.label]: {
                $merge: {
                    error: false,
                    errorType: '',
                },
            },
        }),
    [SOCIAL_STATS_CARD.MODAL_RESET_INPUT]: (
        state: SocialStatsCardState,
        { payload }: AnyAction,
    ): SocialStatsCardState =>
        update(state, {
            [payload.type]: {
                $set: {
                    url: '',
                    error: false,
                    errorType: '',
                },
            },
        }),
};

export default createReducer<SocialStatsCardState, AnyAction>(initialState, reducers);
