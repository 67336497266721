import { CANCELED, CONTACTED, REFUSED, SIGNED, VALIDATED } from 'constants/proposition';
import PropositionHeaderLexique from 'locales/types/containers/propositionPage/propositionHeader';

export const headerLexique: PropositionHeaderLexique = {
    state: {
        [CONTACTED]: `En attente de réponse`,
        [VALIDATED]: `Accepté`,
        [SIGNED]: `Signé`,
        [CANCELED]: `Annulé`,
        [REFUSED]: `Refusé`,
    },
    answer: `Répondre`,
};

export default headerLexique;
