import React, { FC } from 'react';

import InfoCircle from '@brandandcelebrities/icons/regular/InfoCircle';

import './PlaceholderInfo.scss';

interface Props {
    text: string;
}

const PlaceholderInfo: FC<Props> = ({ text }) => (
    <div className="bc-analytics__placeholder-info">
        <div className="bc-analytics-placeholder-info__icon">
            <InfoCircle />
        </div>
        <div className="bc-analytics-placeholder-info__text">{text}</div>
    </div>
);

export default PlaceholderInfo;
