/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useCallback } from 'react';
import { Icon, Text, Truncate } from '@brandandcelebrities/kolkit';

import { useDispatch, useSelector, useLexique } from 'utils/redux';

import { Tag } from 'containers/messagingPage/Components';
import { Timeago } from 'containers/messagingPage/Modules';
import { mobileDisplay } from 'actions/messaging';

import MessagingLexique from 'locales/types/containers/messagingPage';

import { AppState } from 'reducers';
import { ProfileState } from 'reducers/messaging';

import styles from './Chat.module.scss';

interface Selector {
  subject: string;
  participants: ProfileState[];
  lastMessageAt: string;
}

const ChatHeader = () => {
  const lexique = useLexique<MessagingLexique>('containers.messagingPage');
  const dispatch = useDispatch();

  const { participants, subject, lastMessageAt } = useSelector<Selector>(
    ({ messaging, user }: AppState) => ({
      subject: messaging?.currentConversation?.subject,
      participants: (messaging?.currentConversation?.participants || []).filter(p => p.id.toString() !== user.profileId.toString()),
      lastMessageAt: messaging?.currentConversation?.lastMessageAt,
    })
  );

  const handleViewPanel = useCallback(
    () => dispatch(mobileDisplay(true)),
    [dispatch]
  );

  return (
    <div className={styles.header}>
      <div className={styles.mobile}>
        <Icon
          isButton
          onClick={handleViewPanel}
          label="arrow-left"
          theme="solid"
        />
      </div>
      <div className={styles.info}>
        <div className={styles.contactItem}>
          {React.Children.toArray(
            participants.map( (participant, index) => (
              <Text tag="span" resetMargin fontWeight={700}>
                {index > 0 && ', '}{participant?.name}
              </Text>
            ))
          )}
        </div>
        <div className={styles.lastMessage}>
          <span>{lexique.chat.lastMessageAt}: </span>
          <Timeago
            timestamp={lastMessageAt}
            format="DD MMM YYYY"
          />
        </div>
      </div>
      <Tag className={styles.tag}>
        <Truncate>
          {subject}
        </Truncate>
      </Tag>
    </div>
  )
};

ChatHeader.displayName = 'ChatHeader';

export default memo(ChatHeader);

