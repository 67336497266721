import React, { FC, memo, useEffect, useMemo, useCallback } from 'react';
import { push } from 'connected-react-router';

import routes from 'config/routes';
import { Networks } from 'constants/networks';

import { useLexique, useSelector, useDispatch } from 'utils/redux';
import { AppState } from 'reducers';
import { SocialStatsCardState } from 'reducers/ui/socialStatsCards';
import { Profile } from 'types/profiles';
import { SnasState } from 'reducers/snas';
import { CompletionPercent } from 'reducers/user';
import { ProgressMeter } from 'components/atoms';
import SocialStatsCardModal from 'components/templates/SocialStatsCard/SocialStatsCardModal';

import { updateCompletionPercent } from 'actions/user';
import { showModale } from 'actions/modale';
import { openModal, closeModal, onSend } from 'actions/socialStatsCard';

import { ProfileStrengthMeterLexique } from 'locales/types/containers/shared/profileStrengthMeter';

import { ReactComponent as AwardIcon } from 'assets/images/svg/award.svg';
import ItemSection from './ItemSection';
import './ProfileStrengthMeter.scss';

const SECTIONS = ['personalInfo', 'moreInfo', 'instagramAccount', 'otherNetworks'];

interface Store {
    profile: Profile;
    snas: SnasState;
    socialStatsCards: SocialStatsCardState;
    completionPercent: CompletionPercent;
}

const ProfileStrengthMeter: FC = () => {
    const { profile, snas, socialStatsCards, completionPercent }: Store = useSelector((state: AppState) => ({
        profile: state.profile.profile,
        snas: state.snas,
        socialStatsCards: state.ui.socialStatsCards,
        completionPercent: state.user.completionPercent,
    }));

    const dispatch = useDispatch();

    const hasInstagramAccount = useMemo(() => snas.instagram.length && snas.instagram[0].filled, [snas.instagram]);

    const hasOtherNetworks = useMemo(
        () =>
            Object.entries(snas).find(
                ([key, networks]) => key !== Networks.instagram && networks.find((sna) => sna.supported && sna.filled),
            ),
        [snas],
    );

    useEffect(() => {
        dispatch(updateCompletionPercent(profile, hasInstagramAccount, hasOtherNetworks));
    }, [profile, hasInstagramAccount, hasOtherNetworks, dispatch]);

    // Lexique
    const lexique = useLexique<ProfileStrengthMeterLexique>('containers.shared.profileStrengthMeter');

    const goTo = useCallback(
        (section) => {
            if (section === 'personalInfo' || section === 'moreInfo') {
                dispatch(push(routes.profile));
                return;
            }
            if (section === 'instagramAccount') {
                dispatch(openModal(Networks.instagram));
                return;
            }
            if (section === 'otherNetworks') {
                dispatch(
                    showModale({
                        body: 'SnaForm',
                    }),
                );
            }
        },
        [dispatch],
    );

    const onCloseInstagramModal = useCallback(() => {
        dispatch(closeModal(Networks.instagram));
    }, [dispatch]);

    const onClickFetchInstagramUrl = useCallback(
        (network, url) => {
            dispatch(onSend(network, url));
        },
        [dispatch],
    );

    const renderSections = useMemo(() => {
        return (
            <div className="profile-strength-metter__sections">
                {SECTIONS.map((section) => (
                    <ItemSection
                        key={section}
                        section={section}
                        onClick={goTo}
                        isCompleted={completionPercent.completed[section]}
                    />
                ))}
                <SocialStatsCardModal
                    socialStatsCard={socialStatsCards[Networks.instagram]}
                    onCloseModal={onCloseInstagramModal}
                    type={Networks.instagram}
                    onClickFetchUrl={onClickFetchInstagramUrl}
                />
            </div>
        );
    }, [goTo, completionPercent.completed, socialStatsCards, onCloseInstagramModal, onClickFetchInstagramUrl]);

    const renderContent = useMemo(() => {
        if (completionPercent.total === 100) {
            return (
                <div className="profile-strength-metter__full">
                    <div className="profile-strength-metter__full-icon">
                        <AwardIcon />
                    </div>
                    <p className="profile-strength-metter__full-title">{lexique.full.title}</p>
                    <p className="profile-strength-metter__full-subtitle">{lexique.full.subtitle}</p>
                </div>
            );
        }

        return (
            <>
                <p className="profile-strength-metter__subtitle">{lexique.subtitle}</p>

                <ProgressMeter value={completionPercent.total} />
                {renderSections}
            </>
        );
    }, [completionPercent.total, renderSections, lexique]);

    return (
        <div className="profile-strength-metter">
            <h3 className="profile-strength-metter__title">{lexique.title}</h3>
            {renderContent}
        </div>
    );
};

export default memo(ProfileStrengthMeter);
