import React, { useCallback } from 'react';
import { Icon } from '@brandandcelebrities/kolkit';
import { useSelector, useDispatch, useLexique } from 'utils/redux';
import { AppState } from 'reducers';
import { UserState } from 'reducers/user';
import { resendConfirmationEmail } from 'actions/user';
import UnconfirmedEmailBannerLexique from 'locales/types/components/organisms/UnconfirmedEmailBanner';
import styles from './UnconfirmedEmailBanner.module.scss';

const UnconfirmedEmailBanner: React.FC = () => {
    const dispatch = useDispatch();
    const { email } = useSelector<UserState>((state: AppState) => state.user);
    const lexique = useLexique<UnconfirmedEmailBannerLexique>('components.organisms.unconfirmedEmailBanner');
    const resendEmail = useCallback(() => {
        dispatch(resendConfirmationEmail());
    }, [dispatch]);

    return (
        <div className={styles.banner}>
            <Icon className={styles.icon} fill="white" theme="solid" label="envelope" />
            &nbsp;
            <span className={styles.description}>
                {lexique.description} {email}
            </span>
            &nbsp;
            <button type="button" onClick={resendEmail} className={styles.send}>
                {lexique.resend}
            </button>
        </div>
    );
};

export default UnconfirmedEmailBanner;
