import SocialStatsContainerLexique from 'locales/types/containers/shared/socialStatsContainer';

export const socialStatsContainerLexique: SocialStatsContainerLexique = {
    modal: {
        addInstagram: `Connect with Instagram`,
        addInstagramBusiness: `Connect with Instagram Business`,
        addFacebook: `Connect with facebook`,
        addFacebookBusiness: `Connect with Facebook Business`,
        selectPages: {
            instagram: {
                title: `Connect your Instagram Business accounts`,
                text: `Choose which Instagram Business Profiles to import`,
                noPages: `There are no business or creator accounts related to this profile.`,
                link: `Discover how to convert your personal Instagram profile to a business profile`,
            },
            facebook: {
                title: `Connect your Facebook Business accounts`,
                text: `Choose which Facebook Business Profiles to import`,
                noPages: `There are no business or creator accounts related to this profile.`,
                link: ``,
            },
            next: 'Next',
        },
        or: `Or`,
        title: {
            facebook: `Connect your Facebook fanpage`,
            twitter: `Connect your Twitter account`,
            instagram: `Connect your Instagram account`,
            youtube: `Connect your Youtube channel`,
            tiktok: `Connect your Tiktok account`,
        },
        text: {
            facebook: `Copy/Paste your fanpage address`,
            twitter: `Copy/Paste your Twitter address`,
            instagram: `Copy/Paste your Instagram address`,
            youtube: `Copy/Paste your channel address`,
            tiktok: `Copy/Paste your tiktok address`,
        },
        form: {
            url: {
                facebook: {
                    placeholder: `www.facebook.com/kolsquare`,
                    errorTypes: {
                        invalidUrl: `Your Facebook fanpage address is invalid.`,
                        privateAccount: `This fanpage is private`,
                        recordNotUnique: `Sorry, an error has occured.`,
                        unknownAccount: `This fanpage doesn't exist`,
                        errorUnknown: `Sorry, an error has occured.`,
                    },
                },
                instagram: {
                    placeholder: `www.instagram.com/kolsquare`,
                    errorTypes: {
                        invalidUrl: `Your Instagram account address is invalid.`,
                        privateAccount: `This account is private`,
                        recordNotUnique: `Sorry, an error has occured.`,
                        unknownAccount: `This account doesn't exist`,
                        errorUnknown: `Sorry, an error has occured.`,
                    },
                },
                twitter: {
                    placeholder: `www.twitter.com/kolsquare`,
                    errorTypes: {
                        invalidUrl: `Your Twitter account address is invalid.`,
                        privateAccount: `This account is private`,
                        recordNotUnique: `Sorry, an error has occured.`,
                        unknownAccount: `This account doesn't exist`,
                        errorUnknown: `Sorry, an error has occured.`,
                    },
                },
                youtube: {
                    placeholder: `www.youtube.com/channel/kolsquare`,
                    errorTypes: {
                        invalidUrl: `Your YouTube channel address is invalid.`,
                        privateAccount: `This channel is private`,
                        recordNotUnique: `Sorry, an error has occured.`,
                        unknownAccount: `This channel doesn't exist`,
                        errorUnknown: `Sorry, an error has occured.`,
                    },
                },
                tiktok: {
                    placeholder: `www.tiktok.com/@kolsquare`,
                    errorTypes: {
                        invalidUrl: `Your Tiktok account address is invalid.`,
                        privateAccount: `This account is private`,
                        recordNotUnique: `Sorry, an error has occured.`,
                        unknownAccount: `This account doesn't exist`,
                        errorUnknown: `Sorry, an error has occured.`,
                    },
                },
            },
            send: `Save`,
        },
    },
    addSNA: 'Add a social network',
};

export default socialStatsContainerLexique;
