import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';

import styles from './PanelWrapper.module.scss';

const PanelWrapper = ({ items, currentTab }) => {
  const [tab, setTab] = useState(currentTab);

  const components = useTransition(items[currentTab], p => p.key, {
    initial: { transform: 'translate3d(0,0,0)' },
    from: { transform: `translate3d(100%,0,0)` },
    enter: { transform: 'translate3d(0,0,0)' },
    leave: { transform: `translate3d(-20%,0,0)`, zIndex: 0 },
    config: { mass: 1, tension: 400, friction: 35 }
  });

  if (currentTab !== tab) setTab(currentTab);

  return (
    <div className={styles.list}>
      {components?.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className={styles.itemWrapper}
          style={props}
        >
          {item?.component}
        </animated.div>
      ))}
    </div>
  );
};

export default PanelWrapper;
