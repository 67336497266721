import React, { memo, ReactElement, forwardRef } from 'react';
import cn from 'classnames';

import { Text } from '@brandandcelebrities/kolkit';

import { Attachments, Avatar } from 'containers/messagingPage/Components';
import { Timeago } from 'containers/messagingPage/Modules';

import { AttachmentState } from 'reducers/messaging';

import styles from './BubbleMessage.module.scss';

interface Props {
  timestamp?: string;
  avatar?: string;
  name?: string;
  message: string | ReactElement;
  attachments?: AttachmentState[];
  showTimestamp?: boolean;
  showTime?: boolean;
  isGrouped?: boolean;
  me?: boolean;
}

const BubbleMessage = forwardRef<HTMLDivElement, Props>(( {timestamp, avatar, name, message, attachments, showTimestamp, showTime, isGrouped, me }, ref) => {
  const cnWrapper = cn(styles.wrapper, {
    [styles.me]: me,
    [styles.grouped]: isGrouped
  });

  return (
    <div className={cnWrapper} ref={ref}>
      {showTimestamp && !isGrouped && (
        <Timeago
          timestamp={timestamp}
          className={styles.timestamp}
          showTime={showTime}
        />
      )}
      <div className={styles.content}>
        <Avatar
          avatar={avatar}
          name={name}
          hide={isGrouped}
        />

        <div className={styles.message}>
          <div className={styles.bubble}>
            {!me && !isGrouped && (
              <span className={styles.author}>{name}</span>
            )}
            <Text className={styles.bubbleMessage}>{message}</Text>
          </div>
          {attachments?.length > 0 && (
            <div className={styles.attachments}>
              <Attachments
                attachments={attachments}
                alignRight={me}
                readOnly
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
});

BubbleMessage.displayName = 'BubbleMessage';

BubbleMessage.defaultProps = {
  me: true,
  showTimestamp: true,
  showTime: true,
  timestamp: null,
  avatar: null,
  attachments: []
};

export default memo(BubbleMessage);
