import UnconfirmedEmailPageLexique from 'locales/types/containers/unconfirmedEmailPage';

const unconfirmedEmailPageLexique: UnconfirmedEmailPageLexique = {
    title: 'Check your inbox',
    description: `We couldn't validate your email. For some security reasons, Kolsquare need it to let you access the platform.`,
    linkSent: 'Click on the link sent at the email address:',
    emailNotReceived: `Didn't receive anything?`,
    resendEmail: `Send again the validation email`,
    changeEmail: 'Change my email address',
    deleteAccount: 'Delete my account',
};

export default unconfirmedEmailPageLexique;
