import React, { FC, memo, useMemo, useCallback } from 'react';

import { Button } from '@brandandcelebrities/kolkit';

import { openOnboardingStatsSharing } from 'actions/user';
import { NotificationLexique } from 'locales/types/containers/messagingPage';
import { ProfileState, MESSAGE_SUBTYPES } from 'reducers/messaging';
import { useDispatch } from 'utils/redux';

import { Timeago } from 'containers/messagingPage/Modules';
import Markdown from 'components/atoms/Markdown';

import styles from './Notification.module.scss';

interface Props {
  subtype: string;
  content: string;
  lexique: NotificationLexique;
  timestamp: string;
  writer: ProfileState;
  kolName: string;
}

const Notification: FC<Props> = ({subtype, content, lexique, timestamp, writer, kolName}) => {

  const dispatch = useDispatch();
  const goToStatsSharing = useCallback(() => dispatch(openOnboardingStatsSharing()), [dispatch]);

  const renderContent = useMemo(
    () => {
      switch(subtype) {
        case MESSAGE_SUBTYPES.statsSharingRequest:
          return (
            <div className={styles.statsSharing}>
              <p>{lexique.statsSharing.title}</p>
              <p>{lexique.statsSharing.subTitle.replace('{kolName}', kolName)}</p>
              {content && (
                <p className={styles.bubble}>
                    <strong>{writer.name}:</strong> <i>{content}</i>
                </p>
              )}
              <p>{lexique.statsSharing.linkDescription}</p>
              <Button onClick={goToStatsSharing}>
                {lexique.statsSharing.linkLabel}
              </Button>
            </div>
          )
        
        case MESSAGE_SUBTYPES.answerBrief:
          return (
            <Markdown>
              {lexique[content === 'yes' ? 'agreed':'disagreed']}
            </Markdown>
          )
          case MESSAGE_SUBTYPES.statsSharingHalted: {
            const { username } = JSON.parse(content);
            return lexique.statsSharing.statsSharingHalted.replace('{{username}}', username);
          }
        case MESSAGE_SUBTYPES.statsSharingApproval: 
            return lexique.statsSharing.linkLabel;
        case MESSAGE_SUBTYPES.statsSharingResumed: {
            const { username } = JSON.parse(content);
            return lexique.statsSharing.statsSharingApproval.replace('{{username}}', username);
          }
        default:
          return null;
      }
    },
    [subtype, content, lexique, writer.name, kolName, goToStatsSharing]
  );

  if (subtype === MESSAGE_SUBTYPES.stateUpdated) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Timeago
        timestamp={timestamp}
        className={styles.timestamp}
      />
      <div className={styles.content}>
        {renderContent}
      </div>
    </div>
  )

}

export default memo(Notification);
