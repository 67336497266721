export default {
    engagment: {
        title: 'Engagement rate',
        button: 'More info about my audience',
    },
    profileVisit: {
        title: 'Number of brands having visited my profile on Kolsquare',
        button: 'More info about my profile on Kolsquare',
    },
    mostEngagingHashtag: {
        title: 'Most engaging hashtag',
        button: 'More info about my content',
    },
    legend: 'Data based on the past month, compared to the previous one.',
};
