import React, {FC, ReactChild, ReactFragment, useEffect, useState } from 'react';
import { createConsumer } from '@rails/actioncable';

import { conf, DEV_DEBUG } from 'config/env';
import ActionCableContext from './ActionCableContext';

interface Props {
  children: ReactChild | ReactFragment;
};

const ActionCableProvider: FC<Props> = ({ children }) => {
  const [cable, setCable] = useState(null);

  useEffect(
    () => {
      if (!cable && !DEV_DEBUG) {
        setCable(createConsumer(conf.websocket));
      }

      return () => cable?.disconnect();
    },
    [cable]
  );

  return (
    <ActionCableContext.Provider value={cable}>
      {children}
    </ActionCableContext.Provider>
  );
};

export default ActionCableProvider;