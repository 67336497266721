import React, { Component } from 'react';
import { connect } from 'react-redux';
import classname from 'classnames';
import nl2br from 'react-nl2br';

import { Input } from '@brandandcelebrities/kolkitten';

import { onClick } from 'actions/propositionAnswerCard';
import { CONTACTED } from 'constants/proposition';
import getLexique from 'locales';

import './PropositionAnswerCard.scss';
import { AppState } from 'reducers';
import { Proposition } from 'reducers/proposition';

interface Props {
    proposition?: Proposition;
    onClick: (id: string, type: string, text: string) => void;
    lexique: any; // @TODO type
}

interface State {
    answer: string;
    error: boolean;
}

class PropositionAnswerCard extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            answer: '',
            error: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            error: false,
        };
    }

    handleChange(value) {
        this.setState({
            answer: value,
            error: false,
        });
    }

    onDiscussClick(id, type, text) {
        if (text === '') {
            this.setState({
                error: true,
            });
        } else {
            this.props.onClick(id, type, text);
        }
    }

    render() {
        const { lexique, proposition } = this.props;
        const isEnabled = this.props.proposition.state === CONTACTED && this.props.proposition.answer_value === null;

        return (
            <div className="bc-proposition-answer-card">
                <div className="bc-proposition-answer-card__title">{lexique.title}</div>

                {!(proposition.answer_value !== null && !!proposition.answer_text) && (
                    <Input
                        id="answer"
                        className="bc-proposition-answer-card__textarea"
                        value={this.state.answer}
                        placeholder={lexique.form.textarea.placeholder}
                        onChange={({ value }) => this.handleChange(value)}
                        lines={10}
                        disabled={!isEnabled}
                        error={this.state.error}
                        errorText={lexique.form.textarea.error}
                    />
                )}
                {proposition.answer_value !== null && !!proposition.answer_text && (
                    <div className="bc-proposition-answer-card__answer">{nl2br(proposition.answer_text)}</div>
                )}
                <div className="bc-proposition-answer-card__control-buttons">
                    <div className="bc-proposition-answer-card__control-title">{lexique.form.title}</div>
                    <button
                        type="button"
                        className={classname({
                            'bc-proposition-answer-card__button': true,
                            'bc-proposition-answer-card__button--yes': true,
                            'bc-proposition-answer-card__button--disabled': !isEnabled,
                            'bc-proposition-answer-card__button--selected': proposition.answer_value === 'yes',
                        })}
                        disabled={!isEnabled}
                        onClick={() => this.props.onClick(proposition.id, 'yes', this.state.answer)}
                    >
                        {lexique.form.buttons.yes}
                    </button>
                    <button
                        type="button"
                        className={classname({
                            'bc-proposition-answer-card__button': true,
                            'bc-proposition-answer-card__button--discuss': true,
                            'bc-proposition-answer-card__button--disabled': !isEnabled,
                            'bc-proposition-answer-card__button--selected': proposition.answer_value === 'discuss',
                        })}
                        disabled={!isEnabled}
                        onClick={() => this.onDiscussClick(proposition.id, 'discuss', this.state.answer)}
                    >
                        {lexique.form.buttons.discuss}
                    </button>
                    <button
                        type="button"
                        className={classname({
                            'bc-proposition-answer-card__button': true,
                            'bc-proposition-answer-card__button--no': true,
                            'bc-proposition-answer-card__button--disabled': !isEnabled,
                            'bc-proposition-answer-card__button--selected': proposition.answer_value === 'no',
                        })}
                        disabled={!isEnabled}
                        onClick={() => this.props.onClick(proposition.id, 'no', this.state.answer)}
                    >
                        {lexique.form.buttons.no}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexique: getLexique(state.env.locale).containers.propositionPage.propositionAnswerCard,
});

const mapDispatchToProps = () => ({
    onClick,
});

export default connect(mapStateToProps, mapDispatchToProps())(PropositionAnswerCard);
