import React, { FC, memo } from 'react';
import cn from 'classnames';
import { Tooltip } from '@brandandcelebrities/kolkit';

import getLexique from 'locales';
import { useSelector } from 'utils/redux';

import styles from './Placeholder.module.scss';

interface Props {
    img: string;
    tooltip: string;
    className?: string;
}

const Placeholder: FC<Props> = ({ img, tooltip, className }) => {
    const lexique = useSelector(({ env }) => getLexique(env.locale).components.tooltips[tooltip]);

    const cnPlaceholder = cn(styles.emptyPlaceholder, className);

    return (
        <div className={cnPlaceholder}>
            <Tooltip content={lexique}>
                <img src={img} alt={lexique} />
            </Tooltip>
        </div>
    );
};

export default memo(Placeholder);
