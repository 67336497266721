import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as PROPOSITION from 'constants/proposition';
import * as PROPOSITION_PAGE from 'constants/propositionPage';
import { AnyAction } from 'redux';

interface AccountExecutive {
    first_name: string;
    last_name: string;
    tel: string;
    email: string;
    photo_url: string;
}

export interface Project {
    description?: string;
    broadcast_media?: string[];
    rights_duration?: string;
    media?: string[];
    suggestion?: string;
    amplify_broadcast?: 'yes' | 'no';
    relay_broadcast?: 'yes' | 'no';
    social_networks?: string[];
    project_type: string;
    name?: string;
    client_name?: string;
}

export interface Proposition {
    id: string;
    projectType: string;
    clientName: string;
    title: string;
    budget: number;
    from: string;
    to: string;
    socialNetworks: string;
    state: string;
    account_executive?: AccountExecutive;
    answer_value?: string;
    answer_text?: string;
    project?: Project;
    performance?: string;
    number_of_posts?: number;
}

export interface PropositionsState {
    loading: boolean;
    all: Proposition[];
    current: Proposition;
}

const initialState: PropositionsState = {
    loading: true,
    all: [],
    current: null,
};

const reducers = {
    [PROPOSITION.LOAD_ALL_INIT]: (state: PropositionsState) => update(state, { loading: { $set: true } }),
    [PROPOSITION.LOAD_ALL_COMPLETE]: (state: PropositionsState, { payload: { propositions } }: AnyAction) =>
        update(state, {
            loading: { $set: false },
            all: { $set: propositions },
        }),
    [PROPOSITION_PAGE.LOAD]: (state: PropositionsState) =>
        update(state, {
            loading: { $set: false },
            current: { $set: null },
        }),
    [PROPOSITION_PAGE.FETCH_PROPOSITION_SUCCESS]: (state: PropositionsState, { payload: { proposition } }: AnyAction) =>
        update(state, {
            loading: { $set: false },
            current: { $set: proposition },
        }),
};

export default createReducer<PropositionsState, AnyAction>(initialState, reducers);
