import React, { FC } from 'react';
import nl2br from 'react-nl2br';
import classname from 'classnames';

import './PropositionAnsweredMobile.scss';
import { useLexique } from 'utils/redux';
import { Proposition } from 'reducers/proposition';
import PropositionAnswerCardLexique from 'locales/types/containers/propositionPage/propositionAnswerCard';

interface Props {
    proposition: Proposition;
}

const PropositionAnsweredMobile: FC<Props> = ({ proposition }) => {
    const lexique = useLexique<PropositionAnswerCardLexique>('containers.propositionPage.propositionAnsweredMobile');
    return (
        <div className="bc-proposition-answered-mobile">
            {proposition.answer_value !== null && !!proposition.answer_text && (
                <div className="bc-proposition-answered-mobile__answer-container">
                    <div className="bc-proposition-answered-mobile__title">{lexique.title}</div>
                    <div className="bc-proposition-answered-mobile__answer">{nl2br(proposition.answer_text)}</div>
                </div>
            )}
            <div className="bc-proposition-answered-mobile__control-buttons">
                <div className="bc-proposition-answered-mobile__control-title">{lexique.form.title}</div>
                {proposition.answer_value === 'yes' && (
                    <button
                        type="button"
                        className={classname({
                            'bc-proposition-answered-mobile__button': true,
                            'bc-proposition-answered-mobile__button--selected': true,
                        })}
                        disabled
                    >
                        {lexique.form.buttons.yes}
                    </button>
                )}
                {proposition.answer_value === 'discuss' && (
                    <button
                        type="button"
                        className={classname({
                            'bc-proposition-answered-mobile__button': true,
                            'bc-proposition-answered-mobile__button--selected': true,
                        })}
                        disabled
                    >
                        {lexique.form.buttons.discuss}
                    </button>
                )}
                {proposition.answer_value === 'no' && (
                    <button
                        type="button"
                        className={classname({
                            'bc-proposition-answered-mobile__button': true,
                            'bc-proposition-answered-mobile__button--selected': true,
                        })}
                        disabled
                    >
                        {lexique.form.buttons.no}
                    </button>
                )}
            </div>
        </div>
    );
};

export default PropositionAnsweredMobile;
