import 'core-js/stable';
import 'regenerator-runtime/runtime';
// Polyfill for safari < 12.0
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';

import { ActionCableProvider } from 'utils/hooks/useActionCable';
import './window';
import './css/dist/index.css';

import '@brandandcelebrities/kolkitten/css/style.css';

import configureGATracker from 'utils/ga';

import { conf } from 'config/env';
import theme from 'config/theme';
import App from 'containers/App';
import rootSaga from 'sagas';

import { store } from './api/store';

// import registerServiceWorker from './registerServiceWorker'
configureGATracker(conf.GATracker);

store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={store.getState()?.env?.locale}>
        <ActionCableProvider>
          <App />
        </ActionCableProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// Hot reloading
if (module && module.hot) {
  module.hot.accept();
}
