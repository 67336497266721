import React, { useState, useEffect, useCallback, memo } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import moment from 'moment';

import { load } from 'actions/home';
import { loadProfile, saveAutoUserToken } from 'actions/profile';
import { openOnboardingStatsSharing } from 'actions/user';
import { showModale } from 'actions/modale';

import InstaConnectLexique from 'locales/types/components/instaconnect';

import {
  InstaConnect,
  RenewingInstaConnect,
  ProfileStrengthMeter,
  RecapBlock,
  SocialStatsHomePage,
} from 'components/templates';
import { ProfileCard } from 'components/organisms';
import { fbc } from 'config/connect';
import { queryParameters, routes } from 'config/routes';

import { ON_REFRESHED_TOKENS, ON_REFRESHED_TOKENS_ERROR } from 'utils/fbc/FacebookBusinessConnect';
import { useDispatch, useLexique, useSelector } from 'utils/redux';

import HomeSlide from './homeSlide';

import styles from './HomePage.module.scss';

const INSTACONNECT_ANALYTICS = {
  onSubmit: {
    eventCategory: 'HomeSlide',
    eventAction: 'Connect',
    eventLabel: 'Add Instagram Connect',
  },
};

const HomePage: React.FC = () => {
  const [instaModalState, setInstaModalState] = useState({
    isRenewing: false,
    open: false,
  });
  const [fbcTokenAutoRefreshed, setFbcTokenAutoRefreshed] = useState(false);
  const dispatch = useDispatch();

  const lexique: {
    headTitle: string;
  } = useLexique('containers.homePage.homePage');
  const instaConnectLexique: InstaConnectLexique = useLexique('components.instaconnect');

  const { shoudReconectInstagram, statsSharing, fbcLoginStatus, noMoreInsta } = useSelector(
    ({
      router: {
        location: { search },
      },
      user: { statsSharing, isInstaconnectIgnored: noMoreInsta },
      fbc,
    }) => ({
      shoudReconectInstagram: search.includes(queryParameters.reconnectInstagram),
      noMoreInsta,
      fbcLoginStatus: fbc.loginStatus,
      statsSharing,
    }),
  );

  const onInstaConnectButtonClick = useCallback(() => {
    setInstaModalState((s) => ({ ...s, open: true }));
  }, []);

  const onCloseInstaConnectModal = useCallback(() => {
    setInstaModalState({
      open: false,
      isRenewing: false,
    });
    setFbcTokenAutoRefreshed(true);
  }, []);

  useEffect(() => {
    dispatch(load());
    dispatch(loadProfile());
  }, [dispatch]);

  useEffect(() => {
    if (shoudReconectInstagram) {
      dispatch(openOnboardingStatsSharing());
    }
  }, [dispatch, shoudReconectInstagram]);

  useEffect(() => {
    if (
      fbcLoginStatus && // FB.getLoginStatus checked
      statsSharing?.fromRouter === routes.login && // From Login form
      statsSharing?.tokenExpiresAt && // has instagram added
      !fbcTokenAutoRefreshed
    ) {
      const expireAt = moment(statsSharing?.tokenExpiresAt);
      const now = moment();

      fbc.once(ON_REFRESHED_TOKENS, async ({ tokens, id }) => {
        console.log('ON_REFRESHED_TOKENS', tokens, id);
        setFbcTokenAutoRefreshed(true);

        const token = tokens?.find((t) => t?.instagramBusinessId === statsSharing?.instagramBusinessId);
        if (token) {
          await dispatch(
            saveAutoUserToken({
              ...token,
              triggerMode: 'auto',
            }),
          );

          if (now.unix() >= expireAt.unix()) {
            dispatch(showModale({ body: 'FbcTokenSuccess' }));
          }
        }
      });

      fbc.once(ON_REFRESHED_TOKENS_ERROR, ({ status }) => {
        console.log('ON_REFRESHED_TOKENS_ERROR', status);

        if (
          !noMoreInsta && // user preference checkbox
          statsSharing?.isNeededByCustomer && // startDate and endDate of campaign
          expireAt.diff(now, 'days') < 15 // if validity token < 15 jours
        ) {
          setInstaModalState({
            isRenewing: true,
            open: true,
          });
        }
      });

      fbc.refreshTokens(true);
    }
  }, [fbcLoginStatus, statsSharing, dispatch, noMoreInsta, fbcTokenAutoRefreshed]);

  const cnRecap = cn(styles.block, styles.recap);

  const cnStats = cn(styles.block, styles.reverseOnMobile);

  return (
    <div>
      <Helmet>
        <title>{lexique.headTitle}</title>
      </Helmet>
      <div className={styles.home}>
        <div className={styles.block}>
          <div className={styles.left}>
            <ProfileCard />
          </div>
          <div className={styles.right}>
            <HomeSlide onInstaConnectButtonClick={onInstaConnectButtonClick} />
          </div>
        </div>
        <div className={cnRecap}>
          <RecapBlock />
        </div>
        <div className={cnStats}>
          <div className={styles.left}>
            <ProfileStrengthMeter />
          </div>
          <div className={styles.right}>
            <SocialStatsHomePage />
          </div>
        </div>
      </div>

      <InstaConnect
        modalOpened={!instaModalState.isRenewing && instaModalState.open}
        lexique={{
          title: instaConnectLexique.modal.new.title,
          text: instaConnectLexique.modal.new.content,
          submit: instaConnectLexique.modal.new.addInstagramBusiness,
        }}
        onClose={onCloseInstaConnectModal}
        analytics={INSTACONNECT_ANALYTICS}
      />
      <RenewingInstaConnect
        modalOpened={instaModalState.isRenewing && instaModalState.open}
        onClose={onCloseInstaConnectModal}
      />
    </div>
  );
};

export default memo(HomePage);
