import { combineReducers } from 'redux';

import analytics, { AnalyticsState } from './ui/analytics';
import homepage, { HomePageState } from './ui/homepage';
import menu, { MenuState } from './ui/menu';
import modal, { ModalState } from './ui/modal';
import modals, { ModalsState } from './ui/modals';
import propositionPage, { PropositionPageState } from './ui/propositionPage';
import socialStatsCards, { SocialStatsCardState } from './ui/socialStatsCards';
import modale, { ModaleState } from './ui/modale';
import snaform, { SnaFormState } from './ui/snaform';
import loader from './ui/loader';

export interface UIState {
    analytics: AnalyticsState;
    homepage: HomePageState;
    menu: MenuState;
    modal: ModalState;
    modals: ModalsState;
    propositionPage: PropositionPageState;
    socialStatsCards: SocialStatsCardState;
    modale: ModaleState;
    snaform: SnaFormState;
    loader: string[];
}

const ui = combineReducers<UIState>({
    analytics,
    homepage,
    menu,
    modal,
    modals,
    propositionPage,
    socialStatsCards,
    modale,
    snaform,
    loader,
});

export default ui;
