import React, { FC } from 'react';
import cn from 'classnames';
import './AddButton.scss';
import { ReactComponent as Plus } from 'assets/images/svg/plus-regular.svg';

interface Props {
    onClick: (...args: any[]) => void;
    className?: string;
    label: string;
}

const AddButton: FC<Props> = ({ onClick, className, label }) => {
    const classNames = cn('add-button', className);
    return (
        <button type="button" onClick={onClick} className={classNames}>
            <span className="add-button__plus">
                <Plus fill="#003057" />
            </span>
            <span className="add-button__label">{label}</span>
        </button>
    );
};

export default AddButton;
