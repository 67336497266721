import { env, ENV_PROD } from 'config/env';

interface GAAction {
    eventCategory?: string;
    eventAction?: string;
    eventLabel?: string;
}

export const sendGAEvent = (action: GAAction) => {
    window.ga('send', 'event', action);
};

export const callGAEvent = (eventCategory: string, eventAction?: string, eventLabel?: string) => {
    if (env !== ENV_PROD) {
        console.log('[LOG event]', eventCategory, eventAction, eventLabel);
    }
    sendGAEvent({
        eventCategory,
        eventAction,
        eventLabel,
    });
};
