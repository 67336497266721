import MessagingLexique from 'locales/types/containers/messagingPage';

const messaging: MessagingLexique = {
  headTitle: 'Messaging - Kolsquare',
  noConversations: 'No message here!',
  all: {
    title: 'All',
    noResult: 'No message here!'
  },
  unread: {
    title: 'Unread',
    noResult: 'No message here!'
  },
  draft: {
    title: 'Draft',
    noResult: 'No drafts. All your unsent messages will appear here',
  },
  removeDraftModal: {
    title: 'Delete draft',
    message: 'Are you sure you want to delete this draft',
    yes: `Yes, delete`,
		no: `No, cancel`,
  },
  chat: {
    goTo: 'Go to...',
    me: 'You',
    draft: 'Draft',
    inputPlaceholder: 'Message...',
    pickFile: 'Attach files',
    pickEmoji: 'Insert emoji',
    link: 'Insert link',
    unLink: 'Delete link',
    sendButton: 'Send',
    lastMessageAt: 'Last message',
    noConversations: 'No conversations',
    brief: {
      agree: 'I am interested',
      disagree: 'I am not interested',
    },
    notification: {
      agreed: `You have declared that you are interested! \n\nYou can now compose a message.`,
      disagreed: `You are not interested by the offer of `,
      statsSharing: {
        title: `Help us complete our reporting.`,
        subTitle: `{kolName} we would like to access the statistics of your Instagram posts and stories via Kolsquare in order to complete our reporting and save time.`,
        linkDescription: `No need to share screenshots, you can safely give us access by following the link :`,
        linkLabel: `Share my statistics`,
        statsSharingApproval: 'Sharing of my Instagram statistics: @{{username}}',
        statsSharingHalted: `The sharing of statistics was interrupted because the connection to the Instagram @{{username}} was lost.`,
      },
    },
  },
  panelHeader: {
    title: 'Conversations',
    search: 'Search for a contact or brand name',
  },
  attachmentError: {
    global: 'Error while retrieving the file',
    size: `Unable to upload "{{fileName}}" because the file is too large. Please attach a file under 10Mb`,
    totalSize: `Unable to send this message because the files are too large. Please attach a total of files under 10Mb`,
    type: `Unable to upload "{{fileName}}" because the file format is not supported. Please attach a file in Pdf, JPEG, PNG, Word, PowerPoint or Excel format`,
    maxFiles: `Max files exceeded: "{{maxFiles}}"`,
  },
}

export default messaging;
