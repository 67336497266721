import React, { FC, useCallback, useState, useMemo, memo } from 'react';

import { Networks, SOCIAL_NETWORKS } from 'constants/networks';
import { SocialNetwork } from 'reducers/ui/socialStatsCards';
import { Input, Button } from '@brandandcelebrities/kolkitten';
import { Modal, SocialPicto } from 'components/atoms';
import InstaConnectLexique from 'locales/types/components/instaconnect';
import { useLexique } from 'utils/redux';
import { SocialStatsModalLexique } from 'locales/types/containers/shared/SocialStatsModalLexique';
import 'components/templates/modales/SnaForm/snaform.scss';

import InstaConnect from '../InstaConnect';

interface ModalFields {
    connect: boolean;
    input: boolean;
}

interface Props {
    onCloseModal: (type: Networks) => void;
    type: Networks;
    socialStatsCard: SocialNetwork;
    onClickFetchUrl: (type: Networks, url: string) => void;
    // handleChange: (type: Networks, value: string) => void;
}

const MODAL_FIELDS = {
    facebook: {
        connect: false,
        input: true,
    },
    instagram: {
        connect: true,
        input: false,
    },
    twitter: {
        connect: false,
        input: true,
    },
    youtube: {
        connect: false,
        input: true,
    },
    tiktok: {
        connect: false,
        input: true,
    },
};

const SocialStatsCardModal: FC<Props> = ({ onClickFetchUrl, onCloseModal, type, socialStatsCard }) => {
    const [inputValue, setInputValue] = useState<string>(socialStatsCard.url);
    const lexique = useLexique<SocialStatsModalLexique>('containers.shared.socialStatsContainer.modal');
    const instaConnectLexique = useLexique<InstaConnectLexique>('components.instaconnect');
    const onChange = useCallback(
        ({ value }) => {
            setInputValue(value);
        },
        [setInputValue],
    );

    const onClose = useCallback(() => {
        onCloseModal(type);
    }, [onCloseModal, type]);

    const onInputValidated = useCallback(() => {
        onClickFetchUrl(type, inputValue);
    }, [type, inputValue, onClickFetchUrl]);

    const modalFields: ModalFields = useMemo(() => MODAL_FIELDS[type], [type]);

    if (modalFields.input) {
        return (
            <Modal onClose={onClose} display={socialStatsCard.modal.display}>
                <div className="bc-modal-content__title bc-modal-network__title">
                    <h3>{lexique.title[type]}</h3>
                </div>
                <div>
                    <div className="bc-modal-content__text bc-modal-connect-network__text">
                        <p>{lexique.text[type]}</p>
                    </div>
                    <div className="bc-modal-addsna__input-list">
                        <Input
                            id={type}
                            className={`input-${type}`}
                            autoFocus
                            onEnterPress={onInputValidated}
                            placeholder={lexique.form.url[type].placeholder}
                            value={inputValue}
                            onChange={onChange}
                            error={socialStatsCard.url !== '' && socialStatsCard.error}
                            errorText={lexique.form.url[type].errorTypes[socialStatsCard.errorType]}
                            iconLeft={<SocialPicto network={type} fill={SOCIAL_NETWORKS[type].inverseColor} />}
                        />
                    </div>
                    <div className="bc-modal-content__btn-container bc-modal-content__btn-container--fetchurl">
                        <Button
                            id={`button-fetch-${type}`}
                            label={lexique.form.send}
                            loading={socialStatsCard.loading}
                            onClick={onInputValidated}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
    return (
        <InstaConnect
            onClose={onClose}
            modalOpened={socialStatsCard.modal.display}
            analytics={{
                onSubmit: {
                    eventCategory: 'Dashboard',
                    eventAction: 'Connect',
                    eventLabel: 'Add Instagram Connect',
                },
            }}
            lexique={{
                title: instaConnectLexique.modal.new.title,
                text: instaConnectLexique.modal.new.content,
                submit: lexique.form.send,
            }}
        />
    );
};

export default memo(SocialStatsCardModal);
