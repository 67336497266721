/* eslint-disable react/jsx-props-no-spreading */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Slide } from 'react-slideshow-image';
import orderBy from 'lodash.orderby';
import flatten from 'lodash.flatten';

import { SnasState } from 'reducers/snas';
import { supportedSocialNetwork } from 'constants/networks';
import { UserState } from 'reducers/user';
import CredibilityScore from './slides/CredibilityScore';
import EngagementRate from './slides/EngagementRate';
import DataPending from './slides/DataPending';
import Placeholder from './Placeholder';

import './HomeSlide.scss';

const properties = {
    duration: 6500,
    transitionDuration: 500,
    infinite: true,
    autoplay: true,
    arrows: true,
};

interface Props {
    snas: SnasState;
    user: UserState;
    onInstaConnectButtonClick: () => void;
}

class HomeSlide extends PureComponent<Props> {
    generateSlidesOrPlaceholder() {
        const { snas, user } = this.props;

        const listSnas = flatten(supportedSocialNetwork.map(({ id }) => snas[id].map((sn) => sn)));
        const sortedSnas = orderBy(listSnas, ['community_count'], ['desc']);

        const slideList = [];
        if (user.qualityScore === null) {
            if (snas.instagram && snas.instagram.length) {
                slideList.push({ type: 'DataPending' });
            } else {
                slideList.push({ type: 'Placeholder' });
            }
        } else if (snas.instagram && snas.instagram.length) {
            slideList.push({ type: 'CredibilityScore' });
        } else {
            slideList.push({ type: 'Placeholder' });
        }

        sortedSnas
            .filter((sna) => sna.stats && sna.stats.engagement_rate_progression >= 1)
            .map((sna) => slideList.push({ type: 'EngagementRate', sna }));

        if (sortedSnas.length > 0 && sortedSnas.filter((s) => s.community_count === null).length >= 1) {
            slideList.push({ type: 'div' });
        }

        if (slideList.length === 1) {
            return <div className="bc-home__slide-container">{this.generateSlides(slideList)}</div>;
        }

        return (
            <div className="bc-home__slide-container">
                <Slide {...properties}>{this.generateSlides(slideList)}</Slide>
            </div>
        );
    }

    generateSlides(slideList) {
        return slideList.map((slide) => {
            switch (slide.type) {
                case 'DataPending':
                    return <DataPending key="DataPending" />;
                case 'Placeholder':
                    return (
                        <Placeholder
                            key="Placeholder"
                            onInstaConnectButtonClick={this.props.onInstaConnectButtonClick}
                        />
                    );
                case 'CredibilityScore':
                    return <CredibilityScore key="CredibilityScore" />;
                case 'EngagementRate':
                    return (
                        <EngagementRate
                            key={slide.sna.id}
                            network={slide.sna.label}
                            firstValue={slide.sna.stats.avg_engagement_rate_per_post}
                            secondValue={slide.sna.stats.engagement_rate_progression}
                        />
                    );
                default:
                    return <DataPending key="DataPending" />;
            }
        });
    }

    render() {
        return this.generateSlidesOrPlaceholder();
    }
}

const mapStateToProps = ({ user, snas }) => ({
    user,
    snas,
});

export default connect(mapStateToProps, null)(HomeSlide);
