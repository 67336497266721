import ProfilePageLexique from 'locales/types/containers/profilePage';

export const profilePageLexique: ProfilePageLexique = {
    headTitle: 'Mon Profil - Kolsquare',
    pageTitle: `Profil`,
    titleInfos: `Vos informations personnelles`,
    titleActivity: `Votre activité`,
    firstName: `Prénom`,
    lastName: `NOM`,
    stageName: `Pseudo sur les réseaux`,
    errorRequired: `Veuillez remplir votre Prénom NOM ou votre pseudo sur les réseaux`,
    errorWebsiteInvalid: `L'adresse de votre site web est invalide.`,
    gender: {
        male: `Homme`,
        female: `Femme`,
        other: `Autre`,
    },
    birthdate: {
        label: `Date de naissance`,
        day: `Jour`,
        month: `Mois`,
        months: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
        ],
        year: `Année`,
    },
    nationality: `Nationalité (2 max)`,
    phone: {
        label: `Téléphone`,
        placeholder: `+33 X XX XX XX XX`,
    },
    activities: `Activités (3 max)`,
    influenceThemes: `Thèmes d'influence (5 max)`,
    website: `Site web officiel`,
    livingPlace: `Ville de résidence`,
    bio: {
        label: `Bio (80 caractères maximum)`,
        placeholder: `Exemple : Influence fitness et coach sportif`,
    },
    description: {
        label: `Description (histoire, parcours, thèmes, activités, ...)`,
        placeholder: `Exemple : Je suis journaliste, coach sportif, professeur de fitness (... et jeune maman !). J'ai débuté en tant que journaliste sportif, puis j'ai décidé de m'investir dans ma passion du fitness en acquérant un diplôme de coach sportif. En 2011, je crée une série Web qui propose des entraînements de fitness accessibles à tous. Enfin, en 2012, je deviens ambassadrice pour une marque de sportswear. Je souhaite réaliser davantage de partenariats en lien avec le sport, le lifestyle, la nutrition saine ainsi que la famille.`,
    },
    save: `Enregistrer`,
    saved: 'Vos informations ont été sauvegardées',
};

export default profilePageLexique;
