import socialStatsCards from './socialStatsCards';
import modales from './modales';
import toasts from './toasts';
import postEstimationValueCards from './postEstimationValueCards';
import tooltips from './tooltips';
import instaconnect from './instaconnect';
import organisms from './organisms';
import atoms from './atoms';

export default {
    socialStatsCards,
    postEstimationValueCards,
    modales,
    toasts,
    tooltips,
    instaconnect,
    organisms,
    atoms,
};
