import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Button2 } from '@brandandcelebrities/kolkit';

import routes from 'config/routes';
import { ReactComponent as NoStatGear } from 'assets/images/svg/no-stat-gear.svg';

import '../HomeSlide.scss';
import { useLexique } from 'utils/redux';

const DataPending: FC<RouteComponentProps> = ({ history }) => {
    const lexique = useLexique<any>('containers.homePage.homeSlide.slides.dataPending');

    return (
        <div className="bc-home__slide-container__slide">
            <div className="bc-home__slide-container__slide__stats">
                <NoStatGear />
            </div>
            <div className="bc-home__slide-container__slide__title">{lexique.title}</div>
            <div className="bc-home__slide-container__slide__text">
                {lexique.text}
                <div className="bc-home__slide-container__slide__text__button">
                    <Button2
                        label={lexique.button}
                        onClick={() => history.push(routes.profile)}
                        size="small"
                    />
                </div>
            </div>
        </div>
    );
};

export default withRouter(DataPending);
