import React, { memo, useCallback, useMemo } from 'react';
import { Button } from '@brandandcelebrities/kolkit';

import Markdown from 'components/atoms/Markdown';
import { RequestStatsModalLexique } from 'locales/types/components/modales';
import { callGAEvent } from 'utils/googleAnalytics';
import { useLexique, useSelector } from 'utils/redux';

import styles from './StepThanks.module.scss';

interface Props {
    from: string;
    onButtonClick: () => void;
}

const StepThanks: React.FC<Props> = ({ from, onButtonClick }) => {
    const lexiqueStatsSharingWithoutRequest = useLexique<
        RequestStatsModalLexique['thankingStatsSharingWithoutRequest']
    >('components.modales.requestStats.thankingStatsSharingWithoutRequest');
    const lexiqueStatsSharingWithRequest = useLexique<RequestStatsModalLexique['thankingStatsSharingRequestFromBrand']>(
        'components.modales.requestStats.thankingStatsSharingRequestFromBrand',
    );

    const { user, brandName, requestsCount } = useSelector(({ user }) => ({
        user,
        brandName: user.statsSharing.brandName,
        requestsCount: user.statsSharing.requestsCount,
    }));

    const { title, text, buttonLabel } = useMemo(() => {
        if (brandName) {
            // eslint-disable-next-line no-nested-ternary
            const textCategory = requestsCount === 0 ? 'textAlreadyActivated' : requestsCount > 1 ? 'textMany' : 'text';
            return {
                title: lexiqueStatsSharingWithRequest.title.replace('{pseudo}', user.pseudo || user.firstName),
                text: lexiqueStatsSharingWithRequest[textCategory]
                    .replace('{brandName}', brandName)
                    .replace('{length}', `${requestsCount - 1}`),
                buttonLabel: lexiqueStatsSharingWithRequest.button,
            };
        }
        return {
            title: lexiqueStatsSharingWithoutRequest.title.replace('{pseudo}', user.pseudo || user.firstName),
            text: lexiqueStatsSharingWithoutRequest.text,
            buttonLabel: lexiqueStatsSharingWithoutRequest.button,
        };
    }, [
        lexiqueStatsSharingWithoutRequest,
        lexiqueStatsSharingWithRequest,
        user.pseudo,
        user.firstName,
        brandName,
        requestsCount,
    ]);

    const onClickValidate = useCallback(() => {
        onButtonClick();
        callGAEvent(from, 'Next', 'Thanks');
    }, [onButtonClick, from]);

    return (
        <div className={styles.container}>
            <h3>
                <Markdown>{title}</Markdown>
            </h3>
            <Markdown>{text}</Markdown>
            <Button fullWidth size="big" className={styles.button} onClick={onClickValidate}>
                {buttonLabel}
            </Button>
        </div>
    );
};

export default memo(StepThanks);
