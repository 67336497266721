import MessagingLexique from 'locales/types/containers/messagingPage';

const messaging: MessagingLexique = {
  headTitle: 'Messagerie - Kolsquare',
  noConversations: 'Aucun message par ici!',
  all: {
    title: 'Tous',
    noResult: `Aucun message par ici!`,
  },
  unread: {
    title: 'Non lus',
    noResult: 'Aucun message par ici!'
  },
  draft: {
    title: 'Brouillons',
    noResult: 'Aucun brouillon. Tous vos messages non envoyés apparaîtront ici',
  },
  removeDraftModal: {
    title: 'Supprimer un brouillon',
    message: 'Êtes-vous sûr(e) de vouloir supprimer ce brouillon',
    yes: `Oui, supprimer`,
		no: `Non, annuler`,
  },
  chat: {
    goTo: 'Accéder à...',
    me: 'Vous',
    draft: 'Brouillon',
    inputPlaceholder: 'Message...',
    pickFile: 'Joindre des fichiers',
    pickEmoji: 'Insérer un émoji',
    link: 'Insérer un lien',
    unLink: 'Supprimer le lien',
    sendButton: 'Envoyer',
    lastMessageAt: 'Dernier message',
    noConversations: 'Conversations vide',
    brief: {
      agree: 'Je suis intéressé',
      disagree: 'Je ne suis pas intéressé',
    },
    notification: {
      agreed: `Vous avez déclaré être intéressée ! \n\nVous pouvez désormais rédiger un message.`,
      disagreed: `Vous n'êtes pas intéressé par la proposition de `,
      statsSharing: {
        title: `Aidez-nous à compléter notre reporting.`,
        subTitle: `{kolName} nous souhaiterions obtenir les statistiques de vos posts et stories Instagram via Kolsquare. Cela nous permettra de compléter notre rapport de statistiques et gagner du temps.`,
        linkDescription: `Plus besoin de capture d'écran, donnez-nous accès en toute sécurité en suivant le lien:`,
        linkLabel: `Partage des statistiques`,
        statsSharingApproval: 'Partage de mes statistiques Instagram: @{{username}}',
        statsSharingHalted: `Le partage des statistiques a été interrompu car la connexion avec l’Instagram @{{username}} a été perdue.`,
      },
    },
  },
  panelHeader: {
    title: 'Conversations',
    search: 'Rechercher un interlocuteur ou une entreprise',
  },
  attachmentError: {
		global: 'Erreur lors de la récupération du fichier',
		size: `Impossible d’importer "{{fileName}}", car le fichier est trop volumineux. Merci de joindre un fichier de moins de 10Mo`,
		totalSize: `Impossible d'envoyer le message car les fichier sont trop volumineux. Merci de joindre un total de fichiers de moins de 10Mo`,
		type: `Impossible d’importer "{{fileName}}", car le format du fichier n’est pas géré. Merci de joindre un fichier au format {{acceptedFormat}}`,
		maxFiles: `Le nombre maximal est "{{maxFiles}}"`,
	},
}

export default messaging;