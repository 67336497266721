import React, { memo } from 'react';

import Card, { CardColors } from 'components/atoms/Card';
import SocialName from 'components/molecules/SocialName';
import './PostEstimationValueCard.scss';
import { Networks } from 'constants/networks';

interface Props {
    username: string;
    network: Networks;
    min?: string;
    max?: string;
    fallbackMsg: string;
}

const PostEstimationValueCard: React.SFC<Props> = memo(({ username, network, min, max, fallbackMsg }) => (
    <article className="bc-post-estimations-card">
        <SocialName name={username} network={network} />
        {min && max ? (
            <div className="bc-post-estimations-card-prices">
                <Card color={CardColors.blue} label="Min" children={min} />
                <Card color={CardColors.blue} label="Max" children={max} />
            </div>
        ) : (
            <p className="bc-post-estimations-card-no-prices">{fallbackMsg}</p>
        )}
    </article>
));

export default PostEstimationValueCard;
