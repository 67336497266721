import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';

const onboardingPageLexique: OnboardingPageLexique = {
    header: 'Create my profile',
    steps: {
        infos: {
            title: 'Complete your profile to get more views',
            placeholders: {
                firstName: 'First name *',
                lastName: 'Last name *',
                stageName: 'Username *',
                gender: 'Gender',
                birthdate: 'Birthday *',
                nationality: 'Nationality *',
                livingAddress: 'Current city *',
            },
            errors: {
                requiredNameOrPseudo: 'Please fill your username on social networks and/or your full name',
                required: 'Please fill your username on social networks and/or your full name',
            },
        },
        activity: {
            title: 'Tell us more about your activity',
            labels: {
                activities: 'Activities (max. 3)',
                influenceThemes: 'Topics of influence (max. 5)',
                website: 'Official website (optional)',
            },
            placeholders: {
                activities: 'Select...',
                influenceThemes: 'Select...',
                website: 'http://',
            },
        },
        networksInstagramOnly: {
            title: 'Connect your Instagram',
            helpText: 'Connect your Instagram to share your stats with {brandName}',
            helpTextMany: 'Connect your Instagram to share your stats with {brandName} and {length} brand(s)!',
        },
        networks: {
            title: 'Connect your social media',
            titleInstaconnected: 'Great ! Does your influence goes beyond Instagram ?',
            helpText: 'Add at least one to start getting offers.',
            skipAndCreate: 'Skip and Create my profile',
            footerText: 'You can always add more later on',
            cancel: 'Cancel',
            instagram: {
                label: 'Via your Facebook account',
                errors: {
                    invalidUrl: `Your Instagram account URL is invalid`,
                    privateAccount: 'This account is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This account does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            youtube: {
                label: 'Via your Google account',
                inputTitle: 'URL of the channel',
                inputPlaceholder: 'https://www.youtube.com/channel/kolsquare',
                errors: {
                    invalidUrl: `Your YouTube channel URL is invalid`,
                    privateAccount: `This channel is private`,
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This channel does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            facebook: {
                inputTitle: 'URL of the page',
                inputPlaceholder: 'https://www.facebook.com/Kolsquare',
                errors: {
                    invalidUrl: `Your Facebook page URL is invalid`,
                    privateAccount: 'This page is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This page does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            tiktok: {
                inputTitle: `Username`,
                inputPlaceholder: '@Kolsquare',
                errors: {
                    invalidUrl: `Your Tiktok account URL is invalid`,
                    privateAccount: 'This account is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This account does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            twitter: {
                inputTitle: `Username`,
                inputPlaceholder: 'Kolsquare',
                errors: {
                    invalidUrl: `Your Twitter account URL is invalid`,
                    privateAccount: 'This account is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This account does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            pinterest: {
                inputTitle: `URL`,
                inputPlaceholder: 'https://www.pinterest.fr/kolsquare',
                errors: {
                    invalidUrl: `Your Pinterest account URL is invalid`,
                    privateAccount: 'This account is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This account does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            snapchat: {
                inputTitle: `Username`,
                inputPlaceholder: 'kolsquare',
                errors: {
                    invalidUrl: `Your Snapchat account URL is invalid`,
                    privateAccount: 'This account is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This account does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            twentyone_buttons: {
                inputTitle: `URL`,
                inputPlaceholder: 'https://www.21buttons.com/kolsquare',
                errors: {
                    invalidUrl: `Your 21 buttons account URL is invalid`,
                    privateAccount: 'This account is private',
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This account does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            twitch: {
                inputTitle: `URL`,
                inputPlaceholder: 'https://www.twitch.tv/kolsquare',
                errors: {
                    invalidUrl: `Your Twitch channel URL is invalid`,
                    privateAccount: `This channel is private`,
                    recordNotUnique: 'Sorry, an error has occurred',
                    unknownAccount: 'This channel does not exist',
                    errorUnknown: 'Sorry, an error has occurred',
                },
            },
            status: {
                set: 'Connected',
                unset: 'Connect',
                editing: 'OK',
                validating: 'OK',
            },
            see: {
                more: 'See more',
                less: 'See less',
            },
        },
        instaConnect: {
            backButton: 'Back',
            header: 'Instagram connect',
            title: 'Some steps are needed',
            text: "Let's check that everything is set up 👀",
            titleFinished: 'Okay ! Everything is set up to connect your Instagram 🎉',
            positiveButton: 'Yes',
            negativeButton: "I don't know",
            instaAccountType: {
                question: 'Do you have a Creator or Business insta account ?',
                modal: {
                    title: 'How to change your account type',
                    text:
                        'Instagram has strengthened its login process and requires a pro or creator account to use Kolsquare. In your account settings, go to "Upgrade to a pro account" and follow the steps.',
                    imgPath: '/imgs/onboarding/instaconnect-01.png',
                    primaryActionLabel: 'Open Instagram',
                },
            },
            fbAccountType: {
                question: 'Do you have a Business Facebook page on which your are administrator ?',
                modal: {
                    title: 'How to create a Facebook Business Page',
                    text: 'Instagram uses Facebook Pay for payments. You need a page to receive money.',
                    imgPath: '/imgs/onboarding/instaconnect-02.png',
                    primaryActionLabel: 'Create my Facebook page',
                },
            },
            linkedAccount: {
                question: 'Is your facebook page linked to your Instagram account (creator or business) ?',
                modal: {
                    title: 'How to link your Facebook page to your Instagram account',
                    imgPath: '/imgs/onboarding/instaconnect-03.png',
                    primaryActionLabel: 'Link my account on Facebook',
                },
            },
            modalSecondaryActionLabel: "It's done!",
            fbConnect: 'Connect my account via Facebook connect',
            fbConnectWarning: '⚠️  Be sure to give all the authorizations, Kolsquare needs those to work well.',
            error: {
                title: 'Hmm... There seems to be an error 🤔',
                text: 'We were unable to connect your Instagram account to Kolsquare. Please check that you have completed all the steps, and try again. ',
                instagramNotFound: `The Instagram account that was selected does not match the Instagram account that was previously added. Please start over and select the correct Instagram account by clicking "Change Settings" after the Login step.`,
                retry: 'Retry',
                goBack: 'Connect another account',
            },
            submit: 'Save',
        },
        instaStatsSharing: {
            shareButton: `Share my stats`,
            skipButton: `Skip this step`,
        },
    },
    next: 'Continue',
    validate: 'Create my profile',
};

export default onboardingPageLexique;
