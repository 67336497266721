// Is event triggered outside DOM
export const isEventOutside = (e, componentNode) => {
    let current = e.target;

    while (current.parentNode) {
        if (current === componentNode) return false;
        current = current.parentNode;
    }

    return current === document;
};
