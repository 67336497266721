import { createAction } from 'redux-actions';

import * as types from 'constants/analytics';

export const load = createAction(types.LOAD);

export const setSortFilter = createAction(types.SET_SORT_FILTER, (label, options) => ({ label, options }));

export const onChangeSearch = createAction(types.ON_CHANGE_SEARCH, (tags) => ({
    tags,
}));
export const onChangeSortFilter = createAction(types.ON_CHANGE_SORT_FILTER, (label) => ({ label }));
export const onChangeTimeRangeFilter = createAction(types.ON_CHANGE_TIME_RANGE_FILTER, (label) => ({ label }));
export const onChangeSocialNetworksFilter = createAction(
    types.ON_CHANGE_SOCIAL_NETWORKS_FILTER,
    (socialNetwork) => socialNetwork,
);

export const onChangePage = createAction(types.ON_CHANGE_PAGE, (page) => ({
    page,
}));

export const fetchHeaderSuccess = createAction(types.FETCH_HEADER_SUCCESS);
export const fetchHeaderFailure = createAction(types.FETCH_HEADER_FAILURE, (error) => ({ error }));

// Posts
export const loadPosts = createAction(types.LOAD_POSTS);
export const fetchPostsSuccess = createAction(types.FETCH_POSTS_SUCCESS);
export const fetchPostsFailure = createAction(types.FETCH_POSTS_FAILURE, (error): any => ({ error }));

// Community
export const loadCommunity = createAction(types.LOAD_COMMUNITY);
export const fetchCommunitySuccess = createAction(types.FETCH_COMMUNITY_SUCCESS);
export const fetchCommunityFailure = createAction(types.FETCH_COMMUNITY_FAILURE, (error) => ({ error }));

// Statistics
export const loadStatistics = createAction(types.LOAD_STATISTICS);
export const onChangeTimeFilter = createAction(types.ON_CHANGE_TIME_FILTER, (label) => ({ label }));
export const fetchSnaDataChartSuccess = createAction(types.FETCH_CHART_SUCCESS);
export const fetchSnaDataChartFailure = createAction(types.FETCH_CHART_FAILURE, (error) => ({ error }));

// Top hashtags
export const fetchPostsTopHashtags = createAction(types.FETCH_POSTS_TOP_HASHTAGS);
export const fetchPostsTopHashtagsSuccess = createAction(types.FETCH_POSTS_TOP_HASHTAGS_SUCCESS);
export const fetchPostsTopHashtagsFailure = createAction(types.FETCH_POSTS_TOP_HASHTAGS_FAILURE, (error) => ({
    error,
}));

// Top/Flop Posts
export const loadTopFlopPosts = createAction(types.LOAD_TOP_FLOP_POSTS);
export const fetchTopFlopPostsSuccess = createAction(types.FETCH_TOP_FLOP_POSTS_SUCCESS);
export const fetchTopFlopPostsFailure = createAction(types.FETCH_TOP_FLOP_POSTS_FAILURE, (error) => ({ error }));
