/* eslint-disable */

export default (tracker) => {
    if (tracker === '') return;
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
            i[r] ||
            function () {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date().getTime());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        m && m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    window.ga('create', tracker, 'auto');
    // window.ga('send', 'pageview');
    // window.ga('send', 'event', {
    //   'eventCategory': 'Onboarding',
    //   'eventAction': `Next`,
    //   'eventLabel': `Welcome`,
    // })
};
