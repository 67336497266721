import { takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { ActionMeta } from 'redux-actions';

export function* watchReactRouter() {
    yield takeEvery(LOCATION_CHANGE, function* (action: ActionMeta<any, any>) {
        window.ga('set', 'page', action.payload.pathname);
        window.ga('send', 'pageview');
        yield;
    });
}
