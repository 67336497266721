import React, { useMemo, useEffect } from 'react';
import cn from 'classnames';
import { UnconfirmedEmailBanner } from 'components/organisms';
import useSticky from 'utils/hooks/useSticky';
import { useSelector } from 'utils/redux';
import { AppState } from 'reducers';
import { UserState } from 'reducers/user';
import styles from './BannerContainer.module.scss';

interface Props {
    onHasBannerChange?: Function;
}

const BannerContainer: React.FC<Props> = ({ onHasBannerChange }: Props) => {
    const isSticky = useSticky();
    const bannerContainerCn = cn(styles.bannerContainer, {
        [styles.bannerContainerSticky]: isSticky,
    });

    const { userId, isEmailConfirmed, isTrialOver } = useSelector<UserState>((state: AppState) => state.user);

    const displayUnconfirmedEmailBanner = useMemo(() => {
        return !!userId && !isEmailConfirmed && !isTrialOver;
    }, [isEmailConfirmed, isTrialOver, userId]);

    useEffect(() => {
        onHasBannerChange(displayUnconfirmedEmailBanner);
    }, [displayUnconfirmedEmailBanner, onHasBannerChange]);

    if (!displayUnconfirmedEmailBanner) return null;
    return (
        <div className={bannerContainerCn}>
            <UnconfirmedEmailBanner />
        </div>
    );
};

BannerContainer.defaultProps = {
    onHasBannerChange: (): boolean => false,
};

export default BannerContainer;
