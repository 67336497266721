export default {
    website: 'Website',
    other_networks: 'Other netwoks',
    instagram: 'Instagram',
    instagram_stories: ' Instagram stories',
    youtube: 'Youtube',
    tiktok: 'Tiktok',
    facebook: 'Facebook',
    twitter: 'Twitter',
    snapchat: 'Snapchat',
    pinterest: 'Pinterest',
    twentyone_buttons: '21buttons',
    twitch: 'Twitch',
    blog: 'Blog',
};
