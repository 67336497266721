import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as ANALYTICS from 'constants/analytics';
import * as ENV from 'constants/env';
import { AnyAction } from 'redux';
import { GlobalFilters, TimeRange, SortFilters } from 'types/Filters';
import { Networks, SnaDataChartFetch } from 'types/snas';
import { FetchPosts } from 'types/fetchApi';
import { pushSna } from 'utils/snas';

export interface Post {
    id: string;
    published_at: string;
    avg_engagement: {
        absolute: number;
        percent: number;
    };
    engagement_details: {
        [key: string]: number;
    };
    type?: string;
    social_network: Networks;
    picture_url: string;
    content: string;
}

export interface TopFlopPosts {
    loading: boolean;
    filter?: TimeRange;
    topPosts: FetchPosts[];
    flopPosts: FetchPosts[];
    error: string | null;
}

export interface Hashtag {
    key: string;
    engagement_rate: number;
    comments: number;
    likes: number;
}

export interface SearchCard {
    search: any[];
    sortFilter: SortFilters;
}

export interface AnalyticsHeader {
    engagement_4m1m: any;
    accounts: any[];
}

export interface AnalyticsPosts {
    globalFilters: GlobalFilters;
    searchCard: SearchCard;
    page: number;
}

export interface AnalyticsStats {
    filter: string;
    socialNetwork: SnaDataChartFetch[];
}

export interface AnalyticsHashtags {
    loading: boolean;
    filter: string;
    topHashtags: Hashtag[];
    error: null;
}

export interface CodeValue {
    code: string;
    value: number;
}

export interface City extends CodeValue {
    name: string;
}

export interface Country extends CodeValue {
    cities: City[];
}

export interface GenderPerAge {
    slice: string;
    M: number;
    F: number;
}

export interface CommunityStats {
    community_count?: number;
    genders_per_age?: GenderPerAge[];
    countries?: Country[];
    top_countries?: Country[];
}

export interface AnalyticsState {
    header: AnalyticsHeader;
    posts: AnalyticsPosts;
    topFlopPosts: TopFlopPosts;
    stats: AnalyticsStats;
    community: CommunityStats;
    hashtags: AnalyticsHashtags;
}

const initialState: AnalyticsState = {
    header: {
        engagement_4m1m: null,
        accounts: [],
    },
    posts: {
        globalFilters: {
            socialNetworksFilter: {},
            timeRangeFilter: TimeRange.oneMonth,
        },
        searchCard: {
            search: [],
            sortFilter: {
                selectedOption: 'most_engaging',
                options: ['most_recent', 'most_liked', 'most_engaging'],
            },
        },
        page: 0,
    },
    stats: {
        filter: TimeRange.oneYear,
        socialNetwork: [],
    },
    community: {},
    hashtags: {
        loading: true,
        filter: TimeRange.oneYear,
        topHashtags: [],
        error: null,
    },
    topFlopPosts: {
        loading: true,
        filter: TimeRange.oneYear,
        topPosts: [],
        flopPosts: [],
        error: null,
    },
};

const reducers = {
    [ENV.FETCH_APP_INIT_SUCCESS]: (state: AnalyticsState, { payload: { response } }: AnyAction) =>
        update(state, {
            posts: {
                globalFilters: {
                    socialNetworksFilter: {
                        $merge: response.snas.reduce((accumulator, currentValue) => {
                            accumulator[currentValue.uid] = {
                                id: currentValue.id,
                                uid: currentValue.uid,
                                label: currentValue.label,
                                username: currentValue.username,
                                selected: false,
                            };
                            return accumulator;
                        }, {}),
                    },
                },
            },
        }),
    [ANALYTICS.FETCH_HEADER_SUCCESS]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            header: {
                $merge: {
                    ...payload,
                },
            },
        }),
    [ANALYTICS.ON_CHANGE_SEARCH]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            posts: {
                searchCard: {
                    $merge: {
                        search: payload.tags,
                    },
                },
                page: { $set: 0 },
            },
        }),
    [ANALYTICS.SET_SORT_FILTER]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            posts: {
                searchCard: {
                    sortFilter: {
                        $merge: {
                            selectedOption: payload.label,
                            options: payload.options,
                        },
                    },
                    // page: { $set: 0 },
                },
            },
        }),
    [ANALYTICS.ON_CHANGE_SORT_FILTER]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            posts: {
                searchCard: {
                    sortFilter: {
                        $merge: {
                            selectedOption: payload.label,
                        },
                    },
                    // page: { $set: 0 },
                },
            },
        }),
    [ANALYTICS.ON_CHANGE_SOCIAL_NETWORKS_FILTER]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            posts: {
                globalFilters: {
                    socialNetworksFilter: {
                        $merge: {
                            [payload.uid]: payload,
                        },
                    },
                },
                page: { $set: 0 },
            },
        }),
    [ANALYTICS.ON_CHANGE_TIME_RANGE_FILTER]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            posts: {
                globalFilters: {
                    $merge: {
                        timeRangeFilter: payload.label,
                    },
                },
                page: { $set: 0 },
            },
        }),
    [ANALYTICS.ON_CHANGE_PAGE]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            posts: {
                page: { $set: payload.page },
            },
        }),
    [ANALYTICS.FETCH_COMMUNITY_SUCCESS]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            $merge: {
                community: payload.community,
            },
        }),
    [ANALYTICS.FETCH_CHART_SUCCESS]: (state: AnalyticsState, { payload }: AnyAction) => ({
        ...state,
        stats: {
            ...state.stats,
            socialNetwork: pushSna<SnaDataChartFetch>(payload, state.stats.socialNetwork, false),
        },
    }),
    [ANALYTICS.ON_CHANGE_TIME_FILTER]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            stats: {
                $merge: {
                    filter: payload.label,
                },
            },
        }),
    [ANALYTICS.FETCH_POSTS_TOP_HASHTAGS_SUCCESS]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            hashtags: {
                $merge: {
                    topHashtags: payload.topHashtags,
                    error: null,
                    loading: false,
                },
            },
        }),
    [ANALYTICS.FETCH_POSTS_TOP_HASHTAGS_FAILURE]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            hashtags: {
                $merge: {
                    error: payload.error,
                    topHashtags: [],
                    loading: false,
                },
            },
        }),
    [ANALYTICS.FETCH_TOP_FLOP_POSTS_SUCCESS]: (state: AnalyticsState, { payload: { posts } }: AnyAction) =>
        update(state, {
            topFlopPosts: {
                $merge: {
                    topPosts: posts.top_posts,
                    flopPosts: posts.flop_posts,
                    error: null,
                    loading: false,
                },
            },
        }),
    [ANALYTICS.FETCH_TOP_FLOP_POSTS_FAILURE]: (state: AnalyticsState, { payload }: AnyAction) =>
        update(state, {
            topFlopPosts: {
                $merge: {
                    error: payload.error,
                    topPosts: [],
                    flopPosts: [],
                    loading: false,
                },
            },
        }),
};

export default createReducer<AnalyticsState, AnyAction>(initialState, reducers);
