import React, { forwardRef, memo, useEffect, useMemo, useState, useCallback } from 'react';

import { Truncate, Icon } from '@brandandcelebrities/kolkit';

import MessagingLexique from 'locales/types/containers/messagingPage';
import { Timeago } from 'containers/messagingPage/Modules';
import { Tag } from 'containers/messagingPage/Components';
import { markdownToEditor } from 'utils/richTextEditor';
import { ProfileState } from 'reducers/messaging';

import Card from './Card';

import styles from './Card.module.scss';

interface Props {
  content: string;
  participants: ProfileState[];
  lexique: MessagingLexique;
  object?: string;
  date: string;
  onClick: () => void;
  onDelete: () => void;
  index: number;
  active?: boolean;
  animated?: boolean;
  me?: number | null;
  ref?: any;
}

const CardDraft = forwardRef<HTMLDivElement, Props>((
  { content, active, participants, lexique, object, date, onClick, onDelete, index, animated, me, ...rest },
  ref
  ) => {
  const [name, setName] = useState('');

  useEffect(
    () => {
      const otherParticipants = participants.filter(p => p?.id?.toString() !== me.toString());
      const cardName = otherParticipants?.[0]?.name;
      setName(cardName);
    },
    [participants, me]
  );

  const renderParticipants = useMemo(
    () => {
      return (
        <span className={styles.name}>
          <Truncate>{name}</Truncate>
          {participants.length > 2 && <span className={styles.participantsSize}>{`+${ participants.length - 2 }`}</span>}
        </span>
      )
    },
    [name, participants.length]
  );

  const preview = useMemo(
    () => {
      const plainText = markdownToEditor(content)?.getCurrentContent().getPlainText();
      return (
        <div><p>{lexique.chat.me}: <i>{plainText}</i></p></div>
      );
    },
    [content, lexique]
  );

  const handleDelete = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      onDelete();
    },
    [onDelete]
  );

  return (
    <Card
      ref={ref}
      index={index}
      animated={animated}
      className={styles.cardMessage}
      name={name}
      active={active}
      onClick={onClick}
      {...rest}
    >
      <>
        <div className={styles.body}>
          <div className="flexRow">
            {renderParticipants}
            <Tag className={styles.tag}>
              <Truncate>
                {object}
              </Truncate>
            </Tag>
          </div>
          <div className={styles.preview}>
            {preview}
          </div>
        </div>

        <div className={styles.data}>
          <Timeago timestamp={date} className={styles.timeago} />
        </div>

        <Icon
          className={styles.deleteIcon}
          label="trash-alt"
          isButton
          onClick={handleDelete}
        />
      </>
    </Card>
  )
  });

CardDraft.displayName = 'CardDraft';

export default memo(CardDraft);
