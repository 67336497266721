import LoginLexique from 'locales/types/containers/loginPage';

const login: LoginLexique = {
  headTitle: 'Login - Kolsquare',
  logIn: 'Log in',
  register: `You aren't registered yet?`,
  registerLink: 'Sign up',
  successRegister: 'Congratulations your registration has been successfully validated',
  continue: `Continue`,
  email: {
    placeholder: 'Email',
    error: 'Invalid email',
  },
  password: {
    placeholder: 'Password',
    error: 'Incorrect password',
  },
  passwordForgotten: {
    title: 'Password forgotten ?',
    subTitle: 'Enter the email associated with your account.',
    back: 'Back',
    submit: 'Reset my password',
    submitedEmail: '✔ Email sent !',
    resendEmail: 'Click to resend',
  },
  newPassword: {
    title: 'Modify your password',
    password: 'Password',
    confirm: 'Password confirmation',
    submit: 'Reset my password',
  },
  error: {
    passwordLength: "Min 12. characters",
    mismatches: "Passwords mismatches",
    unconfirmed: "Email not confirmed",
    isAlreadyConfirmed: "Email already confirmed",
    invalidEmail: "Invalid email",
    invalidCredentials: "Invalid email/password",
    tokenNotFound: "Your link is invalid. Thanks for checking your email and clicking on the last link we sent you.",
    forbidden: "You don’t have access to this service",
    notActive: "Account not activated. Please contact us via support@kolsquare.com",
    expiredToken: "Your link has expired",
    invalid: "Your link is invalid",
    maintenance: "Our service is under maintenance... Please try again in a few minutes",
    connectionError: "Connection...",
    unknown: "A critical error occured.... Please try again in a few minutes",
    alreadyUsed: "Your link has already been used",
    nonActivated: "Your account is not activated. Please click on the activation link in your account activation email."
  }
}

export default login;