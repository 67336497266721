import React from 'react';

export default {
    title: (name) => `Congratulations ${name} !`,
    text: (network, value) => (
        <>
            {`Your community on ${network} increased by `}
            <span>{`${value} `}</span>
            points in 1 month
        </>
    ),
};
