import { PlaceholderLexique } from 'locales/types/containers/homePage/homeSlide/placeholder';

const placeholder: PlaceholderLexique = {
    title: `Bienvenue sur Community ! 🎉🎉`,
    text: `Connectez directement votre compte Instagram afin de bénéficier de statistiques précises et pertinentes sur votre communauté`,
    submit: 'Ajouter',
    button: {
        label: `Ajouter mon compte Instagram`,
        subtext: `(business uniquement)`,
    },
};

export default placeholder;
