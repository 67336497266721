// Analtyics and Header
export const LOAD = 'analytics/LOAD';

export const FETCH_HEADER_SUCCESS = 'analytics/header/FETCH_HEADER_SUCCESS';
export const FETCH_HEADER_FAILURE = 'analytics/header/FETCH_HEADER_FAILURE';

// Posts
export const ON_CHANGE_TIME_RANGE_FILTER = 'analytics/posts/globalFilters/ON_CHANGE_TIME_RANGE_FILTER';
export const ON_CHANGE_SOCIAL_NETWORKS_FILTER = 'analytics/posts/globalFilters/ON_CHANGE_SOCIAL_NETWORKS_FILTER';

export const LOAD_POSTS = 'analytics/posts/LOAD';

export const FETCH_POSTS_SUCCESS = 'analytics/posts/searchCard/FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'analytics/posts/searchCard/FETCH_POSTS_FAILURE';

export const SET_SORT_FILTER = 'analytics/posts/searchCard/SET_SORT_FILTER';
export const ON_CHANGE_SEARCH = 'analytics/posts/searchCard/ON_CHANGE_SEARCH';
export const ON_CHANGE_SORT_FILTER = 'analytics/posts/searchCard/ON_CHANGE_SORT_FILTER';

export const ON_CHANGE_PAGE = 'analytics/posts/pagination/ON_CHANGE_PAGE';

// Community
export const LOAD_COMMUNITY = 'analytics/community/LOAD';

export const FETCH_COMMUNITY_SUCCESS = 'analytics/community/FETCH_COMMUNITY_SUCCESS';
export const FETCH_COMMUNITY_FAILURE = 'analytics/community/FETCH_COMMUNITY_FAILURE';

// Statistics
export const LOAD_STATISTICS = 'analytics/statistics/LOAD';

export const ON_CHANGE_TIME_FILTER = 'analytics/statistics/chartCard/ON_CHANGE_TIME_FILTER';

export const FETCH_CHART_SUCCESS = 'analytics/statistics/FETCH_CHART_SUCCESS';
export const FETCH_CHART_FAILURE = 'analytics/statistics/FETCH_CHART_FAILURE';

export const FETCH_POSTS_TOP_HASHTAGS = 'analytics/statistics/FETCH_POSTS_TOP_HASHTAGS';
export const FETCH_POSTS_TOP_HASHTAGS_SUCCESS = 'analytics/statistics/FETCH_POSTS_TOP_HASHTAGS_SUCCESS';
export const FETCH_POSTS_TOP_HASHTAGS_FAILURE = 'analytics/statistics/FETCH_POSTS_TOP_HASHTAGS_FAILURE';

// TOP-FLOP POSTS
export const LOAD_TOP_FLOP_POSTS = 'analytics/statistics/LOAD_TOP_FLOP_POSTS';
export const FETCH_TOP_FLOP_POSTS_SUCCESS = 'analytics/statistics/FETCH_TOP_FLOP_POSTS_SUCCESS';
export const FETCH_TOP_FLOP_POSTS_FAILURE = 'analytics/statistics/FETCH_TOP_FLOP_POSTS_FAILURE';
