import React, { memo } from 'react';
import styles from './ChatContainer.module.scss';

const ChatContainer = ({ children }) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
};

ChatContainer.displayName = 'ChatContainer';

export default memo(ChatContainer);
