import React, { memo } from 'react';

import { ReactComponent as Stars } from 'assets/images/svg/tx-engagment-hearts.svg';

import { simpleNumberFormatter } from 'utils';

import './engagement-rate-figure.scss';

interface Props {
    network: string;
    firstValue: number;
    secondValue: number;
}

const EngagementRateFigure: React.SFC<Props> = ({ network, firstValue, secondValue }) => (
    <div className="bc-engagement-rate-figure">
        <div className="bc-engagement-rate-figure__stars">
            <Stars />
        </div>
        <div className="bc-engagement-rate-figure__wrapper">
            <div
                className={`bc-engagement-rate-figure__value bc-engagement-rate-figure__value--${network} bc-engagement-rate-figure__value--first`}
            >
                {simpleNumberFormatter(firstValue)}
                <span>%</span>
            </div>
            <div
                className={`bc-engagement-rate-figure__value bc-engagement-rate-figure__value--${network} bc-engagement-rate-figure__value--second`}
            >
                {simpleNumberFormatter(secondValue)}
                <span>%</span>
            </div>
        </div>
    </div>
);

export default memo(EngagementRateFigure);
