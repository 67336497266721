import React, { FC, useCallback, useMemo, memo } from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { history } from 'store/configureStore';
import routes from 'config/routes';
import { useLexique } from 'utils/redux';

import { ProfileStrengthMeterLexique } from 'locales/types/containers/shared/profileStrengthMeter';

interface SectionProps {
    section: string;
    onClick: (section: string) => void;
    isCompleted: boolean;
}

const ItemSection: FC<SectionProps> = ({ section, onClick, isCompleted }) => {
    const lexique = useLexique<ProfileStrengthMeterLexique>('containers.shared.profileStrengthMeter');

    const onSectionClick = useCallback(() => onClick(section), [section, onClick]);

    const isDisabled = useMemo(() => {
        switch (section) {
            case 'personalInfo':
            case 'moreInfo':
                return history.location.pathname === routes.profile;
            case 'instagramAccount':
                return isCompleted;
            default:
                return false;
        }
    }, [section, isCompleted]);

    return (
        <ButtonBase
            component="div"
            onClick={onSectionClick}
            disabled={isDisabled}
            className="profile-strength-metter__button"
        >
            <div className={`profile-strength-metter__section ${section}`}>
                {isCompleted ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon style={{ fill: '#607E96' }} />}
                <div className="profile-strength-metter__section-label">
                    <div className="section-title">{lexique[section].title}</div>
                    {lexique[section].subtitle && <div className="section-subtitle">{lexique[section].subtitle}</div>}
                </div>
            </div>
        </ButtonBase>
    );
};

export default memo(ItemSection);
