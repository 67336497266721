import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { GoogleChart } from 'components/molecules';

import getLexique from 'locales';
import { SOCIAL_NETWORKS } from 'constants/networks';
import { AppState } from 'reducers';
import { Stats } from 'reducers/posts';
import { TotalChartLexique } from 'locales/types/containers/analyticsPage/posts';
import './GraphCard.scss';

interface Props {
    stats: Stats[];
    lexique: TotalChartLexique;
}

const getValueAt = (column, dataTable, row) => {
    const data = [
        { label: 'T', unit: 1e12 },
        { label: 'B', unit: 1e9 },
        { label: 'M', unit: 1e6 },
        { label: 'k', unit: 1e3 },
        { label: '', unit: 1 },
    ].find(({ unit }) => dataTable.getValue(row, column) / unit >= 1);
    const n = dataTable.getValue(row, column);

    if (n === null) return '---';
    if (!data) return '0';
    return `${Math.floor((n * 10) / data.unit) / 10}${data.label}`;
};

class TotalChart extends PureComponent<Props> {
    render() {
        const { lexique, stats } = this.props;
        if (!stats) {
            return null;
        }

        const createCustomHTMLContent = ({ label, matched_posts, matched_stories }) =>
            `<div class="bc-analytics-graph-card__tooltip">
        <h3 style="color: ${SOCIAL_NETWORKS[label].color};">
          ${SOCIAL_NETWORKS[label].label}
        </h3>
        <p>Posts: <strong>${matched_posts || 0}</strong></p>
        <p>Stories: <strong>${matched_stories || 0}</strong></p>
      </div>`;

        const dataInfos = [
            ['social network', 'average', { role: 'style' }, { type: 'string', role: 'tooltip', p: { html: true } }],
        ];

        const dataStats = stats.map((row) => [
            SOCIAL_NETWORKS[row.social_network].label,
            row.posts.matched_posts + row.posts.matched_stories,
            SOCIAL_NETWORKS[row.social_network].color,
            createCustomHTMLContent({ label: row.social_network, ...row.posts }),
        ]);

        const data = [...dataInfos, ...dataStats];

        return (
            <div className="bc-analytics-graph-card">
                <div className="bc-analytics-graph-card__label">{lexique.label}</div>
                <GoogleChart
                    id="TotalChart"
                    visualizationType="ColumnChart"
                    chartType="Bar"
                    data={data}
                    options={{
                        legend: { position: 'none' },
                        vAxis: {
                            format: 'short',
                            baselineColor: '#DDDDDD',
                            gridlines: { color: '#DDDDDD' },
                        },
                        hAxis: { textPosition: 'none' },
                        enableInteractivity: false,
                        tooltip: { isHtml: true },
                    }}
                    columns={[
                        0,
                        1,
                        {
                            calc: getValueAt.bind(undefined, 1),
                            sourceColumn: 1,
                            type: 'string',
                            role: 'annotation',
                        },
                        2,
                        3,
                    ]}
                    load={['corechart', 'bar']}
                />
                <div className="bc-analytics-graph-card__chart" id="bc-analytics-graph-card__chart-TotalChart" />
                <div className="bc-analytics-graph-card__legends" />
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexique: getLexique(state.env.locale).containers.analyticsPage.posts.charts.totalChart,
    stats: state.posts.all.stats,
});

export default connect(mapStateToProps, null)(TotalChart);
