import React, { FC, memo, useCallback, useMemo } from 'react';
import { TimeRange } from 'types/Filters';
import { useLexique } from 'utils/redux';
import { Select } from '@brandandcelebrities/kolkitten';
import './TimeRangeSelector.scss';
import { randomIdLikeString } from 'utils/randomize';

export interface Props {
    timeRange: TimeRange;
    id?: string;
    canBeAllTime?: boolean;
    onTimeRangeChange: (range: TimeRange) => void;
}

export const TIME_RANGES = Object.keys(TimeRange);

const TimeRangeSelector: FC<Props> = ({ id, timeRange, onTimeRangeChange, canBeAllTime }) => {
    // @TODO type and move lexique
    const lexique = useLexique<any>('containers.analyticsPage.posts.globalFilters');
    const selectDataSet = useMemo(
        () =>
            TIME_RANGES.map((timeRange) => ({
                value: timeRange,
                label: lexique.timeRangeFilter[timeRange],
            })).filter((timeRange) => canBeAllTime || (!canBeAllTime && timeRange.value !== TimeRange.all)),
        [lexique, canBeAllTime],
    );

    const onSelectChange = useCallback(({ value }) => onTimeRangeChange(TimeRange[value]), [onTimeRangeChange]);
    return (
        <Select
            hasSearch={false}
            className="time-filter-component"
            id={id || randomIdLikeString(22)}
            value={timeRange}
            error={false}
            sort={false}
            errorText=""
            dataset={selectDataSet}
            onChange={onSelectChange}
        />
    );
};

TimeRangeSelector.defaultProps = {
    canBeAllTime: true,
};

export default memo(TimeRangeSelector);
