import { fork, takeEvery, select } from 'redux-saga/effects';

import * as types from 'constants/socialStatsCard';

export function* watchModal() {
    yield fork(watchDisplay);
}

// @TODO: remove those pointless once the components modals are refactoring
export function* watchDisplay() {
    const modalOpenedStyleClassName = 'bc-modal--open';
    yield takeEvery([types.MODAL_CLOSE, types.MODAL_OPEN], function* ({ type }) {
        const state = yield select();
        if (type === types.MODAL_CLOSE && state.ui.modal.display) {
            document.body.classList.remove(modalOpenedStyleClassName);
        }
        if (type === types.MODAL_OPEN && !state.ui.modal.display) {
            document.body.classList.add(modalOpenedStyleClassName);
        }
    });
}
