export default {
    BD: 'Bangladesh',
    BE: 'Belgique',
    BF: 'Burkina Faso',
    BG: 'Bulgarie',
    BA: 'Bosnie-Herzégovine',
    BB: 'Barbade',
    WF: 'Wallis et Futuna',
    BL: 'Saint Barthélemy',
    BM: 'Bermudes',
    BN: 'Brunei',
    BO: 'Bolivie',
    BH: 'Bahreïn',
    BI: 'Burundi',
    BJ: 'Bénin',
    BT: 'Bhoutan',
    JM: 'Jamaïque',
    BV: 'Ile Bouvet',
    BW: 'Botswana',
    WS: 'Samoa',
    BQ: 'Bonaire, Saint-Eustache et Saba',
    BR: 'Brésil',
    BS: 'Bahamas',
    JE: 'Jersey',
    BY: 'Biélorussie',
    BZ: 'Belize',
    RU: 'Russie',
    RW: 'Rwanda',
    RS: 'Serbie',
    TL: 'Timor Oriental',
    RE: 'Réunion',
    TM: 'Turkménistan',
    TJ: 'Tadjikistan',
    RO: 'Roumanie',
    TK: 'Tokelau',
    GW: 'Guinée-Bissau',
    GU: 'Guam',
    GT: 'Guatemala',
    GS: 'Géorgie du Sud et Les Îles Sandwich du Sud',
    GR: 'Grèce',
    GQ: 'Guinée équatoriale',
    GP: 'Guadeloupe',
    JP: 'Japon',
    GY: 'Guyane',
    GG: 'Guernesey',
    GF: 'Guyane française',
    GE: 'Georgie',
    GD: 'Grenade',
    GB: 'Royaume-Uni',
    GA: 'Gabon',
    SV: 'El Salvador',
    GN: 'Guinée',
    GM: 'Gambie',
    GL: 'Groenland',
    GI: 'Gibraltar',
    GH: 'Ghana',
    OM: 'Oman',
    TN: 'Tunisie',
    JO: 'Jordanie',
    HR: 'Croatie',
    HT: 'Haïti',
    HU: 'Hongrie',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HM: 'Îles Heard et McDonald',
    VE: 'Venezuela',
    PR: 'Porto Rico',
    PS: 'Palestine',
    PW: 'Palau',
    PT: 'Portugal',
    SJ: 'Svalbard et Jan Mayen',
    PY: 'Paraguay',
    IQ: 'Irak',
    PA: 'Panama',
    PF: 'Polynésie française',
    PG: 'Papouasie-Nouvelle-Guinée',
    PE: 'Pérou',
    PK: 'Pakistan',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Pologne',
    PM: 'Saint-Pierre et Miquelon',
    ZM: 'Zambie',
    EH: 'Sahara occidental',
    EE: 'Estonie',
    EG: 'Egypte',
    ZA: 'Afrique du Sud',
    EC: 'Equateur',
    IT: 'Italie',
    VN: 'Vietnam',
    SB: 'Îles Salomon',
    ET: 'Ethiopie',
    SO: 'Somalie',
    ZW: 'Zimbabwe',
    SA: 'Arabie Saoudite',
    ES: 'Espagne',
    ER: 'Erythrée',
    ME: 'Monténégro',
    MD: 'Moldavie',
    MG: 'Madagascar',
    MF: 'Saint Martin (FR)',
    MA: 'Maroc',
    MC: 'Monaco',
    UZ: 'Ouzbékistan',
    MM: 'Myanmar',
    ML: 'Mali',
    MO: 'Macao',
    MN: 'Mongolie',
    MH: 'Îles Marshall',
    MK: 'Macédoine',
    MU: 'Maurice',
    MT: 'Malte',
    MW: 'Malawi',
    MV: 'Maldives',
    MQ: 'Martinique',
    MP: 'Îles Mariannes du Nord',
    MS: 'Montserrat',
    MR: 'Mauritanie',
    IM: 'Île de Man',
    UG: 'Ouganda',
    TZ: 'Tanzanie',
    MY: 'Malaisie',
    MX: 'Mexique',
    IL: 'Israël',
    FR: 'France',
    IO: "Territoire britannique de l'océan Indien",
    SH: 'Sainte-Hélène',
    FI: 'Finlande',
    FJ: 'Fidji',
    FK: 'Îles Falkland',
    FM: 'Micronésie',
    FO: 'Îles Féroé',
    NI: 'Nicaragua',
    NL: 'Pays-Bas',
    NO: 'Norvège',
    NA: 'Namibie',
    VU: 'Vanuatu',
    NC: 'Nouvelle-Calédonie',
    NE: 'Niger',
    NF: 'Île Norfolk',
    NG: 'Nigeria',
    NZ: 'Nouvelle-Zélande',
    NP: 'Népal',
    NR: 'Nauru',
    NU: 'Niue',
    CK: 'Îles Cook',
    XK: 'Kosovo',
    CI: "Côte d'Ivoire",
    CH: 'Suisse',
    CO: 'Colombie',
    CN: 'Chine',
    CM: 'Cameroun',
    CL: 'Chili',
    CC: 'Îles Cocos',
    CA: 'Canada',
    CG: 'République du Congo',
    CF: 'République centrafricaine',
    CD: 'République démocratique du Congo',
    CZ: 'République tchèque',
    CY: 'Chypre',
    CX: 'Île de Noël',
    CR: 'Costa Rica',
    CW: 'Curaçao',
    CV: 'Cap-Vert',
    CU: 'Cuba',
    SZ: 'Swaziland',
    SY: 'Syrie',
    SX: 'Saint-Martin (P-B)',
    KG: 'Kirghizistan',
    KE: 'Kenya',
    SS: 'Soudan du Sud',
    SR: 'Suriname',
    KI: 'Kiribati',
    KH: 'Cambodge',
    KN: 'Saint-Christophe-et-Niévès',
    KM: 'Comores',
    ST: 'Sao Tomé-et-Principe',
    SK: 'Slovaquie',
    KR: 'Corée du Sud',
    SI: 'Slovénie',
    KP: 'Corée du Nord',
    KW: 'Koweït',
    SN: 'Sénégal',
    SM: 'Saint-Marin',
    SL: 'Sierra Leone',
    SC: 'Seychelles',
    KZ: 'Kazakhstan',
    KY: 'Îles Caïmans',
    SG: 'Singapour',
    SE: 'Suède',
    SD: 'Soudan',
    DO: 'République dominicaine',
    DM: 'Dominique',
    DJ: 'Djibouti',
    DK: 'Danemark',
    VG: 'Îles Vierges britanniques',
    DE: 'Allemagne',
    YE: 'Yémen',
    DZ: 'Algérie',
    US: 'Etats-Unis',
    UY: 'Uruguay',
    YT: 'Mayotte',
    UM: 'Îles mineures éloignées des États-Unis',
    LB: 'Liban',
    LC: 'Sainte-Lucie',
    LA: 'Laos',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TT: 'Trinité-et-Tobago',
    TR: 'Turquie',
    LK: 'Sri Lanka',
    LI: 'Liechtenstein',
    LV: 'Lettonie',
    TO: 'Tonga',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    LR: 'Liberia',
    LS: 'Lesotho',
    TH: 'Thaïlande',
    TF: 'Territoires du Sud français',
    TG: 'Togo',
    TD: 'Tchad',
    TC: 'Îles Turques et Caïques',
    LY: 'Libye',
    VA: 'Vatican',
    VC: 'Saint-Vincent-et-les-Grenadines',
    AE: 'Emirats Arabes Unis',
    AD: 'Andorre',
    AG: 'Antigua-et-Barbuda',
    AF: 'Afghanistan',
    AI: 'Anguilla',
    VI: 'Îles Vierges américaines',
    IS: 'Islande',
    IR: 'Iran',
    AM: 'Arménie',
    AL: 'Albanie',
    AO: 'Angola',
    AQ: 'Antarctique',
    AS: 'Samoa américaines',
    AR: 'Argentine',
    AU: 'Australie',
    AT: 'Autriche',
    AW: 'Aruba',
    IN: 'Inde',
    AX: 'Îles Aland',
    AZ: 'Azerbaïdjan',
    IE: 'Irlande',
    ID: 'Indonésie',
    UA: 'Ukraine',
    QA: 'Qatar',
    MZ: 'Mozambique',
};
