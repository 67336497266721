import React, { useMemo, useCallback } from 'react';
import _isEmpty from 'lodash.isempty';
import cn from 'classnames';

import { Loader, Icon } from '@brandandcelebrities/kolkit';

import { useDispatch } from 'utils/redux';
import { confirmRemoveSna } from 'actions/sna';

import { SocialName, MetricCard } from 'components/molecules';
import { Networks } from 'constants/networks';

import styles from './SocialStatsCard.module.scss';

interface Metric {
    type: string;
    value: string;
    evolution: string;
    star: boolean;
}

interface Props {
    type: Networks;
    metrics?: Metric[];
    lexique?: any;
    username: string;
    row: boolean;
    showDeleteButton?: boolean;
    uid?: string;
}

const SocialStatsCard: React.SFC<Props> = ({ lexique, type, metrics, username, row, showDeleteButton, uid }) => {
    const dispatch = useDispatch();

    const onDelete = useCallback(() => {
        dispatch(
            confirmRemoveSna({
                network: type,
                uid,
            }),
        );
    }, [dispatch, uid, type]);

    const cnWrapper = cn(styles.wrapper, { [styles.row]: row });

    const cnContainer = cn(styles.username, {
        [styles.withDelete]: showDeleteButton,
    });

    const renderContent = useMemo(() => {
        if (_isEmpty(metrics)) {
            return (
                <>
                    <Loader className={styles.loader} />
                    <p className={styles.cardText}>{lexique.waiting}</p>
                </>
            );
        }
        return metrics.map((metric) => (
            <MetricCard
                key={metric.type}
                rate={metric.value}
                evolution={metric.evolution}
                label={lexique.metrics[metric.type]}
            />
        ));
    }, [metrics, lexique]);

    return (
        <div className={cnWrapper}>
            <div className={cnContainer}>
                <SocialName name={username} network={type} />
                {showDeleteButton && (
                    <div className={styles.delete}>
                        <Icon
                            size="small"
                            label="trash-alt"
                            theme="thin"
                            tooltip={lexique.delete}
                            isButton
                            onClick={onDelete}
                        />
                    </div>
                )}
            </div>
            <div className={styles.content}>{renderContent}</div>
        </div>
    );
};

export default SocialStatsCard;
