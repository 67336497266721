import { HeaderLexique } from 'locales/types/containers/analyticsPage/header';

export const headerLexique: HeaderLexique = {
    totalLabel: 'Communauté totale',
    averageLabel: 'Engagements moyen (3 mois)',
    navbar: {
        posts: 'Vos Posts',
        statistics: 'Statistiques',
        community: 'Communauté',
        partenariats: 'Partenariats',
    },
};

export default headerLexique;
