import { HeaderLexique } from 'locales/types/containers/shared/header';

const header: HeaderLexique = {
    navLinks: {
        audience: `My Audience`,
        content: `My content`,
        partner: `My visibility`,
        discuss: `Conversations`,
    },
    feedback: {
        label: `Any questions?`,
        content: {
            subject: `Community: questions from `,
        },
    },
    subMenu: {
        settings: `My Account`,
        profile: `My Profile`,
        logout: `Log out`,
    },
};

export default header;
