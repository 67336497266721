import React, { Component } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash.isempty';

import GoogleChart from 'components/molecules/GoogleChart';
import { percentFormatter } from 'utils';
import getLexique from 'locales';

import './GeoChart.scss';
import { AppState } from 'reducers';
import { CommunityStats, AnalyticsHeader } from 'reducers/ui/analytics';
import { DeviceType } from 'reducers/env';

interface Props {
    lexique: any; // @TODO type
    lexiqueCountries: any;
    community: CommunityStats;
    device: DeviceType;
    header: AnalyticsHeader;
}

const formatValue = (value) => {
    const data = [
        { label: 'T', unit: 1e12 },
        { label: 'B', unit: 1e9 },
        { label: 'M', unit: 1e6 },
        { label: 'k', unit: 1e3 },
        { label: '', unit: 1 },
    ].find(({ unit }) => value / unit >= 1);
    const n = value;

    if (n === null) return '---';
    if (!data) return '0';
    return `${Math.floor((n * 10) / data.unit) / 10}${data.label}`;
};

class GeoChart extends Component<Props> {
    renderChart() {
        const { device, lexique, lexiqueCountries, community, header } = this.props;

        if (_isEmpty(community) || _isEmpty(community.countries) || _isEmpty(community.top_countries)) {
            return <div className="bc-analytics-community-geo-chart__geo-chart-placeholder">{lexique.placeholder}</div>;
        }

        const totalCommunity = _isEmpty(header.accounts)
            ? null
            : header.accounts.reduce(
                  (accumulator, currentValue) =>
                      currentValue.community_count ? accumulator + currentValue.community_count : accumulator,
                  0,
              );

        const rawData = _isEmpty(community.countries)
            ? []
            : community.countries.map((country) => [
                  lexiqueCountries[country.code],
                  {
                      v: Math.floor(country.value * totalCommunity),
                      f: formatValue(Math.floor(country.value * totalCommunity)),
                  },
              ]);
        const data = [[lexique.tooltip.country, lexique.tooltip.followers], ...rawData];

        return (
            <div className="bc-analytics-community-geo-chart__geo-chart">
                {!_isEmpty(rawData) && device !== 'mobile' && (
                    <GoogleChart
                        id="GeoChart"
                        visualizationType="GeoChart"
                        data={data}
                        load={['geochart']}
                        options={{
                            legend: 'none',
                            colorAxis: { colors: ['#aec6ff', '#0A293D'] },
                        }}
                    />
                )}
                <div className="bc-analytics-community-geo-chart__legends">
                    {!_isEmpty(community.top_countries) &&
                        community.top_countries.map((topCountry, i) => (
                            <div key={i} className="bc-analytics-community-geo-chart__legend">
                                <div className="bc-analytics-community-geo-chart-legend__country">
                                    <img
                                        src={`${''}/imgs/flags/${topCountry.code.toLowerCase()}.svg`}
                                        alt={topCountry.code.toLowerCase()}
                                    />
                                    <div className="bc-analytics-community-geo-chart-legend__country-text">
                                        <div className="bc-analytics-community-geo-chart-legend__country-metric">
                                            {percentFormatter(topCountry.value * 100)}
                                        </div>
                                        <div className="bc-analytics-community-geo-chart-legend__country-name">
                                            {lexiqueCountries[topCountry.code]}
                                        </div>
                                    </div>
                                </div>
                                <div className="bc-analytics-community-geo-chart-legend__cities">
                                    {!_isEmpty(topCountry.cities) &&
                                        topCountry.cities.map((city, j) => (
                                            <div key={j} className="bc-analytics-community-geo-chart-legend__city">
                                                <div className="bc-analytics-community-geo-chart-legend__city-metric">
                                                    {percentFormatter(city.value * 100)}
                                                </div>
                                                <div className="bc-analytics-community-geo-chart-legend__city-label">
                                                    {city.name}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="bc-analytics-community-geo-chart__shell">
                <div className="bc-analytics-community-geo-chart">{this.renderChart()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexique: getLexique(state.env.locale).containers.analyticsPage.community.geoChart,
    lexiqueCountries: getLexique(state.env.locale).countries,
    community: state.ui.analytics.community,
    device: state.env.device.type,
    header: state.ui.analytics.header,
});

export default connect(mapStateToProps, null)(GeoChart);
