import compact from 'lodash.compact';

import { Networks, SocialNetworks } from 'types/snas.d';
import { SocialNetwork } from 'types/snas';

export { Networks };

export const SOCIAL_NETWORKS: SocialNetworks = {
    facebook: {
        id: Networks.facebook,
        placeholder: 'https://facebook.com/kolsquare',
        label: 'Facebook',
        color: '#3b5998',
        inverseColor: '#FFFFFF',
        supported: true,
    },
    instagram: {
        id: Networks.instagram,
        placeholder: 'https://instagram.com/kolsquare',
        label: 'Instagram',
        color: '#BB49B1',
        inverseColor: '#FFFFFF',
        supported: true,
    },
    twitter: {
        id: Networks.twitter,
        placeholder: 'https://twitter.com/kolsquare',
        label: 'Twitter',
        color: '#00AAEE',
        inverseColor: '#FFFFFF',
        supported: true,
    },
    youtube: {
        id: Networks.youtube,
        placeholder: 'https://youtube.com/channel/kolsquare',
        label: 'YouTube',
        color: '#ff0400',
        inverseColor: '#FFFFFF',
        supported: true,
    },
    tiktok: {
        id: Networks.tiktok,
        placeholder: 'https://tiktok.com/@kolsquare',
        label: 'Tiktok',
        color: '#101010',
        inverseColor: '#FFFFFF',
        supported: true,
    },
    snapchat: {
        id: Networks.snapchat,
        placeholder: 'kolsquare',
        label: 'Snapchat',
        color: '#FFFC00',
        inverseColor: '#000000',
        supported: false,
    },
    pinterest: {
        id: Networks.pinterest,
        placeholder: 'https://pinterest.fr/kolsquare',
        label: 'Pinterest',
        color: '#D32F2F',
        inverseColor: '#FFFFFF',
        supported: false,
    },
    twentyone_buttons: {
        id: Networks.twentyone_buttons,
        placeholder: 'https://21buttons.com/kolsquare',
        label: '21Buttons',
        color: '#000000',
        inverseColor: '#FFFFFF',
        supported: false,
    },
    twitch: {
        id: Networks.twitch,
        placeholder: 'https://twitch.tv/kolsquare',
        label: 'Twitch',
        color: '#8E24AA',
        inverseColor: '#FFFFFF',
        supported: false,
    },
    blog: {
        id: Networks.blog,
        placeholder: 'blog',
        label: 'Other',
        color: '',
        inverseColor: '#FFFFFF',
        supported: false,
    },
    website: {
        id: Networks.website,
        placeholder: 'website',
        label: 'Other',
        color: '',
        inverseColor: '#FFFFFF',
        supported: false,
    },
    other_networks: {
        id: Networks.other_networks,
        placeholder: 'other',
        label: 'Other',
        inverseColor: '#FFFFFF',
        color: '',
        supported: false,
    },
};

export const supportedSocialNetwork: SocialNetwork[] = compact(
    Object.keys(SOCIAL_NETWORKS).map((sn) => (SOCIAL_NETWORKS[sn].supported ? SOCIAL_NETWORKS[sn] : null)),
);

export const unsupportedSocialNetwork: SocialNetwork[] = compact(
    Object.keys(SOCIAL_NETWORKS).map((sn) => (!SOCIAL_NETWORKS[sn].supported ? SOCIAL_NETWORKS[sn] : null)),
);

export const supportedSortedSnaKeys = supportedSocialNetwork.map((sna) => sna.id);
