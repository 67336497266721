import React, { FC, useMemo, useCallback } from 'react';

import { Button2 } from '@brandandcelebrities/kolkit';

import QualityScore from 'components/atoms/QualityScore/QualityScore';
import { showModale } from 'actions/modale';

import { useSelector, useDispatch, useLexique } from 'utils/redux';

import { CredibilityScoreLexique } from 'locales/types/containers/homePage/homeSlide/slides/credibilityScore';
import { AppState } from 'reducers';

import '../HomeSlide.scss';

const CredibilityScore: FC = () => {
    const lexique = useLexique<CredibilityScoreLexique>('containers.homePage.homeSlide.slides.credibilityScore');

    const { user, device } = useSelector(({ env, user }: AppState) => ({
        device: env.device.type,
        user,
    }));

    const value = useMemo(() => {
        const { qualityScore } = user;
        if (qualityScore < 30) return 0;
        if (qualityScore < 60) return 30;
        if (qualityScore < 70) return 60;
        if (qualityScore < 90) return 70;
        return 90;
    }, [user]);
    const dispatch = useDispatch();

    const toggleModal = useCallback(() => {
        dispatch(
            showModale({
                body: 'CredibilityScore',
            }),
        );
    }, [dispatch]);

    return (
        <div className="bc-home__slide-container__slide">
            <div className="bc-home__slide-container__slide__stats">
                <QualityScore qualityScore={user.qualityScore} radiusSize={device === 'desktop' ? 130 : 82} />
                <p className="bc-home__slide-container__slide__stats__cred">{lexique.score}</p>
            </div>
            <div className="bc-home__slide-container__slide__title">
                {lexique[value].title(user.pseudo || user.firstName)}
            </div>
            <div className="bc-home__slide-container__slide__text">
                {lexique[value].text}
                <div className="bc-home__slide-container__slide__text__button">
                    <Button2
                        onClick={toggleModal}
                        label={lexique.button}
                        size="small"
                    />
                </div>
            </div>
        </div>
    );
};

export default CredibilityScore;
