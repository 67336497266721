/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import _isNumber from 'lodash.isnumber';
import { AxisLeft, AxisRight, AxisBottom } from '@vx/axis';
import { curveLinear, curveStepAfter } from '@vx/curve';
import { localPoint } from '@vx/event';
import { GridRows } from '@vx/grid';
import { Group } from '@vx/group';
import { withParentSize } from '@vx/responsive';
import { scaleTime, scaleLinear } from '@vx/scale';
import { AreaClosed, Line, LinePath, Bar } from '@vx/shape';
import { withTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';
import { extent, max, bisector } from 'd3-array';
import { interpolateLab } from 'd3-interpolate';
import { timeFormat } from 'd3-time-format';

import { datetimeFormatter, simpleNumberFormatter, numberFormatter, LexiqueOfNums } from 'utils';
import { Locale } from 'locales';

const formatDate = timeFormat('%d/%m/%y');

// accessors
const xDate = (d) => new Date(d.date);
const yFollowers = (d) => d.followers;
const yEngagements = (d) => d.engagements;
const yPublications = (d) => d.publications;
const bisectDate = bisector((d) => new Date(d.date)).left;

interface CssMargin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

interface TooltipData {
    publications: number;
    engagements: number;
    followers: number;
}

interface Formats {
    numbers: LexiqueOfNums;
    date: string;
}

interface Props {
    showTooltip: (t: Tooltip) => void;
    data: Record<string, any>;
    parentWidth: number;
    margin: CssMargin;
    tooltipData: TooltipData;
    tooltipTop: TooltipData;
    tooltipLeft: number;
    hideTooltip: () => void;
    color: string;
    mobile: boolean;
    viewportWidth: number;
    locale: Locale;
    formats: Formats;
    lexique: any; // @TODO type
}

class Chart extends Component<Props> {
    svg;

    handleTooltip = ({ event, data, xDate, xScale, yScale, yScaleEngagement, yScaleVolume }) => {
        const { showTooltip } = this.props;

        const { x } = localPoint(event);

        const x0 = xScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && d1.date) {
            d = x0 - xDate(d0.date) > xDate(d1.date) - x0 ? d1 : d0;
        }

        showTooltip({
            tooltipData: d,
            tooltipLeft: x,
            tooltipTop: {
                followers: _isNumber(d.followers) ? yScale(d.followers) : null,
                engagements: _isNumber(d.engagements) ? yScaleEngagement(d.engagements) : null,
                publications: _isNumber(d.publications) ? yScaleVolume(d.publications) : null,
            },
        });
    };

    render() {
        const {
            data,
            parentWidth,
            // parentHeight,
            margin,
            // showTooltip,
            hideTooltip,
            tooltipData,
            tooltipTop,
            tooltipLeft,
            color,
            mobile,
            viewportWidth,
            locale,
            formats,
            lexique,
        } = this.props;
        if (parentWidth < 10) return null;

        // bounds
        const width = parentWidth;

        let height = parentWidth * 0.4;
        if (mobile) height = parentWidth * 0.8;

        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        const yMaxLine = (yMax * 4) / 6;
        if (mobile) height = (yMax * 5) / 6;

        let publicationsHeight = yMax * 0.2;
        if (mobile) publicationsHeight = yMax * 0.1;

        const marginChart = {
            left: 40,
            right: 40,
        };

        // scales
        const xScale = scaleTime({
            range: [marginChart.left, xMax - marginChart.right],
            domain: extent(data, xDate),
        });
        const yScale = scaleLinear({
            range: [yMaxLine, 0],
            domain: extent(data, yFollowers),
            nice: true,
        });
        const yScaleEngagement = scaleLinear({
            range: [yMaxLine, 0],
            domain: extent(data, yEngagements),
            nice: true,
        });
        const yScaleVolume = scaleLinear({
            range: [publicationsHeight, 0],
            domain: [0, max(data, yPublications)],
        });

        return (
            <div>
                <svg
                    ref={(s) => {
                        this.svg = s;
                    }}
                    width={width}
                    height={height}
                >
                    <rect x={0} y={0} width={width} height={height} fill="transparent" />
                    <GridRows
                        left={marginChart.left}
                        numTicks={5}
                        lineStyle={{ pointerEvents: 'none' }}
                        scale={yScale}
                        width={xMax - marginChart.left - marginChart.right}
                        stroke="#f5f5f5"
                    />
                    <LinePath
                        data={data}
                        xScale={xScale}
                        yScale={yScaleEngagement}
                        defined={(d) => yEngagements(d) !== null}
                        x={xDate}
                        y={yEngagements}
                        stroke={interpolateLab('white', color)(0.3)}
                        strokeWidth={2}
                        curve={curveLinear}
                    />
                    <LinePath
                        data={data}
                        xScale={xScale}
                        yScale={yScale}
                        defined={(d) => yFollowers(d) !== null}
                        x={xDate}
                        y={yFollowers}
                        stroke={color}
                        strokeWidth={2}
                        curve={curveLinear}
                    />
                    <Group top={height - margin.bottom - publicationsHeight}>
                        <AreaClosed
                            data={data}
                            xScale={xScale}
                            yScale={yScaleVolume}
                            x={xDate}
                            y={yPublications}
                            strokeWidth={1}
                            stroke="#d4d4d4"
                            fill="#d4d4d4"
                            curve={curveStepAfter}
                        />
                    </Group>
                    <Group top={margin.top}>
                        <AxisLeft
                            left={40}
                            scale={yScale}
                            hideTicks
                            hideAxisLine
                            tickValues={yScale.ticks(5)}
                            tickFormat={(number) => numberFormatter(number, formats.numbers)}
                            tickLabelProps={() => ({
                                textAnchor: 'end',
                                fontSize: 11,
                                fill: color,
                            })}
                            tickComponent={({ formattedValue, ...tickProps }) => (
                                <text {...tickProps}>{formattedValue}</text>
                            )}
                        />
                    </Group>
                    <Group top={margin.top}>
                        <AxisRight
                            left={xMax - marginChart.right}
                            scale={yScaleEngagement}
                            hideTicks
                            hideAxisLine
                            tickValues={yScaleEngagement.ticks(5)}
                            tickFormat={(number) => numberFormatter(number, formats.numbers)}
                            tickLabelProps={() => ({
                                textAnchor: 'start',
                                fontSize: 11,
                                fill: interpolateLab('white', color)(0.3),
                            })}
                            tickComponent={({ formattedValue, ...tickProps }) => (
                                <text {...tickProps}>{formattedValue}</text>
                            )}
                        />
                    </Group>
                    <Group top={height - margin.bottom - publicationsHeight}>
                        <AxisLeft
                            left={marginChart.left}
                            scale={yScaleVolume}
                            hideTicks
                            hideAxisLine
                            tickFormat={(number) => numberFormatter(number, formats.numbers)}
                            tickValues={yScaleVolume.ticks(2)}
                            tickLabelProps={() => ({
                                textAnchor: 'end',
                                fontSize: 10,
                                fill: '#C3C3C3',
                            })}
                            tickComponent={({ formattedValue, ...tickProps }) => (
                                <text {...tickProps}>{formattedValue}</text>
                            )}
                        />
                    </Group>
                    {viewportWidth >= 760 && (
                        <AxisBottom
                            top={(yMax * 2) / 3}
                            left={margin.left}
                            scale={xScale}
                            stroke="rgba(255,255,255,0.5)"
                            tickStroke="rgba(255,255,255,0.5)"
                            numTicks={7}
                            tickFormat={formatDate}
                            tickLabelProps={() => ({
                                textAnchor: 'middle',
                                fontSize: 10,
                                fill: '#003057',
                            })}
                            tickComponent={({ formattedValue, ...tickProps }) => (
                                <text {...tickProps}>{formattedValue}</text>
                            )}
                        />
                    )}
                    <Bar
                        x={marginChart.left}
                        y={0}
                        width={width - marginChart.left - marginChart.right}
                        height={height}
                        fill="transparent"
                        data={data}
                        onTouchStart={(data) => (event) =>
                            this.handleTooltip({
                                event,
                                data,
                                xDate,
                                xScale,
                                yScale,
                                yScaleEngagement,
                                yScaleVolume,
                            })}
                        onTouchMove={(data) => (event) =>
                            this.handleTooltip({
                                event,
                                data,
                                xDate,
                                xScale,
                                yScale,
                                yScaleEngagement,
                                yScaleVolume,
                            })}
                        onMouseMove={(data) => (event) =>
                            this.handleTooltip({
                                event,
                                data,
                                xDate,
                                xScale,
                                yScale,
                                yScaleEngagement,
                                yScaleVolume,
                            })}
                        onMouseLeave={() => () => hideTooltip()}
                    />
                    {tooltipData && (
                        <g>
                            <Line
                                from={{ x: tooltipLeft, y: 0 }}
                                to={{ x: tooltipLeft, y: yMax }}
                                stroke="#9B9B9B"
                                strokeWidth={2}
                                style={{ pointerEvents: 'none' }}
                                strokeDasharray="4,4"
                            />
                            {tooltipData.publications && (
                                <>
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop.publications + 1 + height - margin.bottom - publicationsHeight}
                                        r={4}
                                        fill="black"
                                        fillOpacity={0.1}
                                        stroke="black"
                                        strokeOpacity={0.1}
                                        strokeWidth={2}
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop.publications + height - margin.bottom - publicationsHeight}
                                        r={4}
                                        fill="#d4d4d4"
                                        stroke="white"
                                        strokeWidth={2}
                                        style={{ pointerEvents: 'none' }}
                                    />
                                </>
                            )}
                            {tooltipData.engagements && (
                                <>
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop.engagements + 1}
                                        r={4}
                                        fill="black"
                                        fillOpacity={0.1}
                                        stroke="black"
                                        strokeOpacity={0.1}
                                        strokeWidth={2}
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop.engagements}
                                        r={4}
                                        fill={interpolateLab('white', color)(0.3)}
                                        stroke="white"
                                        strokeWidth={2}
                                        style={{ pointerEvents: 'none' }}
                                    />
                                </>
                            )}
                            {tooltipData.followers && (
                                <>
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop.followers + 1}
                                        r={4}
                                        fill="black"
                                        fillOpacity={0.1}
                                        stroke="black"
                                        strokeOpacity={0.1}
                                        strokeWidth={2}
                                        style={{ pointerEvents: 'none' }}
                                    />
                                    <circle
                                        cx={tooltipLeft}
                                        cy={tooltipTop.followers}
                                        r={4}
                                        fill={color}
                                        stroke="white"
                                        strokeWidth={2}
                                        style={{ pointerEvents: 'none' }}
                                    />
                                </>
                            )}
                        </g>
                    )}
                </svg>
                {tooltipData && !mobile && (
                    <div>
                        {(_isNumber(tooltipTop.followers) ||
                            _isNumber(tooltipTop.engagements) ||
                            _isNumber(tooltipTop.publications)) && (
                            <Spring
                                config={{
                                    tension: 300,
                                    friction: 40,
                                }}
                                to={{ springTooltipLeft: tooltipLeft }}
                            >
                                {() => (
                                    <Tooltip
                                        top={
                                            (tooltipTop.followers ||
                                                tooltipTop.engagements ||
                                                tooltipTop.publications + height - margin.bottom - publicationsHeight) -
                                            12
                                        }
                                        left={tooltipLeft + 12}
                                        style={{
                                            border: 'none',
                                            padding: 0,
                                            zIndex: 10,
                                        }}
                                    >
                                        <div className="bc-chart-tooltip">
                                            <div
                                                className="bc-chart-tooltip__title"
                                                style={{
                                                    color,
                                                }}
                                            >
                                                {datetimeFormatter(xDate(tooltipData), locale, formats.date)}
                                            </div>
                                            <div className="bc-chart-tooltip__label">
                                                {`${lexique.legends.community} ${simpleNumberFormatter(
                                                    yFollowers(tooltipData),
                                                )}`}
                                            </div>
                                            <div className="bc-chart-tooltip__label">
                                                {`${lexique.legends.engagements} ${simpleNumberFormatter(
                                                    yEngagements(tooltipData),
                                                )}`}
                                            </div>
                                            <div className="bc-chart-tooltip__label">
                                                {`${lexique.legends.publications} ${simpleNumberFormatter(
                                                    yPublications(tooltipData),
                                                )}`}
                                            </div>
                                        </div>
                                    </Tooltip>
                                )}
                            </Spring>
                        )}
                        <Tooltip
                            top={yMaxLine + 5}
                            left={tooltipLeft}
                            style={{
                                transform: 'translateX(-50%)',
                            }}
                        >
                            <div className="bc-chart-tooltip__label">{formatDate(xDate(tooltipData))}</div>
                        </Tooltip>
                    </div>
                )}
                {tooltipData &&
                    mobile &&
                    (_isNumber(tooltipTop.followers) ||
                        _isNumber(tooltipTop.engagements) ||
                        _isNumber(tooltipTop.publications)) && (
                        <div className="bc-chart-tooltip">
                            <div
                                className="bc-chart-tooltip__title"
                                style={{
                                    color,
                                }}
                            >
                                {datetimeFormatter(xDate(tooltipData), locale, formats.date)}
                            </div>
                            <div className="bc-chart-tooltip__label">
                                {`${lexique.legends.community} ${simpleNumberFormatter(yFollowers(tooltipData))}`}
                            </div>
                            <div className="bc-chart-tooltip__label">
                                {`${lexique.legends.engagements} ${simpleNumberFormatter(yEngagements(tooltipData))}`}
                            </div>
                            <div className="bc-chart-tooltip__label">
                                {`${lexique.legends.publications} ${simpleNumberFormatter(yPublications(tooltipData))}`}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default withParentSize(withTooltip(Chart));
