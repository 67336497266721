export const SET_ALL_UNREAD_MESSAGES = '[Conversations] --> set all unread messages';
export const LOAD_CONVERSATIONS = '[Conversations] --> load list conversations';
export const UPDATE_CONVERSATIONS_WS = '[Conversations] --> update list conversations from WS';
export const SORT_INBOX_LIST = '[Conversations] -> sort list inbox';
export const LOAD_DRAFT_MESSAGES = '[Conversations] --> load list drafts';
export const DELETE_DRAFT = '[Conversations] --> remove draft';
export const LOAD_CONVERSATION_INFOS = '[Conversations] --> load conversation infos';
export const LOAD_ALL_MESSAGES = '[Conversations] --> load all messages';
export const RESET_CURRENT_CONVERSATION = '[Conversations] --> reset current conversation';
export const ADD_NEW_MESSAGE = '[Conversations] --> add new message';
export const HANDLE_VIEW = '[Conversations] --> display panel';
export const UPDATE_FILTERS = '[Conversations] --> update filters';
export const UPDATE_DRAFT = '[Conversations] --> update draft';
