import _sortBy from 'lodash.sortby';

import fr_FR from './fr-FR';
import en_GB from './en-GB';

export enum Locale {
    fr_FR = 'fr_FR',
    en_GB = 'en_GB',
    'fr-FR' = 'fr-FR',
    'en-GB' = 'en-GB',
}

const lexique = { fr_FR, en_GB };
const defaultLocale: Locale = Locale.fr_FR; // For internal use as fallback. To change user default language, change the value "locale" returned from endpoint api > user.js > appInit

export default (locale: Locale) => {
    const l: Locale = Locale[locale.replace('-', '_')];
    return lexique[l] ? lexique[l] : lexique[defaultLocale];
};

export const getLocale = (locale: Locale): Locale => Locale[locale.replace('_', '-')];

export const availableLocales = _sortBy(
    Object.keys(lexique).map((l) => ({
        label: lexique[l].config.label,
        iso3: lexique[l].config.iso3,
        id: lexique[l].config.iso,
    })),
    ['label'],
);
