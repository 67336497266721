export default {
    metrics: {
        community: `Communauté`,
        engagement: `Taux d'engagement`,
        engagementRate: `Taux d'engagement`,
        likes: 'Likes',
        dislikes: 'Dislikes',
        comments: 'Commentaires',
        shares: 'Partages',
        views: 'Vues',
        plays: 'Plays',
    },
    legend: `Moyennes calculées sur le dernier mois`,
    waiting: `Vos statistiques sont en cours de traitement, vous pourrez y accéder dans moins de 24h`,
    delete: 'Supprimer ce compte',
    expiredInstagram: {
        label: 'La connexion avec Instagram a expiré !',
        button: 'Se reconnecter',
        hover: {
            statsShared: 'Reconnecte toi vite afin de pouvoir continuer à profiter pleinement de l’application et aussi maintenir le partage automatique de tes statistiques à tes marques partenaires.',
            statsNotShared: 'Reconnecte toi afin de pouvoir continuer à profiter pleinement de l’application.',
        },
    },
};
