import { TimeRange } from 'types/Filters';
import moment from 'moment';

export interface TimeRangeParameters {
    start_at: string | null;
    end_at: string | null;
}

export const getTimeRangeParameters = (timeRange: TimeRange, allWithDates = false): TimeRangeParameters => {
    let end_at: string = moment().endOf('day').format();
    const startAt: moment.Moment = moment().startOf('day');
    let start_at: string;
    switch (timeRange) {
        case TimeRange.oneMonth:
            start_at = startAt.subtract(1, 'months').format();
            break;
        case TimeRange.threeMonths:
            start_at = startAt.subtract(3, 'months').format();
            break;
        case TimeRange.sixMonths:
            start_at = startAt.subtract(6, 'months').format();
            break;
        case TimeRange.oneYear:
            start_at = startAt.subtract(1, 'years').format();
            break;
        default:
        case TimeRange.all:
            start_at = allWithDates ? moment(new Date('2017-01-01')).format() : null;
            end_at = allWithDates ? end_at : null;
            break;
    }

    return {
        end_at,
        start_at,
    };
};
