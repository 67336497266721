import ProjectsPageLexique from 'locales/types/containers/projectsPage';

export const projectPageLexique: ProjectsPageLexique = {
    headTitle: 'Messages - Kolsquare',
    back: `Retour aux messages`,
    mobilePlaceholder: `Ecrivez votre message`,
    submit: {
        send: 'Envoyer',
        sent: 'Envoyer !',
        sending: 'En envoie...',
    },
    conversation: {
        brief: {
            agree: 'Je suis intéressé',
            disagree: 'Je ne suis pas intéressé',
        },
        notification: {
            agreed: `Vous êtes intéressé par la proposition de `,
            disagreed: `Vous n'êtes pas intéressé par la proposition de `,
            statsSharing: {
                title: `Aidez-nous à compléter notre reporting.`,
                subTitle: `{kolName} nous souhaiterions obtenir les statistiques de vos posts et stories Instagram via Kolsquare. Cela nous permettra de compléter notre rapport de statistiques et gagner du temps.`,
                linkDescription: `Plus besoin de capture d'écran, donnez-nous accès en toute sécurité en suivant le lien:`,
                linkLabel: `Partage des statistiques`,
            },
        },
    },
};

export default projectPageLexique;
