import { createAction } from 'redux-actions';

import * as types from 'constants/propositionAnswerMobile';

export const openModal = createAction(types.MODAL_OPEN);
export const closeModal = createAction(types.MODAL_CLOSE);

export const onClick = createAction(types.ON_CLICK, (id, type, text) => ({
    id,
    type,
    text,
}));

export const postAnswerSuccess = createAction(types.POST_ANSWER_SUCCESS, (data) => data);
export const postAnswerFailure = createAction(
    types.POST_ANSWER_FAILURE,
    ({ id, type, text, errorType, optionals }) => ({
        error: { id, type, text, errorType, ...optionals },
    }),
);
