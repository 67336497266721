import React, { Component } from 'react';
import { connect } from 'react-redux';
import classname from 'classnames';
import _clone from 'lodash.clone';

import { show } from 'actions/menu';
import { changeLocale } from 'actions/env';
import { availableLocales, getLocale } from 'locales/index';
import getLexique, { Locale } from 'locales';
import { isEventOutside } from 'utils/dom';
import { HeaderLexique } from 'locales/types/containers/shared/header';

import * as FLAGS from 'components/svgs/flags';
import { AppState } from 'reducers';
import './LanguageDropdown.scss';

interface Props {
    changeLocale: (id: string) => void;
    locale: Locale;
    lexique: HeaderLexique;
}

interface State {
    deployed: boolean;
}

class LanguagesDropdown extends Component<Props, State> {
    ref: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.state = { deployed: false };
        this.ref = React.createRef();
    }

    onClick(id: string) {
        this.props.changeLocale(id);
    }

    componentWillUnmount() {
        this.reploy();
    }

    checkClickOutside = (e) => {
        if (isEventOutside(e, this.ref.current)) this.reploy();
    };

    toggleDeploy() {
        if (this.state.deployed) this.reploy();
        else this.deploy();
    }

    deploy() {
        this.setState({ deployed: true });
        document.addEventListener('click', this.checkClickOutside, false);
        document.addEventListener('touchend', this.checkClickOutside, false);
    }

    reploy() {
        this.setState({ deployed: false });
        document.removeEventListener('click', this.checkClickOutside, false);
        document.removeEventListener('touchend', this.checkClickOutside, false);
    }

    clickOnAnItem = () => {
        this.reploy();
    };

    render() {
        const { locale } = this.props;
        const localeName = getLocale(locale);

        if (availableLocales.length <= 1) return null;
        // getting current locale datas
        const index = availableLocales.findIndex((l) => l.id === localeName);
        if (index === -1) return null;

        const current = availableLocales[index];
        const locales = _clone(availableLocales);
        locales.splice(index, 1);

        return (
            <div className="bc-language-dropdown" ref={this.ref}>
                <button type="button" className="bc-language-dropdown" onClick={() => this.toggleDeploy()}>
                    {FLAGS[`FLAG_${current.iso3.toUpperCase()}`]}
                </button>
                <div>
                    <div
                        className={classname({
                            'bc-language-dropdown-menu': true,
                            'bc-language-dropdown-menu--visible': this.state.deployed,
                        })}
                    >
                        <div className="bc-language-dropdown-menu__arrow" />
                        <div className="bc-language-dropdown-menu__content" onClick={this.clickOnAnItem}>
                            {locales.map((l) => (
                                <Item key={l.id} data={l} onClickHandler={(id) => this.onClick(id)} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const Item = (props) => (
    <button
        type="button"
        className="bc-language-dropdown-menu__item"
        onClick={() => props.onClickHandler(props.data.id)}
    >
        <div className="bc-language-dropdown-menu__item-picto">{FLAGS[`FLAG_${props.data.iso3.toUpperCase()}`]}</div>
    </button>
);

const mapStateToProps = (state: AppState): Partial<Props> => ({
    lexique: getLexique(state.env.locale).containers.shared.header.header,
    locale: state.env.locale,
});

export default connect(mapStateToProps, {
    show,
    changeLocale,
})(LanguagesDropdown);
