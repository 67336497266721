import React, { FC, memo, useEffect, useCallback } from 'react';
import _isEmpty from 'lodash.isempty';
import { GoogleCharts } from 'google-charts';

import { conf } from 'config/env';

// @TODO typing data
interface Props {
  id: number | string;
  visualizationType: string;
  chartType?: string;
  data: any[]; // @TODO type
  columns?: any[]; // @TODO type
  load: any[]; // @TODO type
  options: any; // @TODO type
}

const defaultProps = {
  columns: [],
  options: null,
};

const GoogleChart: FC<Props> = ({ id, data, options, columns, visualizationType, chartType, load }) => {
  const drawChart = useCallback(() => {
    if (_isEmpty(data) || !id) return null;

    const dataTable = GoogleCharts.api.visualization.arrayToDataTable(data);
    const view = new GoogleCharts.api.visualization.DataView(dataTable);

    if (!_isEmpty(columns)) {
      view.setColumns(columns);
    }

    let chart;

    try {
      chart = new GoogleCharts.api.visualization[visualizationType](
        document.getElementById(`bc-google-chart__GoogleChart-${id}`),
      );
    } catch (e) {
      chart = new GoogleCharts.api.charts[visualizationType](
        document.getElementById(`bc-google-chart__GoogleChart-${id}`),
      );
    }

    if (chartType) {
      chart.draw(view, GoogleCharts.api.charts[chartType].convertOptions(options));
    } else {
      chart.draw(view, options);
    }
  }, [data, columns, options, chartType, id, visualizationType]);

  useEffect(() => {
    GoogleCharts.load(drawChart, {
      'packages': load,
      'mapsApiKey': conf.GMapAPIKey // Note: you will need to get a mapsApiKey for geochart view
    });
  }, [drawChart, load]);

  if (!data) return null;

  return (
    <div className="bc-google-chart-container">
      <div className="bc-google-chart" id={`bc-google-chart__GoogleChart-${id}`} />
    </div>
  );
};

GoogleChart.defaultProps = defaultProps;

export default memo(GoogleChart);
