import React, { FC } from 'react';
import cn from 'classnames';
import './button.scss';

interface Props {
    onClick: (...args: any[]) => void;
    className?: string;
    label: string;
    disabled?: boolean;
}

const Button: FC<Props> = ({ onClick, className, label, disabled }) => {
    const classNames = cn({
        button: true,
        [className]: !!className,
    });
    return (
        <button type="button" className={classNames} onClick={onClick} disabled={disabled}>
            {label}
        </button>
    );
};

export default Button;
