import { useCallback } from 'react';
import { setLoader } from 'actions/ui';
import { useDispatch, useSelector } from 'utils/redux';

const useLoading = id => {
  const dispatch = useDispatch();

  const loading = useSelector(
    ({ ui }) => ui.loader.includes(id)
  );

  const on = useCallback(
    () => dispatch(setLoader({ id, show: true })),
    [dispatch, id]
  );

  const off = useCallback(
    () => dispatch(setLoader({ id, show: false })),
    [dispatch, id]
  );

  return { on, off, loading };
};

export default useLoading;
