import React, { FC, memo, useCallback, useMemo } from 'react';

import { useSelector, useDispatch, useLexique } from 'utils/redux';

import IconButton from '@material-ui/core/IconButton';
import { Button } from '@brandandcelebrities/kolkitten';
import TrashAlt from '@brandandcelebrities/icons/regular/TrashAlt';

import { SnasState } from 'reducers/snas';
import { confirmRemoveSna } from 'actions/sna';
import { showModale } from 'actions/modale';

import { Networks, SOCIAL_NETWORKS } from 'constants/networks';

import SocialName from 'components/molecules/SocialName';
import Tooltip from 'components/atoms/Tooltip';

import ProfileStrengthMeter from 'components/templates/ProfileStrengthMeter';

import './ProfilePageSidebar.scss';

const unavailabeSnasSideBar = [Networks.instagram_stories, Networks.website, Networks.blog, Networks.other_networks];

interface Lexique {
    title: string;
    delete: string;
    edit: string;
}

interface State {
    snas: SnasState;
}

const ProfilePageSidebar: FC = () => {
    // Lexique
    const lexique = useLexique<Lexique>('containers.shared.socialNetwoksSidebar');

    // Store
    const { snas }: State = useSelector(({ snas }): State => ({ snas }));

    // Dispatch
    const dispatch = useDispatch();

    const onDelete = useCallback(
        (network, uid) => {
            dispatch(
                confirmRemoveSna({
                    network,
                    uid,
                }),
            );
        },
        [dispatch],
    );

    const onClickEditButton = useCallback(() => {
        dispatch(
            showModale({
                body: 'SnaForm',
            }),
        );
    }, [dispatch]);

    const profileSnas = useMemo(
        () =>
            Object.values(Networks).map((network) => {
                if (unavailabeSnasSideBar.includes(network)) return null;

                const snasOfNetwork = snas[network];
                if (!snasOfNetwork || snasOfNetwork.length === 0) return null;

                return snasOfNetwork.map((sna) => {
                    if (SOCIAL_NETWORKS[network].supported) {
                        // supported SNA
                        if (sna && sna.username) {
                            return (
                                <div className="profile-sna__item" key={`${network}${sna.uid}`}>
                                    <SocialName name={sna.username} network={network} />
                                    <DeleteButton network={network} uid={sna.uid} onClick={onDelete} />
                                </div>
                            );
                        }
                    } else if (sna && sna.url && sna.url.length > 0) {
                        // not supported SNA
                        return (
                            <div className={`profile-sna__item ${network}-item`} key={`${network}`}>
                                <SocialName name={sna.url} network={network} />
                                <DeleteButton network={network} onClick={onDelete} />
                            </div>
                        );
                    }
                    return null;
                });
            }),
        [snas, onDelete],
    );

    return (
        <div className="profile-page-sidebar">
            <ProfileStrengthMeter />

            <div className="profile-page-sna">
                <h3 className="profile-page-sidebar__title">{lexique.title}</h3>

                {profileSnas}

                <Button
                    onClick={onClickEditButton}
                    label={lexique.edit}
                    size="small"
                    theme="blue"
                    className="profile-page-sidebar__edit"
                />
            </div>
        </div>
    );
};

interface StateButton {
    network: Networks;
    onClick: (...args: any[]) => void;
    uid?: string;
}

export const DeleteButton: FC<StateButton> = ({ network, uid, onClick }) => {
    const lexique = useLexique<Lexique>('containers.shared.socialNetwoksSidebar');

    const onDelete = useCallback(() => onClick(network, uid), [network, uid, onClick]);

    return (
        <Tooltip html={<div className="tooltip-small">{lexique.delete}</div>} position="top">
            <IconButton className="profile-sna__delete" onClick={onDelete}>
                <TrashAlt />
            </IconButton>
        </Tooltip>
    );
};

export default memo(ProfilePageSidebar);
