import links from '../links';

const register = {
  headTitle: 'Inscrivez-vous - Kolsquare',
  title: "Rejoindre kolsquare",
  subtitle: "Créer ton profil",
  description: "Mets ton profil en avant auprès de nos marques partenaires",

  email: "Email",
  password: "Mot de passe ({{min}} caractères minimum)",
  subscribeNewsletter: "Je souhaite recevoir mon rapport statistique mensuel Kolsquare",
  submit: `S'inscrire`,
  signIn: "Se connecter",
  acceptLegalTerms: `J'accepte les <a href="${links.legalTerms}" target="_blank" rel="noopener noreferrer">CGU</a> de Kolsquare`,
  error: {
    hasNotAcceptedLegalTerms: "Veuillez accepter nos CGU",
    invalidEmail: "Cet email est invalide",
    empty: "Ce champ est requis",
    passwordLength: "{{min}} caractères minimum",
    alreadyUsedEmail: "Cet email est déjà utilisé, essayez de vous connecter",
    maintenance: "Notre application est en cours de maintenance... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée",
    unknown: "Une erreur critique est survenue... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée"
  }
}

export default register;