import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { GoogleChart } from 'components/molecules';

import { numberFormatter, percentFormatter } from 'utils';
import getLexique from 'locales';

import { SOCIAL_NETWORKS } from 'constants/networks';
import { EngagementPerPosts } from 'types/snas.d';
import './GraphCard.scss';

const TOTAL_COLOR = '#DDDDDD';

interface Stats {
    avg_engagement_per_post: EngagementPerPosts;
    social_network: string;
    avg_matched_engagement: EngagementPerPosts;
}

interface Props {
    lexique: any; // @TODO type
    lexiqueNumberFormat: any;
    stats: Stats[];
}

type ChartData =
    | string
    | number
    | {
          role?: string | number;
          type?: string;
          p?: {
              html?: boolean;
          };
      };

type Data = Array<ChartData[]>;

const getValueAt = (column, dataTable, row) => {
    const data = [
        { label: 'T', unit: 1e12 },
        { label: 'B', unit: 1e9 },
        { label: 'M', unit: 1e6 },
        { label: 'k', unit: 1e3 },
        { label: '', unit: 1 },
    ].find(({ unit }) => dataTable.getValue(row, column) / unit >= 1);
    const n = dataTable.getValue(row, column);

    if (n === null) return '---';
    if (!data) return '0';
    return `${Math.floor((n * 10) / data.unit) / 10}${data.label}`;
};

class AverageChart extends PureComponent<Props> {
    render() {
        const { lexique, lexiqueNumberFormat, stats } = this.props;
        if (!stats) {
            return null;
        }

        const createCustomHTMLContent = (label: string, engagement: number, percent?: number) =>
            `<div class="bc-analytics-graph-card__tooltip">
        <h3 style="color: ${SOCIAL_NETWORKS[label].color};">
          ${SOCIAL_NETWORKS[label].label}
        </h3>
        <p>
          ${numberFormatter(engagement, lexiqueNumberFormat)} ${lexique.tooltip.engagement}
        </p>
        <p>
          ${percent !== undefined ? percentFormatter(percent) : ''}
        </p>
      </div>`;

        const data: Data = [
            [
                'social network',
                'average',
                { role: 'style' },
                { type: 'string', role: 'tooltip', p: { html: true } },
                'total',
                { role: 'style' },
                { type: 'string', role: 'tooltip', p: { html: true } },
            ],
        ];

        stats.map((row) => {
            const percent =
                !!row.avg_engagement_per_post.absolute && row.avg_matched_engagement.absolute !== null
                    ? ((row.avg_matched_engagement.absolute - row.avg_engagement_per_post.absolute) /
                          row.avg_engagement_per_post.absolute) *
                      100
                    : null;

            data.push([
                SOCIAL_NETWORKS[row.social_network].label,
                row.avg_matched_engagement.absolute || 0,
                SOCIAL_NETWORKS[row.social_network].color,
                createCustomHTMLContent(row.social_network, row.avg_matched_engagement.absolute, percent),
                row.avg_engagement_per_post.absolute || 0,
                TOTAL_COLOR,
                createCustomHTMLContent(row.social_network, row.avg_engagement_per_post.absolute),
            ]);

            return null;
        });

        return (
            <div className="bc-analytics-graph-card">
                <div className="bc-analytics-graph-card__label">{lexique.label}</div>
                <GoogleChart
                    id="AverageChart"
                    visualizationType="ColumnChart"
                    chartType="Bar"
                    load={['corechart', 'bar']}
                    data={data}
                    columns={[
                        0,
                        1,
                        {
                            calc: getValueAt.bind(undefined, 1),
                            sourceColumn: 1,
                            type: 'string',
                            role: 'annotation',
                        },
                        2,
                        3,
                        4,
                        {
                            calc: getValueAt.bind(undefined, 4),
                            sourceColumn: 4,
                            type: 'string',
                            role: 'annotation',
                        },
                        5,
                        6,
                    ]}
                    options={{
                        legend: { position: 'none' },
                        vAxis: {
                            format: 'short',
                            baselineColor: '#DDDDDD',
                            gridlines: { color: '#DDDDDD' },
                        },
                        hAxis: { textPosition: 'none' },
                        tooltip: { isHtml: true },
                    }}
                />
                <div className="bc-analytics-graph-card__legends">
                    <div className="bc-analytics-graph-card__legend">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                            <rect x="0" y="0" width="300" height="300" fill={SOCIAL_NETWORKS.facebook.color} />
                            <rect x="300" y="0" width="300" height="300" fill={SOCIAL_NETWORKS.instagram.color} />
                            <rect x="0" y="300" width="300" height="300" fill={SOCIAL_NETWORKS.twitter.color} />
                            <rect x="300" y="300" width="300" height="300" fill={SOCIAL_NETWORKS.youtube.color} />
                        </svg>
                        {lexique.legends.searchPosts}
                    </div>
                    <div className="bc-analytics-graph-card__legend">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0" y="0" width="300" height="300" fill="#DDDDDD" />
                        </svg>
                        {lexique.legends.totalPosts}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lexique: getLexique(state.env.locale).containers.analyticsPage.posts.charts.averageChart,
    lexiqueNumberFormat: getLexique(state.env.locale).config.numbers,
    stats: state.posts.all.stats,
    devices: state.env.viewport,
});

export default connect(mapStateToProps, null)(AverageChart);
