import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import moment from 'moment';

import * as ENV from 'constants/env';
import * as PROFILE from 'constants/profile';
import * as USER from 'constants/user';
import { AnyAction } from 'redux';
import { Networks } from 'constants/networks';

export type CompletionPercent = {
    completed: {
        personalInfo: boolean;
        moreInfo: boolean;
        instagramAccount: boolean;
        otherNetworks: boolean;
    };
    total: number;
};

export type SNAUserInfos = {
    uid: string;
    label: Networks;
    username?: string;
};

export type StatsSharingInStore = {
    isWorking: boolean;
    loaded: boolean;
    brandName?: string;
    requestsCount?: number;
    tokenExpiresAt?: moment.Moment;
    isNeededByCustomer?: boolean;
    fromRouter?: string;
    instagramBusinessId?: string;
};

export interface UserState {
    profileId: number | null;
    userId: string | null;
    firstName?: string;
    lastName?: string;
    pseudo?: string;
    gender?: string;
    activityLabels?: string[];
    themeLabels?: string[];
    pictureUrl?: string;
    isMascarade?: boolean;
    isEmailConfirmed?: boolean;
    isTrialOver?: boolean;
    isInstaconnectIgnored?: boolean;
    isLegalTermsSigned?: boolean;
    qualityScore?: number;
    snas: SNAUserInfos[];
    email?: string;
    deleting: boolean;
    enlisted?: boolean;
    errorDeleting?: string;
    completionPercent?: CompletionPercent;
    statsSharing: StatsSharingInStore;
    initialWsData: {
        notifications?: {
            unread_messages_count: number;
        };
    };
}

const initialState: UserState = {
    profileId: null,
    userId: null,
    snas: [],
    deleting: false,
    completionPercent: {
        completed: {
            personalInfo: false,
            moreInfo: false,
            instagramAccount: false,
            otherNetworks: false,
        },
        total: 0,
    },
    statsSharing: {
        isWorking: false,
        loaded: false,
        brandName: '',
        requestsCount: 0,
        instagramBusinessId: '',
    },
    initialWsData: {
        notifications: null,
    },
};

const reducers = {
    [ENV.FETCH_APP_INIT_SUCCESS]: (state: UserState, { payload: { response } }: AnyAction): UserState => {
        return update(state, {
            userId: { $set: response.user_id },
            email: { $set: response.email },
            profileId: { $set: response.profile_id },
            firstName: { $set: response.first_name },
            lastName: { $set: response.last_name },
            pseudo: { $set: response.pseudo },
            gender: { $set: response.gender },
            enlisted: { $set: response.enlisted },
            activityLabels: { $set: response.activity_labels },
            themeLabels: { $set: response.influence_themes },
            snas: { $set: response.snas || [] },
            pictureUrl: { $set: response.picture_url },
            isMascarade: { $set: response.is_masquerade === true },
            isEmailConfirmed: { $set: response.is_email_confirmed === true },
            isTrialOver: { $set: response.is_trial_over === true },
            isInstaconnectIgnored: { $set: response.is_instaconnect_ignored === true },
            isLegalTermsSigned: { $set: response.is_legal_terms_signed === true },
            qualityScore: {
                $set: response.quality_score ? response.quality_score * 100 : null,
            },
            initialWsData: {
                notifications: {
                    $set: response.notifications,
                }
            },
        })
    },
    [PROFILE.SAVE]: (state: UserState, { payload: { profile } }: AnyAction): UserState =>
        update(state, {
            firstName: { $set: profile.firstName },
            lastName: { $set: profile.lastName },
            pseudo: { $set: profile.stageName },
            activityLabels: { $set: profile.activities },
            gender: { $set: profile.gender },
            themeLabels: { $set: profile.influenceThemes },
        }),
    [USER.DELETE_USER]: (state: UserState): UserState =>
        update(state, {
            errorDeleting: { $set: null },
            deleting: { $set: true },
        }),
    [USER.DELETE_USER_FAIL]: (state: UserState, { payload }: AnyAction): UserState =>
        update(state, {
            errorDeleting: { $set: payload.error },
            deleting: { $set: false },
        }),
    [USER.DELETE_USER_SUCCESS]: (state: UserState): UserState =>
        update(state, {
            userId: { $set: initialState.userId },
            email: { $set: initialState.email },
            profileId: { $set: initialState.profileId },
            firstName: { $set: initialState.firstName },
            lastName: { $set: initialState.lastName },
            pseudo: { $set: initialState.pseudo },
            gender: { $set: initialState.gender },
            activityLabels: { $set: initialState.activityLabels },
            themeLabels: { $set: initialState.themeLabels },
            snas: { $set: [] },
            pictureUrl: { $set: null },
            qualityScore: { $set: null },
            errorDeleting: { $set: null },
        }),
    [USER.SET_COMPLETION_PERCENT]: (state: UserState, { payload }: AnyAction): UserState =>
        update(state, {
            completionPercent: {
                $set: payload,
            },
        }),
    [USER.TOGGLE_ENLIST_USER]: (state: UserState, { payload }: AnyAction): UserState =>
        update(state, {
            enlisted: {
                $set: payload,
            },
        }),
    [USER.SAVE_STATS_SHARING]: (state: UserState, { payload }: AnyAction): UserState =>
        update(state, {
            statsSharing: {
                $merge: payload,
            },
        }),
    [USER.SET_NO_MORE_INSTACONNECT]: (state: UserState, { payload }: AnyAction): UserState =>
        update(state, {
            isInstaconnectIgnored: {
                $set: payload,
            },
        }),
    [USER.SET_LEGAL_TERM_SIGNATURE]: (state: UserState, { payload }: AnyAction): UserState =>
        update(state, {
            isLegalTermsSigned: {
                $set: payload,
            },
        }),
};

export default createReducer<UserState, AnyAction>(initialState, reducers);
