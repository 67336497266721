export const LOAD_ALL_INIT = 'propositions/LOAD_INIT';
export const LOAD_ALL_COMPLETE = 'propositions/LOAD_COMPLETE';

export const LOAD_INIT = 'proposition/LOAD_INIT';
export const LOAD_COMPLETE = 'proposition/LOAD_COMPLETE';

export const CONTACTED = 'contacted';
export const SIGNED = 'signed';
export const CANCELED = 'canceled';
export const REFUSED = 'refused';
export const VALIDATED = 'validated';
