import React, { useCallback, useMemo } from 'react';
import { SelectTag } from '@brandandcelebrities/kolkit';
import { Activities } from '@brandandcelebrities/activities-themes';

const activities = new Activities();

const PINNED_ACTIVITIES = ['influencer_global', 'youtuber', 'blogger'];
const EXCLUDED_ACTIVITIES = ['politics', 'licence'];

interface Props {
    name: string;
    locale: string;
    value?: string[];
    placeholder?: string;
    onChange?: (onChangeEvent: { values: string[] }) => void;
    gender?: 'male' | 'female' | 'other';
    disabled?: boolean;
    maxTags?: number;
    className?: string;
}

const SelectActivity: React.FC<Props> = ({
    name,
    locale,
    value,
    placeholder,
    onChange,
    gender,
    disabled,
    maxTags,
    className,
}) => {
    const handleChange = useCallback(
        ({ item, selected }) => {
            onChange({ values: selected || [item] });
        },
        [onChange],
    );

    const dataset = useMemo(() => {
        return activities
            .locale(locale)
            .excludeStage(0)
            .exclude(EXCLUDED_ACTIVITIES)
            .gender(gender)
            .getAsSelectValues();
    }, [gender, locale]);

    return (
        <SelectTag
            id={name}
            onChange={handleChange}
            selected={value}
            label={placeholder}
            dataset={dataset}
            disabled={disabled}
            useNative
            className={className}
            size="big"
            maxTags={maxTags}
            pinned={PINNED_ACTIVITIES}
        />
    );
};

SelectActivity.defaultProps = {
    value: [],
    onChange: (): void => {},
    placeholder: '',
    gender: 'male',
    disabled: false,
    maxTags: 3,
};

export default SelectActivity;
