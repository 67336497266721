import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import orderBy from 'lodash.orderby';
import isEmpty from 'lodash.isempty';
import flatten from 'lodash.flatten';

import classNames from 'classnames';

import routes from 'config/routes';
import ChartArea from '@brandandcelebrities/icons/light/ChartArea';

import getLexique from 'locales';
import { SnasState } from 'reducers/snas';

import { Networks, supportedSocialNetwork } from 'constants/networks';
import { SNAObject } from 'types/snas';
import SocialStatsCardAdd from '../../SocialStatsCard/SocialStatsCardAdd';
import SocialStatsContainer from '../SocialStatsContainer/SocialStatsContainer';
import './SocialStatsList.scss';

interface Props {
    snas: SnasState;
    device: string;
    title?: string;
    link?: string;
    lexique: {
        legend: string;
        link: string;
        title: string;
    };
}

const isContentEmpty = (sna: SNAObject) => !sna.stats || !sna.stats.community_count;

class SocialStatsList extends Component<Props> {
    renderOrderedSnaCards() {
        const { snas } = this.props;
        const availableTypes: Networks[] = [];
        const snaCardsWithStats = flatten(
            supportedSocialNetwork.map(({ id }) => {
                const sortedSnas: SNAObject[] = orderBy<SNAObject>(snas[id], ['stats.community_count'], ['desc'], null);
                return sortedSnas.map((sna) => {
                    if (sna.filled && !isContentEmpty(sna)) {
                        availableTypes.push(id);
                        return <SocialStatsContainer key={`${id}${sna.uid}`} type={id} uid={sna.uid} />;
                    }
                    return null;
                });
            }),
        );
        const snaCardsNoStats = supportedSocialNetwork.map(({ id }) =>
            snas[id].map((sna: SNAObject) => {
                if (sna.filled && isContentEmpty(sna)) {
                    availableTypes.push(id);
                    return <SocialStatsContainer key={`${id}${sna.uid}`} type={id} uid={sna.uid} />;
                }
                return null;
            }),
        );
        const snaCardsEmpty = supportedSocialNetwork.map(({ id }) =>
            !availableTypes.find((t) => t === id) && (id === Networks.instagram || id === Networks.youtube) ? (
                <SocialStatsContainer key={id} type={id} />
            ) : null,
        );
        return [snaCardsWithStats, snaCardsNoStats, snaCardsEmpty];
    }

    renderLink() {
        const { lexique, snas } = this.props;

        if (
            !isEmpty(snas.facebook) ||
            !isEmpty(snas.instagram) ||
            !isEmpty(snas.twitter) ||
            !isEmpty(snas.youtube) ||
            !isEmpty(snas.tiktok)
        ) {
            return (
                <Link
                    className="bc-social-stats-list__link"
                    to={`${routes.analytics.index}${routes.analytics.audience}`}
                    onClick={() =>
                        window.ga('send', 'event', {
                            eventCategory: 'Dashboard',
                            eventAction: `Navigate`,
                            eventLabel: `View_Stats`,
                        })
                    }
                >
                    <div className="bc-social-stats-list__link-body">
                        <ChartArea />
                        {lexique.link}
                    </div>
                </Link>
            );
        }

        return null;
    }

    render() {
        const { lexique, device, link, title } = this.props;
        const [snaCardsWithStats, snaCardsNoStats, snaCardsEmpty] = this.renderOrderedSnaCards();

        return (
            <div className="bc-social-stats-list">
                {title && <h3 className="bc-social-stats-list__title">{lexique.title}</h3>}
                <p className="bc-social-stats__subtitle">({lexique.legend})</p>
                <div
                    className={classNames({
                        'bc-social-stats-list__social-stats-container': true,
                        'bc-social-stats-list__social-stats-container--row': device !== 'mobile',
                    })}
                >
                    {snaCardsWithStats}
                    {snaCardsNoStats}
                    {snaCardsEmpty}
                    <SocialStatsCardAdd />
                </div>
                {link && this.renderLink()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lexique: getLexique(state.env.locale).containers.shared.socialStatsList,
    snas: state.snas,
    device: state.env.device.type,
});

export default connect(mapStateToProps, null)(SocialStatsList);
