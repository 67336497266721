import PropositionDescriptionLexique from 'locales/types/containers/propositionPage/propositionDescription';

const propositionDescription: PropositionDescriptionLexique = {
    title: `Description de la proposition`,
    fields: {
        description: `Contexte et dispositif :`,
        performance: `Prestation :`,
        broadcastMedia: {
            label: `Support(s) de diffusion utilisé(s) par la marque :`,
            facebook: `Facebook`,
            instagram: `Instagram`,
            twitter: `Twitter`,
            youtube: `YouTube`,
            website: `Site officiel de la marque`,
        },
        rightsDuration: `Durée des droits :`,
        numberOfPosts: `Nombre de posts sur vos reseaux sociaux :`,
        media: {
            label: `Type de contenu :`,
            video: `Vidéo`,
            photo: `Photo`,
            text: `Texte`,
        },
        suggestion: `Exemple de post, de hashtag, de mention :`,
        amplifyBroadcast: {
            label: `Amplification :`,
            yes: `Les posts seront amplifiés par la marque`,
        },
        relayBroadcast: {
            label: `Relai / Partage des posts par la marque :`,
            yes: `Oui`,
        },
    },
};

export default propositionDescription;
