import components from './components';
import containers from './containers';
import countries from './countries';
import networks from './networks';
import links from './links';
import { Lexique } from '../types';

export const lexique: Lexique = {
  config: {
    label: 'Français',
    iso3: 'FRA',
    iso: 'fr-FR',
    date: 'DD.MM.YYYY',
    altDate: 'DD/MM/YYYY',
    shortDate: 'D MMMM',
    discussDate: 'D MMMM YYYY - H:mm',
    postDate: 'D MMMM YYYY',
    longDate: 'dddd D MMMM YYYY',
    hour: 'H:mm',
    now: `Maintenant {{time}}`,
    today: `Aujourd'hui {{time}}`,
    yesterday: `Hier {{time}}`,
    currencyCode: 'EUR',
    currency: '# ##0.## €',
    numbers: {
      b: `Md`,
      m: `M`,
      k: `k`,
    },
    gender: {
      male: 'Homme',
      female: 'Femme',
      other: 'Autre',
    },
  },
  links,
  components,
  containers,
  countries,
  networks,
};

export default lexique;
