import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Text } from '@brandandcelebrities/kolkit';

import { saveSna, saveSnaToStore } from 'actions/onboarding';
import { Networks } from 'constants/networks';
import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';
import StepperHeader from 'components/molecules/StepperHeader';
import { useDispatch, useLexique, useSelector } from 'utils/redux';
import { callGAEvent } from 'utils/googleAnalytics';

import SocialNetworksList from './components/SocialNetworksList';
import { NetworkAssociation } from './types.d';

import styles from '../Steps.module.scss';

interface Props {
    onOpenInstaconnect: () => void;
    onClickNext: () => void;
    onSkip: () => void;
}

const StepNetworks: React.FC<Props> = ({ onOpenInstaconnect, onClickNext, onSkip }) => {
    const dispatch = useDispatch();
    const lexique: OnboardingPageLexique = useLexique('containers.onboardingPage');
    const [networkAssociation, setNetworkAssociation] = useState<NetworkAssociation>({});

    const handleSave = useCallback(
        async (network: Networks, networkUrl: string) => {
            setNetworkAssociation((prev) => ({ ...prev, [network]: { ...prev[network], state: 'validating' } }));
            const snaSavingResult = await saveSna(network, networkUrl);
            if (snaSavingResult.error) {
                setNetworkAssociation((prev) => ({
                    ...prev,
                    [network]: { ...prev[network], state: 'editing', error: snaSavingResult.error },
                }));
            } else {
                setNetworkAssociation((prev) => ({ ...prev, [network]: { ...prev[network], state: 'set' } }));
                dispatch(saveSnaToStore(snaSavingResult.snaInfo));
            }
            callGAEvent('Onboarding', `add_social_network_${network}`, snaSavingResult.error ? 'Failure' : 'Success');
            return true;
        },
        [dispatch],
    );

    const { isInstagramConnected, snas } = useSelector(({ fbc: { selectedPages }, snas }) => ({
        isInstagramConnected: selectedPages?.instagram?.length > 0 && snas?.instagram[0] && snas.instagram[0].username,
        snas,
    }));

    useEffect(() => {
        const associationsFromSnas = Object.entries(snas).reduce(
            (associations, [network, accounts]) =>
                accounts?.length && accounts[0].id ? { ...associations, [network]: { state: 'set' } } : associations,
            {},
        );
        setNetworkAssociation((prev) => ({
            ...prev,
            ...associationsFromSnas,
            ...(isInstagramConnected
                ? {
                      [Networks.instagram]: { state: 'set' },
                  }
                : {}),
        }));
    }, [snas, isInstagramConnected]);

    const handleOpen = useCallback(
        (network: Networks) => {
            if (network === Networks.instagram) {
                onOpenInstaconnect();
            }
            setNetworkAssociation((prev) => ({ ...prev, [network]: { ...prev[network], state: 'editing' } }));
        },
        [onOpenInstaconnect],
    );

    const handleCancel = useCallback((network: Networks) => {
        setNetworkAssociation((prev) => ({ ...prev, [network]: { ...prev[network], state: 'unset', error: null } }));
    }, []);

    const handleClickValidate = useCallback(() => {
        if (isInstagramConnected) callGAEvent('Onboarding', 'stats_sharing', 'true');
        callGAEvent('Onboarding', 'step_4_social_networks');
        onClickNext();
    }, [isInstagramConnected, onClickNext]);

    const nextDisabled = useMemo(
        () => Object.values(networkAssociation).findIndex(({ state }) => state === 'set') === -1,
        [networkAssociation],
    );

    const isInstaConnected = useMemo(
        () => networkAssociation.instagram?.state === 'set',
        [networkAssociation.instagram],
    );

    return (
        <div>
            <StepperHeader
                header={lexique.header}
                title={isInstaConnected ? lexique.steps.networks.titleInstaconnected : lexique.steps.networks.title}
            />
            {!isInstaConnected && (
                <Text className={styles.helperText} size="small">
                    {lexique.steps.networks.helpText}
                </Text>
            )}
            <SocialNetworksList
                className={styles.socialNetworksList}
                networkAssociation={networkAssociation}
                onSave={handleSave}
                onOpen={handleOpen}
                onCancel={handleCancel}
            />
            <Button
                fullWidth
                size="fat"
                className={styles.button}
                disabled={nextDisabled}
                onClick={handleClickValidate}
            >
                {lexique.validate}
            </Button>
            <Button
                className={styles.skipAndCreate}
                theme="reset"
                fullWidth
                size="fat"
                onClick={onSkip}
            >
                {lexique.steps.networks.skipAndCreate}
            </Button>
        </div>
    );
};

export default StepNetworks;
