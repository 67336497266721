import { createAction } from 'redux-actions';

import * as types from 'constants/home';

import { TimeRange } from 'types/Filters';
import { AppState } from 'reducers';
import api from '../api';

export const load = createAction(types.LOAD);
const updateHomePageData = createAction(types.LOAD_RECAP_SUCCESS);

const defaultOneMonthFilter = {
    socialNetworksFilter: {},
    timeRangeFilter: TimeRange.oneMonth,
};

const getSocialNetworkFiltersFromAppState = (appState) => {
    const {
        user: { snas },
    }: AppState = appState;
    return snas.reduce((prev, sna) => {
        const { username, ...restOfSna } = sna;
        return {
            ...prev,
            [sna.label]: {
                ...restOfSna,
                selected: true,
            },
        };
    }, {});
};

export const getTopHashTagOneMonth = (): any => async (dispatch, getState) => {
    const { isEmailConfirmed, isTrialOver } = getState()?.user;
    if (!isEmailConfirmed && isTrialOver) return false;

    try {
        const response = await api.fetchPostsTopHashtags({
            ...defaultOneMonthFilter,
            socialNetworksFilter: getSocialNetworkFiltersFromAppState(getState()),
        });
        if (response) {
            dispatch(
                updateHomePageData({
                    field: 'topHashTag',
                    value: response.data[0],
                }),
            );
        }
        return true;
    } catch (e) {
        return false;
        // TODO manage error
    }
};
