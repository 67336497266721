import React, { FC, memo, useEffect, useCallback } from 'react';

import { useSelector, useDispatch, useLexique } from 'utils/redux';
import { datetimeFormatter } from 'utils';
import { loadSnaTopPosts } from 'actions/sna';
import { AppState } from 'reducers';

import { Networks } from 'types/snas';
import { ConfigLexique } from 'locales/types';

import { Post } from 'reducers/posts';
import styles from './SocialStatsTopPost.module.scss';

interface Props {
    snaId: number;
    network: Networks;
    topPosts?: Post[];
}

const SocialStatsTopPost: FC<Props> = ({ snaId, network, topPosts }) => {
    const tConfig = useLexique<ConfigLexique>('config');

    const { locale, isMobile } = useSelector((state: AppState) => ({
        locale: state.env.locale,
        isMobile: state.env.device.isMobile,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!topPosts) {
            dispatch(loadSnaTopPosts({ network, snaId, timeRange: 'oneMonth' }));
        }
    }, [dispatch, topPosts, network, snaId]);

    const renderImg = useCallback(
        (index: number) => {
            if (!topPosts || (topPosts && !topPosts[index])) return null;
            const { picture_url, content_url } = topPosts[index];
            if (!picture_url || picture_url === '') return null;

            return (
                <a href={content_url} target="_blank" rel="noopener noreferrer" className={styles.link}>
                    <div className={styles.image} style={{ backgroundImage: `url(${picture_url})` }} />
                </a>
            );
        },
        [topPosts],
    );

    const renderDate = useCallback(
        (index: number) => {
            if (!topPosts || (topPosts && !topPosts[index])) return null;
            const { published_at } = topPosts[index];

            return <span className={styles.date}>{datetimeFormatter(published_at, locale, tConfig.date)}</span>;
        },
        [topPosts, tConfig, locale],
    );

    if (isMobile && (!topPosts || (topPosts && topPosts.length === 0))) return null;

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Top posts</span>
            <div className={styles.content}>
                {React.Children.toArray(
                    Array(3)
                        .fill(null)
                        .map((n, i) => (
                            <div className={styles.post}>
                                <div className={styles.imgContainer}>{renderImg(i)}</div>
                                {renderDate(i)}
                            </div>
                        )),
                )}
            </div>
        </div>
    );
};

export default memo(SocialStatsTopPost);
