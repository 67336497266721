import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import isEmpty from 'lodash.isempty';
import { Icon, Button2, Tooltip } from '@brandandcelebrities/kolkit';

import SocialStatsCard from 'components/organisms/SocialStatsCard/SocialStatsCard';
import { closeModal, onSend } from 'actions/socialStatsCard';

import getLexique from 'locales';
import { SocialStatsContainerLexique } from 'locales/types/containers/shared/SocialStatsContainerLexique';
import { isNumberStat, numberFormatter, pointFormatter, percentFormatterSpace } from 'utils';

import { SocialStatsCardState } from 'reducers/ui/socialStatsCards';
import { Networks } from 'constants/networks';
import { SnasState } from 'reducers/snas';
import { AppState } from 'reducers';
import { Page } from 'types/Instaconnect';
import { SNAObject } from 'types/snas';
import { RenewingInstaConnect } from 'components/templates';

import SocialStatsCardModal from '../../SocialStatsCard/SocialStatsCardModal';
import SocialStatsTopPost from '../SocialStatsTopPost';
import SocialStatsCardEmpty from '../../SocialStatsCard/SocialStatsCardEmpty';

import styles from './SocialStatsContainer.module.scss';

const isContentEmpty = (sna) => !sna.stats || !sna.stats.community_count;

const printStat = (stat, lexique) => (isNumberStat(stat) ? numberFormatter(stat, lexique, true) : '-');

interface Props {
    type: Networks;
    uid?: string;
    onClickClose: (type: string) => void;
    onInstagramModalClose: (data: Page[]) => void;
    openInstagramModal: () => void;
    snas: SnasState;
    onClickFetchUrl: (
        type: string,
        url: string,
    ) => (e: React.KeyboardEvent<any> | React.MouseEvent<HTMLButtonElement>) => void;
    socialStatsCards: SocialStatsCardState;
    lexiqueNumberFormat: any;
    lexiqueSocialStatsCard: SocialStatsContainerLexique;
    showTopPost?: boolean;
    className?: string;
    hasInstagramExpired?: boolean;
    hasSharedStatistics?: boolean;
}

interface State {
    socialStatsCards: SocialStatsCardState;
    failedConnect: boolean;
    data: {
        [sna: string]: Page[];
    };
    selectedPages?: string[];
    type?: Networks;
    instaconnectOpen: boolean;
}

class SocialStatsContainer extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            socialStatsCards: props.socialStatsCards,
            failedConnect: false,
            data: null,
            instaconnectOpen: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { type } = props;
        if (props.socialStatsCards[type] && props.socialStatsCards[type].error !== state.socialStatsCards[type].error) {
            return {
                ...state,
                socialStatsCards: props.socialStatsCards,
            };
        }
        return state;
    }

    onCloseModal = (type) => {
        // Reset data when modale is closed
        this.props.onClickClose(type);
        this.setState({ data: null, selectedPages: [] });
    };

    toggleRenewingInstaconnect = () => this.setState((prev) => ({ instaconnectOpen: !prev.instaconnectOpen }));

    render() {
        const {
            lexiqueNumberFormat,
            lexiqueSocialStatsCard,
            socialStatsCards,
            type,
            uid,
            snas,
            showTopPost,
            className,
            hasInstagramExpired,
            hasSharedStatistics,
        } = this.props;
        const sna: SNAObject = snas[type].find((s) => s.uid === uid);

        const totalEngagement =
            sna && sna.stats && sna.stats.engagement_details
                ? Object.keys(sna.stats.engagement_details).reduce(
                      (acc, value) => acc + sna.stats.engagement_details[value] || 0,
                      0,
                  )
                : null;
        const avgEngagement = totalEngagement ? 100 * (totalEngagement / sna.stats.community_count) : null;

        let socialStatsComponent;
        switch (true) {
            case isEmpty(sna):
                socialStatsComponent = <SocialStatsCardEmpty type={type} />;
                break;
            case !isEmpty(sna) && isContentEmpty(sna):
                socialStatsComponent = (
                    <SocialStatsCard
                        username={sna.username}
                        type={type}
                        row={showTopPost}
                        lexique={lexiqueSocialStatsCard}
                    />
                );
                break;
            case !isEmpty(sna) && !isContentEmpty(sna):
                socialStatsComponent = (
                    <SocialStatsCard
                        uid={uid}
                        username={sna.username}
                        type={type}
                        metrics={[
                            {
                                type: 'community',
                                value: printStat(sna.stats.community_count, lexiqueNumberFormat),
                                evolution: percentFormatterSpace(sna.stats.community_progression_rate),
                                star: false,
                            },
                            {
                                type: 'engagement',
                                value: percentFormatterSpace(avgEngagement),
                                evolution: pointFormatter(sna.stats.engagement_rate_progression),
                                star: true,
                            },
                        ]}
                        lexique={lexiqueSocialStatsCard}
                        row={showTopPost}
                        showDeleteButton
                    />
                );
                break;
            default:
                socialStatsComponent = <SocialStatsCardEmpty type={type} />;
        }

        const cnContainer = classNames(
            styles.container,
            {
                [styles.showTopPost]: showTopPost,
                [styles.isButton]: isEmpty(sna),
            },
            className,
        );

        return (
            <div className={cnContainer}>
                <div className={styles.content}>
                    {socialStatsComponent}
                    {showTopPost && <SocialStatsTopPost snaId={sna.id} network={sna.label} topPosts={sna.top_posts} />}
                    <SocialStatsCardModal
                        socialStatsCard={socialStatsCards[type]}
                        onCloseModal={this.onCloseModal}
                        type={type}
                        onClickFetchUrl={this.props.onClickFetchUrl}
                    />
                </div>
                {hasInstagramExpired && !this.state.instaconnectOpen && (
                    <Tooltip
                        content={
                            lexiqueSocialStatsCard.expiredInstagram.hover[hasSharedStatistics ? 'statsShared' : 'statsNotShared']
                        }
                    >
                        <div className={styles.expiredInstagram}>
                            <Icon width={20} label="unlink" className={styles.icon} />
                            <span>{lexiqueSocialStatsCard.expiredInstagram.label}</span>
                            <Button2
                                onClick={this.toggleRenewingInstaconnect}
                                className={styles.button}
                                size="small"
                            >
                                {lexiqueSocialStatsCard.expiredInstagram.button}
                            </Button2>
                        </div>
                    </Tooltip>
                )}
                {hasInstagramExpired && (
                    <RenewingInstaConnect
                        modalOpened={this.state.instaconnectOpen}
                        onClose={this.toggleRenewingInstaconnect}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexiqueNumberFormat: getLexique(state.env.locale).config.numbers,
    lexiqueSocialStatsCard: getLexique(state.env.locale).components.socialStatsCards.socialStatsCard,
    socialStatsCards: state.ui.socialStatsCards,
    snas: state.snas,
    user: state.user,
    token: state.env.userToken,
});

const mapDispatchToProps = (dispatch) => ({
    onClickClose: (type) => {
        dispatch(closeModal(type));
    },
    onClickFetchUrl: (type, url) => {
        dispatch(onSend(type, url));
    },
    onInstagramModalClose: (pages) => {
        dispatch(closeModal(pages[0].label.split('_business')[0]));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialStatsContainer);
