import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from '@brandandcelebrities/kolkitten';
import getLexique from 'locales';
import './upgradeModal.scss';

import { AppState } from 'reducers';

interface Props {
    display: boolean;
    lexique: any; // @TODO type
}
class UpgradeModal extends PureComponent<Props> {
    reload = () => window.location.reload();

    render() {
        const { lexique, display } = this.props;
        if (!display) return null;

        return (
            <div className="bc-upgrade-modal">
                <div className="bc-upgrade-modal__text">
                    <p>{lexique.text}</p>
                </div>
                <div className="bc-upgrade-modal__button">
                    <Button onClick={this.reload} label={lexique.button} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ ui, env }: AppState) => ({
    lexique: getLexique(env.locale).containers.shared.modals.upgradeModal,
    display: ui.modals.upgradeModal.display,
});

export default connect(mapStateToProps)(UpgradeModal);
