import { EditorState, Modifier, SelectionState } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';

export const markdownToEditor = mdString => {
	const content = stateFromMarkdown(mdString);
	return EditorState.createWithContent(content);
}

export const editorToMarkdown = editorState => {
	const markdown = stateToMarkdown(editorState.getCurrentContent());

	// hack to remove white space between ++ markdown to fix Underline
	const matchesUnderline = markdown.match(/\++.+?\++/g);
	if (matchesUnderline?.length) {
		let markdownParsed = '';
		matchesUnderline.forEach(mark => {
			markdownParsed = markdown.replaceAll(mark, `++${mark.replaceAll('++','').trim()}++`)
		});
		return markdownParsed;
	}

	return markdown;
}

const getBlockSelection = (block, start, end) => {
  	const blockKey = block.getKey();
	return new SelectionState({
    	anchorKey: blockKey,
		anchorOffset: start,
		focusKey: blockKey,
		focusOffset: end,
  	});
}

export const pasteEditorStyles = editorState => {
	let newEditorState = editorState;
	let newContent = newEditorState?.getCurrentContent();
	const blocks = newContent.getBlocksAsArray();
	blocks.forEach(block => {
		block.findStyleRanges(() => true, (start, end) => {
			newContent = Modifier.applyInlineStyle(
				newContent,
				getBlockSelection(block, start, end),
				block.getInlineStyleAt(start),
			);
		});
		newEditorState = EditorState.push(newEditorState, newContent, 'change-inline-style');
  	});

	return newEditorState;
}