import React, { forwardRef, memo, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';

import { Avatar } from 'containers/messagingPage/Components';

import styles from './Card.module.scss';

interface Props {
  children: React.ReactChild;
  name: string;
  className?: string;
  active: boolean;
  rest?: any;
  onClick?: () => void;
  index: number;
  animated?: boolean;
}

const Card = forwardRef<HTMLDivElement, Props>( ({ children, name, className, animated, active, index, ...rest }, ref) => {

  const [mount, setMount] = useState(false);

  useEffect(() => {
    const delay = setTimeout(
      () => setMount(true),
      index * 50
    );

    return () => {
      setMount(false);
      clearTimeout(delay)
    }
  }, [index]);

  const cardStyle = useMemo(
    () => ({
      transform: (animated && mount) ? 'translateY(0)' : 'translateY(10px)',
      opacity: (animated && mount) ? 1 : 0,
    }),
    [mount, animated],
  );

  const cnWrapper = cn(styles.wrapper, className, {
    [styles.active]: active,
  });

  return (
    <div className={cnWrapper} ref={ref} style={cardStyle} {...rest}>
      <div className={styles.content}>
        <Avatar name={name} />
        <div className={styles.rightContent}>
          {children}
        </div>
      </div>
    </div>
  )
});

Card.displayName = 'Card';

Card.defaultProps = {
  children: null,
  className: '',
  active: false
}

export default memo(Card);
