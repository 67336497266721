import React, { memo, useState, useCallback, FC } from 'react';
import cn from 'classnames';
import { Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import ChevronDown from '@brandandcelebrities/icons/solid/ChevronDown';

import { Header } from 'components/atoms';
import { Placeholder } from 'components/molecules';
import { TopHashtagsLexique } from 'locales/types/containers/analyticsPage/posts';
import { AnalyticsHashtags } from 'reducers/ui/analytics';

import img from 'assets/images/proposition_list_empty.png';
import styles from './TopHashtags.module.scss';

interface Props {
    lexique: TopHashtagsLexique;
    hashtags: AnalyticsHashtags;
}

const TopHashtags: FC<Props> = ({ hashtags, lexique }) => {
    const { topHashtags, error } = hashtags;
    const [collapsed, setCollapse] = useState(true);

    const toggle = useCallback(
        (e) => {
            if (e) e.preventDefault();
            setCollapse((collapsed) => !collapsed);
        },
        [setCollapse],
    );

    const cnContent = cn(styles.content, { [styles.collapsed]: collapsed });
    const cnChevron = cn(styles.chevron, { [styles.chevronUp]: !collapsed });

    if (error) return null;

    return (
        <div className={styles.hashtags} id="top-hashtags">
            <Header title={lexique.title} description={lexique.description} />
            {topHashtags?.length > 1 && (
                <>
                    <section>
                        <div className={cnContent}>
                            <Table className={styles.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={styles.columnKey} />
                                        <TableCell className={styles.columnRate}>{lexique.engagementRate}</TableCell>
                                        <TableCell className={styles.columnComments}>{lexique.comments}</TableCell>
                                        <TableCell className={styles.columnLikes}>{lexique.likes}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {React.Children.toArray(
                                        topHashtags?.map((topHashtag) => {
                                            return (
                                                <TableRow>
                                                    <TableCell
                                                        className={styles.columnKey}
                                                    >{`#${topHashtag.key}`}</TableCell>
                                                    <TableCell className={styles.columnRate}>
                                                        {Math.round(
                                                            parseFloat(topHashtag.engagement_rate.toPrecision(12)) *
                                                                100,
                                                        )}
                                                        %
                                                    </TableCell>
                                                    <TableCell className={styles.columnComments}>
                                                        {topHashtag.comments}
                                                    </TableCell>
                                                    <TableCell className={styles.columnLikes}>
                                                        {topHashtag.likes}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }),
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </section>
                    {topHashtags?.length > 10 && (
                        <div className={styles.toggle}>
                            <a href="/" onClick={toggle}>
                                {collapsed ? lexique.seeMore : lexique.seeLess}
                                <span className={cnChevron}>
                                    <ChevronDown />
                                </span>
                            </a>
                        </div>
                    )}
                </>
            )}
            {(!topHashtags || topHashtags.length === 0) && <Placeholder img={img} tooltip="noResult" />}
        </div>
    );
};

export default memo(TopHashtags);
