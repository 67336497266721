export default {
    modal: {
        addInstagram: `Reconnecter Instagram`,
        addInstagramBusiness: `Reconnecter Instagram Business`,
        title: {
            facebook: `Reconnectez votre fanpage Facebook`,
            twitter: `Reconnectez votre compte Twitter`,
            instagram: `Reconnectez votre compte Instagram Business`,
            youtube: `Reconnectez votre chaîne Youtube`,
        },
        text: `Nous avons besoin de votre autorisation afin de pouvoir afficher vos posts et statistiques. `,
        form: {
            send: `Valider`,
        },
    },
};
