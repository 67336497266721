import ProfilePageLexique from 'locales/types/containers/profilePage';

export const profilePageLexique: ProfilePageLexique = {
    headTitle: 'My Profile - Kolsquare',
    pageTitle: `Profile`,
    titleInfos: `Your personal information`,
    titleActivity: `Your activity`,
    firstName: `Firstname`,
    lastName: `Lastname`,
    stageName: `Username on social networks`,
    errorRequired: `Please fill your full name or your username on social networks`,
    errorWebsiteInvalid: `Your website address is invalid.`,
    gender: {
        male: `Male`,
        female: `Female`,
        other: `Other`,
    },
    birthdate: {
        label: `Date of birth`,
        day: `Day`,
        month: `Month`,
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'Décember',
        ],
        year: `Year`,
    },
    nationality: `Nationality / Citizenship (max. 2)`,
    phone: {
        label: `Phone`,
        placeholder: `+33 X XX XX XX XX`,
    },
    activities: `Activities (max. 3)`,
    influenceThemes: `Topics of influence (max. 5)`,
    website: `Official website`,
    livingPlace: `City of residence`,
    bio: {
        label: `Bio (max. 80 characters)`,
        placeholder: `Example : Fitness coach and influencer`,
    },
    description: {
        label: `Description (bio, career, fields of influence, activities, ...)`,
        placeholder: `Example : I am journalist, fitness coach and a young mother! I was a sports journalist but I recently decided to take a fitness coach diploma in order to pursue my passion. In 2011, I created a fitness Web series accessible to all. In 2012, I was ambassador for a sportswear brand. I am looking for partnerships which match my values and my community : sport, lifestyle, health and family.`,
    },
    save: `Save`,
    saved: 'Your informations have been saved',
};

export default profilePageLexique;
