// eslint-disable-next-line import/no-unresolved
import { CANCELED, CONTACTED, REFUSED, SIGNED, VALIDATED } from 'constants/proposition';

export default {
    title: `My Propositions`,
    empty: {
        title: `You haven’t receive any proposition yet.`,
        mobileTitle: `To increase your visibility:`,
        body: `To increase your visibility on our search engine and receive propositions matching your profile: `,
        bullet1: `1. Connect your social networks`,
        bullet2: `2. Fill in your profile`,
        tagline: `We'll take care of the rest!`,
    },
    fields: {
        budget: {
            label: `Budget:`,
            empty: `To be defined`,
        },
        date: `Date :`,
        fromTo: `{0} to {1}`,
        networks: `Networks:`,
    },
    states: {
        [CONTACTED]: `Pending`,
        [VALIDATED]: `Accepted`,
        [SIGNED]: `Signed`,
        [CANCELED]: `Canceled`,
        [REFUSED]: `Declined`,
    },
};
