export default {
    thankingStatsSharingWithoutRequest: {
        title: '🎉 Merci {pseudo} !',
        text: `Vous partagez à présent vos statistiques.
        \n\n Retrouvez l'évolution de vos statistiques et de votre audience, votre score de crédibilité et l'estimation de vos tarifs directement sur votre tableau de bord Kolsquare !`,
        button: `J'ai compris !`,
    },
    thankingStatsSharingRequestFromBrand: {
        title: `🎉 Merci {pseudo} !`,
        titleAlreadyActivated: `Bonjour {pseudo}`,
        text: `Vos statistiques sont désormais partagées dans le rapport de **{brandName}**. Un délai de 24h est parfois nécessaire.
        \n\n Retrouvez l'évolution de vos statistiques et de votre audience, votre score de crédibilité et l'estimation de vos tarifs directement sur votre tableau de bord Kolsquare !`,
        textMany: `Vos statistiques sont désormais partagées dans le rapport de **{brandName}** et **{length} marque(s)** . Un délai de 24h est parfois nécessaire.
        \n\n Retrouvez l'évolution de vos statistiques et de votre audience, votre score de crédibilité et l'estimation de vos tarifs directement sur votre tableau de bord Kolsquare !`,
        textAlreadyActivated: `Vous avez déjà activé le partage de vos statistiques.
        \n\n Retrouvez l'évolution de vos statistiques et de votre audience, votre score de crédibilité et l'estimation de vos tarifs directement sur votre tableau de bord Kolsquare !`,
        button: `Mon tableau de bord Kolsquare`,
    },
};
