import React, { FC, memo } from 'react';
import cn from 'classnames';
import { Button, Text, Icon } from '@brandandcelebrities/kolkit';

import styles from './Placeholder.module.scss';

interface Props {
  title?: string;
  iconOptions?: {
    label: string;
    color: string;
  };
  description?: string;
  labelButton?: string;
  onClick?: () => void;
  className?: string;
}

const Placeholder: FC<Props> = ({ iconOptions, title, description, labelButton, onClick, className }) => {
  const cnWrapper= cn(styles.wrapper, className);

  return (
    <div className={cnWrapper}>
      {iconOptions && (
        <div className={styles.placeholderImg} style={{backgroundColor: `${iconOptions.color}1A`}}>
          <Icon
            label={iconOptions.label}
            theme="regular"
            width="54"
            fill={iconOptions.color}
          />
        </div>
      )}
      {title && (
        <Text fontWeight={700}>
          {title}
        </Text>
      )}
      {description && (
        <Text size="small" className={styles.description}>
          {description}
        </Text>
      )}
      {onClick && (
        <Button
          onClick={onClick}
          label={labelButton}
          size="fat"
        />
      )}
    </div>
  )
};

Placeholder.displayName = 'PlaceholderCard';

export default memo(Placeholder);
