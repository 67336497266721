import React, { memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

interface Props {
    closeToast?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ToastCloseButton: React.SFC<Props> = memo(({ closeToast }) => (
    <IconButton onClick={closeToast}>
        <Close />
    </IconButton>
));

export default ToastCloseButton;
