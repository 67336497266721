import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import { AnyAction } from 'redux';

import * as HOME from 'constants/home';
import { Hashtag } from './analytics';

export interface HomePageState {
    topHashTag: Hashtag;
}

const initialState: HomePageState = {
    topHashTag: null,
};

const reducers = {
    [HOME.LOAD_RECAP_SUCCESS]: (state: HomePageState, { payload: { field, value } }: AnyAction) =>
        update<HomePageState>(state, {
            [field]: { $set: value },
        }),
};

export default createReducer<HomePageState, AnyAction>(initialState, reducers);
