
export const readUploadedFileAsBase64 = inputFile => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {

    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException("Problem parsing input file."))
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }

    temporaryFileReader.readAsDataURL(inputFile)
  })
}

export const readUrlFileAsBase64 = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const temporaryFileReader = new FileReader();
  await new Promise((resolve, reject) => {
    temporaryFileReader.onload = resolve;
    temporaryFileReader.onerror = reject;
    temporaryFileReader.readAsDataURL(blob);
  });

  return {
    base64: temporaryFileReader.result,
    type: blob.type,
    size: blob.size
  }
}
