import React, { FC, memo } from 'react';
import { Networks } from 'constants/networks';
import { SocialPicto } from 'components/atoms';

import './ConnectButton.scss';

interface Props {
    onClickConnect: (e: React.MouseEvent<HTMLButtonElement>) => void;
    label: string;
}

const ConnectButton: FC<Props> = ({ onClickConnect, label }) => {

    return (
        <button type="button" className="btn-connect-instagram is--business" onClick={onClickConnect}>
            <div>
                <SocialPicto network={Networks.instagram} fill="#fff" /> {label}
            </div>
        </button>
    );
};

export default memo(ConnectButton);
