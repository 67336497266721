import { createAction } from 'redux-actions';

import * as UI from 'constants/ui';

export const showUpgradeModal = createAction(UI.SHOW_UPGRADE_MODAL);

export const showModale = createAction(UI.SHOW_MODAL);
export const hideModale = createAction(UI.HIDE_MODAL);

export const setLoader = createAction(UI.LOADER);
