import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import { AnyAction } from 'redux';
import * as ANALYTICS from 'constants/analytics';
import { Networks } from 'types/snas';

export interface Post {
    id: string;
    story: boolean;
    is_igtv?: boolean;
    is_reel?: boolean;
    social_network: Networks;
    engagement_details: {
        engagement: number;
    };
    avg_engagement: {
        absolute: number;
        percent: number;
    };
    picture_url: string;
    content_url: string;
    username?: string;
    uid: string;
    type: string;
    content: string;
    published_at: string;
}

export interface Stats {
    social_network: string;
    posts: {
        matched_stories: number;
        matched_posts: number;
    };
}

export interface PostsState {
    loading: boolean;
    all: {
        total: number;
        stats: Stats[];
        posts: Post[];
    };
}

const initialState: PostsState = {
    loading: true,
    all: {
        total: 0,
        stats: [],
        posts: [],
    },
};

const reducers = {
    [ANALYTICS.FETCH_POSTS_SUCCESS]: (state: PostsState, { payload: { posts } }: AnyAction) =>
        update(state, {
            loading: { $set: false },
            all: { $set: posts },
        }),
};

export default createReducer<PostsState, AnyAction>(initialState, reducers);
