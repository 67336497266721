import React, { FC } from 'react';

import ExclamationTriangle from '@brandandcelebrities/icons/solid/ExclamationTriangle';

import './Mascarade.scss';
import { useSelector } from 'utils/redux';
import { AppState } from 'reducers';
import { UserState } from 'reducers/user';

interface Selector {
    user: UserState;
}

const Mascarade: FC = () => {
    const { user } = useSelector<Selector>(({ user }: AppState) => ({
        user,
    }));
    if (!user) return null;

    const { isMascarade, firstName, lastName, pseudo } = user;
    if (!isMascarade) return null;

    return (
        <div className="bc-mascarade">
            <ExclamationTriangle />
            {`You are viewing this page as ${pseudo || `${firstName} ${lastName}`.trim()}`}
        </div>
    );
};

export default Mascarade;
