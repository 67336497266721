import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { ConnectedRouter, RouterLocation } from 'connected-react-router';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { ErrorBoundary } from "@appsignal/react";
import { Loader } from '@brandandcelebrities/kolkit';

import { history } from 'store/configureStore';
import routes from 'config/routes';
import { Locale } from 'locales';

import RouterLocationListener from 'components/organisms/RouterLocationListener';
import ScrollToTop from 'components/ScrollToTop';
import { Header, Footer, Modales, ModalKolkit, BannerContainer } from 'components/templates';
import { ToastCloseButton } from 'components/atoms';
import { Mascarade } from 'components/molecules';
import { BodyController, OnboardingGuard, Menu } from 'components/organisms';
import { useDispatch, useSelector } from 'utils/redux';
import { usePrevious } from 'utils/hooks';
import { ON_STATUS_CHANGE } from 'utils/fbc/FacebookBusinessConnect';

import { appInit } from 'actions/env';
import { saveStatsSharing } from 'actions/user';
import { setFbcLoginStatus } from 'actions/fbc';
import { appsignal } from "config/appsignal";
import { fbc } from 'config/connect';
import { UserState } from 'reducers/user';

import Posts from './analyticsPage/posts';
import Stats from './analyticsPage/stats';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import Partenariats from './analyticsPage/partenariats';
import HomePage from './homePage/HomePage';
import SettingsPage from './SettingsPage';
import ProfilePage from './profilePage/ProfilePage';
import PropositionPage from './propositionPage/PropositionPage';
import ConversationsPage from './messagingPage';
import FAQPage from './FAQPage';
import OnboardingPage from './OnboardingPage';
import UnconfirmedEmailPage from './UnconfirmedEmailPage';

// TODO (Hoby): remove when executed from kolkit
import 'react-dates/initialize';

// libs CSS
import 'react-tippy/dist/tippy.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@brandandcelebrities/kolkitten/scss/checkboxes.scss';
import '@brandandcelebrities/kolkitten/scss/button.scss';
import 'react-dates/lib/css/_datepicker.css'; // TODO: same as above

import './App.scss';

interface Selector {
  locale: Locale;
  logged: boolean;
  location: RouterLocation<any>;
  user: UserState;
}

const App = () => {
  const [mounted, setMounted] = useState(false);
  const [hasBanner, setHasBanner] = useState(false);

  const { locale, logged, location, user } = useSelector(
    ({ env, router, user }): Selector => ({
      locale: env.locale,
      logged: env.logged,
      location: router.location,
      user,
    }),
  );

  const prevLocation: RouterLocation<any> = usePrevious(location);
  const dispatch = useDispatch();

  const handleHasBannerChange = useCallback((newValue) => {
    setHasBanner(newValue);
  }, []);

  const mainContentCn = cn('main-content', {
    'has-banner': hasBanner,
  });

  useEffect(() => {
    dispatch(saveStatsSharing({ fromRouter: prevLocation?.pathname }));
  }, [prevLocation, dispatch]);

  useEffect(() => {
    fbc.once(ON_STATUS_CHANGE, (status: string) => {
      dispatch(setFbcLoginStatus(status));
    });
  }, [dispatch]);

  useEffect(() => {
    moment.locale(locale.substr(0, 2));
  }, [locale]);

  useEffect(() => {
    (async () => {
      if (!location?.query?.token && !location?.query?.type) {
        await dispatch(appInit());
      }
      setMounted(true);
    })();
  }, [dispatch, location]);

  const locationLogin = useMemo(
    () => ({
      pathname: routes.login,
      search: location?.search,
    }),
    [location],
  );

  const locationHome = useMemo(
    () => ({
      pathname: '/',
      search: location?.search,
    }),
    [location],
  );

  const renderRoutes = useMemo(() => {
    if (!mounted) return null;

    if (!logged) {
      return (
        <Switch>
          <Route exact path={routes.login} component={LoginPage} />
          <Redirect from="*" to={locationLogin} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path={`${routes.analytics.index}${routes.analytics.audience}`} component={Stats} />
        <Route path={`${routes.analytics.index}${routes.analytics.content}`} component={Posts} />
        <Route path={`${routes.analytics.index}${routes.analytics.partner}`} component={Partenariats} />

        <Route path={`${routes.conversations}/:conversationId`} component={ConversationsPage} />
        <Route path={routes.conversations} exact component={ConversationsPage} />

        <Route path={routes.profile} component={ProfilePage} />
        <Route path={`${routes.propositions}/:propositionId`} component={PropositionPage} />
        <Route exact path={routes.settings} component={SettingsPage} />
        <Route exact path={routes.faq} component={FAQPage} />
        <Route exact path={routes.unconfirmedEmail} component={UnconfirmedEmailPage} />

        {/* Redirect old messaging url */}
        <Redirect from="/projects" to={`${routes.conversations}`} />
        <Redirect from="/projects/:id" to={`${routes.conversations}/:id`} />

        <Redirect to={locationHome} />
      </Switch>
    );
  }, [mounted, logged, locationLogin, locationHome]);

  const renderApp = useMemo(
    () => (
      <div>
        {renderRoutes}
        <RouterLocationListener />

        <Modales />
        <ModalKolkit />
        <Menu />
        <Mascarade />
        <ToastContainer
          transition={Flip}
          autoClose={2000}
          closeButton={<ToastCloseButton />}
          toastClassName="toast"
          position={toast.POSITION.BOTTOM_CENTER}
          closeOnClick
          pauseOnHover
          draggablePercent={40}
        />
      </div>
    ),
    [renderRoutes],
  );

  return (
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <ErrorBoundary
          instance={appsignal}
          tags={{
            id: user.userId,
            name: `${user.firstName}  ${user.lastName}`,
            currentUrl: window.location.href,
          }}
        >
        <div className="App">
          <BodyController />
          {!mounted && <Loader fullScreen />}
          <Switch>
            <Route exact path={routes.onboarding} component={OnboardingPage} />
            <Route exact path={routes.register} component={RegisterPage} />
            <Route>
              <Header />
              <BannerContainer onHasBannerChange={handleHasBannerChange} />
              <main className={mainContentCn}>{renderApp}</main>
              <Footer />
            </Route>
          </Switch>
          <OnboardingGuard />
        </div>
        </ErrorBoundary>
      </ScrollToTop>
    </ConnectedRouter>
  );
};

export default memo(App);
