export default {
    navLinks: {
        home: `Home`,
        audience: `My audience`,
        content: `My content`,
        partner: `My visibility`,
        discuss: `Conversations`,
        profile: `My profile`,
        settings: 'My account',
        signout: `Log out`,
    },
};
