import React, { FC, ReactElement, ReactFragment,  useRef, useEffect, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import styles from './List.module.scss';

interface Props {
  startBottom?: boolean;
  scroll?: {
    top: number;
    behavior: string;
  };
  onScrollStop?: (scrollTop: number) => void;
  children: ReactElement | ReactFragment;
  rest?: any;
}

const List: FC<Props> = ({ startBottom, scroll, children, onScrollStop, ...rest }) => {

  const scrollbars:React.RefObject<any> = useRef(null);

  useEffect(() => {
    (async () => {
      const scroller = await scrollbars.current;
      if(startBottom && scroller) {
        scroller?.view?.scroll({
          top: scroller.getScrollHeight(),
          behavior: 'smooth'
        })
      }
    })();
    },
    [startBottom, scrollbars]
  );

  useEffect(() => {
    (async () => {
      const scroller = await scrollbars.current;
      if(scroll && scroller) {
        scroller?.view?.scroll(scroll)
      }
    })();
    },
    [scroll, scrollbars]
  );

  const handleScrollStop = useCallback(
    () => {
      const scrollTop = scrollbars?.current?.getScrollTop();
      if (onScrollStop) onScrollStop(scrollTop);
    },
    [scrollbars, onScrollStop]
  );

  return (
    <div className={styles.list} {...rest}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        ref={scrollbars}
        onScrollStop={handleScrollStop}
        hideTracksWhenNotNeeded
      >
        {children}
      </Scrollbars>
    </div>
  )
};

List.defaultProps = {
  startBottom: false,
}

export default List;

