import { memo, useEffect } from 'react';
import { useSelector } from 'utils/redux';
import { RouterState } from 'connected-react-router';

import styles from './RouterLocationListener.module.scss';

interface Selector {
    router: RouterState;
}

const headerOffset = parseInt(styles.topOffset, 10);

const RouterLocationListener = memo(() => {
    const hash = useSelector<string>(
        ({
            router: {
                location: { hash },
            },
        }: Selector) => hash,
    );

    useEffect(() => {
        if (hash) {
            // Push onto callback queue so it runs after the DOM is updated
            window.setTimeout(() => {
                const id = hash?.replace('#', '');
                const element = document?.getElementById(id);
                if (element) {
                    const offsetPosition = element.getBoundingClientRect().top - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }
            }, 0);
        }
    }, [hash]);
    return null;
});

export default RouterLocationListener;
