import React, { FC } from 'react';
import './Banner.scss';

interface Props {
    children: React.ReactChild;
}

const Banner: FC<Props> = ({ children }) => (
    <div className="banner">
        <div className="banner__handler">{children}</div>
    </div>
);

export default Banner;
