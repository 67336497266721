import React from 'react';
import { Icon } from '@brandandcelebrities/kolkit';
import * as ICOS_INT from '../components/svgs/interactions';
import { nl2br } from './index';

const getDefaultInteractionIcon = (key: string) => {
    switch (key) {
        case 'likes':
            return ICOS_INT.ICO_THUMB_UP;
        case 'favorites':
            return ICOS_INT.ICO_HEART;
        case 'thumbsup':
            return ICOS_INT.ICO_THUMB_UP;
        case 'thumbsdown':
        case 'dislikes':
            return ICOS_INT.ICO_THUMB_DOWN;
        case 'stars':
            return ICOS_INT.ICO_STAR;
        case 'comments':
            return ICOS_INT.ICO_COMMENT;
        case 'shares':
            return ICOS_INT.ICO_SHARE_ALT;
        case 'retweets':
            return ICOS_INT.ICO_RETWEET;
        case 'views':
            return ICOS_INT.ICO_VIEW;
        case 'videos_views':
            return ICOS_INT.ICO_PLAY;
        case 'plays':
            return ICOS_INT.ICO_PLAY;
        case 'impressions':
            return ICOS_INT.ICO_EYE;
        case 'reach':
            return ICOS_INT.ICO_MEGAPHONE;
        case 'engagement_absolute':
            return ICOS_INT.ICO_ENGAGEMENT;
        case 'engagement_percent':
            return ICOS_INT.ICO_PERCENT;
        default:
            return null;
    }
};

export const getInteractionIcon = (social_network, key) => {
    switch (social_network) {
        case 'instagram':
            switch (key) {
                // case 'likes':
                //   return ICOS_INT.ICO_HEART
                case 'reach':
                    return <Icon className="bc-analytics-post-stats__detail-icon-top-post" label="bullseye" />;
                case 'impressions':
                    return <Icon className="bc-analytics-post-stats__detail-icon-top-post" label="bullhorn" />;
                case 'views':
                    return ICOS_INT.ICO_EYE;
                case 'videos_views':
                    return ICOS_INT.ICO_EYE;
                case 'saved':
                    return <Icon className="bc-analytics-post-stats__detail-icon-top-post" label="bookmark" />;
                default:
                    return getDefaultInteractionIcon(key);
            }
        case 'facebook':
            switch (key) {
                case 'likes':
                    return ICOS_INT.ICO_THUMB_UP;
                case 'shares':
                    return ICOS_INT.ICO_SHARE_ALT;
                case 'comments':
                    return ICOS_INT.ICO_COMMENT;
                default:
                    return getDefaultInteractionIcon(key);
            }
        case 'twitter':
            switch (key) {
                case 'likes':
                    return ICOS_INT.ICO_THUMB_UP;
                // case 'retweets':
                //   return ICOS_INT.ICO_RETWEET
                default:
                    return getDefaultInteractionIcon(key);
            }
        case 'youtube':
            switch (key) {
                case 'likes':
                    return ICOS_INT.ICO_THUMB_UP;
                // case 'dislikes':
                //   return ICOS_INT.ICO_THUMB_DOWN
                case 'comments':
                    return ICOS_INT.ICO_COMMENT;
                case 'views':
                    return ICOS_INT.ICO_EYE;
                // case 'favorites':
                //   return ICOS_INT.ICO_HEART
                default:
                    return getDefaultInteractionIcon(key);
            }
        case 'tiktok':
            switch (key) {
                case 'likes':
                    return ICOS_INT.ICO_THUMB_UP;
                case 'comments':
                    return ICOS_INT.ICO_COMMENT;
                case 'shares':
                    return ICOS_INT.ICO_SHARE_ALT;
                case 'views':
                    return ICOS_INT.ICO_PLAY;
                default:
                    return getDefaultInteractionIcon(key);
            }
        default:
            return null;
    }
};

export const formatPostContent = (content) => {
    if (!content) return null;

    const parts = content.split('©¶@®¢¥');
    if (parts.length > 1) {
        return `<span class="content-title">${parts[0]}</span>${nl2br(parts[1])}`;
    }
    return nl2br(content);
};
