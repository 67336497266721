import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import routes from 'config/routes';
import { Locale } from 'locales';
import { ReactComponent as Logo } from 'assets/images/svg/logo-favicon-navy.svg';
import { useLexique, useSelector } from 'utils/redux';
import { AppState } from 'reducers';
import './footer.scss';

interface Selector {
    locale: Locale;
}

const Footer: FC<RouteComponentProps> = ({ location }) => {
    const lexique = useLexique('containers.shared.footer');
    const linksLexique = useLexique('links');

    const {locale } = useSelector(
        (state: AppState): Selector => ({
            locale: state.env.locale,
        }),
    );
    const currentLocale = locale.split('-')[0];

    if (location.pathname.indexOf(routes.conversations) > -1) return null;

    return (
        <footer className="footer">
            <div className="footer-logo">
                <Logo />
            </div>
            <div className="footer-wrapper">
                <ul>
                    <li>
                        <a
                            href={`https://www.kolsquare.com/${currentLocale}/about-us`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lexique.about}
                        </a>
                    </li>
                    <li>
                        <a
                            href={`https://www.kolsquare.com/${currentLocale}/legal-notice`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lexique.legalMention}
                        </a>
                    </li>
                    <li>
                        <a
                            href={`https://kolsquare.com/${currentLocale}/blog`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lexique.blog}
                        </a>
                    </li>
                    <li>
                        <a
                            href={linksLexique.privacyPolicy}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lexique.privacyPolicy}
                        </a>
                    </li>
                    <li>
                        <a
                            href={linksLexique.legalTerms}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lexique.cgu}
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};
export default withRouter(Footer);
