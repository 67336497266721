import React, {FC,  memo, useCallback } from 'react';
import cn from 'classnames';
import { Icon } from '@brandandcelebrities/kolkit';

import styles from './Input.module.scss';

interface Props {
  disabled?: boolean;
  id?: string | number;
  onChange: (onChangeEvent: {value: string, name: string, id: string | number}) => void;
  placeholder?: string;
  value: string;
  onKeyPress?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onEnterPress?: (e: React.MouseEvent<HTMLInputElement>) => void;
  className?: string;
  theme?: string;
  rest?: any;
}

const Input: FC<Props> = ({ id, onChange, value, onKeyPress, onEnterPress, className, theme, ...rest }) => {
  const handleChange = useCallback(
    e => onChange && onChange({
      value: e?.target?.value,
      name: e?.target?.name,
      id
    }),
    [id, onChange]
  );

  const handleKeyPress = useCallback(
    e => {
      if (onEnterPress && e?.key === 'Enter') onEnterPress(e);
      if (onKeyPress && e?.key !== 'Enter') onKeyPress(e);
    },
    [onEnterPress, onKeyPress]
  );

  const cnInput = cn(styles.container, className, styles[theme]);

  return (
    <div className={cnInput}>
      {theme !== 'white' && (
        <Icon
          label="search"
          className={styles.icon}
          theme="regular"
        />
      )}
      <input
        {...rest}
        className={styles.input}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        value={value}
        type="text"
      />
    </div>
  )
};

Input.defaultProps = {
  onChange: () => {},
}

export default memo(Input);
