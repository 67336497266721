export default {
    headTitle: 'My Audience - Kolsquare',
    placeholder: 'You must add your Instagram account to get community information.',
    text:
        'The data presented below are from Likers. Thus, they may differ from the statistics provided directly on Instagram that are from Followers.',
    ageChart: {
        title: 'Engaged audience distribution per gender and age',
        legend: {
            male: 'Male',
            female: 'Female',
            years: '',
        },
        placeholder: 'There is no data.',
        bottomInfo: 'Source: Instagram',
    },
    geoChart: {
        title: 'Engaged audience distribution per country',
        tooltip: {
            country: 'Pays',
            followers: '',
        },
        placeholder: 'There is no data.',
        bottomInfo: 'Source: Instagram',
    },
};
