import React, { FC, useEffect, useCallback, memo } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'utils/redux';
import { showModale, hideModale } from 'actions/ui';

interface Props {
    display: boolean;
    onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    children: any;
}

const Modal: FC<Props> = ({ display, onClose, className, children }) => {
    const dispatch = useDispatch();
    const onKeyDown = useCallback(
        (e) => {
            if (!onClose) return;

            switch (e.keyCode) {
                case 27:
                    // ESC
                    onClose();
                    break;
                default:
            }
        },
        [onClose],
    );

    const openModal = useCallback(() => {
        dispatch(showModale());
        document.addEventListener('keydown', onKeyDown);
    }, [onKeyDown, dispatch]);

    const closeModal = useCallback(() => {
        dispatch(hideModale());
        document.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown, dispatch]);

    useEffect(() => {
        if (display) {
            openModal();
        }
        if (!display) {
            closeModal();
        }
        return () => {
            closeModal();
        };
    }, [onKeyDown, display, openModal, closeModal]);

    return (
        <div
            role="dialog"
            className={classNames('bc-modal bc--fade bc--show', {
                'bc-modal--show': display,
                [className]: className,
            })}
        >
            <div className="bc-modal__dialog bc-modal__dialog--full-screen bc-modal-content" role="document">
                <div className="bc-modal__content">
                    {onClose && (
                        <div className="bc-modal__header">
                            <button
                                type="button"
                                className="bc-modal__header__icon bc-modal__header__icon--right"
                                onClick={onClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    )}
                    <div className="bc-modal__body">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default memo(Modal);
