import React, { ReactChild, forwardRef, memo } from 'react';
import cn from 'classnames';
import { Truncate } from '@brandandcelebrities/kolkit';
import styles from './Tag.module.scss';

interface Props {
  children: ReactChild;
  className?: string;
  rest?: any;
}
const Tag = forwardRef<HTMLSpanElement, Props>(( { children, className, ...rest }, ref) => {
  const cnTag = cn(styles.tag, className);

  return (
    <span className={cnTag} ref={ref} {...rest}>
      <Truncate>{children}</Truncate>
    </span>
  )
});

Tag.displayName = 'Tag';

export default memo(Tag);
