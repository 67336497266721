import { createAction } from 'redux-actions';

import * as types from 'constants/modale';
import { ModaleState } from 'reducers/ui/modale';

type ShowModalArgs = Omit<ModaleState, 'show' | 'modalStack'>;

export const showModale = createAction<ShowModalArgs>(types.SHOW__MODALE);
export const hideModale = createAction(types.HIDE__MODALE);
export const updateModaleAction = createAction(types.UPDATE_MODALE);

export const updateModale = (data) => (dispatch, getState) => {
    const { show } = getState().ui.modale;
    if (show) dispatch(updateModaleAction(data));
};
