import React, { FC } from 'react';

import { IconButton, Dialog as MuiDialog, DialogTitle, DialogContent, withMobileDialog } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import './dialog.scss';

interface Props {
    display: boolean;
    title?: string;
    onClose?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    fullScreen?: boolean;
}

const Dialog: FC<Props> = ({ display, title, onClose, children, fullScreen }) => {
    return (
        <MuiDialog open={display} onClose={onClose} className="bc-dialog" fullScreen={fullScreen}>
            <IconButton className="bc-dialog__close" onClick={onClose}>
                <CloseIcon />
            </IconButton>

            <DialogTitle className="bc-dialog__title">{title}</DialogTitle>

            <DialogContent>{children}</DialogContent>
        </MuiDialog>
    );
};

export default withMobileDialog<Props>()(Dialog);
