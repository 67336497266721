import React, { useMemo, useCallback } from 'react';
import { Radios } from '@brandandcelebrities/kolkit';
import cn from 'classnames';

import { useLexique } from 'utils/redux';
import { GenderLexique } from 'locales/types';

import styles from './GenderRadios.module.scss';

const options = ['male', 'female', 'other'];

interface Props {
    name: string;
    title?: string;
    checked?: string;
    onChange?: (onChangeEvent: { value: string }) => void;
    className?: string;
    disabled?: boolean;
}

const GenderRadios: React.FC<Props> = ({ name, title, checked, className, disabled, onChange }) => {
    const lexique: GenderLexique = useLexique('config.gender');

    const radioOptions = useMemo(() => options.map((option) => ({ label: lexique[option], value: option })), [lexique]);

    const handleChange = useCallback(
        (e) => {
            if (!onChange) return;
            onChange({ value: e.currentTarget.value });
        },
        [onChange],
    );

    const cnRadio = cn(styles.radios, className);

    return (
        <Radios
            title={title}
            options={radioOptions}
            name={name}
            checked={checked}
            onChange={handleChange}
            className={cnRadio}
            disabled={disabled}
        />
    );
};

GenderRadios.defaultProps = {
    title: undefined,
    checked: undefined,
    onChange: undefined,
    disabled: false,
};

export default GenderRadios;
