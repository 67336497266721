import React, { useState, useEffect, useCallback, memo } from 'react';
import Divider from '@material-ui/core/Divider';

import { Birthdate } from '@brandandcelebrities/kolkitten';
import { Input, Button, InputGmap, Textarea } from '@brandandcelebrities/kolkit';
import '@brandandcelebrities/kolkitten/css/style.css';

import { updateField, loadProfile, saveProfile } from 'actions/profile';
import { conf } from 'config/env';
import { Networks } from 'constants/networks';
import { SectionsLayout } from 'components/templates';
import GenderRadios from 'components/atoms/GenderRadios';
import SelectActivity from 'components/atoms/SelectActivity';
import SelectThemes from 'components/atoms/SelectThemes';
import SelectNationality from 'components/atoms/SelectNationality';
import getLexique, { getLocale } from 'locales';
import { useDispatch, useSelector } from 'utils/redux';
import { isSnaUrlValid, formatSnaUrl } from 'utils/snas';

import picto from 'assets/images/profile.png';

import ProfilePageSidebar from './ProfilePageSidebar';

import styles from './ProfilePage.module.scss';

const ProfilePage = () => {
  const [state, setState] = useState({
    saving: false,
    saved: false,
    error: {
      noNameSpecified: false,
      websiteNotValid: false,
    },
  });

  const dispatch = useDispatch();
  const { lexique, form, loading, useNative, locale } = useSelector(({ env, profile }) => {
    const lexique = getLexique(env.locale).containers.profilePage;
    return {
      lexique,
      form: profile.profile,
      loading: profile.loading,
      useNative: !env.device.isDesktop,
      locale: getLocale(env.locale),
    };
  });

  useEffect(() => {
    // Load profile
    dispatch(loadProfile());
  }, [dispatch]);

  const checkFormError = useCallback((form) => {
    const noNameSpecified = !(
      (form.firstName.trim() !== '' && form.lastName.trim() !== '') ||
      form.stageName.trim() !== ''
    );
    const websiteNotValid =
      form.website.trim() && !isSnaUrlValid(Networks.website, formatSnaUrl(form.website.trim()));

    return {
      noNameSpecified,
      websiteNotValid,
    };
  }, []);

  const handleClickSave = useCallback(async () => {
    const error = checkFormError(form);

    setState({ saving: false, saved: false, error });
    if (error.noNameSpecified) {
      window.ga('send', 'event', {
        eventCategory: 'Profile',
        eventAction: `Error`,
        eventLabel: `Name_Missing`,
      });

      window.scrollTo(0, 0);
      return;
    }
    if (error.websiteNotValid) {
      return;
    }

    setState((oldState) => ({ ...oldState, saving: true }));
    await dispatch(saveProfile(form));
    setState((oldState) => ({ ...oldState, saving: false, saved: true }));
    setTimeout(() => setState((oldState) => ({ ...oldState, saved: false })), 3000);
  }, [form, dispatch, checkFormError]);

  const handleChangeValues = useCallback(
    (id) => ({ values }) => {
      dispatch(updateField({ field: id, value: values }));
    },
    [dispatch],
  );

  const handleChangeTextArea = useCallback(
    (id) => ({ target: { value } }) => {
      dispatch(updateField({ field: id, value }));
    },
    [dispatch],
  );

  const handleChangeInput = useCallback(
    (id) => ({ value }) => {
      dispatch(updateField({ field: id, value }));
    },
    [dispatch],
  );

  const handleChangeGM = useCallback(
    (id) => (value) => {
      dispatch(updateField({ field: id, value }));
    },
    [dispatch],
  );

  return loading ? null : (
    <SectionsLayout title={lexique.headTitle} picto={picto} sideContent={<ProfilePageSidebar />}>
      <section>
        <h3>{lexique.titleInfos}</h3>
        <Input
          id="firstName"
          placeholder={lexique.firstName}
          onChange={handleChangeInput('firstName')}
          value={form.firstName}
          error={state.error.noNameSpecified}
          errorMessage={lexique.errorRequired}
          fullWidth
          size="big"
        />
        <Input
          id="lastName"
          placeholder={lexique.lastName}
          onChange={handleChangeInput('lastName')}
          value={form.lastName}
          error={state.error.noNameSpecified}
          errorMessage={lexique.errorRequired}
          fullWidth
          size="big"
        />
        <Input
          id="stageName"
          placeholder={lexique.stageName}
          onChange={handleChangeInput('stageName')}
          value={form.stageName}
          error={state.error.noNameSpecified}
          errorMessage=" "
          fullWidth
          size="big"
        />
        <GenderRadios name="gender" onChange={handleChangeInput('gender')} checked={form.gender} />
        <Birthdate
          id="birthdate"
          onChange={handleChangeInput('birthdate')}
          value={form.birthdate}
          label={lexique.birthdate.label}
          dayLabel={lexique.birthdate.day}
          monthLabel={lexique.birthdate.month}
          monthLabels={lexique.birthdate.months}
          yearLabel={lexique.birthdate.year}
          useNative={useNative}
        />
        <SelectNationality
          name="nationality"
          value={form.nationality}
          onChange={handleChangeValues('nationality')}
          placeholder={lexique.nationality}
          className={styles.select}
        />
        <Input
          placeholder={lexique.phone.label}
          id="phone"
          onChange={handleChangeInput('phone')}
          value={form.phone}
          fullWidth
          size="big"
        />
      </section>
      <Divider />
      <section>
        <h3>{lexique.titleActivity}</h3>
        <SelectActivity
          name="activities"
          value={form.activities}
          onChange={handleChangeValues('activities')}
          placeholder={lexique.activities}
          locale={locale}
          gender={form.gender as 'male' | 'female' | 'other'}
          className={styles.select}
        />
        <SelectThemes
          name="influenceThemes"
          value={form.influenceThemes}
          onChange={handleChangeValues('influenceThemes')}
          placeholder={lexique.influenceThemes}
          locale={locale}
          className={styles.select}
        />
        <Input
          id="website"
          placeholder={lexique.website}
          onChange={handleChangeInput('website')}
          value={form.website}
          error={state.error.websiteNotValid}
          errorMessage={lexique.errorWebsiteInvalid}
          fullWidth
          size="big"
        />
        <InputGmap
          gmapApiKey={conf.GMapAPIKey}
          id="Google-Map-Input-livingAddress"
          placeholder={lexique.livingPlace}
          value={form.livingAddress.mapped?.g_formatted_address || form.livingAddress.placeSearched}
          onChange={handleChangeGM('livingAddress')}
          extraProperties={{
            types: [`(cities)`],
          }}
          size="big"
        />
        <Input
          id="bio"
          onChange={handleChangeInput('bio')}
          value={form.bio}
          placeholder={lexique.bio.placeholder}
          label={lexique.bio.label}
          maxLength={80}
          fullWidth
          size="big"
        />
        <Textarea
          onChange={handleChangeTextArea('description')}
          value={form.description}
          placeholder={lexique.description.label}
          rows={10}
        />
        <div className={styles.saveBtnContainer}>
          <Button
            id="form-profile-save"
            onClick={handleClickSave}
            label={lexique.save}
            loading={state.saving}
            complete={state.saved}
          />
        </div>
      </section>
    </SectionsLayout>
  );
};

export default memo(ProfilePage);
