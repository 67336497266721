import React, { memo, useCallback, FC } from 'react';
import './CampaignBanner.scss';
import { Switch } from '@brandandcelebrities/kolkitten';
import { Banner } from 'components/molecules';
import CampaignBannerLexique from 'locales/types/components/organisms/CampaignBanner';
import { useDispatch, useLexique, useSelector } from 'utils/redux';
import { toggleUserMobilisationEnlisting } from 'actions/user';
import { parseHtml } from 'utils/htmlParser';

export const CampaignBanner: FC = () => {
    const dispatch = useDispatch();
    const lexique: CampaignBannerLexique = useLexique<CampaignBannerLexique>('components.organisms.campaignBanner');
    const toggle = useCallback(
        ({ active }) => {
            dispatch(toggleUserMobilisationEnlisting(active));
        },
        [dispatch],
    );
    const enlisted: boolean = useSelector(({ user }) => user.enlisted);

    return (
        <Banner>
            <>
                <div className="banner__left">
                    <h3 className="banner__left__title">{parseHtml(lexique.title)}</h3>
                    <p className="banner__left__text">
                        {lexique.text}
                        <br />
                        {lexique.text2}
                    </p>
                </div>
                <div className="banner__right">
                    <span className="banner__right__label__left">{lexique.switch.no}</span>
                    <Switch active={enlisted} onChange={toggle} />
                    <span className="banner__right__label__right">{lexique.switch.yes}</span>
                </div>
            </>
        </Banner>
    );
};

export default memo(CampaignBanner);
