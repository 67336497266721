import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import isEmpty from 'lodash.isempty';

import * as types from 'constants/socialStatsCard';
import { Networks } from 'types/snas.d';
import {
    closeModal,
    fetchInfoSnaFailure,
    fetchInfoSnaSuccess,
    infoSnaError,
    clearSnaError,
    resetInputSna,
    postSnaFailure,
    postSnaSuccess,
    fetchSnaSuccess,
    fetchSnaFailure,
} from 'actions/socialStatsCard';
import { displayAddToast } from 'actions/sna';
import { isSnaUrlValid, isSupportedSna } from 'utils/snas';
import { ActionMeta } from 'redux-actions';
import { hideModale } from 'actions/modale';
import { SOCIAL_NETWORKS } from 'constants/networks';
import { AppState } from '../reducers';
import api from '../api';
import { removeSna } from './sna';

export function* watchSocialStatsCard() {
    yield fork(watchSocialStatsCardModal);
}

export function* watchSocialStatsCardModal() {
    yield fork(watchUrl);
    yield fork(watchEdit);
}

export function* watchUrl() {
    yield takeEvery(types.MODAL_ON_SEND, function* ({ payload }: ActionMeta<any, any>) {
        if (payload && !isEmpty(payload.url)) {
            yield postSnaIfNotEmpty(payload.type, payload.url);
        } else if (isEmpty(payload.url) && payload.type && payload.id) {
            yield removeSnaFromModal(payload.type, payload.id);
        }

        const { ui }: AppState = yield select();
        if (noError(ui.socialStatsCards[payload.type])) {
            yield put(closeModal(payload.type));
        } else {
            gaSendError(payload.type);
        }
    });
}

export function* watchEdit() {
    yield takeEvery(types.MODAL_ON_EDIT, function* ({ payload }: ActionMeta<any, any>) {
        let hasError = false;
        yield* Object.keys(payload).map(function* (type) {
            try {
                const { url, disabled, network, id } = payload[type];
                if (!isEmpty(url) && !disabled) {
                    yield postSnaIfNotEmpty(type, url);
                    const { ui }: AppState = yield select();
                    if (ui.socialStatsCards[type] && !noError(ui.socialStatsCards[type])) {
                        hasError = true;
                    }
                } else if (isEmpty(url) && network) {
                    yield removeSnaFromModal(network, id);
                }
            } catch (e) {
                console.log(e);
            }
        });
        if (!hasError) {
            yield put(hideModale());
        }
    });
}

export function* removeSnaFromModal(network, id) {
    const { snas }: AppState = yield select();
    if (SOCIAL_NETWORKS[network].supported) {
        const sna = snas[Networks[network]].find((sna) => sna.id === id);
        if (sna && sna.url !== '') {
            // yield removeSna
            /* @TODO move all this into sna */
            yield fork(removeSna, {
                uid: sna.uid,
                network,
            });
        }
    } else {
        const sna = snas[Networks[network]][0];
        if (sna && sna.filled) {
            yield fork(removeSna, {
                network,
            });
        }
    }
}

export function* postSnaIfNotEmpty(type, url) {
    yield put(clearSnaError({ type }));
    if (url) {
        if (!isSnaUrlValid(type, url)) {
            yield put(infoSnaError({ type, errorType: 'invalidUrl' }));
        } else {
            try {
                if (isSupportedSna(type)) {
                    const fetchInfoSnaAction = yield call(api.fetchInfoSna, type, url);
                    yield put(fetchInfoSnaSuccess(fetchInfoSnaAction));

                    switch (true) {
                        case validAccount(fetchInfoSnaAction):
                            yield call(postSna, {
                                ...fetchInfoSnaAction.data,
                                label: type,
                                url,
                            });
                            break;
                        case privateAccount(fetchInfoSnaAction):
                            yield put(infoSnaError({ type, errorType: 'privateAccount' }));
                            break;
                        case unknownAccount(fetchInfoSnaAction):
                            yield put(infoSnaError({ type, errorType: 'unknownAccount' }));
                            break;
                        default:
                            yield put(infoSnaError({ type, errorType: 'errorUnknown' }));
                    }
                } else {
                    try {
                        const fetchUnsupportedSnaResponse = yield call(api.postUnsupportedSnaRequest, { type, url });
                        yield put(
                            fetchSnaSuccess({
                                ...fetchUnsupportedSnaResponse.data,
                                label: type,
                                url,
                            }),
                        );
                        const locale = yield select((state) => state.env.locale);
                        displayAddToast(type, locale);
                    } catch (e) {
                        yield put(infoSnaError({ type, errorType: 'errorUnknown' }));
                    }
                }
            } catch (error) {
                yield put(fetchInfoSnaFailure({ type, errorType: 'errorUnknown' }));
            }
        }
    } else {
        yield put(resetInputSna(type));
    }
}

export function* postSna(snaInfo) {
    const { label, url } = snaInfo;
    try {
        yield call(api.postSnaRequest, snaInfo);
        yield put(postSnaSuccess(snaInfo));

        // TODO: Maybe move that in another saga
        const fetchSnaResponse = yield call(api.fetchSna, snaInfo.uid);
        yield put(
            fetchSnaSuccess({
                ...fetchSnaResponse.data,
                uid: snaInfo.uid,
                label: snaInfo.label,
                url,
            }),
        );
        yield put(closeModal(label));
    } catch (error) {
        switch (true) {
            case recordNotUnique(error):
                yield put(infoSnaError({ type: label, errorType: 'recordNotUnique' }));
                break;
            default:
                yield put(postSnaFailure(error));
        }
    }
}

export function* fetchSnas() {
    // : AppState
    const { user }: AppState = yield select();
    const snas = user.snas.filter((sna) => SOCIAL_NETWORKS[sna.label].supported);
    const requests = snas.map((sna) => fork(fetchSnaSaga, sna.uid, sna.label, sna.username));

    yield all(requests);
}

function* fetchSnaSaga(uid: string, label: Networks, username: string) {
    try {
        const fetchSnaResponse = yield call(api.fetchSna, uid);
        yield put(fetchSnaSuccess({ ...fetchSnaResponse.data, uid, label, username }));
    } catch (error) {
        yield put(fetchSnaFailure(error));
    }
}

// Private methods

const validAccount = (account) => account?.status === 200 && !!account?.data?.uid && !account.data.is_private;

const privateAccount = (account) => account?.status === 200 && !!account?.data?.is_private;

const unknownAccount = (account) => account?.status === 204 || account?.status === 422;

const recordNotUnique = (account) =>
    account?.response?.status === 422 &&
    !!account?.response?.data &&
    account.response.data.message === 'Record not unique';

const noError = ({ error }) => !error;

const gaSendError = (type) =>
    window.ga('send', 'event', {
        eventCategory: 'Dashboard',
        eventAction: `SNA`,
        eventLabel: `Add ${type} error`,
    });
