import React, { Children, memo, useCallback, useEffect, useState } from 'react';
import Tab from './Tab';

import styles from './TabMenu.module.scss';

const TabMenu = ({ dataset, onClick, activeIndex }) => {
  const [active, setActive] = useState(activeIndex);

  useEffect(
    () => {
      setActive(activeIndex);
    },
    [activeIndex]
  );

  const handleClick = useCallback(
    ({ id, value, disabled }) => {
      if (disabled) return false;
      setActive(id);
      onClick({ id, value })
    },
    [onClick]
  )
  return (
    <div className={styles.wrapper}>
      <ul className={styles.tabs}>
        {Children.toArray(dataset?.map((item, id) => (
          <Tab
            active={active === id}
            onClick={() => handleClick({
              id,
              value: item?.value,
              disabled: item?.disabled
            })}
            disabled={item?.disabled}
          >
            {item?.label}
          </Tab>
        )))}
      </ul>
    </div>
  )
}

TabMenu.displaName = 'TabMenu';

TabMenu.defaultProps = {
  dataset: [],
  onClick: () => {},
  activeIndex: 0,
}

export default memo(TabMenu);
