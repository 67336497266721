import CampaignBannerLexique from 'locales/types/components/organisms/CampaignBanner';

export const campaignBannerLexique: CampaignBannerLexique = {
    title:
        "Join the <a target='_blank' href='https://www.kolsquare.com/en/blog/influence-for-good'>#InfluenceForGood</a> movement! ",
    text: 'Offer your influence to brands and organizations in need during the crisis.',
    text2: 'By checking "yes", your profile will be highlighted in Kolsquare as a volunteer.',
    switch: {
        yes: 'YES',
        no: 'NO',
    },
};

export default campaignBannerLexique;
