import React, { memo } from 'react';
import Instagram from '@brandandcelebrities/icons/brands/Instagram';

import styles from './InstagramButton.module.scss';

interface Props {
    lexique: {
        label: string;
        subtext: string;
    };
    onClick: () => void;
    disabled?: boolean;
}

const InstagramButton: React.SFC<Props> = ({ onClick, disabled, lexique }) => {
    return (
        <button type="button" className={styles.button} onClick={onClick} disabled={disabled}>
            <div className={styles.wrapper}>
                <Instagram />
                <div className={styles.label}>
                    <div className={styles.text}>{lexique.label}</div>
                </div>
            </div>
        </button>
    );
};

export default memo(InstagramButton);
