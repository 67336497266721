import { combineReducers, Reducer, AnyAction } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import { History, LocationState } from 'history';
import env, { EnvState } from './env';
import snas, { SnasState } from './snas';
import ui, { UIState } from './ui';
import user, { UserState } from './user';
import posts, { PostsState } from './posts';
import profile, { ProfileState } from './profile';
import proposition, { PropositionsState } from './proposition';
import messaging, { MessagingState } from './messaging';
import fbc, { FBCDataState } from './fbc';
import onboarding, { OnboardingState } from './onboarding';

export interface AppState {
    env: EnvState;
    snas: SnasState;
    user: UserState;
    ui: UIState;
    profile: ProfileState;
    posts: PostsState;
    proposition: PropositionsState;
    messaging: MessagingState;
    fbc: FBCDataState;
    onboarding: OnboardingState;
    router: RouterState<LocationState>;
}

export default (history: History): Reducer<AppState, AnyAction> =>
    combineReducers<AppState>({
        env,
        ui,
        user,
        profile,
        snas,
        posts,
        proposition,
        messaging,
        fbc,
        onboarding,
        router: connectRouter(history),
    });
