import React, { FC, memo } from 'react';
import { Helmet } from 'react-helmet';
import { useLexique } from 'utils/redux';
import { PartenariatsPageLexique } from 'locales/types/containers/partenariatsPage';
import { PostEstimationValueCards, SocialStatsList } from 'components/templates';

import './PartenariatsPage.scss';

const PartenariatsPage: FC = () => {
    const lexique = useLexique<PartenariatsPageLexique>('containers.partenariatsPage');

    return (
        <div className="bc-analytics-stats partenariats">
            <Helmet>
                <title>{lexique.headTitle}</title>
            </Helmet>
            <div className="bc-analytics-stats__title">{lexique.headTitle}</div>
            <SocialStatsList />
            <div className="partenariats__content">
                <PostEstimationValueCards />
            </div>
        </div>
    );
};

export default memo(PartenariatsPage);
