import React, { useEffect, useMemo } from 'react';
import { Button, Text } from '@brandandcelebrities/kolkit';

import { Networks } from 'constants/networks';
import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';
import StepperHeader from 'components/molecules/StepperHeader';
import { useLexique, useSelector } from 'utils/redux';
import { callGAEvent } from 'utils/googleAnalytics';

import styles from '../Steps.module.scss';
import NetworkItem from './components/NetworkItem';

interface Props {
    onClickNext: () => void;
    onOpenInstaconnect: () => void;
    onStatsSharingSkip: () => void;
    className?: string;
}

const StepNetworksInstagramOnly: React.FC<Props> = ({
  onClickNext,
  onOpenInstaconnect,
  onStatsSharingSkip,
  className,
}) => {
  const lexique: OnboardingPageLexique['steps'] = useLexique('containers.onboardingPage.steps');

  const { isInstagramConnected, brandName, requestsCount } = useSelector(({ fbc: { selectedPages }, snas, user }) => ({
    isInstagramConnected: selectedPages?.instagram?.length > 0 && snas?.instagram[0] && snas.instagram[0].username,
    brandName: user.statsSharing.brandName,
    requestsCount: user.statsSharing.requestsCount,
  }));

  const helpText = useMemo(
    () =>
      lexique.networksInstagramOnly[requestsCount > 1 ? 'helpTextMany' : 'helpText']
        .replace('{brandName}', brandName)
        .replace('{length}', `${requestsCount - 1}`),
    [lexique, requestsCount, brandName],
  );

  useEffect(() => {
    if (isInstagramConnected) {
      callGAEvent('Onboarding', 'step_4_social_networks');
      onClickNext();
    }
  }, [isInstagramConnected, onClickNext]);

  return (
    <div className={className}>
      <StepperHeader title={lexique.networksInstagramOnly.title} />
      <Text className={styles.helperText} size="small">
        {helpText}
      </Text>
      <NetworkItem
        key={Networks.instagram}
        network={Networks.instagram}
        state={isInstagramConnected ? 'set' : 'unset'}
        onOpen={onOpenInstaconnect}
        className={styles.networkInstagramOnly}
      />
      <Button fullWidth size="fat" className={styles.button} onClick={onOpenInstaconnect}>
        {lexique.instaStatsSharing.shareButton}
      </Button>
      <Button fullWidth size="large" theme="reset" className={styles.buttonLight} onClick={onStatsSharingSkip}>
        {lexique.instaStatsSharing.skipButton}
      </Button>
    </div>
  );
};

StepNetworksInstagramOnly.defaultProps = {
    className: undefined,
}

export default StepNetworksInstagramOnly;
