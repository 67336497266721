import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { Input, InputGmap } from '@brandandcelebrities/kolkit';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import DatePickerTheme from 'config/date-picker-theme';
import { conf } from 'config/env';
import { useLexique } from 'utils/redux';
import DatePickerLocales from 'locales/types/components/atoms/date-picker';

import GenderRadios from '../GenderRadios';
import SelectActivity from '../SelectActivity';
import SelectThemes from '../SelectThemes';
import SelectNationality from '../SelectNationality';
import dateStyles from './date-styles.module.scss';

export enum TYPES {
    TEXT,
    GENDER,
    DATE,
    ACTIVITIES,
    THEMES,
    NATIONALITY,
    CITY,
}

interface Props {
    field: string;
    type?: TYPES;
    value?: any;
    onChange?: (changeEvent: {
        type: TYPES;
        field: string;
        value?: string;
        values?: string[];
        gmapValue?: { placeSearched: string; mapped: { g_formatted_address: string } };
    }) => void;
    className?: string;
    placeholder?: string;
    fullWidth?: boolean;
    locale?: string;
    size?: 'small' | 'big';
    options?: {
        minDate?: moment.Moment;
        maxDate?: moment.Moment;
        gender?: 'male' | 'female' | 'other';
    };
    disabled?: boolean;
    error?: string | boolean;
    onBlur?: () => void;
}

const FormField: React.FC<Props> = ({
    field,
    type,
    value,
    onChange,
    className,
    placeholder,
    fullWidth,
    size,
    options,
    locale,
    disabled,
    error,
    onBlur,
}) => {
    const datePickerlexique: DatePickerLocales = useLexique('components.atoms.datePicker');
    const handleFieldChange = useCallback(
        ({ value: newValue }) => onChange && onChange({ type, field, value: newValue }),
        [onChange, type, field],
    );

    const handleDateChange = useCallback(
        (date) => onChange && onChange({ type, field, value: date ? date.format('YYYY-MM-DD') : '' }),
        [onChange, type, field],
    );

    const handleChangeGM = useCallback(
        ({ placeSearched, mapped }) => {
            if (!onChange) return;
            onChange({ type, field, gmapValue: { placeSearched, mapped } });
        },
        [onChange, type, field],
    );

    const handleChangeMultiSelect = useCallback(
        ({ values }) => {
            if (!onChange) return;
            onChange({ type, field, values });
        },
        [onChange, type, field],
    );

    useEffect(() => {
        if (locale) moment.locale(locale.slice(0, 2));
    }, [locale]);

    switch (type) {
        case TYPES.GENDER:
            return (
                <GenderRadios
                    name={field}
                    title={placeholder || field}
                    checked={value && (value as string)}
                    onChange={handleFieldChange}
                    className={className}
                    disabled={disabled}
                />
            );
        case TYPES.DATE:
            // TODO: enable withPortal when on mobile, and verify that the year selectors are visible
            return (
                <ThemeProvider theme={DatePickerTheme}>
                    <KeyboardDatePicker
                        autoOk
                        fullWidth
                        inputVariant="outlined"
                        variant="inline"
                        format="DD/MM/YYYY"
                        margin="normal"
                        views={['year', 'month', 'date']}
                        maxDateMessage={datePickerlexique.maxDate}
                        minDateMessage={datePickerlexique.minDate}
                        invalidDateMessage={datePickerlexique.invalidDate}
                        value={value || null}
                        placeholder={placeholder}
                        initialFocusedDate={options?.maxDate}
                        maxDate={options?.maxDate}
                        onChange={handleDateChange}
                        InputProps={{
                            classes: { root: dateStyles.root, notchedOutline: dateStyles.notchedOutline },
                        }}
                        className={dateStyles.datePicker}
                    />
                </ThemeProvider>
            );
        case TYPES.ACTIVITIES:
            return (
                <SelectActivity
                    name={field}
                    value={(value || []) as string[]}
                    onChange={handleChangeMultiSelect}
                    placeholder={placeholder}
                    locale={locale}
                    gender={options.gender}
                    className={className}
                    disabled={disabled}
                />
            );
        case TYPES.THEMES:
            return (
                <SelectThemes
                    name={field}
                    value={(value || []) as string[]}
                    onChange={handleChangeMultiSelect}
                    placeholder={placeholder}
                    locale={locale}
                    className={className}
                    disabled={disabled}
                />
            );
        case TYPES.NATIONALITY:
            return (
                <SelectNationality
                    name={field}
                    value={(value || []) as string[]}
                    onChange={handleChangeMultiSelect}
                    placeholder={placeholder}
                    className={className}
                    disabled={disabled}
                    error={typeof error === 'boolean' ? '' : error}
                />
            );

        case TYPES.CITY:
            return (
                <InputGmap
                    placeholder={placeholder}
                    gmapApiKey={conf.GMapAPIKey}
                    id="Google-Map-Input-livingAddress"
                    value={value.mapped?.g_formatted_address || value.placeSearched}
                    onChange={handleChangeGM}
                    disabled={disabled}
                    extraProperties={{
                        types: [`(cities)`],
                    }}
                    error={error}
                    size="big"
                    onBlur={onBlur}
                    autocomplete="off"
                />
            );
        case TYPES.TEXT:
        default:
            return (
                <Input
                    name={field}
                    placeholder={placeholder || field}
                    value={value}
                    onChange={handleFieldChange}
                    fullWidth={fullWidth}
                    size={size}
                    className={className}
                    disabled={disabled}
                    error={!!error}
                    errorMessage={typeof error === 'boolean' ? undefined : error}
                    onBlur={onBlur}
                />
            );
    }
};

export default FormField;
