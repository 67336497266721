import { APIResponseData } from 'api/api.d';
import { FetchInfoSna } from 'types/fetchApi';

export const validAccount = (account: APIResponseData<FetchInfoSna>): boolean =>
    account?.status === 200 && !!account?.data?.uid && !account.data.is_private;

export const privateAccount = (account: APIResponseData<FetchInfoSna>): boolean =>
    account?.status === 200 && !!account?.data?.is_private;

export const unknownAccount = (account: APIResponseData<FetchInfoSna>): boolean =>
    account?.status === 204 || account?.status === 422;

export const recordNotUnique = (account: APIResponseData<{ message }>): boolean =>
    account?.status === 422 && !!account?.data && account.data.message === 'Record not unique';
