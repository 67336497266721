// @TODO: remove this reducer once the components modals are refactoring
import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as SOCIAL_STATS_CARD from 'constants/socialStatsCard';
import * as UI from 'constants/ui';
import { AnyAction } from 'redux';

export interface ModalState {
    display: boolean;
}

const initialState: ModalState = {
    display: false,
};

const reducers = {
    [UI.SHOW_MODAL]: (state: ModalState) =>
        update(state, {
            $merge: { display: true },
        }),
    [UI.HIDE_MODAL]: (state: ModalState) =>
        update(state, {
            $merge: { display: false },
        }),
    // @TODO remove those pointless shits but actually it's still needy...
    [SOCIAL_STATS_CARD.MODAL_OPEN]: (state: ModalState) =>
        update(state, {
            $merge: { display: true },
        }),
    [SOCIAL_STATS_CARD.MODAL_CLOSE]: (state: ModalState) =>
        update(state, {
            $merge: { display: false },
        }),
    [SOCIAL_STATS_CARD.MODAL_ON_CONNECT]: (state: ModalState) =>
        update(state, {
            $merge: { display: false },
        }),
};

export default createReducer<ModalState, AnyAction>(initialState, reducers);
