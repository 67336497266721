import InstaConnect from 'locales/types/components/instaconnect';

const instaconnect: InstaConnect = {
    instagramButton: {
        label: `Ajouter un compte Instagram`,
        subtext: `(Business ou Créateur uniquement)`,
    },
    consentInstagramButton: {
        label: `Connecter mon compte Instagram`,
        subtext: `(Business ou Créateur lié à une page Facebook uniquement)`,
    },
    errorConnect: `Il n’y a pas de compte Instagram business ou créateur associé à ce compte Facebook.`,
    instagramNotFound: `Le compte Instagram qui a été sélectionné ne correspond pas au compte Instagram déjà ajouté. Veuillez recommencer et sélectionner le bon compte Instagram en cliquant sur “Modifier les paramètres” après l'étape de Login.`,
    selectPages: {
        instagram: {
            title: `Connectez vos comptes Instagram Business`,
            text: `Choisissez les comptes Instagram Business que vous souhaitez importer`,
            noPages: `Il n'y a pas de compte business associé à ce profil.`,
            link: {
                label: `Je n'arrive pas à connecter mon compte Instagram`,
                url: 'https://www.kolsquare.com/fr/guide/community-rapport-activite',
            },
        },
        facebook: {
            title: `Connectez vos comptes Facebook Business`,
            text: `Choisissez les comptes Facebook Business que vous souhaitez importer`,
            noPages: `Il n'y a pas de compte business associé à ce profil.`,
            link: ``,
        },
    },
    submit: `Suivant`,
    modal: {
        warning: "Si vous n'avez pas de compte Business ou créateur, passez directement à l'étape suivante.",
        consentWarning: `Kolsquare est un Partenaire Officiel Instagram.`,
        new: {
            title: 'Connectez votre compte Instagram via Facebook',
            content: "Pour ajouter votre compte Instagram **business** ou **créateur**, vous devez avoir **lié une page Facebook** à votre compte Instagram",
            addInstagramBusiness: `Ajouter mon compte Instagram via Facebook`,
            reconnectInstagramBusiness: `Reconnectez votre compte Instagram Business`,
        },
        renewing: {
            title: 'La connexion avec le compte **{{username}}** Instagram expire le {{expiredAt}}!',
            hasExpiredTitle: 'La connexion avec le compte **{{username}}** Instagram a expiré',
            content: "Attention, vous devez avoir lié une **Page Facebook** à votre compte Instagram **Business** ou **Createur**.",
            addInstagramBusiness: `Renouveler la connexion`,
            noMoreShowRenewing: `Ne plus afficher ce message`,
        }
    },
    congratulations: {
        title: 'Félicitations ## !',
        text:
            'Votre compte instagram a bien été ajouté sur Kolsquare. Vous aurez accès à vos statistiques dans moins de 24h.',
    },
};

export default instaconnect;
