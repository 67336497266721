import crypto from 'crypto';
import { FetchPosts } from 'types/fetchApi';

const networks = ['facebook', 'twitter', 'instagram', 'youtube', 'tiktok'];
const imgskeys = [
    'nature',
    'water',
    'sport',
    'tennis',
    'golf',
    'game',
    'mountain',
    'sand',
    'beach',
    'ice',
    'glacier',
    'people',
    'soccer',
    'baseball',
];

// RANDOMIZATION
export const random = (max) => Math.floor(Math.random() * max);
export const randomRange = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;
export const randomBool = () => random(2) === 1;
export const randomFromArray = (arr) => arr[random(arr.length)];
export const randomIdLikeString = (length) => crypto.randomBytes(length / 2).toString('hex');
export const randomDate = () => new Date(+new Date() - Math.floor(Math.random() * 10000000000));

export const randomizePost = (): FetchPosts => {
    const socialNetwork = randomFromArray(networks);
    const isIgtv = socialNetwork === 'instagram' && randomBool();
    const isReel = socialNetwork === 'instagram' && !isIgtv && randomBool();
    const isStory = !isIgtv && randomBool();

    return {
        id: `${randomRange(1, 1000000)}`,
        social_network: socialNetwork,
        story: isStory,
        is_igtv: isIgtv,
        is_reel: isReel,
        published_at: `${randomRange(2016, 2019)}-0${randomRange(1, 9)}-${randomRange(10, 28)}T${randomRange(
            10,
            23,
        )}:${randomRange(10, 59)}:${randomRange(10, 59)}Z`,
        content:
            'Lorem ipsum dolor sit amet, sumo ipsum commodo at has, vix ea probatus repudiandae. His nusquam albucius contentiones cu, vis no singulis expetendis disputando. Vel mollis eligendi ea, id possim recteque intellegam ius, mea ex vitae ancillae imperdiet.',
        picture_url: randomBool()
            ? null
            : `https://loremflickr.com/g/${randomRange(320, 640)}/${randomRange(320, 640)}/${randomFromArray(
                  imgskeys,
              )}`,
        content_url: 'http://brandandcelebrities.com',
        username: 'Lorem Ipsum',
        sna_id: randomRange(1, 1000000),
        avg_engagement: {
            absolute: randomRange(1, 1000000),
            percent: randomRange(1, 1000) / 1000,
        },
        engagement_details: {
            ...(randomBool() ? { likes: randomRange(1, 1000000) } : null),
            ...(randomBool() ? { dislikes: randomRange(1, 1000000) } : null),
            ...(randomBool() ? { impressions: randomRange(1, 1000000) } : null),
            ...(randomBool() ? { reach: randomRange(1, 1000000) } : null),
            ...(randomBool() ? { views: randomRange(1, 1000000) } : { videos_views: randomRange(1, 1000000) }),
            ...(randomBool() ? { comments: randomRange(1, 1000000) } : null),
            ...(randomBool() ? { shares: randomRange(1, 1000000) } : null),
            ...(randomBool() ? { plays: randomRange(1, 1000000) } : null),
        },
    };
};
