import { createAction } from 'redux-actions';

import api from 'api';
import * as MESSAGING from 'constants/messaging';
import * as mappers from 'mappers/messaging';

import { setLoader } from 'actions/ui';
import { showModale } from 'actions/modale';
import { ENUM_TABS } from 'reducers/messaging';
import { AllMessagesParams } from 'types/Filters';

export const setAllUnreadMessages = createAction(MESSAGING.SET_ALL_UNREAD_MESSAGES);
const loadConversations = createAction(MESSAGING.LOAD_CONVERSATIONS);
const updateConversationsFromWS = createAction(MESSAGING.UPDATE_CONVERSATIONS_WS);
const updateDraftFromWS = createAction(MESSAGING.UPDATE_DRAFT);
const sortInboxListAction = createAction(MESSAGING.SORT_INBOX_LIST);
const loadDraftMessages = createAction(MESSAGING.LOAD_DRAFT_MESSAGES);
const removeDraft = createAction(MESSAGING.DELETE_DRAFT);
const loadConversationInfos = createAction(MESSAGING.LOAD_CONVERSATION_INFOS);
const loadAllMessages = createAction(MESSAGING.LOAD_ALL_MESSAGES);
export const resetCurrentConversation = createAction(MESSAGING.RESET_CURRENT_CONVERSATION);
export const addNewMessage = createAction(MESSAGING.ADD_NEW_MESSAGE);
const handleView = createAction(MESSAGING.HANDLE_VIEW);
export const updateFilters = createAction(MESSAGING.UPDATE_FILTERS);

export const getConversations = (filters?) => async dispatch => {
  dispatch(setLoader({id: 'listConversations', show: true}));
  const mappedFilters = mappers.getConversations.toApi(filters);
  const response = await api.fetchConversations(mappedFilters);
  const dataMapped = mappers.getConversations.fromApi(response?.data);

  dispatch(loadConversations(dataMapped));
  dispatch(setLoader({id: 'listConversations', show: false}));
}

export const updateConversationsListFromWS = data => async dispatch => {
  if (data?.action_type === 'create_draft_message' || data?.action_type === 'update_draft_message') {
    const mappedDraft = mappers.mapDraftMessage(data?.draft_message);
    await dispatch(updateDraftFromWS(mappedDraft));
  }
  if (data?.action_type === 'destroy_draft_message') {
    await dispatch(removeDraft(data?.draft_message_id))
  }
  if (data?.action_type === 'new_message') {
    const mappedData = mappers.getConversationsWS.fromApi(data);
    await dispatch(updateConversationsFromWS(mappedData));
  }
  dispatch(sortInboxListAction());
  return true;
}

export const updateUnreadMessage = data => dispatch => {
  const count = mappers.getUnreadMessageWS.fromApi(data);
  dispatch(setAllUnreadMessages(count));
}

export const getDraftMessages = (filters?) => async dispatch => {
  dispatch(setLoader({id: 'listConversations', show: true}));
  const mappedFilters = mappers.getDrafMessages.toApi(filters);
  const response = await api.getDraftMessages(mappedFilters);
  const dataMapped = mappers.getDrafMessages.fromApi(response?.data);

  dispatch(loadDraftMessages(dataMapped));
  dispatch(setLoader({id: 'listConversations', show: false}));
}

export const deleteDraft = (id) => async dispatch => {
  dispatch(setLoader({id: 'listConversations', show: true}));
  await api.deleteDraftMessage(id);
  dispatch(setLoader({id: 'listConversations', show: false}));
}

export const saveDraftMessage = ({conversationId, message}) => async (dispatch) => {
  const draftToApi = mappers.postDraftMessage.toApi({...message, conversationId});
  const messagePlainText = message.content?.getCurrentContent()?.getPlainText();
  if (message.draftId) {
    if (messagePlainText.trim() !== '') {
      await api.putDraftMessage(message.draftId, draftToApi);
    } else {
      dispatch(
        showModale({
          body: 'ConfirmRemoveDraft',
          data: {id: message.draftId},
        })
      );
    }
  } else {
    if (messagePlainText.trim() !== '') {
      await api.postDraftMessage(draftToApi);
    }
  }
}

export const getConversationInfos = conversationId => async dispatch => {
  dispatch(setLoader({id: 'getMessage', show: true}));
  const response  = await api.getConversationInfos(conversationId);
  const conversationInfosMapped = mappers.getConversationInfos.fromApi(response.data);
  dispatch(loadConversationInfos(conversationInfosMapped));
  dispatch(setLoader({id: 'getMessage', show: false}));
}

export const getAllMessages = (params: AllMessagesParams) => async dispatch => {
  dispatch(setLoader({id: 'getMessage', show: true}));
  const paramsMapped = mappers.getAllMessages.toApi(params);
  const response  = await api.getAllMessages(paramsMapped);
  const { total, rows} = mappers.getAllMessages.fromApi(response.data);
  dispatch(loadAllMessages({
    total,
    rows,
    page: params?.page,
    perPage: params?.perPage,
  }));
  dispatch(setLoader({id: 'getMessage', show: false}));

  return rows;
}

export const loadOldMessages = messageId => async (dispatch, getState) => {
  dispatch(setLoader({id: 'getMessage', show: true}));

  const { messages, id } = getState()?.messaging?.currentConversation;

  let newRows = [];
  const getPerPage = async (page) => {
    const params = {
      conversationId: id,
      perPage: messages.perPage,
      page,
    };
    const paramsMapped = mappers.getAllMessages.toApi(params);
    const response  = await api.getAllMessages(paramsMapped);
    const { rows, total } = mappers.getAllMessages.fromApi(response.data);
    newRows = [...rows, ...newRows];

    const messageFound = rows.find(r => r.id === messageId);

    if (messageFound) {
      return {
        rows: newRows,
        total,
        page,
        perPage: messages.perPage
      };
    } else {
      return await getPerPage(page + 1);
    }
  }

  const { total, rows, page, perPage } = await getPerPage(messages.page + 1);

  dispatch(loadAllMessages({
    total,
    rows,
    page,
    perPage,
  }));

  dispatch(setLoader({id: 'getMessage', show: false}));
}

export const sendMessage = ({ conversationId, message }) => async (dispatch, getState) => {
  const { filters } = getState().messaging;

  dispatch(setLoader({id: 'setMessage', show: true}));
  const messageToApi = mappers.postMessage.toApi(message);
  const response = await api.postMessage(conversationId, messageToApi);

  if (!response) return false;

  if (message.draftId !== null) {
    await api.deleteDraftMessage(message.draftId);

    if (filters.tab === ENUM_TABS.draft) {
      dispatch(updateFilters({
        page: 0,
        tab: ENUM_TABS.all
      }));
    }
  }

  dispatch(setLoader({id: 'setMessage', show: false}));

  return true;
}

export const mobileDisplay = displayPanel => dispatch => {
  dispatch(handleView({ displayPanel }));
}