import PropositionAnswerCardLexique from 'locales/types/containers/propositionPage/propositionAnswerCard';

const propositionAnsweredMobile: PropositionAnswerCardLexique = {
    title: `Votre réponse :`,
    form: {
        textarea: {
            placeholder: `N’hésitez pas à laisser un commentaire si vous avez besoin de précision`,
            error: `Ce champ est obligatoire`,
        },
        title: `Intéressé ?`,
        buttons: {
            yes: `Oui`,
            no: `Non`,
            discuss: `A discuter`,
        },
    },
};

export default propositionAnsweredMobile;
