import networks from './networks';
import components from './components';
import containers from './containers';
import countries from './countries';
import links from './links';
import { Lexique } from '../types';

export const lexique: Lexique = {
  config: {
    label: 'English',
    iso3: 'GBR',
    iso: 'en-GB',
    date: 'DD.MM.YYYY',
    altDate: 'DD/MM/YYYY',
    shortDate: 'D MMMM',
    discussDate: 'D MMMM YYYY - H:mm a',
    postDate: 'D MMMM YYYY',
    longDate: 'dddd D MMMM YYYY',
    hour: 'h:mm a',
    now: `Now {{time}}`,
    today: `Today {{time}}`,
    yesterday: `Yesterday {{time}}`,
    currencyCode: 'EUR',
    currency: '# ##0.## €',
    numbers: {
      b: `Bn`,
      m: `M`,
      k: `k`,
    },
    gender: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
  },
  links,
  components,
  containers,
  countries,
  networks,
};

export default lexique;
