import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import format from 'number-format.js';

import EuroSign from '@brandandcelebrities/icons/regular/EuroSign';
import CalendarAlt from '@brandandcelebrities/icons/regular/CalendarAlt';
import Comments from '@brandandcelebrities/icons/regular/Comments';

import { ReactComponent as Facebook } from 'assets/images/svg/facebook.svg';
import { ReactComponent as Instagram } from 'assets/images/svg/instagram.svg';
import { ReactComponent as Twitter } from 'assets/images/svg/twitter.svg';
import { ReactComponent as Youtube } from 'assets/images/svg/youtube.svg';
import { ReactComponent as Tiktok } from 'assets/images/svg/tiktok.svg';

import './PropositionDetails.scss';
import getLexique from 'locales';
import { Proposition } from 'reducers/proposition';
import PropositionDetailsLexique from 'locales/types/containers/propositionPage/propositionDetails';

const Networks = {
    facebook: Facebook,
    twitter: Twitter,
    instagram: Instagram,
    youtube: Youtube,
    tiktok: Tiktok,
};

interface Props {
    lexique: PropositionDetailsLexique;
    formats: any;
    proposition: Proposition;
}

class PropositionDetails extends Component<Props> {
    renderDate = (lexique, proposition) => {
        if (proposition.starts_at && proposition.ends_at) {
            if (proposition.starts_at === proposition.ends_at) {
                return `${moment(proposition.starts_at).format('DD/MM/YY')}`;
            }
            return `${moment(proposition.starts_at).format('DD/MM/YY')} ${lexique.date.to} ${moment(
                proposition.ends_at,
            ).format('DD/MM/YY')}`;
        }
        return '';
    };

    render() {
        const { lexique, formats, proposition } = this.props;

        return (
            <div className="bc-proposition-details">
                <div className="bc-proposition-details__title">{lexique.title}</div>
                <div className="bc-proposition-details__body">
                    <div className="bc-proposition-details__detail">
                        <h4 className="bc-proposition-details__detail-subtitle">
                            <EuroSign />
                            {lexique.budget.label}
                        </h4>
                        <p className="bc-proposition-details__detail-text">
                            {proposition.budget && proposition.budget !== 0
                                ? format(formats.currency, proposition.budget)
                                : lexique.budget.empty}
                        </p>
                    </div>
                    <div className="bc-proposition-details__detail">
                        <h4 className="bc-proposition-details__detail-subtitle">
                            <CalendarAlt />
                            {lexique.date.label}
                        </h4>
                        <p className="bc-proposition-details__detail-text">{this.renderDate(lexique, proposition)}</p>
                    </div>
                    <div className="bc-proposition-details__detail">
                        <h4 className="bc-proposition-details__detail-subtitle">
                            <Comments />
                            {lexique.socialNetworks}
                        </h4>
                        <div className="bc-proposition-details__detail-social-networks">
                            {proposition.project &&
                                proposition.project.social_networks &&
                                proposition.project.social_networks.map((network) => {
                                    const Icon = Networks[network];
                                    return (
                                        <span key={network} className={`icon icon--${network}`}>
                                            <Icon />
                                        </span>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lexique: getLexique(state.env.locale).containers.propositionPage.propositionDetails,
    formats: getLexique(state.env.locale).config,
    proposition: state.proposition.current,
});

export default connect(mapStateToProps, null)(PropositionDetails);
