import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash.isempty';

import { SearchBar, Select } from '@brandandcelebrities/kolkitten';

import { onChangeSearch, onChangeSortFilter, setSortFilter } from 'actions/analytics';

import './SearchCard.scss';
import getLexique from 'locales';
import { useSelector, useDispatch } from 'utils/redux';
import { AppState } from 'reducers';
import { SNAUserInfos } from 'reducers/user';
import { SearchCardLexique } from 'locales/types/containers/analyticsPage/posts';
import { SearchCard as ISearchCard } from 'reducers/ui/analytics';

const EMPTY_FILTERS = ['most_recent', 'most_engaging', 'most_liked'];

const ALL_FILTERS = ['most_relevant', ...EMPTY_FILTERS];

interface Selector {
    snas: SNAUserInfos[];
    lexique: SearchCardLexique;
    searchCard: ISearchCard;
}

const SearchCard: FC = () => {
    const { lexique, searchCard } = useSelector<Selector>((state: AppState) => ({
        lexique: getLexique(state.env.locale).containers.analyticsPage.posts.searchCard,
        searchCard: state.ui.analytics.posts.searchCard,
        snas: state.user.snas,
    }));
    const dispatch = useDispatch();

    const onChangeSearchCallback = useCallback(
        ({ tags }) => {
            if (_isEmpty(tags)) {
                dispatch(setSortFilter('most_engaging', EMPTY_FILTERS));
            } else if (tags.length === 1) {
                dispatch(setSortFilter('most_relevant', ALL_FILTERS));
            }
            dispatch(onChangeSearch(tags));

            // Google Analytics
            window.ga('send', 'event', {
                eventCategory: 'Posts',
                eventAction: `Search`,
                eventLabel: tags.join(','),
            });
        },
        [dispatch],
    );

    const onChangeSortFilterCallback = useCallback(
        (label: string) => {
            dispatch(onChangeSortFilter(label));

            // Google Analytics
            window.ga('send', 'event', {
                eventCategory: 'Posts',
                eventAction: `Sort`,
                eventLabel: label,
            });
        },
        [dispatch],
    );

    return (
        <div className="bc-analytics-search-card">
            <div className="bc-analytics-search-card__row">
                <div className="bc-analytics-search-card__search-input">
                    <SearchBar
                        id="input-engine-search-bar"
                        onChange={onChangeSearchCallback}
                        tags={searchCard.search}
                        advancedSearch={false}
                        autoFocus={false}
                    />
                </div>
                <Select
                    hasSearch={false}
                    className="bc-analytics-search-card__rating"
                    id="sortFilter"
                    value={searchCard.sortFilter.selectedOption}
                    defaultOptionLabel={lexique.sortFilter.options[searchCard.sortFilter.selectedOption]}
                    error={false}
                    errorText=""
                    dataset={searchCard.sortFilter.options.map((option) => ({
                        value: option,
                        label: lexique.sortFilter.options[option],
                    }))}
                    onChange={({ value }) => onChangeSortFilterCallback(value || searchCard.sortFilter.selectedOption)}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    lexique: getLexique(state.env.locale).containers.analyticsPage.posts.searchCard,
    searchCard: state.ui.analytics.posts.searchCard,
    snas: state.user.snas,
});

const mapDispatchToProps = (dispatch) => ({
    onChangeSearch: ({ tags }) => {
        if (_isEmpty(tags)) {
            dispatch(setSortFilter('most_engaging', EMPTY_FILTERS));
        } else if (tags.length === 1) {
            dispatch(setSortFilter('most_relevant', ALL_FILTERS));
        }
        dispatch(onChangeSearch(tags));

        // Google Analytics
        window.ga('send', 'event', {
            eventCategory: 'Posts',
            eventAction: `Search`,
            eventLabel: tags.join(','),
        });
    },
    onChangeSortFilter: (label) => {
        dispatch(onChangeSortFilter(label));

        // Google Analytics
        window.ga('send', 'event', {
            eventCategory: 'Posts',
            eventAction: `Sort`,
            eventLabel: label,
        });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCard);
