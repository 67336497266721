import React, { FC } from 'react';

import { EngagementRateFigure } from 'components/atoms';
import getLexique from 'locales';
import { simpleNumberFormatter } from 'utils';

import '../HomeSlide.scss';
import { useSelector } from 'utils/redux';
import { AppState } from 'reducers';

interface Props {
    network: string;
    firstValue: number;
    secondValue: number;
}

const EngagementRate: FC<Props> = ({ network, firstValue, secondValue }) => {
    const { lexique, user } = useSelector(({ env, user }: AppState) => ({
        lexique: getLexique(env.locale).containers.homePage.homeSlide.slides.engagementRate,
        user,
    }));

    return (
        <div className="bc-home__slide-container__slide">
            <div className="bc-home__slide-container__slide__stats">
                <EngagementRateFigure
                    network={network}
                    firstValue={firstValue}
                    secondValue={firstValue + secondValue}
                />
            </div>
            <div className="bc-home__slide-container__slide__title">{lexique.title(user.pseudo || user.firstName)}</div>
            <div className={`bc-home__slide-container__slide__text bc-home__slide-container__slide__text--${network}`}>
                {lexique.text(network, simpleNumberFormatter(secondValue))}
            </div>
        </div>
    );
};

export default EngagementRate;
