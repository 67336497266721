import React, { memo } from 'react';
import { Alert, Button } from '@brandandcelebrities/kolkit';

import styles from './TutorialModal.module.scss';

const PaperProps = {
    classes: { root: styles.root },
};

interface Props {
    open: boolean;
    title: string;
    subtitle?: string;
    img: string;
    primaryActionLabel: string;
    secondaryActionLabel: string;
    primaryActionLink: string;
    onSecondaryActionClick: () => void;
}

const TutorialModal: React.FC<Props> = ({
    open,
    title,
    subtitle,
    img,
    primaryActionLabel,
    primaryActionLink,
    secondaryActionLabel,
    onSecondaryActionClick,
}) => {
    return (
        <Alert className={styles.modal} on={open} PaperProps={PaperProps} width="auto">
            <div className={styles.modalContent}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subtitle}>{subtitle}</div>
                <img className={styles.img} src={img} alt="" />
                <a href={primaryActionLink} target="_blank" rel="noopener noreferrer">
                    <Button size="fat" icon="external-link" reverse fullWidth className={styles.primaryButton}>
                        {primaryActionLabel}
                    </Button>
                </a>
                <Button size="fat" fullWidth onClick={onSecondaryActionClick} theme="navy">
                    {secondaryActionLabel}
                </Button>
            </div>
        </Alert>
    );
};

export default memo(TutorialModal);
