import LoginLexique from 'locales/types/containers/loginPage';

const login: LoginLexique = {
  headTitle: 'Connectez-vous - Kolsquare',
  logIn: 'Se connecter',
  register: `Vous n’êtes pas encore inscrit(e) ?`,
  registerLink: 'Inscrivez-vous',
  successRegister: 'Félicitations votre inscription a été validé avec succès',
  continue: `Continuer`,
  email: {
    placeholder: 'Email',
    error: 'Cet email est invalide',
  },
  password: {
    placeholder: 'Mot de passe',
    error: 'Mot de passe incorrect',
  },
  passwordForgotten: {
    title: 'Mot de passe oublié ?',
    subTitle: 'Saisissez le mail associé à votre compte.',
    back: 'Retour',
    submit: 'Réinitialiser mon mot de passe',
    submitedEmail: '✔ Email envoyé !',
    resendEmail: 'Cliquez pour renvoyer',
  },
  newPassword: {
    title: 'Modifier votre mot de passe',
    password: 'Mot de passe',
    confirm: 'Confirmer le mot de passe',
    submit: 'Réinitialiser mon mot de passe',
  },
  error: {
    passwordLength: "12 caractères minimum",
    mismatches: 'Le mot de passe ne correspond pas',
    unconfirmed: "Email en attente de confirmation",
    tokenNotFound: "Votre lien est invalide. Merci de vérifier vos emails et cliquer sur le dernier lien que nous vous avons envoyé",
    isAlreadyConfirmed: "Email déjà confirmé",
    invalidEmail: "Email incorrect",
    invalidCredentials: "Email et/ou mot de passe incorrect(s)",
    forbidden: "Vous n’avez pas accès à ce service",
    notActive: "Votre compte est désactivé. Merci de nous contacter via support@kolsquare.com",
    expiredToken: "Votre lien a expiré",
    invalid: "Votre lien est invalide",
    maintenance: "Notre application est en cours de maintenance... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée",
    connectionError: "Connexion...",
    unknown: "Une erreur critique est survenue... Veuillez réessayer dans quelques minutes et nous excuser pour la gêne occasionnée",
    alreadyUsed: "Votre lien a déjà été utilisé",
    nonActivated: "Votre compte n’est pas activé. Activez votre compte avec l’e-mail d’activation que vous avez reçu."
  }
}

export default login;