import { Component, ComponentClass } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps<any, {}>;

class ScrollToTop extends Component<Props> {
    componentDidUpdate(prevProps: Props) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter<Props, ComponentClass<Props>>(ScrollToTop);
