import React, { FC } from 'react';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-component';
import { AppState } from 'reducers';
import { Post as IPost } from 'reducers/posts';

import Post from '../Post';

import './PostsList.scss';

interface Props {
    posts: IPost[];
}

const PostsList: FC<Props> = ({ posts }) => (
    <div className="bc-analytics-posts-list">
        <Masonry key="masonry" elementType="div" updateOnEachImageLoad options={{ gutter: 0, transitionDuration: 0 }}>
            {posts && posts.filter((p) => !p.story).map((data) => <Post key={data.id} data={data} />)}
        </Masonry>
    </div>
);

const mapStateToProps = (state: AppState) => ({
    posts: state.posts.all.posts,
});

export default connect(mapStateToProps, null)(PostsList);
