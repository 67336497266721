import React, { FC } from 'react';

import './ContactCard.scss';
import { useLexique } from 'utils/redux';
import { Proposition } from 'reducers/proposition';
import ContactCardLexique from 'locales/types/containers/propositionPage/contactCard';

interface Props {
    proposition: Proposition;
}

const ContactCard: FC<Props> = ({ proposition }) => {
    const lexique = useLexique<ContactCardLexique>('containers.propositionPage.contactCard');

    const style =
        proposition.account_executive && proposition.account_executive.photo_url
            ? { backgroundImage: `url('${proposition.account_executive.photo_url}')` }
            : {};

    return (
        <div className="bc-contact-card">
            <div className="bc-contact-card__top">
                <h3 className="bc-contact-card__title">{lexique.title}</h3>
                <p className="bc-contact-card__subtext">{lexique.subText}</p>
            </div>
            <div className="bc-contact-card__picture" style={style} />
            <div className="bc-contact-card__infos">
                <div className="bc-contact-card__full-name">
                    {proposition.account_executive
                        ? `${proposition.account_executive.first_name} ${proposition.account_executive.last_name}`
                        : 'Account Executive'}
                </div>
                <div className="bc-contact-card__mobile">
                    {proposition.account_executive && !!proposition.account_executive.tel
                        ? proposition.account_executive.tel
                        : '+33 01 84 16 98 13'}
                </div>
                <a
                    href={
                        proposition.account_executive && !!proposition.account_executive.email
                            ? `mailto:${proposition.account_executive.email}`
                            : 'mailto:contact@brandancelebrities.com'
                    }
                >
                    <div className="bc-contact-card__email">
                        {proposition.account_executive && !!proposition.account_executive.email
                            ? proposition.account_executive.email
                            : 'contact@brandancelebrities.com'}
                    </div>
                </a>
            </div>
        </div>
    );
};

export default ContactCard;
