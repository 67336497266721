import React, { useMemo, memo } from 'react';
import { Button } from '@brandandcelebrities/kolkit';
import nl2br from 'react-nl2br';

import { useLexique, useSelector } from 'utils/redux';

import { FAQPageLexique } from 'locales/types/containers/faqPage';
import { mailToRecipe } from 'config/env';

import styles from './FAQPage.module.scss';

const FAQPage = memo(() => {
    const lexique = useLexique<FAQPageLexique>('containers.faqPage');
    const subjectMail = useSelector((state) => {
        const { pseudo, firstName, lastName } = state.user;
        return lexique.mailObject.replace('{{1}}', pseudo || `${firstName} ${lastName}`.trim());
    });

    const renderFaqs = useMemo(
        () =>
            lexique.faqs.map((qa) => (
                <>
                    <div className={styles.question}>{qa.question}</div>
                    <div className={styles.answer}>
                        {qa.answer}
                        {qa.list && (
                            <ul>
                                {qa.list.map((li) => (
                                    <li>{li}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </>
            )),
        [lexique],
    );

    return (
        <div className={styles.faq}>
            <div className={styles.title}>{lexique.title}</div>
            <div className={styles.desc}>{nl2br(lexique.description)}</div>
            <div className={styles.content}>
                {renderFaqs}
                <div className={styles.cta}>
                    <span>{lexique.preCta}</span>
                    <a href={`mailto:${mailToRecipe}?subject=${subjectMail}`}>
                        <Button label={lexique.cta} size="big" />
                    </a>
                </div>
            </div>
        </div>
    );
});

FAQPage.displayName = 'FAQPage';

export default FAQPage;
