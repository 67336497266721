import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Loader } from '@brandandcelebrities/kolkit';

import { fetchFbc, reset, onConnect, onAccountDataReceived, onError, onSelect } from 'actions/fbc';
import { saveInstagramSelectedPages, saveUserToken, saveAutoUserToken } from 'actions/profile';
import { fetchStatsSharing } from 'actions/user';
import { SocialPicto } from 'components/atoms';
import { PagesPicker } from 'components/molecules';
import { fbc } from 'config/connect';
import { Networks } from 'constants/networks';
import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';
import InstaConnectLexique from 'locales/types/components/instaconnect';
import { Pages, Page } from 'types/Instaconnect';
import { useLexique, useDispatch, useSelector } from 'utils/redux';
import { ON_ACCOUNT_DATAS, ON_DISCONNECTED } from 'utils/fbc/FacebookBusinessConnect';
import { callGAEvent } from 'utils/googleAnalytics';

import QuestionCard from './components/QuestionCard';
import TutorialModal from './components/TutorialModal';

import styles from './StepInstaConnect.module.scss';

const QUESTIONS = ['instaAccountType', 'fbAccountType', 'linkedAccount'];
const MODAL_LINKS = [
  'https://instagram.com',
  'https://facebook.com/pages/creation',
  'https://facebook.com/pages/?category=your_pages&ref=bookmarks',
];

interface Props {
  onInstaConnectSuccess: () => void;
  onInstaConnectFail: () => void;
  className?: string;
  hideBackButton?: boolean;
}

const StepInstaConnect: React.FC<Props> = ({
   onInstaConnectSuccess,
   onInstaConnectFail,
   className,
   hideBackButton,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [instagramNotFound, setInstagramNotFound] = useState(false);
  const toastLexique = useLexique('components.toasts');
  const lexique: OnboardingPageLexique['steps']['instaConnect'] = useLexique(
    'containers.onboardingPage.steps.instaConnect',
  );
  const instaConnectLexique = useLexique<InstaConnectLexique>('components.instaconnect');
  const dispatch = useDispatch();

  const {
    fbcState: { pages, selectedPages, loading, error }, statsSharing
  } = useSelector(({ fbc: fbcState, user: { statsSharing } }) => ({
    fbcState,
    statsSharing,
  }));

  const isRenewingToken = useMemo(
    () => Boolean(statsSharing.instagramBusinessId),
    [statsSharing.instagramBusinessId]
  );

  const handlePositiveClick = useCallback(() => {
    setCurrentStep((step) => step + 1);
    callGAEvent('Onboarding', `instaconnect_step${currentStep + 1}`, 'yes');
  }, [currentStep]);

  const handleModalSecondaryClick = useCallback(() => {
    setOpenModal(false);
    setCurrentStep((step) => step + 1);
  }, []);

  const renderModal = useMemo(() => {
    const modalLexique = lexique[QUESTIONS[currentStep]]?.modal;

    return modalLexique ? (
      <TutorialModal
        open={openModal}
        title={modalLexique.title}
        subtitle={modalLexique.text}
        img={modalLexique.imgPath}
        primaryActionLabel={modalLexique.primaryActionLabel}
        primaryActionLink={MODAL_LINKS[currentStep]}
        secondaryActionLabel={lexique.modalSecondaryActionLabel}
        onSecondaryActionClick={handleModalSecondaryClick}
      />
    ) : null;
  }, [openModal, currentStep, lexique, handleModalSecondaryClick]);

  const handleNegativeClick = useCallback(() => {
    setOpenModal(true);
    callGAEvent('Onboarding', `instaconnect_step${currentStep + 1}`, 'idk');
  }, [currentStep]);

  // FBC
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const selectPages = useCallback(
    (selected: string | number) => {
      const selectedPage = pages.instagram.find((p) => p.uid === selected);
      dispatch(
        onSelect({
          network: Networks.instagram,
          pages: [selectedPage] || [],
        }),
      );
    },
    [dispatch, pages],
  );

  const onSubmitSendInstagramPages = useCallback(async (_pages: Page[]) => {
      try {
        // Send to back-end
        await dispatch(saveInstagramSelectedPages(_pages));

        // update snas
        _pages.map((page) =>
          dispatch(
            onConnect({
              ...page,
              label: Networks.instagram,
              url: `https://instagram.com/${page.username}`,
            }),
          ),
        );
        toast(toastLexique.instaConnect);
      } catch (err) {
        dispatch(onError(err));
      }
    }, [dispatch, toastLexique],
  );

  const submit = useCallback(async () => {
    if (selectedPages.instagram.length > 0) {
      await onSubmitSendInstagramPages(selectedPages.instagram);
      await dispatch(saveUserToken(selectedPages));
      onInstaConnectSuccess();
    }
  }, [onInstaConnectSuccess, onSubmitSendInstagramPages, selectedPages, dispatch]);

  const saveNewToken = useCallback(
    async (_pages: Pages) => {
      const instagramBusiness = _pages?.instagram.find(data => data?.businessId === statsSharing?.instagramBusinessId);
      if (!instagramBusiness) {
        await dispatch(onAccountDataReceived(_pages));
        setInstagramNotFound(true);
        return false;
      }

      const tokenData = {
        accessToken: instagramBusiness.accessToken,
        uid: instagramBusiness.uid,
        instagramBusinessId: instagramBusiness.businessId,
        triggerMode: 'manual'
      }
      await dispatch(saveAutoUserToken(tokenData));
      onInstaConnectSuccess();
    },
    [dispatch, statsSharing, onInstaConnectSuccess],
  );

  const getFBCData = useCallback(
    async (_pages: Pages) => {
      try {
        if (!_pages.instagram || _pages.instagram.length === 0) {
          throw new Error('No_pages');
        }
        if (_pages.accessToken) {
          await dispatch(fetchStatsSharing());
          if (_pages.instagram.length > 1) {
            await dispatch(onAccountDataReceived(_pages));
          } else {
            await dispatch(
              onSelect({
                network: Networks.instagram,
                pages: _pages.instagram,
              }),
            );
            await onSubmitSendInstagramPages(_pages.instagram);
            await dispatch(saveUserToken(_pages));
            onInstaConnectSuccess();
          }
          callGAEvent('Onboarding', 'instaconnect_success');
        } else {
          throw new Error('Not logged in');
        }
      } catch (e) {
        dispatch(onError(e));
        callGAEvent('Onboarding', 'instaconnect_failure');
      }
    },
    [dispatch, onSubmitSendInstagramPages, onInstaConnectSuccess],
  );

  const fail = useCallback(
    (e) => {
      dispatch(onError(e.reason));
      callGAEvent('Onboarding', `instaconnect_failure`);
    },
    [dispatch],
  );

  const getFacebookPages = useCallback(async () => {
    await dispatch(fetchFbc());
    fbc.once(ON_ACCOUNT_DATAS, isRenewingToken ? saveNewToken : getFBCData);
    fbc.once(ON_DISCONNECTED, fail);
    fbc.requestDatas();
  }, [dispatch, isRenewingToken, saveNewToken, getFBCData, fail]);

  if (error || (isRenewingToken && instagramNotFound)) {
    const errorMessage = error ? lexique.error.text : lexique.error.instagramNotFound;
    return (
      <div>
        {!hideBackButton && (
          <Button size="big" theme="ghost" icon="arrow-left" iconTheme="solid" onClick={onInstaConnectFail}>
            {lexique.backButton}
          </Button>
        )}
        <div className={styles.headerBlock}>
          <p className={styles.title}>{lexique.error.title}</p>
          <p className={styles.text}>{errorMessage}</p>
        </div>

        <Button fullWidth size="fat" className={styles.connectButton} onClick={getFacebookPages}>
          {lexique.error.retry}
        </Button>
        <Button fullWidth theme="reset" onClick={onInstaConnectFail}>
          {lexique.error.goBack}
        </Button>
      </div>
    );
  }

  return (
    <div className={className}>
      {!hideBackButton && (
        <Button size="big" theme="ghost" icon="arrow-left" iconTheme="solid" onClick={onInstaConnectFail}>
          {lexique.backButton}
        </Button>
      )}
      {!pages.instagram.length && (
        <>
          <div className={styles.headerBlock}>
            {currentStep === 3 ? (
              <div className={styles.title}>{lexique.titleFinished}</div>
            ) : (
              <>
                <div className={styles.header}>
                  <SocialPicto network={Networks.instagram} />
                  {lexique.header}
                </div>
                <div className={styles.title}>{lexique.title}</div>
                <div className={styles.text}>{lexique.text}</div>
              </>
            )}
          </div>
          <QuestionCard
            key="question-card-01"
            question={lexique.instaAccountType.question}
            positiveButtonLabel={lexique.positiveButton}
            negativeButtonLabel={lexique.negativeButton}
            onPositiveButtonClick={handlePositiveClick}
            onNegativeButtonClick={handleNegativeClick}
            active={currentStep === 0}
            finished={currentStep > 0}
          />
          <QuestionCard
            key="question-card-02"
            question={lexique.fbAccountType.question}
            positiveButtonLabel={lexique.positiveButton}
            negativeButtonLabel={lexique.negativeButton}
            onPositiveButtonClick={handlePositiveClick}
            onNegativeButtonClick={handleNegativeClick}
            active={currentStep === 1}
            finished={currentStep > 1}
          />
          <QuestionCard
            key="question-card-03"
            question={lexique.linkedAccount.question}
            positiveButtonLabel={lexique.positiveButton}
            negativeButtonLabel={lexique.negativeButton}
            onPositiveButtonClick={handlePositiveClick}
            onNegativeButtonClick={handleNegativeClick}
            active={currentStep === 2}
            finished={currentStep > 2}
          />
        </>
      )}
      {currentStep === 3 && (
        <div>
          {!loading && !pages.instagram.length && (
            <>
              <Button fullWidth size="fat" className={styles.connectButton} onClick={getFacebookPages}>
                {lexique.fbConnect}
              </Button>
              <div className={styles.warningMsg}>{lexique.fbConnectWarning}</div>
            </>
          )}
          {
            pages.instagram.length > 1 && (
              <div className={styles.pagePicker}>
                <PagesPicker
                  lexique={instaConnectLexique.selectPages.instagram}
                  pages={pages.instagram}
                  selected={selectedPages.instagram?.[0]?.uid}
                  onChange={selectPages}
                />
                <div className="mb20">
                  <Button
                    fullWidth
                    size="fat"
                    id="fbc-validate-pages"
                    label={lexique.submit}
                    onClick={submit}
                    disabled={selectedPages.instagram.length === 0}
                  />
                </div>
              </div>
            )
          }
        </div>
      )}
      {loading && <Loader full className={styles.loader} />}
      {renderModal}
    </div>
  );
};

export default StepInstaConnect;
