import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';
import { AnyAction } from 'redux';

import * as ENV from 'constants/env';
import * as ONBOARDING from 'constants/onboarding';

export interface OnboardingState {
    currentStep?: number;
    isOnInstaConnect?: boolean;
    isInstaConnected?: boolean;
    isRequestedToShareStats?: boolean;
    isStatsShared?: boolean;
}

const initialState: OnboardingState = {
    currentStep: -1,
    isOnInstaConnect: false,
    isInstaConnected: false,
    isRequestedToShareStats: false,
    isStatsShared: false,
};

const reducers = {
    [ENV.FETCH_APP_INIT_SUCCESS]: (
        state: OnboardingState,
        {
            payload: {
                response: { onboarding_current_step: currentStep },
            },
        }: AnyAction,
    ): OnboardingState =>
        update(state, {
            currentStep: {
                $set: currentStep ? parseInt(currentStep, 10) : null,
            },
        }),
    [ONBOARDING.UPDATE_STATE]: (state: OnboardingState, { payload }: AnyAction): OnboardingState =>
        update(
            state,
            Object.entries(payload).reduce((prev, [key, value]) => {
                return { ...prev, [key]: { $set: value } };
            }, {}),
        ),
};

export default createReducer<OnboardingState, AnyAction>(initialState, reducers);
