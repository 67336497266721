export enum Networks {
    facebook = 'facebook',
    instagram = 'instagram',
    instagram_stories = 'instagram_stories',
    twitter = 'twitter',
    youtube = 'youtube',
    tiktok = 'tiktok',
    snapchat = 'snapchat',
    pinterest = 'pinterest',
    twentyone_buttons = 'twentyone_buttons',
    twitch = 'twitch',
    blog = 'blog',
    website = 'website',
    other_networks = 'other_networks',
}

export interface NetworkVariant {
    isStory?: boolean;
    isIgtv?: boolean;
    isReel?: boolean;
}

export interface SnaRequestData {
    label: string;
    uid?: string;
    username?: string;
    picture_url?: string;
}

export interface UnsupportedSnaRequestData {
    type: string;
    url: string;
}

export interface SocialNetwork {
    id: Networks;
    placeholder: string;
    label: string;
    color: string;
    inverseColor: string;
    supported: boolean;
}

export interface SocialNetworks {
    [sna: string]: SocialNetwork;
}

export interface EngagementPerPosts {
    absolute: number;
    percent: number;
}

export interface EngagementDetails {
    avg_likes?: number;
    avg_dislikes?: number;
    avg_comments?: number;
    avg_views?: number;
    avg_shares?: number;
}

export interface SnaFormData {
    url: string;
    error: boolean;
    errorType: string;
    disabled: boolean;
}

export interface SnaStats {
    community_count?: number;
    posts_per_week?: number;
    avg_engagement_rate?: number;
    avg_engagement_rate_per_post?: number;
    avg_engagement_per_post?: EngagementPerPosts;
    engagement_details?: EngagementDetails;
    community_progression_rate?: number;
    engagement_rate_progression?: number;
    total_posts?: number;
    emv?: number;
    min_emv_per_post?: number;
    max_emv_per_post?: number;
    emv_per_story?: number;
    min_emv_per_story?: number;
    max_emv_per_story?: number;
}

export interface SnaData {
    id: number;
    username?: string;
    uid?: string;
    url?: string;
    label?: Networks;
}

export interface SNAMappedData extends SnaData {
    stats?: SnaStats;
}

export interface SNAObject extends SNAMappedData {
    supported?: boolean;
    stats?: SnaStats;
    filled: boolean;
    valid_token?: boolean;
    top_posts?: Post[];
}

export type SNAObjects = SNAObject[];

export interface SNAAPIData extends Omit<SnaData, 'label'>, SnaStats {
    label: string;
}

export interface SnasMapper {
    fromApi: (data: SNAAPIData[]) => SNAMappedData[];
}

export interface SnaMapper {
    fromApi: (data: SNAAPIData) => SNAMappedData;
}

export interface SnaDataChartDataFetch {
    dates: string[];
    community: number[];
    engagement: number[];
    content: number[];
}

export interface SnaDataChartFetch {
    label: Networks;
    username: string;
    uid: string;
    data: SnaDataChartDataFetch;
}
