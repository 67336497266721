import React, { memo } from 'react';
import './card.scss';

export enum CardColors {
    blue = 'blue',
    red = 'red',
    green = 'green',
}

interface Props {
    label: string;
    color: CardColors;
}

const Card: React.SFC<Props> = memo(({ children, label, color }) => (
    <div className="card">
        <sup className="card-label">{label}</sup>
        <div className={`card-box card-box-${color}`}>{children}</div>
    </div>
));

Card.defaultProps = {
    color: CardColors.blue,
};

export default Card;
