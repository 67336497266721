export const MODAL_OPEN: 'community/socialStatsCard/MODAL_OPEN' = 'community/socialStatsCard/MODAL_OPEN';
export const MODAL_CLOSE: 'community/socialStatsCard/MODAL_CLOSE' = 'community/socialStatsCard/MODAL_CLOSE';
export const MODAL_ON_CONNECT: 'community/socialStatsCard/MODAL_ON_CONNECT' =
    'community/socialStatsCard/MODAL_ON_CONNECT';

export const MODAL_RESET_INPUT: 'community/socialStatsCard/MODAL_RESET_INPUT' =
    'community/socialStatsCard/MODAL_RESET_INPUT';
export const MODAL_ON_SEND: 'community/socialStatsCard/MODAL_ON_SEND' = 'community/socialStatsCard/MODAL_ON_SEND';
export const MODAL_ON_EDIT: 'community/socialStatsCard/MODAL_ON_EDIT' = 'community/socialStatsCard/MODAL_ON_EDIT';
export const MODAL_FETCH_INFO_SNA_SUCCESS: 'community/socialStatsCard/MODAL_FETCH_INFO_SNA_SUCCESS' =
    'community/socialStatsCard/MODAL_FETCH_INFO_SNA_SUCCESS';
export const MODAL_FETCH_INFO_SNA_FAILURE: 'community/socialStatsCard/MODAL_FETCH_INFO_SNA_FAILURE' =
    'community/socialStatsCard/MODAL_FETCH_INFO_SNA_FAILURE';
export const MODAL_INFO_SNA_ERROR: 'community/socialStatsCard/MODAL_INFO_SNA_ERROR' =
    'community/socialStatsCard/MODAL_INFO_SNA_ERROR';
export const MODAL_CLEAR_SNA_ERROR: 'community/socialStatsCard/MODAL_CLEAR_SNA_ERROR' =
    'community/socialStatsCard/MODAL_CLEAR_SNA_ERROR';
export const MODAL_POST_SNA_SUCCESS: 'community/socialStatsCard/MODAL_POST_SNA_SUCCESS' =
    'community/socialStatsCard/MODAL_POST_SNA_SUCCESS';
export const MODAL_POST_SNA_FAILURE: 'community/socialStatsCard/MODAL_POST_SNA_FAILURE' =
    'community/socialStatsCard/MODAL_POST_SNA_FAILURE';

export const FETCH_STATS_SNA_SUCCESS: 'community/socialStatsCard/FETCH_STATS_SNA_SUCCESS' =
    'community/socialStatsCard/FETCH_STATS_SNA_SUCCESS';
export const FETCH_STATS_SNA_FAILURE: 'community/socialStatsCard/FETCH_STATS_SNA_FAILURE' =
    'community/socialStatsCard/FETCH_STATS_SNA_FAILURE';
export const INFO_SNA_ERROR: 'community/socialStatsCard/INFO_SNA_ERROR' = 'community/socialStatsCard/INFO_SNA_ERROR';
