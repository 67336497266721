import React, { Component } from 'react';
import { connect } from 'react-redux';
import classname from 'classnames';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash.isempty';
import { HeaderLexique } from 'locales/types/containers/shared/header';

import { postLogout } from 'actions/header';
import getLexique from 'locales/index';
import routes from 'config/routes';

import { isEventOutside } from 'utils/dom';
import { UserState } from 'reducers/user';
import { AppState } from 'reducers';
import './SubMenu.scss';

interface State {
    deployed: boolean;
}

interface Props {
    postLogout: () => void;
    lexique: HeaderLexique;
    user: UserState;
}

class SubMenu extends Component<Props, State> {
    ref: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = { deployed: false };
    }

    componentWillUnmount() {
        this.reploy();
    }

    checkClickOutside = (e) => {
        if (isEventOutside(e, this.ref.current)) this.reploy();
    };

    toggleDeploy() {
        if (this.state.deployed) this.reploy();
        else this.deploy();
    }

    deploy() {
        this.setState({ deployed: true });
        document.addEventListener('click', this.checkClickOutside, false);
        document.addEventListener('touchend', this.checkClickOutside, false);
    }

    reploy() {
        this.setState({ deployed: false });
        document.removeEventListener('click', this.checkClickOutside, false);
        document.removeEventListener('touchend', this.checkClickOutside, false);
    }

    clickOnAnItem = () => {
        this.reploy();
    };

    render() {
        const { lexique, user } = this.props;

        const profilePictureStyle = _isEmpty(user.pictureUrl) ? {} : { backgroundImage: `url('${user.pictureUrl}')` };

        return (
            <div
                className={classname({
                    'bc-header__profile-picture-container': true,
                    'bc-header__profile-picture-container--visible': this.state.deployed,
                })}
                ref={this.ref}
            >
                <button
                    type="button"
                    className={classname({
                        'bc-header__profile-picture': true,
                        'bc-header__profile-picture--empty': _isEmpty(user.pictureUrl),
                    })}
                    style={profilePictureStyle}
                    onClick={() => this.toggleDeploy()}
                />
                <div
                    className={classname({
                        'bc-sub-menu': true,
                        'bc-sub-menu--visible': this.state.deployed,
                    })}
                >
                    <div className="bc-sub-menu__content" onClick={this.clickOnAnItem}>
                        <Link to={routes.settings} className="bc-sub-menu__card">
                            {lexique.subMenu.settings}
                        </Link>
                        <Link to={routes.profile} className="bc-sub-menu__card">
                            {lexique.subMenu.profile}
                        </Link>
                        <button type="button" className="bc-sub-menu__card" onClick={() => this.props.postLogout()}>
                            {lexique.subMenu.logout}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): Partial<Props> => ({
    lexique: getLexique(state.env.locale).containers.shared.header.header,
    user: state.user,
});

export default connect(mapStateToProps, { postLogout })(SubMenu);
