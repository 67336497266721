import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Loader } from '@brandandcelebrities/kolkit';

import { updateField, loadProfile, saveProfile } from 'actions/profile';
import { Networks } from 'constants/networks';
import { TYPES } from 'components/atoms/FormField/FormField';
import Form, { FieldType } from 'components/molecules/Form/Form';
import StepperHeader from 'components/molecules/StepperHeader';
import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';
import { useLexique, useSelector, useDispatch } from 'utils/redux';
import { isSnaUrlValid, formatSnaUrl } from 'utils/snas';
import { callGAEvent } from 'utils/googleAnalytics';

import ProfilePageLexique from 'locales/types/containers/profilePage';
import styles from './Steps.module.scss';

const FIELDSET = (gender = 'male'): FieldType[] => [
    {
        field: 'activities',
        type: TYPES.ACTIVITIES,
        options: { gender },
    },
    {
        field: 'influenceThemes',
        type: TYPES.THEMES,
    },
    {
        field: 'website',
        type: TYPES.TEXT,
    },
];

interface Props {
    onClickNext: () => void;
}

const StepActivity: React.FC<Props> = ({ onClickNext }) => {
    const lexique: OnboardingPageLexique = useLexique('containers.onboardingPage');
    const profileLexique: ProfilePageLexique = useLexique('containers.profilePage');
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({ website: '' });
    const { form, loading, locale, FIELDSET_WITH_GENDER } = useSelector(({ env, profile }) => ({
        locale: env.locale,
        form: profile.profile,
        FIELDSET_WITH_GENDER: FIELDSET(profile.profile.gender),
        loading: profile.loading,
    }));

    useEffect(() => {
        // Load profile
        dispatch(loadProfile());
    }, [dispatch]);

    const handleChange = (name: string, value: string): void => {
        dispatch(updateField({ field: name, value }));
    };

    const handleBlur = (name: string): void => {
        if (name === 'website') {
            setErrors({ website: '' });
        }
    };

    const handleClickSave = useCallback(async () => {
        if (form.website?.trim() && !isSnaUrlValid(Networks.website, formatSnaUrl(form.website.trim()))) {
            setErrors({ website: profileLexique.errorWebsiteInvalid });
            return null;
        }
        setSaving(true);
        await dispatch(saveProfile(form, 3));
        setSaving(false);
        callGAEvent('Onboarding', 'step_3_validated');
        return onClickNext();
    }, [form, profileLexique.errorWebsiteInvalid, dispatch, onClickNext]);

    const disabled = useMemo(() => loading || saving, [loading, saving]);

    return (
        <div>
            <StepperHeader header={lexique.header} title={lexique.steps.activity.title} className={styles.header} />
            <Form
                fields={FIELDSET_WITH_GENDER}
                placeholders={lexique.steps.activity.placeholders}
                labels={lexique.steps.activity.labels}
                className={styles.form}
                onChange={handleChange}
                values={form}
                locale={locale}
                disabled={disabled}
                errors={errors}
                onBlur={handleBlur}
            />
            <Button fullWidth size="fat" className={styles.button} disabled={disabled} onClick={handleClickSave}>
                {lexique.next}
            </Button>
            {disabled && <Loader full className={styles.loader} />}
        </div>
    );
};

export default StepActivity;
