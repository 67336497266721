import PropositionDetailsLexique from 'locales/types/containers/propositionPage/propositionDetails';

const propositionDetails: PropositionDetailsLexique = {
    title: `Project details`,
    budget: {
        label: `Budget:`,
        empty: `To be defined`,
    },
    date: {
        label: `Dates:`,
        to: `to`,
    },
    socialNetworks: `Networks:`,
};

export default propositionDetails;
