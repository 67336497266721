import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';

import { Icon, Truncate } from '@brandandcelebrities/kolkit';

import MessagingLexique from 'locales/types/containers/messagingPage';
import { Timeago } from 'containers/messagingPage/Modules';
import { Tag } from 'containers/messagingPage/Components';
import Markdown from 'components/atoms/Markdown';
import { markdownToEditor } from 'utils/richTextEditor';
import { DraftState, MessageState, MESSAGE_SUBTYPES, ProfileState } from 'reducers/messaging';

import Card from './Card';

import styles from './Card.module.scss';

interface Props {
  lastMessage: MessageState;
  draft?: DraftState | null;
  participants: ProfileState[];
  lexique: MessagingLexique;
  subject?: string;
  date: string;
  unreadMessages: number;
  active?: boolean;
  onClick: () => void;
  index: number;
  hasBeenRead?: boolean;
  animated?: boolean;
  me?: number | null;
  ref?: any;
}

const CardMessage = forwardRef<HTMLDivElement, Props>((
  { lastMessage, draft, participants, lexique, subject, date, unreadMessages, hasBeenRead, active, onClick, index, animated, me, ...rest },
  ref
) => {
  const [name, setName] = useState('');

  const isMe = useMemo(
    () => lastMessage?.writer?.id?.toString() === me.toString(),
    [lastMessage, me]
  );

  useEffect(
    () => {
      const otherParticipants = participants.filter(p => p?.id?.toString() !== me.toString());
      const cardName = (!isMe && lastMessage?.writer?.name)
        ? lastMessage.writer.name
        : otherParticipants?.[0].name;
      setName(cardName);
    },
    [participants, lastMessage, isMe, me]
  );

  const getPreview = useMemo(() => {
    const { subtype, content } = lastMessage;
    const prefix = isMe ? `${lexique.chat.me}: ` : '';

    if (draft) {
      const plainText = markdownToEditor(draft?.content)?.getCurrentContent().getPlainText()
      return (
        <div><p>{prefix}<i>{plainText}</i></p></div>
      )
    }

    if (subtype === MESSAGE_SUBTYPES.stateUpdated) return null;
    if (subtype === MESSAGE_SUBTYPES.statsSharingRequest && !content) {
      return (
        <div>
          <p>{lexique.chat.notification.statsSharing.title}</p>
        </div>
      );
    }

    if (subtype === MESSAGE_SUBTYPES.statsSharingHalted) {
      const { username } = JSON.parse(content);
      return (
        <div>
          <p>{lexique.chat.notification.statsSharing.statsSharingHalted.replace('{{username}}', username)}</p>
        </div>
      );
    }

    if (subtype === MESSAGE_SUBTYPES.statsSharingApproval) {
      return (
        <div>
          <p>{lexique.chat.notification.statsSharing.linkLabel}</p>
        </div>
      );
    }

    if (subtype === MESSAGE_SUBTYPES.statsSharingResumed) {
      const { username } = JSON.parse(content);
      return (
        <div>
          <p>{lexique.chat.notification.statsSharing.statsSharingApproval.replace('{{username}}', username)}</p>
        </div>
      );
    }

    if (subtype === MESSAGE_SUBTYPES.answerBrief) {
        return (
          <div>
            <p>{prefix}{lexique.chat.brief[content === 'yes' ? 'agreed' : 'disagreed']}</p>
        </div>
      );
    }

    const truncatedPreview = content ? content?.slice(0, 50) : '';
    return (
      <>
        {`${prefix}`}<Markdown>{truncatedPreview}</Markdown>
      </>
    )
  }, [lexique, lastMessage, isMe, draft]);

  const renderParticipants = useMemo(
    () => {
      return (
        <span className={styles.name}>
          <Truncate>{name}</Truncate>
          {participants.length > 2 && <span className={styles.participantsSize}>{`+${ participants.length - 2 }`}</span>}
        </span>
      )
    },
    [name, participants.length]
  );

  const read = useMemo(
    () => {
      if (unreadMessages > 0) {
        return (
          <span className={styles.unreadMessages}>
            {unreadMessages > 9 ? '9+' : unreadMessages}
          </span>
        )
      }

      if (hasBeenRead && isMe) {
        return (
          <Icon
            label="check"
            fill="#30E2BF"
            theme="solid"
            width={12}
          />
        )
      }

      return null;
    },
    [unreadMessages, hasBeenRead, isMe]
  )

  return (
    <Card
      ref={ref}
      index={index}
      animated={animated}
      className={styles.cardMessage}
      name={name}
      active={active}
      onClick={onClick}
      {...rest}
    >
      <>
        <div className={styles.body}>
          <div className="flexRow">
            {renderParticipants}
            <Tag className={styles.tag}>
              <Truncate>
                {subject}
              </Truncate>
            </Tag>
          </div>
          <div className={styles.preview}>
            {getPreview}
          </div>
        </div>

        <div className={styles.data}>
          <Timeago timestamp={date} className={styles.timeago} />
          {read}
        </div>
      </>
    </Card>
  )
});

CardMessage.displayName = 'CardMessage';

export default memo(CardMessage);
