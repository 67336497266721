// @TODO: remove this reducer once the components modals are refactoring
import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as UI from 'constants/ui';
import { AnyAction } from 'redux';

export interface ModalsState {
    upgradeModal: {
        display: boolean;
    };
}

const initialState: ModalsState = {
    upgradeModal: {
        display: false,
    },
};

const reducers = {
    [UI.SHOW_UPGRADE_MODAL]: (state: ModalsState): ModalsState =>
        update(state, {
            upgradeModal: {
                $merge: { display: true },
            },
        }),
};

export default createReducer<ModalsState, AnyAction>(initialState, reducers);
