import React, { FC, useCallback, useRef, useMemo } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import classname from 'classnames';

import Badge from '@material-ui/core/Badge';

import UserCircle from '@brandandcelebrities/icons/light/UserCircle';
import PowerOff from '@brandandcelebrities/icons/light/PowerOff';

import { hide, postLogout } from 'actions/menu';
import { changeLocale } from 'actions/env';

import { availableLocales, Locale } from 'locales/index';

import routes from 'config/routes';

import { useDispatch, useSelector, useLexique } from 'utils/redux';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { sendGAEvent } from 'utils/googleAnalytics';

import { AppState } from 'reducers';
import { MenuState } from 'reducers/ui/menu';
import { MenuLexique } from 'locales/types/containers/shared/menu';

import * as FLAGS from 'components/svgs/flags';

import './Menu.scss';
import { ReactComponent as Settings } from 'assets/images/svg/settings.svg';
import { ReactComponent as Home } from 'assets/images/svg/home.svg';
import { ReactComponent as Envelope } from 'assets/images/svg/envelope.svg';
import { ReactComponent as Users } from 'assets/images/svg/users.svg';
import { ReactComponent as Eye } from 'assets/images/svg/eye.svg';
import { ReactComponent as PhotoVideo } from 'assets/images/svg/photo-video-solid.svg';

interface Selector {
    allUnreadMessages: number;
    menu: MenuState;
    logged: boolean;
}

const Menu: FC = () => {
    const lexique = useLexique<MenuLexique>('containers.shared.menu');

    const { menu, allUnreadMessages, logged } = useSelector(
        ( {ui, messaging, env}: AppState): Selector => ({
            menu: ui?.menu,
            allUnreadMessages: messaging?.allUnreadMessages,
            logged: env.logged,
        }),
    );

    const dispatch = useDispatch();

    const wrapperRef = useRef();

    const onChangeLocale = useCallback(
        (locale: Locale) => {
            dispatch(changeLocale(locale));
            dispatch(hide());
        },
        [dispatch],
    );

    const logout = useCallback(() => dispatch(postLogout()), [dispatch]);
    const onClickMenu = useCallback(
        (menu) => {
            dispatch(hide());

            // GA
            if (['audience', 'content', 'partner'].includes(menu)) {
                sendGAEvent({
                    eventCategory: 'Analytics',
                    eventAction: `Nav`,
                    eventLabel: menu === 'audience' ? `Statistics` : menu === 'content' ? `Posts` : `Partners`,
                });
            }
        },
        [dispatch],
    );

    useOnClickOutside(wrapperRef, () => {
        if (menu.display) dispatch(hide());
    });

    const cnMenu = classname({
        'bc-menu': true,
        'bc-menu--animatable': true,
        'bc-menu--visible': menu.display,
    });

    const allMenu = useMemo(
        () => ({
            home: {
                icon: <Home />,
                to: '/',
                exact: true,
                label: lexique.navLinks.home,
            },
            audience: {
                icon: <Users />,
                to: `${routes.analytics.index}${routes.analytics.audience}`,
                exact: false,
                label: lexique.navLinks.audience,
            },
            content: {
                icon: <PhotoVideo />,
                to: `${routes.analytics.index}${routes.analytics.content}`,
                exact: false,
                label: lexique.navLinks.content,
            },
            partner: {
                icon: <Eye />,
                to: `${routes.analytics.index}${routes.analytics.partner}`,
                exact: false,
                label: lexique.navLinks.partner,
            },
            discuss: {
                icon: <Envelope />,
                to: routes.conversations,
                exact: false,
                label: lexique.navLinks.discuss,
                badge: allUnreadMessages,
            },
            settings: {
                icon: <Settings />,
                to: routes.settings,
                exact: true,
                label: lexique.navLinks.settings,
            },
            profile: {
                icon: <UserCircle />,
                to: routes.profile,
                exact: true,
                label: lexique.navLinks.profile,
            },
        }),
        [lexique, allUnreadMessages],
    );

    return (
        <div className={cnMenu}>
            <div className="bc-menu__container" ref={wrapperRef}>
                {logged && Object.keys(allMenu).map((menu) => (
                    <MenuItem key={`menu-${menu}`} data={allMenu[menu]} menu={menu} onClick={onClickMenu} />
                ))}
                {logged && (
                    <button type="button" className="bc-menu__card" onClick={logout}>
                        <div className="bc-menu__card-icon">
                            <PowerOff />
                        </div>
                        <div className="bc-menu__card-text">{lexique.navLinks.signout}</div>
                    </button>
                )}
                <div className="bc-menu__card bc-menu__card-languages">
                    {availableLocales.map((l) => (
                        <LocalItem key={l.id} data={l} onClickHandler={onChangeLocale} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const LocalItem = ({ onClickHandler, data }) => {
    const onClickButton = useCallback(() => {
        onClickHandler(data.id);
    }, [onClickHandler, data.id]);

    return (
        <button type="button" className="bc-menu__card-language" onClick={onClickButton}>
            {FLAGS[`FLAG_${data.iso3.toUpperCase()}`]}
        </button>
    );
};

const MenuItem = ({ onClick, data, menu }) => {
    const onClickItem = useCallback(() => {
        onClick(menu);
    }, [onClick, menu]);

    const renderLabel = useMemo(() => {
        if (data.badge)
            return (
                <Badge className="bc-menu__badge" badgeContent={data.badge}>
                    {data.label}
                </Badge>
            );
        return data.label;
    }, [data.badge, data.label]);

    const isActive = useCallback(
        (_, location) => {
            if (menu === 'home') return location?.pathname === '/';
            return location?.pathname?.indexOf(data?.to) > -1
        },
        [data, menu]
    );

    const cnLink = classname('bc-menu__card', {
        'bc-menu__card--disabled': data.disabled,
    });

    return (
        <NavLink
            exact
            to={data.to}
            className={cnLink}
            activeClassName="bc-menu__card--active"
            onClick={onClickItem}
            isActive={isActive}
        >
            <div className="bc-menu__card-icon">{data.icon}</div>
            <div className="bc-menu__card-text">{renderLabel}</div>
        </NavLink>
    );
};

export default withRouter(Menu);
