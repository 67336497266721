import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import * as ENV from 'constants/env';
import * as PROPOSITION from 'constants/propositionPage';
import * as PROPOSITION_ANSWER_CARD from 'constants/propositionAnswerCard';
import * as PROPOSITION_ANSWER_MOBILE from 'constants/propositionAnswerMobile';

import { fetchPropositionFailure, fetchPropositionSuccess } from 'actions/propositionPage';
import { ActionMeta } from 'redux-actions';
import * as card from 'actions/propositionAnswerCard';
import * as mobile from 'actions/propositionAnswerMobile';
import api from '../api';

export function* watchPropositionPage() {
    yield all([
        // fork(watchPropositionAnswerMobileModal),
        fork(watchPropositionPageLoad),
        fork(watchPropositionAnswerCard),
        fork(watchPropositionAnswerMobile),
    ]);
}

export function* watchPropositionPageLoad() {
    let action = yield take([ENV.FETCH_APP_INIT_SUCCESS, PROPOSITION.LOAD]);
    if (action.type === PROPOSITION.LOAD) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        action = yield take(PROPOSITION.LOAD);
    }

    const { env } = yield select();
    yield fork(fetchPropositionPage, env.userToken, action.payload.id);

    yield takeEvery(PROPOSITION.LOAD, function* ({ payload }: ActionMeta<any, any>) {
        const { env } = yield select();
        yield fork(fetchPropositionPage, env.userToken, payload.id);
    });
}

function* watchPropositionAnswerCard() {
    yield takeEvery(PROPOSITION_ANSWER_CARD.ON_CLICK, function* ({ payload }: ActionMeta<any, any>) {
        const { env } = yield select();

        try {
            const response = yield call(api.postAnswer, env.userToken, payload.id, payload.type, payload.text);
            yield put(
                card.postAnswerSuccess({
                    data: { ...response.data },
                    id: payload.id,
                    type: payload.type,
                    text: payload.text,
                }),
            );
            yield call(fetchPropositionPage, env.userToken, payload.id);
        } catch (error) {
            yield put(
                card.postAnswerFailure({
                    id: payload.id,
                    type: payload.type,
                    text: payload.text,
                    errorType: 'unkownError',
                    optional: { response: error },
                }),
            );
        }
    });
}

function* watchPropositionAnswerMobile() {
    yield takeEvery(PROPOSITION_ANSWER_MOBILE.ON_CLICK, function* ({ payload }: ActionMeta<any, any>) {
        const { env } = yield select();
        try {
            const response = yield call(api.postAnswer, env.userToken, payload.id, payload.type, payload.text);
            yield put(
                mobile.postAnswerSuccess({
                    data: { ...response.data },
                    id: payload.id,
                    type: payload.type,
                    text: payload.text,
                }),
            );
            yield put(mobile.closeModal());
            yield call(fetchPropositionPage, env.userToken, payload.id);
        } catch (error) {
            yield put(
                mobile.postAnswerFailure({
                    id: payload.id,
                    type: payload.type,
                    text: payload.text,
                    errorType: 'unkownError',
                    optionals: { response: error },
                }),
            );
        }
    });
}

function* fetchPropositionPage(token, id) {
    try {
        const fetchPropositionResponse = yield call(api.fetchProposition, token, id);
        yield put(fetchPropositionSuccess({ proposition: fetchPropositionResponse.data }));
    } catch (error) {
        yield put(fetchPropositionFailure(error));
        yield put(push('/'));
    }
}
