import { HeaderLexique } from 'locales/types/containers/analyticsPage/header';

export const headerLexique: HeaderLexique = {
    totalLabel: 'Total community',
    averageLabel: 'Average engagements (3 months)',
    navbar: {
        posts: 'Your Posts',
        statistics: 'Statistics',
        community: 'Community',
        partenariats: 'Partnership',
    },
};

export default headerLexique;
