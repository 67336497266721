import { createAction } from 'redux-actions';
import _get from 'lodash.get';

import { updateOnboardingState } from 'actions/onboarding';
import { fetchStatsSharing, saveStatsSharing, openOnboardingStatsSharing } from 'actions/user';
import { showModale } from 'actions/modale';

import * as ENV from 'constants/env';
import { conf } from 'config/env';
import routes from 'config/routes';
import api from 'api';
import { getTokenFromCookies } from 'utils';
import { callGAEvent } from 'utils/googleAnalytics';

export const setDevice = createAction(ENV.DEVICE_CHANGED, (device) => ({ device }));
export const setViewport = createAction(ENV.VIEWPORT_CHANGED, (w, h) => ({ w, h }));
export const setClientToken = createAction(ENV.CLIENT_TOKEN_SET, (token) => ({ token }));

export const fetchAppInitSuccess = createAction(ENV.FETCH_APP_INIT_SUCCESS, (response) => ({ response }));
export const fetchAppInitFailure = createAction(ENV.FETCH_APP_INIT_FAILURE, (error) => ({ error }));
export const changeLocaleRequest = createAction(ENV.LOCALE_CHANGED, (locale): any => ({ locale }));

export const changeLocale = (locale) => async (dispatch, getState) => {
  const token = getState().env.userToken;

  dispatch(changeLocaleRequest(locale));
  api.putUserUpdateLocale(token, locale);
};

export const appInit = () => async (dispatch, getState) => {
    const { location } = getState().router;
    const { stats_sharing_request_id: requestId } = location.query;
    const token = getTokenFromCookies();

    await dispatch(setClientToken(token));
    try {
        const response = await api.fetchAppInit();
        dispatch(fetchAppInitSuccess(response?.data));

        const {
            is_masquerade: isMasquerade,
            email,
            onboarding_current_step: onboardingCurrentStep,
            is_legal_terms_signed: isLegalTermsSigned,
        } = response.data;

        // Set userId of users only for GA for the User Focused view
        if (!isMasquerade) callGAEvent('set', 'userId', email);

        if (requestId) {
            if (!onboardingCurrentStep && location.pathname !== routes.settings) {
                window.location.href = `${conf.urls.app}${routes.settings}${location.search}`;
                return true;
            }
            const infosResponse = await api.getRequestStatsSharingInfos(requestId);
            const brandName = _get(infosResponse, 'data.requested_by', '');
            const requestsCount = _get(infosResponse, 'data.requests_count', 0);
            const isInstagramWorking = _get(infosResponse, 'data.is_working', false);

            dispatch(
                saveStatsSharing({
                    brandName,
                    requestsCount,
                    isInstagramWorking,
                }),
            );

            dispatch(
                updateOnboardingState({
                    isRequestedToShareStats: true,
                }),
            );

            if (!onboardingCurrentStep) {
                await dispatch(fetchStatsSharing());
                dispatch(openOnboardingStatsSharing(requestsCount === 0 ? 3 : null));
            }
        }

        if (!isLegalTermsSigned) {
            dispatch(showModale({ body: 'BlockingUpdatedCGU' }));
        }
    } catch (error) {
        console.log('error appInit', error);
        dispatch(fetchAppInitFailure(error));
        return false;
    }

    return true;
};
