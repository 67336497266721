/* eslint-disable no-alert */
import { maxFileSizeUpload } from 'config/files';
import { readUploadedFileAsBase64 } from 'utils/base64';

export const transformDroppedFiles = async (oldDroppedFiles, droppedFiles, lexique) => {
  const oldDroppedSize = oldDroppedFiles.reduce( (acc, curr) => acc + curr.size, 0);
  const totalSize = droppedFiles.reduce( (acc, curr) => acc + curr.size, oldDroppedSize);

  if (totalSize > maxFileSizeUpload) {
    window.alert(lexique.totalSize);
    return null;
  }

  const promisesFiles = droppedFiles.map(async f => {
    if (f.size > maxFileSizeUpload) {
      window.alert(lexique.size.replace('{{fileName}}', f.name ));
      return null
    }

    try {
      const base64 = await readUploadedFileAsBase64(f)
      return {
        name: f.name,
        type: f.type,
        size: f.size,
        base64,
      }
    } catch (e) {
      window.alert(lexique.global);
    }
  })

  const files = await Promise.all(promisesFiles);
  const filteredFiles = files.filter(f => f !== null);

  return filteredFiles;
}
