// @TODO remove any and set real types :)

import { createAction } from 'redux-actions';

import * as types from 'constants/socialStatsCard';
import { SNAMappedData, SNAAPIData } from 'types/snas';
import { mapSna } from '../mappers/snas';

export const openModal = createAction(types.MODAL_OPEN, (type): any => ({
    type,
}));
export const closeModal = createAction(types.MODAL_CLOSE, (type): any => ({
    type,
}));
export const onConnect = createAction(types.MODAL_ON_CONNECT, (data): any => data);

export const infoSnaError = createAction(types.MODAL_INFO_SNA_ERROR, (error) => ({ error }));
export const clearSnaError = createAction(types.MODAL_CLEAR_SNA_ERROR, ({ type }): any => ({
    type,
}));
export const resetInputSna = createAction(types.MODAL_RESET_INPUT, (type): any => ({ type }));

export const onSend = createAction(types.MODAL_ON_SEND, (type, url): any => ({
    type,
    url,
}));
export const onEdit = createAction(types.MODAL_ON_EDIT, (data): any => data);

export const fetchInfoSnaSuccess = createAction(types.MODAL_FETCH_INFO_SNA_SUCCESS, (data): any => data);
export const fetchInfoSnaFailure = createAction(types.MODAL_FETCH_INFO_SNA_FAILURE, (error) => ({ error }));

export const postSnaSuccess = createAction(types.MODAL_POST_SNA_SUCCESS, (data): any => data);
export const postSnaFailure = createAction(types.MODAL_POST_SNA_FAILURE, (error): any => ({ error }));

export const fetchSnaSuccess = createAction<SNAMappedData, SNAAPIData>(
    types.FETCH_STATS_SNA_SUCCESS,
    (data: SNAAPIData): SNAMappedData => mapSna.fromApi(data),
);
export const fetchSnaFailure = createAction(types.FETCH_STATS_SNA_FAILURE, (error): any => ({ error }));
