import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(0,48,87,0.5)',
            },
        },
    },
});
