import React, { useMemo, useCallback } from 'react';
import cn from 'classnames';

import FormField, { TYPES } from 'components/atoms/FormField/FormField';

import styles from './Form.module.scss';

type ObjectFieldType = {
    field: string;
    type?: TYPES;
    className?: string;
    options?: { [key: string]: string | moment.Moment | Date };
    optional?: boolean;
};

export type FieldType = string | ObjectFieldType;

interface Props {
    fields: (FieldType | FieldType[])[];
    placeholders: { [x: string]: string };
    locale: string;
    className?: string;
    fieldsClassName?: string;
    onChange?: (name: string, value: any) => void;
    values?: { [x: string]: any };
    labels?: { [x: string]: string };
    errors?: { [x: string]: string };
    disabled?: boolean;
    onBlur?: (name: string) => void;
}

interface ChangeEventArg {
    type: TYPES;
    field: string;
    value?: string;
    values?: string[];
    gmapValue?: {
        placeSearched: string;
        mapped: {
            g_formatted_address: string;
        };
    };
}

const Form: React.FC<Props> = ({
    fields,
    placeholders,
    locale,
    className,
    fieldsClassName,
    values,
    labels,
    errors,
    disabled,
    onChange,
    onBlur,
}) => {
    const handleChange = useCallback(
        ({ type, field, value, values: fieldValues, gmapValue }: ChangeEventArg) => {
            if (!onChange) return;
            switch (type) {
                case TYPES.CITY:
                    onChange(field, gmapValue);
                    break;
                case TYPES.ACTIVITIES:
                case TYPES.THEMES:
                case TYPES.NATIONALITY:
                    onChange(field, fieldValues);
                    break;
                default:
                    onChange(field, value);
                    break;
            }
        },
        [onChange],
    );

    const handleBlur = useCallback(
        (fieldName) => () => {
            if (onBlur) onBlur(fieldName);
        },
        [onBlur],
    );

    const renderField = useCallback(
        (field: FieldType) => {
            const isString = typeof field === 'string' || field instanceof String;
            const fieldName: string = isString ? (field as string) : (field as { field: string }).field;
            const fieldObject = !isString && (field as ObjectFieldType);

            return (
                <div key={fieldName}>
                    {labels[fieldName] && <div className={styles.labels}>{labels[fieldName]}</div>}
                    <FormField
                        key={fieldName}
                        field={fieldName}
                        type={(!isString && fieldObject.type) || TYPES.TEXT}
                        className={cn(fieldsClassName, fieldObject.className)}
                        placeholder={placeholders[fieldName]}
                        options={fieldObject && fieldObject.options}
                        onChange={handleChange}
                        value={(values && values[fieldName]) || ''}
                        locale={locale}
                        fullWidth
                        size="big"
                        disabled={disabled}
                        error={errors[fieldName]}
                        onBlur={handleBlur(fieldName)}
                    />
                </div>
            );
        },

        [fieldsClassName, placeholders, labels, locale, values, errors, disabled, handleChange, handleBlur],
    );

    const renderForm = useMemo(
        () =>
            React.Children.toArray(
                fields.map((field) => (
                    <div className={styles.row}>
                        {Array.isArray(field) ? field.map(renderField) : renderField(field)}
                    </div>
                )),
            ),
        [fields, renderField],
    );
    return <div className={className}>{renderForm}</div>;
};

Form.defaultProps = {
    className: undefined,
    fieldsClassName: undefined,
    values: {},
    labels: {},
    errors: {},
    disabled: false,
};

export default Form;
