import CampaignBannerLexique from 'locales/types/components/organisms/CampaignBanner';

export const campaignBannerLexique: CampaignBannerLexique = {
    title:
        "Rejoignez le mouvement <a target='_blank' href='https://www.kolsquare.com/fr/blog/offrons-notre-influence'>#OffronsNotreInfluence</a> !",
    text: 'Offrez votre influence aux marques et organismes en difficulté.',
    text2: 'En cochant “oui”, vous serez mis(e) en avant sur Kolsquare en tant que volontaire.',
    switch: {
        yes: 'OUI',
        no: 'NON',
    },
};

export default campaignBannerLexique;
