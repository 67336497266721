import { FAQPageLexique } from 'locales/types/containers/faqPage';

export const partenariatsPageLexique: FAQPageLexique = {
    headTitle: 'FAQ Instagram - Kolsquare ',
    title: 'Partager mes statistiques Instagram sur Kolsquare',
    description:
        'Kolsquare vous permet de partager vos statistiques Instagram en temps réel et automatiquement avec ses marques clientes.\nPlus besoin de faire des captures d’écran ou de mettre à jour son média-kit pour avoir et partager ses derniers chiffres !',
    faqs: [
        {
            question: 'Qui peut consulter mes statistiques ?',
            answer:
                "Si vous autorisez Kolsquare à transmettre vos statistiques en temps réel, seules les marques clientes de Kolsquare et l'équipe Kolsquare pourront y accéder. Cet accès est uniquement possible depuis la plateforme Kolsquare réservée aux marques et via l’extension Chrome Kolsquare.",
        },
        {
            question:
                'Si j’autorise le partage, quelles statistiques de mon compte Instagram seront partagées aux marques clientes de Kolsquare ?',
            list: [
                'Le nombre de likes, commentaires, impressions, portée (Reach), vues des vidéos, saves et replies de vos posts et stories Instagram;',
                'Les données sociodémographiques de votre audience sur Instagram (âge, genre et répartition géographique).',
            ],
        },
        {
            question: 'À quelles occasions les marques clientes de Kolsquare peuvent consulter ces statistiques ?',
            answer:
                'Vos statistiques ne sont accessibles que dans la version payante réservée aux marques clientes de Kolsquare et via l’extension Chrome Kolsquare.',
            list: [
                'Les statistiques de votre audience seront accessibles à chaque fois que votre profil sera consulté par une marque cliente sur Kolsquare, ou dans le cadre de recherches d’influenceurs utilisant des filtres sur l’audience.',
                'Les statistiques de vos contenus seront uniquement partagées dans les rapports de campagnes auxquelles vous aurez été ajouté(e) par les marques clientes sur Kolsquare. Ainsi, nous ne communiquons pas l’ensemble des statistiques de vos contenus à toutes nos marques clientes.',
            ],
        },
        {
            question: 'Pour combien de temps le partage de mes statistiques est-il effectif sur Kolsquare ?',
            answer:
                'Lorsque vous autorisez Kolsquare à partager vos statistiques avec ses clients, vous l’autorisez pour une période de 6 mois à compter de la date d’autorisation.',
        },
        {
            question: 'Puis-je révoquer ce partage ?',
            answer:
                'Vous pouvez à tout moment révoquer ce partage de statistiques Instagram via Kolsquare depuis la page “Mon compte”. Une fois révoqué, vous pourrez toujours revenir sur votre décision, et réactiver ce partage.',
        },
    ],
    preCta: 'Une question ?',
    cta: 'Contactez-nous',
    mailObject: 'Community : une question de {{1}} sur le partage de statistiques',
};

export default partenariatsPageLexique;
