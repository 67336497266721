import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classname from 'classnames';
import _isEmpty from 'lodash.isempty';

import { Activities, Themes } from '@brandandcelebrities/activities-themes';
import { Tag, TagsContainer } from '@brandandcelebrities/kolkitten';
import Pen from '@brandandcelebrities/icons/regular/Pen';

import { getLocale, Locale } from 'locales/index';

import './ProfileCard.scss';
import { AppState } from 'reducers';
import { useSelector } from 'utils/redux';
import { UserState } from 'reducers/user';

const activities = new Activities();
const themes = new Themes();

interface Selector {
    user: UserState;
    locale: Locale;
}

const ProfileCard: FC = () => {
    const { user, locale } = useSelector<Selector>((state: AppState) => ({
        user: state.user,
        locale: getLocale(state.env.locale),
    }));

    const profilePictureStyle = _isEmpty(user.pictureUrl) ? {} : { backgroundImage: `url('${user.pictureUrl}')` };

    return (
        <div className="bc-profile-card">
            <div className="bc-profile-card__mobile-edit">
                <Link
                    onClick={() =>
                        window.ga('send', 'event', {
                            eventCategory: 'Dashboard',
                            eventAction: `Nagivate`,
                            eventLabel: `Edit_Profile`,
                        })
                    }
                    to="/profile"
                >
                    <Pen />
                </Link>
            </div>
            <div className="bc-profile-card__body">
                <div className="bc-profile-card__background-img" style={profilePictureStyle} />
                <div
                    className={classname({
                        'bc-profile-card__img-profile': true,
                        'bc-profile-card__img-profile--empty': _isEmpty(user.pictureUrl),
                    })}
                    style={profilePictureStyle}
                />
                <div className="bc-profile-card__infos">
                    <h3>{user.pseudo || `${user.firstName} ${user.lastName}`.trim()}</h3>
                    {user && user.activityLabels && user.activityLabels.length > 0 && (
                        <div className="bc-profile-card__infos-activities">
                            {user.activityLabels
                                .map((activity) => activities.locale(locale).gender(user.gender).one(activity))
                                .join(' / ')}
                        </div>
                    )}
                    {user && user.themeLabels && (
                        <div className="bc-profile-card__infos-tags">
                            <TagsContainer>
                                {user.themeLabels.map((theme) => (
                                    <Tag key={theme} label={themes.locale(locale).one(theme)} />
                                ))}
                            </TagsContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
