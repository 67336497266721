import React, { memo, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { StepWrapper, Loader } from '@brandandcelebrities/kolkit';

import { setOnboardingStep } from 'actions/profile';
import { updateOnboardingState } from 'actions/onboarding';
import { useDispatch, useSelector } from 'utils/redux';
import routes from 'config/routes';

import StepInfos from './steps/StepInfos';
import StepActivity from './steps/StepActivity';
import StepNetworks, { StepNetworksInstagramOnly } from './steps/StepNetworks';
import StepInstaConnect from './steps/StepInstaConnect';
import StepThanks from './steps/StepThanks';

import styles from './OnboardingPage.module.scss';

const OnboardingPage: React.FC = () => {
    const {
        currentStep: currentOnboardingStep,
        isOnInstaConnect,
        isInstaConnected,
        isRequestedToShareStats,
        isStatsShared,
    } = useSelector(({ onboarding }) => onboarding);

    const allUnreadMessages = useSelector( ({ messaging }) => messaging?.allUnreadMessages);
    const dispatch = useDispatch();

    const { renderContent, stepperStep } = useMemo(() => {
        const goToNextStep = (): void => {
            dispatch(updateOnboardingState({ currentStep: currentOnboardingStep + 1 }));
        };
        const handleOpenInstaConnect = (): void => {
            dispatch(updateOnboardingState({ isOnInstaConnect: true }));
        };
        const handleInstaConnectSuccess = (): void => {
            dispatch(
                updateOnboardingState({
                    isOnInstaConnect: false,
                    isInstaConnected: true,
                }),
            );
        };
        const handleInstaConnectFail = (): void => {
            dispatch(updateOnboardingState({ isOnInstaConnect: false }));
        };

        const handleOnboardingEnd = async (): Promise<void> => {
            dispatch(
                updateOnboardingState({
                    currentStep: null,
                    isOnInstaConnect: false,
                    isInstaConnected: false,
                    isStatsShared: false,
                }),
            );
            await dispatch(setOnboardingStep(null));
        };

        const handleStatsSharingSuccess = (): void => {
            dispatch(updateOnboardingState({ isStatsShared: true }));
        };

        const handleValidationOnStepNetworks = (): void => {
            if (!isRequestedToShareStats) handleOnboardingEnd();
            else handleStatsSharingSuccess();
        };

        switch (currentOnboardingStep) {
            case 1:
                return {
                    stepperStep: 1,
                    renderContent: <StepInfos onClickNext={goToNextStep} />,
                };

            case 2:
                return {
                    stepperStep: 2,
                    renderContent: <StepActivity onClickNext={goToNextStep} />,
                };
            case 3:
                if (isOnInstaConnect) {
                    return {
                        stepperStep: 4,
                        renderContent: (
                            <StepInstaConnect
                                onInstaConnectSuccess={handleInstaConnectSuccess}
                                onInstaConnectFail={handleInstaConnectFail}
                                hideBackButton={isRequestedToShareStats}
                            />
                        ),
                    };
                }
                if (isStatsShared) {
                    return {
                        stepperStep: 6,
                        renderContent: <StepThanks from="Onboarding" onButtonClick={handleOnboardingEnd} />,
                    };
                }
                return {
                    stepperStep: isInstaConnected ? 5 : 3,
                    renderContent: isRequestedToShareStats ? (
                        <StepNetworksInstagramOnly
                            onOpenInstaconnect={handleOpenInstaConnect}
                            onClickNext={handleStatsSharingSuccess}
                            onStatsSharingSkip={handleOnboardingEnd}
                        />
                    ) : (
                        <StepNetworks
                            onOpenInstaconnect={handleOpenInstaConnect}
                            onClickNext={handleValidationOnStepNetworks}
                            onSkip={handleOnboardingEnd}
                        />
                    ),
                };
            case -1:
                return { stepperStep: 0, renderContent: <Loader full /> };
            default:
                return { stepperStep: 0, renderContent: <Redirect to={allUnreadMessages > 0 ? routes.conversations : '/'} /> };
        }
    }, [
        currentOnboardingStep,
        isOnInstaConnect,
        isInstaConnected,
        isRequestedToShareStats,
        isStatsShared,
        allUnreadMessages,
        dispatch,
    ]);

    return (
        <div className={styles.background}>
            <div className={styles.logo}>
                <span className={styles.imgPlaceholder} />
            </div>
            <StepWrapper className={styles.stepper} totalStep={6} step={stepperStep} width="100%">
                <div className={styles.contentWrapper}>{renderContent}</div>
            </StepWrapper>
        </div>
    );
};

export default memo(OnboardingPage);
