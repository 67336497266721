export default {
    headTitle: 'My Statistics - Kolsquare',
    title: 'Audience',
    placeholder: 'You must add at least one social account to access your stats.',
    charts: {
        title: `Performance`,
        chartCard: {
            filter: {
                oneMonth: `1 month`,
                threeMonths: `3 months`,
                sixMonths: `6 months`,
                oneYear: `1 year`,
                all: 'All',
            },
            chart: {
                legends: {
                    community: 'Community :',
                    engagements: 'Average engagement :',
                    publications: 'Publications :',
                },
            },
            legends: {
                community: 'Community',
                engagements: 'Average engagement (1 month)',
                publications: 'Publications',
            },
            noData: 'There is no data available for the selected period.',
        },
    },
};
