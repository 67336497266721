import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';

import styles from './SectionsLayout.module.scss';

interface Props {
    title: string;
    picto: string;
    children?: ReactNode;
    sideContent?: ReactNode;
    className?: string;
}

const SectionsLayout: FC<Props> = ({ title, picto, children, sideContent, className }) => {
    const cnLayout = cn(styles.layout, className);

    return (
        <div className={cnLayout}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {sideContent}
            <div className={styles.container}>
                <span className={styles.logo}>
                    <img src={picto} alt="" />
                </span>
                {children}
            </div>
        </div>
    );
};

export default SectionsLayout;
