import FacebookBusinessConnect from 'utils/fbc/FacebookBusinessConnect';
import FacebookBusinessConnectMock from 'utils/tests/mocks/fbc';

import { env, ENV_MOCK, ENV_PROD } from './env';

const getFbc = (): FacebookBusinessConnect => {
    if (env === ENV_MOCK) {
        return new FacebookBusinessConnectMock(true);
    }

    return new FacebookBusinessConnect({
        debug: env !== ENV_PROD,
    });
};

export const fbc: FacebookBusinessConnect = getFbc();
