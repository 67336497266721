import React, { FC } from 'react';
import Card, { CardColors } from 'components/atoms/Card';
import classnames from 'classnames';
import Arrow, { Direction } from 'components/atoms/Arrow';
import './metricCard.scss';

interface Props {
    rate: string;
    evolution: string;
    label: string;
}
// eslint-disable-next-line @typescript-eslint/no-var-requires
const css = require('../../../css/scss/_vars.scss');

enum SIGNS {
    POSITIVE = 'arrow-alt-to-top',
    EQUAL = 'equal',
    NEGATIVE = 'arrow-alt-to-bottom',
}

const MetricCard: FC<Props> = ({ rate, evolution, label }) => {
    let sign: string;
    let color: CardColors;
    let arrow: JSX.Element | string;
    const progression: number = parseFloat(evolution);
    switch (true) {
        case progression > 0:
            sign = SIGNS.POSITIVE;
            color = CardColors.green;
            arrow = <Arrow direction={Direction.toTop} color={css.green4} />;
            break;
        case progression < 0:
            sign = SIGNS.NEGATIVE;
            color = CardColors.red;
            arrow = <Arrow direction={Direction.toBottom} color={css.red} />;
            break;
        case progression === 0:
            sign = SIGNS.EQUAL;
            color = CardColors.blue;
            arrow = <span className="bc-metrics-card-evolution-equalsign">{'= '}</span>;
            break;
        case !evolution:
        default:
            sign = SIGNS.EQUAL;
            color = CardColors.blue;
            arrow = ' ';
            break;
    }
    const rateMetricClassNames = classnames({
        'bc-metrics-card-rate': true,
        'bc-metrics-card-rate-positive': sign === SIGNS.POSITIVE,
        'bc-metrics-card-rate-equal': sign === SIGNS.EQUAL,
        'bc-metrics-card-rate-negative': sign === SIGNS.NEGATIVE,
    });
    const evolutionMetricClassNames = classnames({
        'bc-metrics-card-evolution': true,
        'bc-metrics-card-evolution-positive': sign === SIGNS.POSITIVE,
        'bc-metrics-card-evolution-equal': sign === SIGNS.EQUAL,
        'bc-metrics-card-evolution-negative': sign === SIGNS.NEGATIVE,
    });
    return (
        <Card color={color} label={label}>
            <div className="bc-metrics-card">
                <p className={rateMetricClassNames}>{rate}</p>
                <p className={evolutionMetricClassNames}>
                    {arrow}
                    {evolution}
                </p>
            </div>
        </Card>
    );
};

export default MetricCard;
