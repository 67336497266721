import React, { FC, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { withRouter, Link, NavLink } from 'react-router-dom';

import Badge from '@material-ui/core/Badge';
import Comment from '@brandandcelebrities/icons/solid/Comment';

import { mailToRecipe } from 'config/env';
import routes from 'config/routes';

import { useLexique, useSelector, useDispatch } from 'utils/redux';
import { useSticky } from 'utils/hooks';
import { CHANNELS, useActionCable } from 'utils/hooks/useActionCable';

import { UserState } from 'reducers/user';
import { AppState } from 'reducers';

import { show } from 'actions/menu';
import { updateUnreadMessage } from 'actions/messaging';

import { HeaderLexique } from 'locales/types/containers/shared/header';

import { SubMenu, LanguagesDropdown } from 'components/organisms';

import logo from 'assets/images/logo_kolsquare.png';
import './navbar.scss';
import './Header.scss';

const Header: FC = () => {
    const lexique = useLexique<HeaderLexique>('containers.shared.header.header');
    const isSticky = useSticky();
    const dispatch = useDispatch();

    const {
      isEmailConfirmed,
      isTrialOver,
      pseudo,
      firstName,
      lastName,
      initialWsData: { notifications },
    } = useSelector<UserState>((state: AppState) => state.user);

    const { allUnreadMessages, logged } = useSelector( ({messaging, env}: AppState) => ({
        allUnreadMessages: messaging?.allUnreadMessages,
        logged: env.logged,
    }));

    const onCountReceived = useCallback(
        (data) => {
            dispatch(updateUnreadMessage(data));
        },
        [dispatch]
    );

    useEffect(() => {
      if (notifications) onCountReceived(notifications);
    }, [notifications, onCountReceived]);

    useActionCable({
        channel: CHANNELS.NOTIFICATION,
        onDataReceived: onCountReceived,
        // getInitialState: true,
    });

    const showNavbarMobile = useCallback(() => dispatch(show()), [dispatch]);

    const subjectMail = `${lexique.feedback.content.subject}${pseudo || `${firstName} ${lastName}`.trim()}`;

    const navBarCN = cn('navbar', 'bc-header', {
        'bc-header--sticky': isSticky,
    });

    const menuActiveState = useMemo(
        () => isEmailConfirmed || !isTrialOver,
        [isTrialOver, isEmailConfirmed]
    );

    const handleLinkClick = useCallback(
        (e) => {
            if (!menuActiveState) e.preventDefault();
        },
        [menuActiveState],
    );

    const conversationsNavLink = useMemo(
        () => {
            if (!menuActiveState) {
                return (
                    <span className="nav-link disabled">
                        {lexique.navLinks.discuss}
                    </span>
                );
            }

            const isActive = (_, location) => location.pathname.indexOf(routes.conversations) > -1;

            return (
                <NavLink
                    onClick={handleLinkClick}
                    to={routes.conversations}
                    className="nav-link"
                    activeClassName="active"
                    isActive={isActive}
                >
                    <Badge className="navbar-badge" badgeContent={allUnreadMessages}>
                        {lexique.navLinks.discuss}
                    </Badge>
                </NavLink>
            );
        },
        [allUnreadMessages, lexique.navLinks.discuss, handleLinkClick, menuActiveState]
    );

    const links = useMemo(() => {
        const { analytics } = routes;
        return [
            { href: `${analytics.index}${analytics.audience}`, label: lexique.navLinks.audience },
            { href: `${analytics.index}${analytics.content}`, label: lexique.navLinks.content },
            { href: `${analytics.index}${analytics.partner}`, label: lexique.navLinks.partner },
        ];
    }, [lexique]);

    const navigationLinks = useMemo(() => {
        if (!logged) return null;
        const linkCn = cn('nav-link', { disabled: !menuActiveState });
        return (
            <div className="collapse dropup navbar-collapse">
                <ul className="navbar-nav">
                    {links.map(({ href, label }) => (
                        <li className="nav-item" key={`header-link-${href}`}>
                            <NavLink onClick={handleLinkClick} to={href} className={linkCn} activeClassName="active">
                                {label}
                            </NavLink>
                        </li>
                    ))}
                    <li className="nav-item">{conversationsNavLink}</li>
                </ul>
            </div>
        );
    }, [conversationsNavLink, links, menuActiveState, handleLinkClick, logged]);

    return (
        <nav className={navBarCN}>
            <Link to="/" className="navbar-brand">
                <img src={logo} className="d-inline-block align-top" alt="" />
            </Link>
            {navigationLinks}
            <div className="bc-header__right-container">
                <a href={`mailto:${mailToRecipe}?subject=${subjectMail}`} className="bc-header__feedback">
                    <div className="bc-header__feedback-content">
                        <Comment />
                        {lexique.feedback.label}
                    </div>
                </a>
                <div className="bc-header__language-selector-container">
                    <LanguagesDropdown />
                </div>
                {logged && <SubMenu />}
            </div>

            <div className="navbar-mobile">
                <a
                    href={`mailto:${mailToRecipe}?subject=${subjectMail}`}
                    className="navbar-toggler bc-header__feedback"
                >
                    <div className="bc-header__feedback-content">
                        <Comment />
                    </div>
                </a>
                <Badge className="navbar-badge" badgeContent={allUnreadMessages}>
                    <button className="navbar-toggler" type="button" onClick={showNavbarMobile}>
                        <div className="navbar-toggler-line" />
                        <div className="navbar-toggler-line" />
                        <div className="navbar-toggler-line" />
                    </button>
                </Badge>
            </div>
        </nav>
    );
};

export default withRouter(Header);
