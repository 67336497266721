import React, { useCallback, useMemo } from 'react';
import { SelectTag } from '@brandandcelebrities/kolkit';
import { Themes } from '@brandandcelebrities/activities-themes';

const themes = new Themes();

interface Props {
    name: string;
    locale: string;
    value?: string[];
    placeholder?: string;
    onChange?: (onChangeEvent: { values: string[] }) => void;
    disabled?: boolean;
    maxTags?: number;
    className?: string;
}

const SelectThemes: React.FC<Props> = ({
    name,
    locale,
    value,
    placeholder,
    onChange,
    disabled,
    maxTags,
    className,
}) => {
    const handleChange = useCallback(
        ({ value: _value, selected }) => {
            onChange({ values: selected || _value });
        },
        [onChange],
    );

    const dataset = useMemo(() => themes.locale(locale).getAsSelectValues(), [locale]);

    return (
        <SelectTag
            id={name}
            onChange={handleChange}
            selected={value}
            label={placeholder}
            dataset={dataset}
            maxTags={maxTags}
            disabled={disabled}
            useNative
            className={className}
            size="big"
        />
    );
};

SelectThemes.defaultProps = {
    value: [],
    onChange: (): void => {},
    placeholder: '',
    disabled: false,
    maxTags: 5,
};

export default SelectThemes;
