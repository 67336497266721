import React, { memo, useCallback, useState } from 'react';
import { HeightTransition } from '@brandandcelebrities/kolkit';

import FormField, { TYPES } from 'components/atoms/FormField/FormField';

import styles from './FullNameAndStageName.module.scss';

type fieldNames = 'firstName' | 'lastName' | 'stageName';

interface ErrorState {
    firstName?: boolean;
    lastName?: boolean;
    stageName?: boolean;
}
interface Props {
    placeholders: { [x in fieldNames]: string };
    errorLexique: { stageName: string; firstNameOrlastName: string };
    values: { [x in fieldNames]: string };
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
}

const isEmptyField: (values: { [x in fieldNames]: string }, field: fieldNames) => boolean = (values, field) =>
    !values[field]?.trim().length;

const FullNameAndStageName: React.FC<Props> = ({ placeholders, errorLexique, values, onChange, disabled }) => {
    const [errors, setErrors] = useState<ErrorState>({});

    const handleChange = useCallback(
        ({ field, value }) => {
            onChange(field, value);
            setErrors((err) => err);
        },
        [onChange],
    );

    const handleBlur = useCallback(
        (fieldName) => (): void => {
            // If stage name is not empty, all good
            if (!isEmptyField(values, 'stageName')) {
                return setErrors({});
            }

            // rang 2
            // assuming stage name empty
            // if coming out of stage name and no first name nor last name, show error on stage name
            if (fieldName === 'stageName' && isEmptyField(values, 'firstName') && isEmptyField(values, 'lastName')) {
                return setErrors({ firstName: false, lastName: false, stageName: true });
            }

            // rang 1
            if (fieldName !== 'stageName') {
                // if the field we left is not empty
                if (!isEmptyField(values, fieldName))
                    return setErrors((prev) => ({ ...prev, firstName: false, lastName: false }));

                // If it's empty
                const theOtherField = fieldName === 'firstName' ? 'lastName' : 'firstName';
                return setErrors((prev) => ({
                    ...prev,
                    [fieldName]: !isEmptyField(values, theOtherField),
                    [theOtherField]: false,
                }));
            }
            return null;
        },
        [values],
    );

    return (
        <>
            <div className={styles.row}>
                <div className={styles.subRow}>
                    <FormField
                        key="firstName"
                        field="firstName"
                        type={TYPES.TEXT}
                        placeholder={placeholders.firstName}
                        onChange={handleChange}
                        value={values.firstName}
                        fullWidth
                        size="big"
                        disabled={disabled}
                        error={errors.firstName}
                        onBlur={handleBlur('firstName')}
                    />
                    <FormField
                        key="lastName"
                        field="lastName"
                        type={TYPES.TEXT}
                        placeholder={placeholders.lastName}
                        onChange={handleChange}
                        value={values.lastName}
                        fullWidth
                        size="big"
                        disabled={disabled}
                        error={errors.lastName}
                        onBlur={handleBlur('lastName')}
                    />
                </div>
                <HeightTransition on={errors.firstName || errors.lastName} className={styles.error}>
                    {errorLexique.firstNameOrlastName}
                </HeightTransition>
            </div>
            <div className={styles.row}>
                <FormField
                    key="stageName"
                    field="stageName"
                    type={TYPES.TEXT}
                    placeholder={placeholders.stageName}
                    onChange={handleChange}
                    value={values.stageName}
                    fullWidth
                    size="big"
                    disabled={disabled}
                    error={errors.stageName && errorLexique.stageName}
                    onBlur={handleBlur('stageName')}
                />
            </div>
        </>
    );
};

export default memo(FullNameAndStageName);
