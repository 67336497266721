import React, { useCallback, useMemo, useState, useRef } from 'react';
import cn from 'classnames';
import { Button, Input } from '@brandandcelebrities/kolkit';

import { SocialPicto, LoaderCircle } from 'components/atoms';
import { Networks } from 'constants/networks';
import { useLexique } from 'utils/redux';
import { constructFullUrl } from 'utils/snas';

import { NetworkState, ValidationError } from '../types.d';
import styles from './NetworkItem.module.scss';

interface Props {
    network: Networks;
    state?: NetworkState;
    error?: ValidationError;
    ctaTheme?: string;
    onSave?: (network: Networks, networkUrl?: string) => void;
    onOpen: (network: Networks) => void;
    onCancel?: (network: Networks) => void;
    className?: string;
}

const NetworkItem: React.FC<Props> = ({ network, state, error, ctaTheme, onOpen, onCancel, onSave, className }) => {
    const networksLexique = useLexique('networks');
    const lexique = useLexique(`containers.onboardingPage.steps.networks`);
    const [networkUrl, setNetworkUrl] = useState('');
    const btnDisabled = useMemo(() => state === 'validating' || (state === 'editing' && networkUrl === ''), [
        state,
        networkUrl,
    ]);
    const inputElement = useRef(null);
    const updateSnaIdentifier = useCallback(({ value }) => setNetworkUrl(value), [setNetworkUrl]);

    const handleClick = useCallback(() => {
        if (state === 'unset') {
            // should set state to "editing" except if instagram
            onOpen(network);
        } else if (state === 'editing' && networkUrl && onSave) {
            onSave(network, constructFullUrl(network, networkUrl));
        }
        inputElement?.current?.blur();
    }, [onSave, onOpen, network, state, networkUrl, inputElement]);

    const hasError = useMemo(() => !!error, [error]);

    const handleCancel = useCallback(() => {
        setNetworkUrl('');
        if (onCancel) onCancel(network);
    }, [onCancel, network]);

    const cnButton = cn({ [styles.btSuccess]: state === 'set' });
    const cnLi = cn(styles.row, className);

    return (
        <li className={cnLi}>
            <SocialPicto network={network} isForOnboarding />
            <div className={styles.body}>
                <p className={styles.network}>{networksLexique[network]}</p>
                {lexique[network]?.label && <p className={styles.label}>{lexique[network].label}</p>}
            </div>
            {state === 'editing' && (
                <Button theme="reset" className={styles.cancel} onClick={handleCancel}>
                    {lexique.cancel}
                </Button>
            )}
            <Button
                size="big"
                theme={state === 'editing' ? 'default' : ctaTheme}
                onClick={handleClick}
                className={cnButton}
                disabled={btnDisabled}
            >
                {lexique.status[state]}
            </Button>
            <div className={styles.break} />
            {state === 'validating' && (
                <div className={styles.loaderContainer}>
                    <LoaderCircle />
                </div>
            )}
            {state === 'editing' && (
                <div className={styles.snaIdentifierInputContainer}>
                    <div className={styles.snaIdentifierInputTitle}>{lexique[network]?.inputTitle}</div>
                    <Input
                        ref={inputElement}
                        onChange={updateSnaIdentifier}
                        value={networkUrl}
                        fullWidth
                        forceError={hasError}
                        errorMessage={lexique[network]?.errors?.[error]}
                        error={hasError}
                        placeholder={lexique[network]?.inputPlaceholder}
                    />
                </div>
            )}
        </li>
    );
};

NetworkItem.defaultProps = {
    state: 'unset',
    ctaTheme: 'navy',
};

export default NetworkItem;
