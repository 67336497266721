import React, {FC, memo} from 'react';

import { Text } from '@brandandcelebrities/kolkit';

import { Input } from 'containers/messagingPage/Components';

import styles from './PanelHeader.module.scss';

interface Props {
  disabled: boolean;
  searchQuery: string;
  onChange?: (...args: any[]) => void;
  title: string;
  placeholder: string;
  rest?: any;
}

const PanelHeader: FC<Props> = ({ disabled, searchQuery, onChange, title, placeholder, ...rest }) => {

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Text tag="h4" fontTitle resetMargin>{title}</Text>
      </div>
      <Input
        {...rest}
        placeholder={placeholder}
        onChange={onChange}
        value={searchQuery}
        className={styles.input}
        disabled={disabled}
      />
    </div>
  )
};

PanelHeader.displayName = 'PanelHeader';

export default memo(PanelHeader);
