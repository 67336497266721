import React, { FC, useMemo, useEffect } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import flatten from 'lodash.flatten';

import { Button2 } from '@brandandcelebrities/kolkit';

import { fetchStatsSharing } from 'actions/user';
import { useSelector, useDispatch, useLexique } from 'utils/redux';
import { SocialStatsHomePageLexique } from 'locales/types/containers/shared/SocialStatsHomePageLexique';
import SocialStatsContainer from 'components/templates/SocialStats/SocialStatsContainer';
import SocialStatsUnsupported from 'components/templates/SocialStats/SocialStatsUnsupported';
import { Networks, supportedSocialNetwork } from 'constants/networks';
import { SNAObject } from 'types/snas';
import routes from 'config/routes';

import styles from './SocialStatsHomePage.module.scss';

const SocialStatsHomePage: FC<RouteComponentProps> = () => {
    const lexique: SocialStatsHomePageLexique = useLexique<SocialStatsHomePageLexique>(
        'containers.shared.socialStatsHomePage',
    );

    const dispatch = useDispatch();
    const { snas, statsSharing, isLegalTermsSigned } = useSelector(
      ({ snas, user: { statsSharing, isLegalTermsSigned } }) => ({
        snas,
        statsSharing,
        isLegalTermsSigned,
      }),
    );

    const snaCardsFilled = useMemo(() => {
      return flatten(
        supportedSocialNetwork.map(({ id }) => {
          return snas[id]
            .filter((sna) => sna.filled)
            .map((sna: SNAObject) => (
              <SocialStatsContainer
                key={`${id}${sna.uid}`}
                type={id}
                uid={sna.uid}
                showTopPost
                hasInstagramExpired={sna.label === Networks.instagram && !statsSharing.isWorking && statsSharing.loaded}
                hasSharedStatistics={isLegalTermsSigned}
              />
            ));
        }),
      );
    }, [snas, statsSharing, isLegalTermsSigned]);

    useEffect(() => {
      dispatch(fetchStatsSharing());
    }, [dispatch]);

    if (snaCardsFilled.length === 0) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h3 className={styles.title}>{lexique.title}</h3>
                </div>
                <div className={styles.container}>
                    <div className={styles.empty}>
                        <p className={styles.emptyPlaceholder}>{lexique.snaEmpty}</p>
                        <SocialStatsContainer type={Networks.instagram} className={styles.cardEmpty} />
                    </div>
                    <SocialStatsUnsupported />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div>
                    <h3 className={styles.title}>{lexique.title}</h3>
                    <p className={styles.subTitle}>{lexique.legend}</p>
                </div>
                <Link to={`${routes.analytics.index}${routes.analytics.content}#top-posts`} className={styles.seeLink}>
                    <Button2
                        icon="long-arrow-up"
                        className={styles.seeButton}
                        size="small"
                    >
                        {lexique.seePerformance}
                    </Button2>
                </Link>
            </div>
            <div className={styles.container}>
                {snaCardsFilled}
                <SocialStatsUnsupported />
            </div>
        </div>
    );
};

export default withRouter(SocialStatsHomePage);
