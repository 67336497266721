import { conf } from 'config/env';
import routes from 'config/routes';
import api from 'api';

export const eject = () => async (_, getState) => {
    const { isMascarade, profileId } = getState().user;
    window.location.href = isMascarade ? `${conf.urls.admin}/catalog/profiles/${profileId}` : `${conf.urls.app}${routes.login}` ;
};

export const postLogout = () => async (dispatch) => {
    await api.deleteUserLogout();
    dispatch(eject());
};
