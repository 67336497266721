import React from 'react';
import cn from 'classnames';
import { Button, Icon } from '@brandandcelebrities/kolkit';

import styles from './QuestionCard.module.scss';

interface Props {
    question: string;
    positiveButtonLabel: string;
    negativeButtonLabel: string;
    onPositiveButtonClick: () => void;
    onNegativeButtonClick: () => void;
    active: boolean;
    finished: boolean;
}

const QuestionCard: React.FC<Props> = ({
    question,
    positiveButtonLabel,
    negativeButtonLabel,
    onPositiveButtonClick,
    onNegativeButtonClick,
    active,
    finished,
}) => {
    const cnCard = cn(styles.card, {
        [styles.active]: active,
        [styles.inactive]: !active && !finished,
        [styles.finished]: finished,
    });
    return (
        <div className={cnCard}>
            <span className={styles.question}>{question}</span>
            {active && (
                <div className={styles.buttons}>
                    <Button size="big" onClick={onPositiveButtonClick}>
                        {positiveButtonLabel}
                    </Button>
                    <Button theme="navy" size="big" onClick={onNegativeButtonClick}>
                        {negativeButtonLabel}
                    </Button>
                </div>
            )}
            {finished && <Icon label="check" theme="solid" className={styles.check} />}
        </div>
    );
};

export default QuestionCard;
