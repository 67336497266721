export default {
    0: {
        title: (name) => `Continue like this ${name}!`,
        text: `Oups! It looks like your profile is followed by some fake accounts. Too many of them might give brands the impression that you bought parts of your audience when it’s not necessarily the case.`,
    },
    30: {
        title: (name) => `Continue like this ${name}!`,
        text: `It seems like there are still some fake accounts in your followers. It has an impact on your credibility score, and can be spreading a wrong image to brands.`,
    },
    60: {
        title: (name) => `Congratulations ${name}!`,
        text: `Your audience is in great shape! There might still be a few fake accounts sticking in your followers’ base. Don't worry, it's totally normal and you can always fix it to perfection if you like.`,
    },
    70: {
        title: (name) => `Congratulations ${name}!`,
        text: `Your community is a real inspiring one! This score tells us that the majority of your followers are real ones, and that’s amazing.`,
    },
    90: {
        title: (name) => `Congratulations ${name}!`,
        text: `You are to the influence business what Micheal Jordan was to Basketball! You have built a high-standard community.`,
    },
    button: `How to improve my score?`,
    score: 'Audience quality score',
};
