import _isEmpty from 'lodash.isempty';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';

import {
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER,
  SET_COMPLETION_PERCENT,
  TOGGLE_ENLIST_USER,
  SAVE_STATS_SHARING,
  SET_NO_MORE_INSTACONNECT,
  SET_LEGAL_TERM_SIGNATURE,
} from 'constants/user';
import { showModale } from 'actions/modale';
import { appInit } from 'actions/env';
import api from 'api';
import getLexique from 'locales';
import { callGAEvent } from 'utils/googleAnalytics';
import { eject } from './header';
import * as mapper from '../mappers/user';

export const startDeleteUser = createAction(DELETE_USER);
export const deleteUserSuccess = createAction(DELETE_USER_SUCCESS);
export const deleteUserFail = createAction(DELETE_USER_FAIL);
const setNoMoreInstaconnect = createAction(SET_NO_MORE_INSTACONNECT);
const setCompletionPercent = createAction(SET_COMPLETION_PERCENT);
const saveLegalTermSignature = createAction(SET_LEGAL_TERM_SIGNATURE);

const translateStatusCode = ({data, status}) => {
  if (status === 401) return 'invalidCredentials';
  if (status === 422 && data?.error === 'email_taken') return 'alreadyUsedEmail';
  if (!data) return 'unknown';

  switch (data.error_code) {
    case 601: return 'expiredToken';
    case 602: return 'unconfirmed';
    case 603: return 'invalid';
    case 604: return 'isAlreadyConfirmed';
    case 605: return 'emailNotFound';
    case 607: return 'wrongPassword';
    case 608: return 'notActive';
    case 609: return 'alreadyUsed';
    case 610: return 'nonActivated';
    case 701: return 'connectionError';
    case 801: return 'tokenNotFound';
    case 803: return 'alreadyUsed';
    default: return 'unknown';
  }
}

export const submitRegister = ({ email, password, token }) => async (dispatch, getState) => {
  const { locale } = getState().env
  console.log(email, password, token)
  if (!email || !password) return '';

  try {
    const dataToApi = mapper.register.toApi({
      email,
      password,
      token,
      locale: locale === 'fr' ? 'fr-FR' : 'en-GB',
    });

    const res = await api.register(dataToApi);
    if (res?.status === 201 || res?.status === 200) {
      callGAEvent(
        'Onboarding',
        'step_1_register',
        `monthly_report_true`
      );
      // BE doesn't set cookie => Auto login
      await dispatch(submitLogin({email, password}));
    }

    return '';
  } catch(error) {
    return translateStatusCode(error?.response);
  }
}

export const submitLogin = ({email, password}) => async dispatch => {
  if (!email || !password) return false;

  try {
    const dataToApi = mapper.login.toApi({email, password});
    const res = await api.login(dataToApi);
    const { hasCommunityAccess } = mapper.login.fromApi(res?.data);
    if (hasCommunityAccess) {
      dispatch(appInit());
      return false;
    } else {
      return 'invalidCredentials';
    }
  } catch(error) {
    return translateStatusCode(error?.response);
  }
}

export const submitNewPassword = ({password, confirmPassword, token}) => async () => {
  try {
    const dataToApi = mapper.submitNewPassword.toApi({password, confirmPassword, token});
    await api.sendNewPassword(dataToApi);
    return false;
  } catch(error) {
    return translateStatusCode(error?.response);
  }
}

export const verifyToken = ({token, type}) => async () => {
  if (!token || !type) return false;

  try {
    const tokenToapi = mapper.verifyToken.toApi({token, type})
    await api.verifyToken(tokenToapi);
    return false;
  } catch(error) {
    return translateStatusCode(error?.response);
  }
}

export const confirmRegister = (token) => async () => {
  if (!token) return false;

  try {
    const tokenToapi = mapper.confirmRegister.toApi(token);
    await api.sendConfirmationRegister(tokenToapi);
    return false;
  } catch(error) {
    return translateStatusCode(error?.response);
  }
}

export const resetForgottenPassword = (email) => async () => {
  if (!email) return false;
  try {
    await api.resetForgottenPassword(email?.trim());
    return false;
  } catch(error) {
    return translateStatusCode(error?.response);
  }
}

export const deleteUser = () => async (dispatch, getState) => {
  try {
    dispatch(startDeleteUser());
    await api.deleteUser();
    dispatch(eject());
    dispatch(deleteUserSuccess());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const { env } = getState();
    const lexique = getLexique(env.locale).components.modales.deleteAccount;
    dispatch(deleteUserFail(lexique.error));
  }
};

export const resendConfirmationEmail = () => async (dispatch, getState) => {
  const {
    env,
    user: { email },
  } = getState();
  await api.resendConfirmationEmail(email);
  const lexique = getLexique(env.locale).components.organisms.unconfirmedEmailBanner;
  toast(`${lexique.emailResent} ${email}`);
  callGAEvent('Onboarding', 'resent_email_validation');
};

export const updateCompletionPercent = (profile, hasInstagramAccount, hasOtherNetworks) => async (dispatch) => {
  const {
    firstName,
    lastName,
    gender,
    birthdate,
    phone,
    nationality,
    influenceThemes,
    website,
    bio,
    livingAddress,
    description,
  } = profile;

  let personalInfo = 0;
  if (firstName.length > 0 && lastName.length > 0) personalInfo += 10;
  if (gender && gender !== '') personalInfo += 2;
  if (birthdate && birthdate !== '' && birthdate.split('-').length === 3) personalInfo += 3; // year, month and day are completed
  if (phone && phone !== '') personalInfo += 5;
  if (nationality && nationality.length > 0) personalInfo += 5;
  if (!_isEmpty(livingAddress.placeSearched) && !_isEmpty(livingAddress.mapped)) personalInfo += 5;

  let moreInfo = 0;
  if (influenceThemes && influenceThemes.length > 0) moreInfo += 10;
  if (website && website !== '') moreInfo += 10;
  if (bio && bio !== '') moreInfo += 10;
  if (description && description !== '') moreInfo += 10;

  const instagramAccount = hasInstagramAccount ? 15 : 0;
  const otherNetworks = hasOtherNetworks ? 15 : 0;

  dispatch(
    setCompletionPercent({
      completed: {
        personalInfo: personalInfo === 30,
        moreInfo: moreInfo === 40,
        instagramAccount: instagramAccount === 15,
        otherNetworks: otherNetworks === 15,
      },
      total: personalInfo + moreInfo + instagramAccount + otherNetworks,
    }),
  );
};

export const toggleUserMobilisationEnlistingState = createAction(TOGGLE_ENLIST_USER);

export const toggleUserMobilisationEnlisting = (enlisted: boolean) => async (dispatch) => {
  await api.toggleUserMobilisationEnlisting(enlisted);
  dispatch(toggleUserMobilisationEnlistingState(enlisted));
};

// Instagram stats sharing
export const saveStatsSharing = createAction(SAVE_STATS_SHARING);

export const fetchStatsSharing = () => async (dispatch): Promise<void> => {
  dispatch(saveStatsSharing({ loaded: false }));
  const response = await api.fetchStatsSharing();

  if (response) {
    dispatch(saveStatsSharing({
      ...mapper.fetchStatsSharing.fromApi(response.data),
      loaded: true,
    }));
  }
};

export const postLegalTermSignature = () => async (dispatch): Promise<void> => {
  const response = await api.postLegalTermSignature();

  if (response?.data) {
    dispatch(saveLegalTermSignature(response.data.is_legal_terms_signed));
  }
};

export const openOnboardingStatsSharing =
  (stepToOpen = null) =>
  async (dispatch, getState): Promise<void> => {
    const {
      user: {
        statsSharing: { isWorking },
      },
    } = getState();
    callGAEvent('Stats_Share', !isWorking ? 'Activate' : 'Reactivate', 'Account');
    dispatch(
      showModale({
        body: 'RequestStats',
        data: {
          step: stepToOpen || (isWorking ? 3 : 1),
        },
      }),
    );
  };

export const setCheckNoMoreInsta = (checked: boolean) => async (dispatch) => {
  dispatch(setNoMoreInstaconnect(checked));
  return api.putIgnoredInstaconnect(checked);
};
