import React, { memo, useState, useCallback } from 'react';
import { Tooltip, Position } from 'react-tippy';

interface Props {
  html: JSX.Element;
  position?: Position;
  distance?: number;
}

const TooltipComponent: React.FC<Props> = memo(({ html, children, position, distance }) => {
  const [over, setHover] = useState(false);

  const onMouseEnter = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const renderContent = () => {
    if (!over) return children;

    return (
      <Tooltip html={html} position={position || 'left'} distance={distance || 10}>
        {children}
      </Tooltip>
    );
  };

  return (
    <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {renderContent()}
    </span>
  );
});

export default TooltipComponent;
