import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as MENU from 'constants/menu';
import { AnyAction } from 'redux';

export interface MenuState {
    display: boolean;
}

const initialState: MenuState = {
    display: false,
};

const reducers = {
    [MENU.SHOW]: (state: MenuState) =>
        update(state, {
            $set: { display: true },
        }),
    [MENU.HIDE]: (state: MenuState) =>
        update(state, {
            $set: { display: false },
        }),
};

export default createReducer<MenuState, AnyAction>(initialState, reducers);
