import React, { useMemo, useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useSpring, animated } from 'react-spring';

import { useSelector, useLexique, useDispatch } from 'utils/redux';
import MessagingLexique from 'locales/types/containers/messagingPage';

import { AppState } from 'reducers';
import { DEFAULT_FILTERS } from 'reducers/messaging';
import { updateFilters, resetCurrentConversation } from 'actions/messaging';

import { PanelWrapper } from 'containers/messagingPage/Components';
import { Chat, Inbox } from './Modules';

import styles from './Messaging.module.scss';

interface Selector {
  displayPanel: boolean;
}

const Messaging = ({ className }) => {

  const lexique = useLexique<MessagingLexique>('containers.messagingPage');
  const dispatch = useDispatch();
  const { displayPanel } = useSelector(
    (state: AppState): Selector => ({
      displayPanel: state.messaging.displayPanel,
    })
  );

  useEffect(() => {
    return () => {
      dispatch(updateFilters(DEFAULT_FILTERS));
      dispatch(resetCurrentConversation())
    }
  }, [dispatch]);

  // this spring animated panel to chat on mobile
  // Handle setViewList to manage it
  const props = useSpring({
    transform: `translateX(${displayPanel ? 0 : -50}%)`,
    config: { mass: 1, tension: 400, friction: 35 }
  });

  const panelItems = useMemo(
    () => ([
      {
        key: 1,
        component: <Inbox />
      },
    ]),
    []
  );

  const cnContainer = cn(styles.container, className);

  return (
    <>
      <Helmet>
        <title>{lexique.headTitle}</title>
      </Helmet>
      <div className={cnContainer}>
        <animated.div style={props}>
          <PanelWrapper currentTab={0} items={panelItems} />
          <Chat />
        </animated.div>
      </div>
    </>
  )
};

export default Messaging;
