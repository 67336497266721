/* eslint-disable global-require */
/* eslint-disable import/no-mutable-exports */
import { DEV_DEBUG } from '../config/env';
import { Api } from './api.d';

let api: Api;
if (DEV_DEBUG) {
    api = require('./api.dev').api as Api;
} else {
    api = require('./api.prod').api as Api;
}

export default api;
