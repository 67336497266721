import { createAction } from 'redux-actions';

import * as types from 'constants/propositionAnswerCard';

export const onClick = createAction(types.ON_CLICK, (id, type, text) => ({
    id,
    type,
    text,
}));

export const postAnswerSuccess = createAction(types.POST_ANSWER_SUCCESS, (data): any => data);

export const postAnswerFailure = createAction(types.POST_ANSWER_FAILURE, ({ id, type, text, errorType, optional }) => ({
    error: { id, type, text, errorType, ...optional },
}));
