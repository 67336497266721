import React, { FC, lazy, memo, useEffect, useState, Suspense, useCallback, useMemo } from 'react';
import { Modal2, Alert } from '@brandandcelebrities/kolkit';

import { AppState } from 'reducers';
import { ModaleState } from 'reducers/ui/modale';
import { useDispatch, useSelector } from 'utils/redux';
import { hideModale } from 'actions/modale';

const ModalKolkit: FC = () => {
    const dispatch = useDispatch();
    const [Module, setModule] = useState(null);

    const {
        title,
        primaryAction,
        primaryTitle,
        secondaryAction,
        secondaryTitle,
        onCloseAction,
        show,
        data,
        body,
        isAlert,
        iconTitle,
        disableBackdropClick,
        disableEscapeKeyDown,
        maxWidth,
        disableFullScreen,
    }: ModaleState = useSelector((state: AppState): ModaleState => state.ui.modale);

    const isMobile = useSelector((state: AppState): boolean => state.env.device.isMobile);

    useEffect(() => {
        (async () => {
            if (body !== null) {
                const Component = await lazy(() => import(`./${body}`));
                setModule(Component);
            }
        })();

        return () => setModule(null);
    }, [body, setModule]);

    const handleClose = useCallback(() => {
        if (onCloseAction) onCloseAction();
        dispatch(hideModale());
        setModule(null);
    }, [dispatch, onCloseAction]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    const content = useMemo(() => <Suspense fallback="...">{Module && <Module {...data} />}</Suspense>, [Module, data]);

    if (isAlert) {
        return (
            <Alert
                on={show}
                title={title}
                primaryTitle={primaryTitle}
                primaryAction={primaryAction}
                secondaryTitle={secondaryTitle}
                secondaryAction={secondaryAction}
                iconTitle={iconTitle}
                width="auto"
            >
                {content}
            </Alert>
        );
    }

    return (
        <Modal2
            on={show}
            title={title}
            onClick={handleClose}
            action={{
                primary: {
                    title: primaryTitle,
                    onClick: primaryAction,
                },
                secondary: {
                    title: secondaryTitle,
                    onClick: secondaryAction,
                },
            }}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            fullScreen={isMobile && !disableFullScreen}
            maxWidth={maxWidth}
            width="auto"
        >
            {content}
        </Modal2>
    );
};

ModalKolkit.defaultProps = {
    isAlert: false,
    disableBackdropClick: false,
    disableEscapeKeyDown: false,
    maxWidth: 'sm',
    disableFullScreen: false,
};

export default memo(ModalKolkit);
