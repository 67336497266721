import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as ENV from 'constants/env';
import { AnyAction } from 'redux';
import { Locale } from 'locales';

export interface Viewport {
    height: number;
    width: number;
}

export enum DeviceType {
    desktop = 'desktop',
    mobile = 'mobile',
    tabletPortrait = 'tabletPortrait',
    tabletLandscape = 'tabletLandscape',
}

export interface Device {
    type: DeviceType;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isTabletLandscape: boolean;
    isTabletPortrait: boolean;
    isTouch: boolean;
}

export interface EnvState {
    logged: boolean;
    locale: Locale;
    userToken: string;
    mustRefresh: boolean;
    device: Device;
    viewport: Viewport;
}

const initialState: EnvState = {
    logged: false,
    locale: Locale.fr_FR,
    userToken: '',
    mustRefresh: false,
    device: {
        type: DeviceType.desktop,
        isMobile: false,
        isDesktop: true,
        isTablet: false,
        isTabletLandscape: false,
        isTabletPortrait: false,
        isTouch: false,
    },
    viewport: {
        height: 0,
        width: 0,
    },
};

const reducers = {
    [ENV.CLIENT_TOKEN_SET]: (state: EnvState, { payload: { token } }: AnyAction): EnvState =>
        update(state, {
            userToken: { $set: token },
        }),
    [ENV.FETCH_APP_INIT_SUCCESS]: (
        state: EnvState,
        {
            payload: {
                response: { locale },
            },
        }: AnyAction,
    ): EnvState =>
        update(state, {
            logged: { $set: true },
            locale: {
                $set: locale && locale !== '' ? locale : getNavigatorLanguage(),
            },
        }),
    [ENV.FETCH_APP_INIT_FAILURE]: (state: EnvState): EnvState => update(state, {
        logged: { $set: false},
        userToken: { $set: null },
    }),
    [ENV.DEVICE_CHANGED]: (state, action) =>
        update(state, {
            device: {
                $set: {
                    type: action.payload.device,
                    isMobile: action.payload.device === 'mobile',
                    isDesktop: action.payload.device === 'desktop',
                    isTablet: action.payload.device === 'tabletPortrait' || action.payload.device === 'tabletLandscape',
                    isTabletLandscape: action.payload.device === 'tabletLandscape',
                    isTabletPortrait: action.payload.device === 'tabletPortrait',
                    isTouch:
                        action.payload.device === 'mobile' ||
                        action.payload.device === 'tabletPortrait' ||
                        action.payload.device === 'tabletLandscape',
                },
            },
        }),
    [ENV.LOCALE_CHANGED]: (state, action) =>
        update(state, {
            locale: { $set: action.payload.locale },
        }),
    [ENV.VIEWPORT_CHANGED]: (state, action) =>
        update(state, {
            viewport: { $set: { width: action.payload.w, height: action.payload.h } },
        }),
    [ENV.TOGGLE_REFRESH]: (state, { payload }: AnyAction) => ({
        ...state,
        mustRefresh: payload,
    }),
};

// @TODO type
export default createReducer<EnvState, AnyAction>(initialState, reducers);

const getNavigatorLanguage = () => {
    const locale = navigator.language || navigator.userLanguage;
    if (locale.indexOf('fr') > -1) return 'fr-FR';

    return 'en-GB';
};
