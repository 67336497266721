import React, { memo } from 'react';

import { useSelector } from 'utils/redux';
import { Networks } from 'constants/networks';
import PostEstimationValueCard from 'components/organisms/PostEstimationValueCard';
import getLexique from 'locales';
import './PostEstimationValueCards.scss';
import { getSortedSnasStats } from 'utils/snas';
import { formatPrice } from 'utils/currency';

const PostEstimationValueCards = memo(() => {
    const { lexique, snas, locale, currency } = useSelector(({ env, snas }) => ({
        locale: env.locale,
        currency: getLexique(env.locale).config.currencyCode,
        lexique: getLexique(env.locale).components.postEstimationValueCards,
        snas: getSortedSnasStats(snas),
    }));

    const display =
        snas.length > 0 && snas.map((sn) => sn.username && sn.username.length > 0).filter((t) => t === true).length > 0;
    const validSnas = snas.filter(({ username, network }) => username && network !== Networks.tiktok);

    return (
        display && (
            <section className="bc-post-estimations">
                <header>
                    <h3>{lexique.title}</h3>
                    <p>
                        {lexique.description}
                    </p>
                </header>
                {validSnas.map(({ username, network, min, max }) => (
                    <PostEstimationValueCard
                        key={`${username}_${network}`}
                        min={formatPrice(min, locale, currency)}
                        max={formatPrice(max, locale, currency)}
                        username={username}
                        network={network}
                        fallbackMsg={lexique.no_price}
                    />
                ))}
            </section>
        )
    );
});

export default PostEstimationValueCards;
