import { createReducer } from 'redux-create-reducer';
import { Action } from 'redux';
import {
    SnaFormState,
    OnEditActionPayload,
    OnErrorActionPayload,
    OnClearActionPayload,
    Payload,
    Reducers,
} from './snaform.reducer.d';
import { SOCIAL_NETWORKS } from '../../../constants/networks';
import * as SOCIAL_STATS_CARD from '../../../constants/socialStatsCard';

export const initialState: SnaFormState = Object.keys(SOCIAL_NETWORKS).reduce((acc, sn) => {
    return {
        ...acc,
        [sn]: {
            url: '',
            error: false,
            errorType: '',
            disabled: false,
        },
    };
}, {});

const onEdit = (state: SnaFormState, { payload }: Payload<OnEditActionPayload>): SnaFormState => ({
    ...state,
    ...Object.keys(payload).reduce((acc, p) => {
        acc[p] = {
            ...acc[p],
            url: payload[p].url,
        };
        return acc;
    }, {}),
});

const onError = (
    state: SnaFormState,
    {
        payload: {
            error: { type, errorType },
        },
    }: Payload<OnErrorActionPayload>,
): SnaFormState => ({
    ...state,
    [type]: {
        ...state[type],
        error: true,
        errorType,
    },
});

const clearError = (state: SnaFormState, { payload: { network } }: Payload<OnClearActionPayload>): SnaFormState => ({
    ...state,
    [network]: {
        ...state[network],
        error: false,
        errorType: '',
    },
});

// @TODO change constants, use new ones. This is temporary because no time to update sagas...
export const reducers: Reducers = {
    [SOCIAL_STATS_CARD.MODAL_INFO_SNA_ERROR]: onError,
    [SOCIAL_STATS_CARD.MODAL_CLEAR_SNA_ERROR]: clearError,
    [SOCIAL_STATS_CARD.MODAL_ON_EDIT]: onEdit,
};

export default createReducer<SnaFormState, Action<Reducers>>(initialState, reducers);
