const ucfirst = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

const getWordingForSNA = (socialNetwork) => {
    let kindOfAccount = 'compte';
    if (socialNetwork === 'site web' || socialNetwork === 'page web' || socialNetwork === 'blog') kindOfAccount = '';
    if (socialNetwork === 'Youtube' || socialNetwork === 'Twitch') kindOfAccount = 'chaîne';
    if (socialNetwork === 'Facebook') kindOfAccount = 'fanpage';
    const masculin = kindOfAccount === 'compte';
    const pronom = masculin ? 'Ce' : 'Cette';
    return {
        errorTypes: {
            invalidUrl: `L'adresse de votre ${kindOfAccount} ${socialNetwork} est invalide.`,
            privateAccount: `${pronom} ${kindOfAccount} est privé${masculin ? '' : 'e'}`,
            recordNotUnique: `${pronom} ${kindOfAccount} est déjà pris${masculin ? '' : 'e'}`,
            unknownAccount: `${pronom} ${kindOfAccount} n'existe pas`,
            errorUnknown: `Erreur serveur`,
        },
        placeholder: socialNetwork === 'page web' ? 'Autres' : ucfirst(socialNetwork),
    };
};

export default {
    title: 'Éditez vos réseaux',
    description: `Les statistiques portent uniquement sur Instagram, Youtube, Tiktok, Facebook et Twitter, les autres réseaux sont affichés à titre informatif aux marques.`,
    submit: 'Éditer',
    warningMessage: 'En cliquant sur “Éditer” vous certifiez être le ou la propriétaire des réseaux sociaux renseignés',
    form: {
        facebook: getWordingForSNA('Facebook'),
        twitter: getWordingForSNA('Twitter'),
        youtube: getWordingForSNA('Youtube'),
        tiktok: getWordingForSNA('Tiktok'),
        snapchat: getWordingForSNA('Snapchat'),
        pinterest: getWordingForSNA('Pinterest'),
        twentyone_buttons: getWordingForSNA('21 buttons'),
        twitch: getWordingForSNA('Twitch'),
        blog: getWordingForSNA('blog'),
        website: getWordingForSNA('site web'),
        other_networks: getWordingForSNA('page web'),
    },
};
