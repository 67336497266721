import _isEmpty from 'lodash.isempty';
import _find from 'lodash.find';
import { TokenData } from 'types/Instaconnect';
import { map } from './utils';

// http://itdoc.brandandcelebrities.com/team_app/eden/community/v1/profiles/show/
const loadProfile = {
    fromApi: (ob) => {
        const livingInfo = ob.addresses ? ob.addresses.find((a) => a.category === 'living') : null;

        let livingAddress;
        if (livingInfo) {
            livingAddress = {
                placeSearched: livingInfo.g_formatted_address || '',
                mapped: livingInfo.g_formatted_address ? { g_formatted_address: livingInfo.g_formatted_address } : {},
            };
        }

        const descs = ob.descriptions ? ob.descriptions.find((d) => d.locale === 'fr') : null;

        return {
            ...map(ob.pseudo, 'stageName'),
            ...map(ob.first_name, 'firstName'),
            ...map(ob.last_name, 'lastName'),
            ...map(ob.gender, 'gender'),
            ...map(ob.birthdate, 'birthdate'),
            ...map(ob.addresses, 'addresses'),
            ...map(livingAddress, 'livingAddress'),
            ...map(ob.nationalities, 'nationality'),
            ...map(ob.phone, 'phone'),
            ...map(ob.activity_labels, 'activities'),
            ...map(ob.influence_themes, 'influenceThemes'),
            ...map(ob.website, 'website'),
            ...map(descs ? descs.small_descr : null, 'bio'),
            ...map(descs ? descs.full_descr : null, 'description'),
        };
    },
};

const setOnboardingStep = {
    toApi: (nextOnboardingStep) => ({ onboarding_current_step: nextOnboardingStep }),
};

const saveProfile = {
    toApi: (ob, profileAddresses, nextOnboardingStep) => {
        const addresses = ['birth', 'living'].reduce((accumulator, category) => {
            const pAddress = _find(profileAddresses, { category });
            if (
                ob[`${category}Address`] &&
                ob[`${category}Address`].placeSearched === '' &&
                pAddress &&
                pAddress.g_formatted_address &&
                pAddress.g_formatted_address !== ob[`${category}Address`].placeSearched
            ) {
                accumulator.push({
                    category,
                    _destroy: true,
                });
            } else if (
                ob[`${category}Address`] &&
                !_isEmpty(ob[`${category}Address`].placeSearched) &&
                !_isEmpty(ob[`${category}Address`].mapped)
            ) {
                accumulator.push({
                    category,
                    ...ob[`${category}Address`].mapped,
                });
            }
            // else there is a problem with the values in the store

            return accumulator;
        }, []);

        const descriptions = [
            {
                locale: 'fr',
                small_descr: ob.bio,
                full_descr: ob.description,
            },
        ];

        let birthdate;
        if (ob.birthdate && ob.birthdate !== '') {
            const splitted = ob.birthdate.split('-');
            birthdate = {
                ...(splitted.length >= 1 ? { year: splitted[0] } : null),
                ...(splitted.length >= 2 ? { month: splitted[1] } : null),
                ...(splitted.length >= 3 ? { day: splitted[2] } : null),
            };
        }

        return {
            ...map(ob.stageName, 'pseudo'),
            ...map(ob.firstName, 'first_name'),
            ...map(ob.lastName, 'last_name'),
            ...map(ob.gender, 'gender', true),
            ...map(birthdate, 'birthdate'),
            ...map(ob.nationality, 'nationalities'),
            ...map(ob.phone, 'phone'),
            ...map(ob.activities, 'activities'),
            ...map(ob.influenceThemes, 'influence_themes'),
            ...map(ob.website, 'website'),
            addresses,
            descriptions,
            onboarding_current_step: nextOnboardingStep,
        };
    },
};

const sendSelectedInstagramPages = {
    toApi: (pages) => ({
        snas: pages.map(({ label, picture, uid, username, name, businessId, facebookPageId }) => ({
            label,
            profile_picture: picture,
            uid,
            username,
            display_name: name,
            business_id: businessId,
            page_id: facebookPageId,
        })),
    }),
};

const sendSelectedFacebookPages = {
    toApi: (pages) => ({
        snas: pages.map(({ label, picture, uid, username, name }) => ({
            label,
            profile_picture: picture,
            uid,
            username,
            display_name: name,
        })),
    }),
};

const saveUserToken = {
    toApi: (token: TokenData) => ({
        uid: token.uid,
        business_id: token.instagramBusinessId,
        short_lived_token: token.shortLivedToken,
        trigger_mode: token.triggerMode,
    }),
};

const updateEmail = {
    toApi: ({ email }) => ({ email }),
};

const updatePassword = {
    toApi: ({ password, newPassword, confirmNewPassword }) => ({
        current_password: password,
        password: newPassword,
        password_confirmation: confirmNewPassword,
    }),
};

export {
    loadProfile,
    setOnboardingStep,
    saveProfile,
    sendSelectedInstagramPages,
    sendSelectedFacebookPages,
    saveUserToken,
    updateEmail,
    updatePassword,
};
