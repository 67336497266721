import React, { FC, memo } from 'react';
import { CardColors } from 'components/atoms/Card';
import classnames from 'classnames';
import Arrow, { Direction } from 'components/atoms/Arrow';

import styles from './EvolutionCard.module.scss';

interface Props {
    evolution: string;
}

enum SIGNS {
    POSITIVE = 'arrow-alt-to-top',
    EQUAL = 'equal',
    NEGATIVE = 'arrow-alt-to-bottom',
}

const MetricCard: FC<Props> = memo(({ evolution }) => {
    let sign: string;
    let color: CardColors;
    let arrow: JSX.Element | string;
    const progression: number = parseFloat(evolution);
    switch (true) {
        case progression > 0:
            sign = SIGNS.POSITIVE;
            color = CardColors.green;
            arrow = <Arrow direction={Direction.toTop} color={styles.arrowGreen} />;
            break;
        case progression < 0:
            sign = SIGNS.NEGATIVE;
            color = CardColors.red;
            arrow = <Arrow direction={Direction.toBottom} color={styles.arrowRed} />;
            break;
        case progression === 0:
            sign = SIGNS.EQUAL;
            color = CardColors.blue;
            arrow = <span className={styles.equalsign}>{'= '}</span>;
            break;
        case !evolution:
        default:
            sign = SIGNS.EQUAL;
            color = CardColors.blue;
            arrow = ' ';
            break;
    }
    const evolutionMetricClassNames = classnames(styles.evolutionCard, styles[color], {
        [styles.positive]: sign === SIGNS.POSITIVE,
        [styles.equal]: sign === SIGNS.EQUAL,
        [styles.negative]: sign === SIGNS.NEGATIVE,
    });
    return (
        <div className={evolutionMetricClassNames}>
            {arrow}
            {evolution}
        </div>
    );
});

export default MetricCard;
