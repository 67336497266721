import React, { Component } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash.isempty';
import _maxBy from 'lodash.maxby';
import _orderBy from 'lodash.orderby';

import GoogleChart from 'components/molecules/GoogleChart';
import { percentFormatter } from 'utils';
import getLexique from 'locales';

import './AgeChart.scss';
import { AppState } from 'reducers';
import { DeviceType } from 'reducers/env';
import { CommunityStats } from 'reducers/ui/analytics';

interface Props {
    lexique: any; // @TODO type
    device: DeviceType;
    community: CommunityStats;
}

const getValueAt = (column, dataTable, row) => {
    return percentFormatter(dataTable.getValue(row, column));
};

class AgeChart extends Component<Props> {
    renderChart() {
        const { device, lexique, community } = this.props;

        if (_isEmpty(community) || _isEmpty(community.genders_per_age)) {
            return <div className="bc-analytics-community-age-chart__age-chart-placeholder">{lexique.placeholder}</div>;
        }

        const maxValueFemale = _maxBy(
            community.genders_per_age.map((item) => [item.slice, item.F * 100]),
            1,
        )[1];
        const maxValueMale = _maxBy(
            community.genders_per_age.map((item) => [item.slice, item.M * 100]),
            1,
        )[1];
        const maxValue = Math.max(maxValueFemale, maxValueMale);

        const getTotal = (sex) =>
            community.genders_per_age.reduce(
                (accumulator, currentValue) => accumulator + parseFloat(currentValue[sex]),
                0,
            ) * 100;
        const data = (sex) => {
            const header = ['type', 'value'];
            const rows = _orderBy(community.genders_per_age, ['slice']).map((item) => {
                return [item.slice, item[sex] * 100];
            });
            return [header, ...rows];
        };

        return (
            <>
                <div className="bc-analytics-community-age-chart__age-chart">
                    <div className="bc-analytics-community-age-chart__single-chart">
                        <div className="bc-analytics-community-age-chart__legend-container">
                            <div className="bc-analytics-community-age-chart__legend-title">
                                {lexique.legend.female}
                            </div>
                            <div className="bc-analytics-community-age-chart__legend-metric">
                                <svg viewBox="0 0 400 400">
                                    <circle r={200} cx={200} cy={200} fill="#4A90E2" />
                                </svg>
                                {percentFormatter(getTotal('F'))}
                            </div>
                        </div>
                        <div className="bc-analytics-community-age-chart__chart">
                            <GoogleChart
                                id="AgeChartFemale"
                                visualizationType="ColumnChart"
                                chartType="Bar"
                                load={['corechart', 'bar']}
                                data={data('F')}
                                columns={[
                                    0,
                                    1,
                                    {
                                        calc: getValueAt.bind(undefined, 1),
                                        sourceColumn: 1,
                                        type: 'string',
                                        role: 'annotation',
                                    },
                                ]}
                                options={{
                                    legend: { position: 'none' },
                                    vAxis: {
                                        baselineColor: 'transparent',
                                        gridlines: { color: 'transparent' },
                                        minValue: 0,
                                        maxValue,
                                        textPosition: 'none',
                                    },
                                    chartArea: {
                                        width: '100%',
                                        height: '100%',
                                    },
                                    bar: {
                                        groupWidth: '85%',
                                    },
                                    colors: ['#4A90E2'],
                                    enableInteractivity: false,
                                }}
                            />
                        </div>
                    </div>

                    <div className="bc-analytics-community-age-chart__age-axis">
                        {data('F')
                            .splice(1)
                            .map((item) => {
                                return (
                                    <div className="bc-analytics-community-age-chart__age-axis-item" key={item[0]}>
                                        {device !== 'mobile' ? `${item[0]}${lexique.legend.years}` : item[0]}
                                    </div>
                                );
                            })}
                    </div>

                    <div className="bc-analytics-community-age-chart__single-chart bc-analytics-community-age-chart__single-chart--male">
                        <div className="bc-analytics-community-age-chart__legend-container">
                            <div className="bc-analytics-community-age-chart__legend-title">{lexique.legend.male}</div>
                            <div className="bc-analytics-community-age-chart__legend-metric">
                                <svg viewBox="0 0 400 400">
                                    <circle r={200} cx={200} cy={200} fill="#0A293D" />
                                </svg>
                                {percentFormatter(getTotal('M'))}
                            </div>
                        </div>
                        <div className="bc-analytics-community-age-chart__chart">
                            <GoogleChart
                                id="AgeChartMale"
                                visualizationType="ColumnChart"
                                chartType="Bar"
                                load={['corechart', 'bar']}
                                data={data('M')}
                                columns={[
                                    0,
                                    1,
                                    {
                                        calc: getValueAt.bind(undefined, 1),
                                        sourceColumn: 1,
                                        type: 'string',
                                        role: 'annotation',
                                    },
                                ]}
                                options={{
                                    legend: { position: 'none' },
                                    vAxis: {
                                        baselineColor: 'transparent',
                                        gridlines: { color: 'transparent' },
                                        minValue: 0,
                                        maxValue,
                                        textPosition: 'none',
                                        direction: -1,
                                    },
                                    chartArea: {
                                        width: '100%',
                                        height: '100%',
                                    },
                                    bar: {
                                        groupWidth: '85%',
                                    },
                                    colors: ['#0A293D'],
                                    enableInteractivity: false,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="bc-analytics-community-age-chart__legend-mobile-container">
                    <div className="bc-analytics-community-age-chart__legend-mobile">
                        <div className="bc-analytics-community-age-chart__legend-mobile-title">
                            {lexique.legend.female}
                        </div>
                        <div className="bc-analytics-community-age-chart__legend-mobile-metric">
                            <svg viewBox="0 0 400 400">
                                <rect x={0} y={0} width={400} height={400} fill="#4A90E2" />
                            </svg>
                            {percentFormatter(getTotal('F'))}
                        </div>
                    </div>
                    <div className="bc-analytics-community-age-chart__legend-mobile">
                        <div className="bc-analytics-community-age-chart__legend-mobile-title">
                            {lexique.legend.male}
                        </div>
                        <div className="bc-analytics-community-age-chart__legend-mobile-metric">
                            <svg viewBox="0 0 400 400">
                                <rect x={0} y={0} width={400} height={400} fill="#0A293D" />
                            </svg>
                            {percentFormatter(getTotal('M'))}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="bc-analytics-community-age-chart-shell">
                <div className="bc-analytics-community-age-chart">{this.renderChart()}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexique: getLexique(state.env.locale).containers.analyticsPage.community.ageChart,
    community: state.ui.analytics.community,
    device: state.env.device.type,
});

export default connect(mapStateToProps)(AgeChart);
