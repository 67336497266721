import React, { FC, memo } from 'react';

import './progress-metter.scss';

interface Props {
    value: number;
}

const ProgressMeter: FC<Props> = ({ value }) => {
    return (
        <div className="bc-progress-metter">
            <div className="bc-progress-metter__tooltip" style={{ left: `${value}%` }}>
                {`${value}%`}
                <div className="bc-progress-metter__tooltip-arrow" />
            </div>
            <div className="bc-progress-metter__bar" style={{ width: `${value}%` }} />
        </div>
    );
};

export default memo(ProgressMeter);
