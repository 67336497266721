export default {
    title: `Completion of your profile`,
    subtitle: `Gain visibility by completing your profile as much as possible`,
    personalInfo: {
        title: `Fill in all your personal information`,
        subtitle: `(surname, first name, gender, date of birth, city, nationality, telephone)`,
    },
    moreInfo: {
        title: `Tell us more about you`,
        subtitle: `(influence themes, website, bio, description)`,
    },
    instagramAccount: {
        title: `Add your business or creator Instagram account`,
    },
    otherNetworks: {
        title: `Add at least one other social network`,
    },
    full: {
        title: `Congratulations!`,
        subtitle: `Your profile is complete and its visibility is maximum on our platform`,
    },
};
