import React, { Component } from 'react';
import classNames from 'classnames';

import ChevronDown from '@brandandcelebrities/icons/solid/ChevronDown';
import Check from '@brandandcelebrities/icons/light/Check';

import SocialPicto from 'components/atoms/SocialPicto';

import './SelectSocialNetwork.scss';
import { Networks } from 'types/snas';

interface Item {
    uid: string;
    label: Networks;
    username?: string;
    selected: boolean;
}

interface Props {
    onClickItem: (item: Item) => void;
    placeholder: string;
    dataset: Item[];
}

interface State {
    deployed: boolean;
}

class SelectSocialNetwork extends Component<Props, State> {
    filterRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        this.state = {
            deployed: false,
        };
    }

    onClickItem = (uid) => this.props.onClickItem(uid);

    onClickDeploy = () => {
        ['mousedown', 'touchstart'].forEach((event) => document.addEventListener(event, this.handleClickOutside));
        this.setState((prevState) => ({
            deployed: !prevState.deployed,
        }));
    };

    handleClickOutside = (event) => {
        if (
            this.state.deployed &&
            this.filterRef &&
            this.filterRef.current &&
            !this.filterRef.current.contains(event.target)
        ) {
            ['mousedown', 'touchstart'].forEach((event) =>
                document.removeEventListener(event, this.handleClickOutside),
            );
            this.setState({
                deployed: false,
            });
        }
    };

    componentWillUnmount() {
        ['mousedown', 'touchstart'].forEach((event) => document.removeEventListener(event, this.handleClickOutside));
    }

    render() {
        const { placeholder, dataset } = this.props;
        const { deployed } = this.state;

        return (
            <div className="bc-analytics-search-card__social-filters" ref={this.filterRef}>
                <div className="bc-analytics-search-card__social-filter" onClick={this.onClickDeploy}>
                    <div className="bc-analytics-search-card-social-filter__placeholder">{placeholder}</div>
                    <div
                        className={classNames({
                            'bc-analytics-search-card-social-filter__arrow': true,
                            'bc-analytics-search-card-social-filter__arrow--deployed': deployed,
                        })}
                    >
                        <ChevronDown />
                    </div>
                </div>
                {deployed && (
                    <div className="bc-analytics-search-card-social-filter__options">
                        {dataset &&
                            dataset.length &&
                            dataset.map((item: Item) => (
                                <div
                                    key={item.uid}
                                    className="bc-analytics-search-card-social-filter__option"
                                    onClick={() => this.onClickItem({ ...item, selected: !item.selected })}
                                >
                                    <div className="bc-analytics-search-card-social-filter-option__checkbox">
                                        {item.selected && <Check />}
                                    </div>
                                    <div className="bc-analytics-search-card-social-filter-option__icon">
                                        <SocialPicto network={item.label} />
                                    </div>
                                    <div className="bc-analytics-search-card-social-filter-option__text">
                                        {item.username || item.uid}
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        );
    }
}

export default SelectSocialNetwork;
