import header from './header';
import menu from './menu';
import modals from './modals';
import footer from './footer';
import modalReconnect from './modalReconnect';
import projectList from './projectList';
import proposition from './proposition';
import socialStatsContainer from './socialStatsContainer';
import socialStatsList from './socialStatsList';
import socialNetwoksSidebar from './socialNetwoksSidebar';
import profileStrengthMeter from './profileStrengthMeter';
import recapBlock from './recapBlock';
import socialStatsHomePage from './socialStatsHomePage';
import unSupportedSnaList from './unSupportedSnaList';

export default {
    header,
    menu,
    modals,
    footer,
    modalReconnect,
    projectList,
    proposition,
    socialStatsContainer,
    socialStatsList,
    socialNetwoksSidebar,
    profileStrengthMeter,
    recapBlock,
    socialStatsHomePage,
    unSupportedSnaList,
};
