export default {
    engagment: {
        title: 'Taux d’engagement',
        button: 'Plus d’infos sur mon audience',
    },
    profileVisit: {
        title: 'Nombre de marques ayant visité mon profil sur Kolsquare',
        button: 'Plus d’infos sur mon profil sur Kolsquare',
    },
    mostEngagingHashtag: {
        title: 'Hashtag le plus engageant',
        button: 'Plus d’infos sur mon contenu',
    },
    legend: 'Données calculées sur le dernier mois, comparées au mois précédent.',
};
