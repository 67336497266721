export default {
    title: 'My projects',
    project: {
        date: {
            the: 'the',
            at: 'at',
        },
    },
    empty: {
        title: `You haven’t receive any proposition yet.`,
        mobileTitle: `To increase your visibility:`,
        body: `To increase your visibility on our search engine and receive propositions matching your profile: `,
        bullet1: `1. Connect your social networks`,
        bullet2: `2. Fill in your profile`,
        tagline: `We'll take care of the rest!`,
        error: `An erreur occured while loading projects...`,
        restablish: `We're working to fix it as soon as possible...`,
    },
};
