import React, { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { Button } from '@brandandcelebrities/kolkit';

import styles from './Back.module.scss';

interface Props {
  label: string;
  onClick: (...args: any[]) => void;
  id: number | string;
  onlyMobile?: boolean;
}

const BackButton: FC<Props> = ({ label, onClick, id, onlyMobile }) => {
  const handleChange = useCallback(
    () => onClick && onClick(id),
    [id, onClick]
  );

  const cnButton = cn(styles.backButton, {
    [styles.onlyMobile]: onlyMobile
  })

  return (
    <div className={cnButton}>
      <Button
        label={label}
        theme="light"
        icon="arrow-left"
        iconTheme="solid"
        size="big"
        onClick={handleChange}
      />
    </div>
  )
};

export default memo(BackButton);
