const ucfirst = (str) => `${str[0].toUpperCase()}${str.slice(1)}`;

const getWordingForSNA = (socialNetwork) => {
    let kindOfAccount = 'account';
    if (socialNetwork === 'web page' || socialNetwork === 'blog' || socialNetwork === 'website') kindOfAccount = '';
    if (socialNetwork === 'Youtube' || socialNetwork === 'Twitch') kindOfAccount = 'channel';
    if (socialNetwork === 'Facebook') kindOfAccount = 'fanpage';
    return {
        errorTypes: {
            invalidUrl: `Your ${socialNetwork} ${kindOfAccount} address is invalid.`,
            privateAccount: `This ${kindOfAccount} is private`,
            recordNotUnique: `Sorry, an error has occured.`,
            unknownAccount: `This ${kindOfAccount} doesn't exist`,
            errorUnknown: `Sorry, an error has occured.`,
        },
        placeholder: socialNetwork === 'web page' ? 'Other' : ucfirst(socialNetwork),
    };
};

export default {
    title: 'Edit your social networks',
    description: `The statistics relate only to Instagram, Youtube, Tiktok, Facebook and Twitter, the other networks are displayed for the information of the brands.`,
    submit: 'Edit',
    warningMessage:
        'By clicking on “Edit” I certify that I own the completed accounts and guarantee Kolsquare against any recourse of third party owner for accounts that I would have claimed ownership.',
    form: {
        facebook: getWordingForSNA('Facebook'),
        twitter: getWordingForSNA('Twitter'),
        youtube: getWordingForSNA('Youtube'),
        tiktok: getWordingForSNA('Tiktok'),
        snapchat: getWordingForSNA('Snapchat'),
        pinterest: getWordingForSNA('Pinterest'),
        twentyone_buttons: getWordingForSNA('21 buttons'),
        twitch: getWordingForSNA('Twitch'),
        website: getWordingForSNA('website'),
        blog: getWordingForSNA('blog'),
        other_networks: getWordingForSNA('web page'),
    },
};
