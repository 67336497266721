import React, { useCallback, useMemo } from 'react';
import { SelectNative } from '@brandandcelebrities/kolkit';

import { useLexique } from 'utils/redux';

interface Props {
    name: string;
    value?: string[];
    placeholder?: string;
    onChange?: (onChangeEvent: { values: string[] }) => void;
    className?: string;
    disabled?: boolean;
    error?: string;
}

const countriesThatAppearFirst = ['FR', 'ES', 'DE', 'IT', 'GB'];

const SelectNationality: React.FC<Props> = ({ name, value, placeholder, onChange, disabled, className, error }) => {
    const countries = useLexique('countries');

    const handleChange = useCallback(
        ({ value: _value, selected }) => {
            onChange({ values: [selected || _value] });
        },
        [onChange],
    );

    const countriesList = useMemo(
        () => [
            ...countriesThatAppearFirst.map((key) => ({
                value: key,
                label: countries[key],
            })),
            ...Object.entries(countries)
                .filter(([countryCode]) => !countriesThatAppearFirst.includes(countryCode))
                .sort(([, label1], [, label2]) => label1.localeCompare(label2))
                .map(([key, label]) => ({
                    value: key,
                    label,
                })),
        ],
        [countries],
    );

    return (
        <SelectNative
            id={name}
            onChange={handleChange}
            value={value[0] || ''}
            placeholder={placeholder}
            dataset={countriesList}
            className={className}
            size="big"
            fullWidth
            disabled={disabled}
            error={error}
        />
    );
};

SelectNationality.defaultProps = {
    value: [],
    onChange: (): void => {},
    placeholder: '',
    disabled: false,
};

export default SelectNationality;
