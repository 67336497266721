import React from 'react';

export default {
    title: (name) => `Félicitations ${name} !`,
    text: (network, value) => (
        <>
            {`Votre taux d'engagement sur ${network} a augmenté de `}
            <span>{`${value}`}</span>
            {` points en 1 mois`}
        </>
    ),
};
