import React from 'react';
import { Icon } from '@brandandcelebrities/kolkit';
import './header.scss';

interface Props {
    title: string;
    description?: string;
    icon?: string;
}

const Header: React.SFC<Props> = ({ title, description, icon }) => (
    <header className="bc-content-header">
        <span className="bc-content-header_title">
            {icon && <Icon label={icon} className="bc-content-header_icon" width="24" />}
            <h3>{title}</h3>
        </span>
        {description && <p>{description}</p>}
    </header>
);

export default Header;
