import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDevice, setViewport } from 'actions/env';
import { DeviceType } from 'reducers/env';

const DIV_CHECKER_ID = 'resize-size-checker';

interface Props {
    device: DeviceType;
    actions: {
        setDevice: (newDeviceType: DeviceType) => void;
        setViewport: (w: number, h: number) => void;
    };
}

class BodyController extends Component<Props> {
    componentDidMount() {
        // Adding checker to detect rendering view, and listener
        const elemCheck = document.getElementById(DIV_CHECKER_ID);
        if (!elemCheck) {
            const elem = document.createElement('div');
            elem.id = DIV_CHECKER_ID;
            document.body.appendChild(elem);
        }

        window.addEventListener('resize', this.onWindowResize, false);
        this.onWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        // Getting
        const elem = document.getElementById(DIV_CHECKER_ID);
        const style = window.getComputedStyle(elem);
        const wi = parseInt(style.width, 10);
        let newDeviceType = null;
        switch (wi) {
            default:
            case 5:
                newDeviceType = DeviceType.desktop;
                break;
            case 4:
                newDeviceType = DeviceType.tabletLandscape;
                break;
            case 3:
                newDeviceType = DeviceType.tabletPortrait;
                break;
            case 2:
                newDeviceType = DeviceType.mobile;
                break;
        }
        if (newDeviceType !== this.props.device) {
            this.props.actions.setDevice(newDeviceType);
        }

        /* Set widow width and height */
        this.props.actions.setViewport(window.innerWidth, window.innerHeight);
    };

    getDevice() {
        return this.props.device;
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    device: state.env.device.type,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ setDevice, setViewport }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BodyController);
