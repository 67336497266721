import React, { Component } from 'react';
import { connect } from 'react-redux';
import nl2br from 'react-nl2br';
import classname from 'classnames';

import { Input } from '@brandandcelebrities/kolkitten';

import { Modal } from 'components/atoms';
import { openModal, closeModal, onClick } from 'actions/propositionAnswerMobile';
import { CONTACTED } from 'constants/proposition';
import getLexique from 'locales';

import './PropositionAnswerMobile.scss';
import { Proposition } from 'reducers/proposition';
import { PropositionAnswerMobileState } from 'reducers/ui/propositionPage';
import PropositionAnswerCardLexique from 'locales/types/containers/propositionPage/propositionAnswerCard';

const STICKY_LINE = 500; // px
const STICKY_MARGIN = 5; // px

interface Props {
    proposition: Proposition;
    lexique: PropositionAnswerCardLexique;
    propositionAnswerMobile: PropositionAnswerMobileState;
    onClick: (id: string, type: string, text: string) => void;
    openModal: () => void;
    closeModal: () => void;
}

interface State {
    display: boolean;
    answer: string;
    error: boolean;
    sticky: boolean;
}

class PropositionAnswerMobile extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            answer: '',
            error: false,
            sticky: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            error: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { sticky } = this.state;

        if (sticky && window.scrollY < STICKY_LINE - STICKY_MARGIN) {
            this.setState({
                sticky: false,
            });
        } else if (!sticky && window.scrollY > STICKY_LINE + STICKY_MARGIN) {
            this.setState({
                sticky: true,
            });
        }
    };

    handleChange(value) {
        this.setState({
            answer: value,
            error: false,
        });
    }

    onDiscussClick(id, type, text) {
        if (text === '') {
            this.setState({
                error: true,
            });
        } else {
            this.props.onClick(id, type, text);
        }
    }

    render() {
        const { lexique, proposition, propositionAnswerMobile } = this.props;
        const { sticky } = this.state;
        const isEnabled = this.props.proposition.state === CONTACTED && this.props.proposition.answer_value === null;

        return (
            <div className="bc-proposition-answer-mobile">
                <div
                    className={classname({
                        'bc-proposition-answer-mobile__answer-button-container': true,
                        'bc-proposition-answer-mobile__answer-button-container--sticky': sticky,
                    })}
                >
                    <button
                        type="button"
                        className="bc-proposition-answer-mobile__answer-button"
                        onClick={() => this.props.openModal()}
                    >
                        Repondre
                    </button>
                </div>
                <Modal display={propositionAnswerMobile.modal.display} onClose={() => this.props.closeModal()}>
                    <div className="bc-proposition-answer-mobile-modal">
                        <div className="bc-proposition-answer-mobile-modal__title">{lexique.title}</div>
                        {!(proposition.answer_value !== null && !!proposition.answer_text) && (
                            <Input
                                id="answer"
                                className="bc-proposition-answer-mobile-modal__textarea"
                                value={this.state.answer}
                                placeholder={lexique.form.textarea.placeholder}
                                onChange={({ value }) => this.handleChange(value)}
                                lines={10}
                                disabled={!isEnabled}
                                error={this.state.error}
                                errorText={lexique.form.textarea.error}
                            />
                        )}
                        {proposition.answer_value !== null && !!proposition.answer_text && (
                            <div className="bc-proposition-answer-mobile-modal__answer">
                                {nl2br(proposition.answer_text)}
                            </div>
                        )}
                        <div className="bc-proposition-answer-mobile-modal__control-buttons">
                            <div className="bc-proposition-answer-mobile-modal__control-title">
                                {lexique.form.title}
                            </div>
                            <button
                                type="button"
                                className={classname({
                                    'bc-proposition-answer-mobile-modal__button': true,
                                    'bc-proposition-answer-mobile-modal__button--yes': true,
                                    'bc-proposition-answer-mobile-modal__button--disabled': !isEnabled,
                                    'bc-proposition-answer-mobile-modal__button--selected':
                                        proposition.answer_value === 'yes',
                                })}
                                disabled={!isEnabled}
                                onClick={() => this.props.onClick(proposition.id, 'yes', this.state.answer)}
                            >
                                {lexique.form.buttons.yes}
                            </button>
                            <button
                                type="button"
                                className={classname({
                                    'bc-proposition-answer-mobile-modal__button': true,
                                    'bc-proposition-answer-mobile-modal__button--discuss': true,
                                    'bc-proposition-answer-mobile-modal__button--disabled': !isEnabled,
                                    'bc-proposition-answer-mobile-modal__button--selected':
                                        proposition.answer_value === 'discuss',
                                })}
                                disabled={!isEnabled}
                                onClick={() => this.onDiscussClick(proposition.id, 'discuss', this.state.answer)}
                            >
                                {lexique.form.buttons.discuss}
                            </button>
                            <button
                                type="button"
                                className={classname({
                                    'bc-proposition-answer-mobile-modal__button': true,
                                    'bc-proposition-answer-mobile-modal__button--no': true,
                                    'bc-proposition-answer-mobile-modal__button--disabled': !isEnabled,
                                    'bc-proposition-answer-mobile-modal__button--selected':
                                        proposition.answer_value === 'no',
                                })}
                                disabled={!isEnabled}
                                onClick={() => this.props.onClick(proposition.id, 'no', this.state.answer)}
                            >
                                {lexique.form.buttons.no}
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    lexique: getLexique(state.env.locale).containers.propositionPage.propositionAnswerCard,
    proposition: state.proposition.current,
    propositionAnswerMobile: state.ui.propositionPage.propositionAnswerMobile,
});

const mapDispatchToProps = () => ({
    onClick,
    openModal,
    closeModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(PropositionAnswerMobile);
