import { Pages } from 'types/Instaconnect';
import FacebookBusinessConnect from 'utils/fbc/FacebookBusinessConnect';

export const ON_STATUS_CHANGE = 'facebookBusinessConnect::onStatusChange';
export const ON_CONNECTED = 'facebookBusinessConnect::status::connected';
export const ON_DISCONNECTED = 'facebookBusinessConnect::status::notConnected';
export const SHOULD_RECONNECT = 'facebookBusinessConnect::shouldReconnection';
export const ON_REFRESHED_TOKENS = 'facebookBusinessConnect::onRefreshedTokens';
export const ON_ACCOUNT_DATAS = 'facebookBusinessConnect::onAccountDatas';

/* this class is used in local dev environment and simulates a return of data from facebook */

export const fbcMocks: Pages = {
    id: '12345679',
    accessToken: 'zjdfhjkgfhdsgfhjdghfgdshgfsdhjgf',
    instagram: [
        {
            label: 'instagram',
            uid: '15424982100',
            picture: 'https://fr.gravatar.com/userimage/99586743/2cf79c87a5a2cbc9112e28236a5760cd.jpg?size=400',
            username: 'cyberlife.music',
            name: 'Cyberlife',
            displayName: 'Cyberlife',
        },
        {
            label: 'instagram',
            uid: '11294382218',
            picture: 'https://www.biography.com/.image/t_share/MTE1ODA0OTcxODA5NzM2MjA1/mike-tyson-9512980-2-402.jpg',
            username: 'miketyson',
            name: 'Mike Tyson',
            displayName: 'Mike Tyson',
        },
    ],
    facebook: [],
};

export class FacebookBusinessConnectMock extends FacebookBusinessConnect {
    /* one chance on six to have a failing data */
    dice = () => {
        const value = Math.ceil(Math.random() * 6);
        return value === 6;
    };

    /* bypass fb connection and return data like a working fb call */
    once = (event: string, fnc: (...args) => this) => {
        switch (event) {
            case ON_ACCOUNT_DATAS: {
                let data = {
                    instagram: fbcMocks.instagram,
                };
                if (this.dice()) {
                    data = {
                        instagram: [],
                    };
                }
                setTimeout(() => {
                    fnc(data);
                }, 1250);
                break;
            }
            default:
        }
        return this;
    };

    requestDatas = () => {
        console.log('data requested');
    };
}

export default FacebookBusinessConnectMock;
