import { Networks } from 'constants/networks';

export enum TimeRange {
    oneMonth = 'oneMonth',
    threeMonths = 'threeMonths',
    sixMonths = 'sixMonths',
    oneYear = 'oneYear',
    all = 'all',
}

export interface SocialNetworksFilter {
    [socialNetworkName: string]: {
        id: number;
        uid: string;
        label: Networks;
        selected: boolean;
    };
}

export interface GlobalFilters {
    timeRangeFilter: TimeRange;
    socialNetworksFilter: SocialNetworksFilter;
}

export interface SortFilters {
    selectedOption: string;
    options: string[];
}

export interface SNATopPostsParams {
    snaId: string;
    timeRange: TimeRange;
}

export interface AllMessagesParams {
    conversationId: number | string;
    page?: number;
    perPage: number;
}
