import React, { useState, useMemo, useCallback, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Button, Loader } from '@brandandcelebrities/kolkit';

import { KOL_MINIMUM_AGE } from 'config/env';
import { updateField, loadProfile, saveProfile } from 'actions/profile';
import { TYPES } from 'components/atoms/FormField/FormField';
import Form from 'components/molecules/Form/Form';
import StepperHeader from 'components/molecules/StepperHeader';
import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';
import { useLexique, useSelector, useDispatch } from 'utils/redux';
import { callGAEvent } from 'utils/googleAnalytics';

import styles from '../Steps.module.scss';
import FullNameAndStageName from './FullNameAndStageName';

const FIELDSET = [
    {
        field: 'gender',
        type: TYPES.GENDER,
    },
    {
        field: 'birthdate',
        type: TYPES.DATE,
        options: {
            minDate: moment().subtract(100, 'year'),
            maxDate: moment().subtract(KOL_MINIMUM_AGE, 'years'),
        },
    },
    {
        field: 'nationality',
        type: TYPES.NATIONALITY,
    },
    { field: 'livingAddress', type: TYPES.CITY },
];

const REQUIRED_FIELDS = ['gender', 'birthdate', 'nationality'];

interface Props {
    onClickNext: () => void;
}

const StepInfos: React.FC<Props> = ({ onClickNext }) => {
    const [state, setState] = useState({ saving: false, hasError: false, errors: {} });
    const lexique: OnboardingPageLexique = useLexique('containers.onboardingPage');
    const dispatch = useDispatch();
    const { form, loading, locale } = useSelector(({ env, profile }) => {
        return {
            locale: env.locale,
            form: profile.profile,
            loading: profile.loading,
        };
    });

    useEffect(() => {
        // Load profile
        dispatch(loadProfile());
    }, [dispatch]);

    const checkFormError = useCallback((formData) => {
        const noNameSpecified = !(
            (formData.firstName.trim() !== '' && formData.lastName.trim() !== '') ||
            formData.stageName.trim() !== ''
        );
        const requiredFields = REQUIRED_FIELDS.filter(
            (fieldName) =>
                !formData[fieldName] ||
                (Array.isArray(formData[fieldName])
                    ? formData[fieldName].length === 0
                    : formData[fieldName].trim().length === 0),
        );
        const noCity = !formData.livingAddress?.mapped?.g_formatted_address;
        return {
            noNameSpecified,
            requiredFields,
            noCity,
            hasError: noNameSpecified || noCity || requiredFields.length > 0,
        };
    }, []);

    const handleBlur = useCallback(
        (fieldName) => (): void => {
            const { hasError, requiredFields, noCity } = checkFormError(form);
            if (hasError) {
                setState((prev) => ({
                    ...prev,
                    hasError: true,
                    errors: {
                        ...prev.errors,
                        [fieldName]: requiredFields.includes(fieldName),
                        livingAddress:
                            fieldName === 'livingAddress' && noCity ? lexique.steps.infos.errors.required : null,
                    },
                }));
            } else {
                setState((prev) => ({ ...prev, hasError: false, errors: {} }));
            }
        },
        [checkFormError, lexique, form],
    );

    const handleChange = useCallback(
        (name: string, value: string) => {
            dispatch(updateField({ field: name, value }));
        },
        [dispatch],
    );

    const handleClickSave = useCallback(async () => {
        if (state.hasError) {
            return null;
        }
        setState((prev) => ({ ...prev, saving: true }));
        await dispatch(saveProfile(form, 2));
        setState((prev) => ({ ...prev, saving: false }));
        callGAEvent('Onboarding', 'step_2_validated');
        return onClickNext();
    }, [form, state.hasError, dispatch, onClickNext]);

    const nextDisabled = useMemo(() => checkFormError(form).hasError || loading || state.saving, [
        form,
        loading,
        state.saving,
        checkFormError,
    ]);

    const namesErrorsLexique = useMemo(
        () => ({
            stageName: lexique.steps.infos.errors.requiredNameOrPseudo,
            firstNameOrlastName: lexique.steps.infos.errors.required,
        }),
        [lexique],
    );

    const cnForm = cn(styles.form, styles.onStepInfos);

    return (
        <div>
            <StepperHeader header={lexique.header} title={lexique.steps.infos.title} className={styles.header} />
            <FullNameAndStageName
                placeholders={lexique.steps.infos.placeholders}
                values={form}
                onChange={handleChange}
                disabled={loading || state.saving}
                errorLexique={namesErrorsLexique}
            />
            <Form
                fields={FIELDSET}
                placeholders={lexique.steps.infos.placeholders}
                className={cnForm}
                onChange={handleChange}
                values={form}
                locale={locale}
                disabled={loading || state.saving}
                errors={state.errors}
                onBlur={handleBlur}
            />
            <Button fullWidth size="fat" className={styles.button} disabled={nextDisabled} onClick={handleClickSave}>
                {lexique.next}
            </Button>
            {(loading || state.saving) && <Loader full className={styles.loader} />}
        </div>
    );
};

export default StepInfos;
