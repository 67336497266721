import { delay, all, call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import * as ENV from 'constants/env';
import * as ANALYTICS from 'constants/analytics';

import {
    fetchCommunityFailure,
    fetchCommunitySuccess,
    fetchHeaderFailure,
    fetchHeaderSuccess,
    fetchPostsFailure,
    fetchPostsSuccess,
    fetchSnaDataChartFailure,
    fetchSnaDataChartSuccess,
    fetchPostsTopHashtagsSuccess,
    fetchPostsTopHashtagsFailure,
    fetchTopFlopPostsSuccess,
    fetchTopFlopPostsFailure,
} from 'actions/analytics';
import { GlobalFilters, TimeRange } from 'types/Filters';
import api from '../api';

const BUFFERING_TIME = 500; // ms

export function* watchAnalyticsPage() {
    yield all([
        fork(watchAnalyticsLoad),
        fork(watchAnalyticsLoadPosts),
        fork(watchAnalyticsFilters),
        fork(watchAnalyticsPagination),
        fork(watchAnalyticsLoadCommunity),
        fork(watchAnalyticsLoadStatistics),
        fork(watchAnalyticsLoadPostsTopHashtags),
        fork(watchAnalyticsLoadTopFlopPosts),
    ]);
}

export function* watchAnalyticsLoad() {
    const action = yield take([ENV.FETCH_APP_INIT_SUCCESS, ANALYTICS.LOAD]);
    if (action.type === ANALYTICS.LOAD) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        yield take(ANALYTICS.LOAD);
    }

    yield fork(fetchHeaderSection);
}

export function* watchAnalyticsLoadPosts() {
    const action = yield take([ENV.FETCH_APP_INIT_SUCCESS, ANALYTICS.LOAD_POSTS]);
    if (action.type === ANALYTICS.LOAD_POSTS) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        yield take(ANALYTICS.LOAD_POSTS);
    }

    const { env } = yield select();
    const { globalFilters, searchCard } = yield select((state) => state.ui.analytics.posts);
    const snas = yield select((state) => state.user.snas);

    yield fork(fetchPostsSection, env.userToken, snas, globalFilters, searchCard);

    yield takeEvery(ANALYTICS.LOAD_POSTS, function* () {
        const { globalFilters, searchCard } = yield select((state) => state.ui.analytics.posts);
        const { env } = yield select();
        const snas = yield select((state) => state.user.snas);

        yield fork(fetchPostsSection, env.userToken, snas, globalFilters, searchCard);
    });
}

export function* watchAnalyticsLoadPostsTopHashtags() {
    yield takeEvery(ANALYTICS.FETCH_POSTS_TOP_HASHTAGS, function* () {
        const { globalFilters } = yield select((state) => state.ui.analytics.posts);
        yield fork(fetchPostsTopHashtags, globalFilters);
    });
}

export function* watchAnalyticsLoadTopFlopPosts() {
    yield takeEvery(ANALYTICS.LOAD_TOP_FLOP_POSTS, function* () {
        const { globalFilters } = yield select((state) => state.ui.analytics.posts);
        yield fork(fetchTopFlopPosts, globalFilters);
    });
}

export function* watchAnalyticsFilters() {
    const action = yield take([ENV.FETCH_APP_INIT_SUCCESS, ANALYTICS.LOAD_POSTS]);
    if (action.type === ANALYTICS.LOAD_POSTS) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        yield take(ANALYTICS.LOAD_POSTS);
    }

    let task;
    let tophashtagTask;
    let topFlopTask;
    yield takeEvery(
        [
            ANALYTICS.ON_CHANGE_SORT_FILTER,
            ANALYTICS.ON_CHANGE_SOCIAL_NETWORKS_FILTER,
            ANALYTICS.ON_CHANGE_TIME_RANGE_FILTER,
            ANALYTICS.ON_CHANGE_SEARCH,
        ],
        function* () {
            if (task) {
                yield cancel(task);
            }
            if (tophashtagTask) {
                yield cancel(tophashtagTask);
            }
            if (topFlopTask) {
                yield cancel(topFlopTask);
            }

            const { globalFilters, searchCard } = yield select((state) => state.ui.analytics.posts);
            const { env } = yield select();
            const snas = yield select((state) => state.user.snas);
            task = yield fork(fetchPostsSection, env.userToken, snas, globalFilters, searchCard);
            tophashtagTask = yield fork(fetchPostsTopHashtags, globalFilters);
            topFlopTask = yield fork(fetchTopFlopPosts, globalFilters);
        },
    );
}

function* fetchPostsSection(token, snas, globalFilters: GlobalFilters, searchCard) {
    yield delay(BUFFERING_TIME);

    try {
        const fetchPostsResponse = yield call(api.fetchPosts, token, snas, globalFilters, searchCard);
        yield put(fetchPostsSuccess({ posts: fetchPostsResponse.data }));
    } catch (error) {
        yield put(fetchPostsFailure(error));
    }
}

export function* watchAnalyticsPagination() {
    let task;
    yield takeEvery([ANALYTICS.ON_CHANGE_PAGE], function* () {
        if (task) {
            yield cancel(task);
        }

        const { globalFilters, searchCard, page } = yield select((state) => state.ui.analytics.posts);
        const { env } = yield select();
        const snas = yield select((state) => state.user.snas);

        task = yield fork(fetchPostsSectionWithPage, env.userToken, snas, globalFilters, searchCard, page);
    });
}

function* fetchPostsSectionWithPage(token, snas, globalFilters, searchCard, page) {
    try {
        const fetchPostsResponse = yield call(api.fetchPosts, token, snas, globalFilters, searchCard, page);
        yield put(fetchPostsSuccess({ posts: fetchPostsResponse.data }));
    } catch (error) {
        yield put(fetchPostsFailure(error));
    }
}

export function* fetchPostsTopHashtags(globalFilters) {
    const { isEmailConfirmed, isTrialOver } = yield select((state) => state.user);
    if (!isEmailConfirmed && isTrialOver) return false;

    try {
        const response = yield call(api.fetchPostsTopHashtags, globalFilters);

        yield put(fetchPostsTopHashtagsSuccess({ topHashtags: response.data }));
    } catch (error) {
        yield put(fetchPostsTopHashtagsFailure(error));
    }
}

export function* fetchTopFlopPosts(globalFilters) {
    try {
        const response = yield call(api.fetchTopFlopPosts, globalFilters);

        yield put(fetchTopFlopPostsSuccess({ posts: response.data }));
    } catch (error) {
        console.log(error);
        yield put(fetchTopFlopPostsFailure(error));
    }
}

function* fetchHeaderSection() {
    try {
        const fetchHeaderResponse = yield call(api.fetchHeader);
        yield put(fetchHeaderSuccess({ ...fetchHeaderResponse.data }));
    } catch (error) {
        yield put(fetchHeaderFailure(error));
    }
}

export function* watchAnalyticsLoadCommunity() {
    const action = yield take([ENV.FETCH_APP_INIT_SUCCESS, ANALYTICS.LOAD_COMMUNITY]);
    if (action.type === ANALYTICS.LOAD_COMMUNITY) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        yield take(ANALYTICS.LOAD_COMMUNITY);
    }

    yield fork(fetchCommunitySection);
    yield takeEvery(ANALYTICS.LOAD_COMMUNITY, function* () {
        yield fork(fetchCommunitySection);
    });
}

function* fetchCommunitySection() {
    try {
        const fetchCommunityResponse = yield call(api.fetchCommunity);
        yield put(fetchCommunitySuccess({ community: fetchCommunityResponse.data }));
    } catch (error) {
        yield put(fetchCommunityFailure(error));
    }
}

export function* watchAnalyticsLoadStatistics() {
    const action = yield take([ENV.FETCH_APP_INIT_SUCCESS, ANALYTICS.LOAD_STATISTICS]);
    if (action.type === ANALYTICS.LOAD_STATISTICS) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        yield take(ANALYTICS.LOAD_STATISTICS);
    }

    yield fork(fetchSnasDataChart);

    let task;
    yield takeEvery(ANALYTICS.ON_CHANGE_TIME_FILTER, function* () {
        if (task) {
            yield cancel(task);
        }
        task = yield fork(fetchSnasDataChart);
    });
}

export function* fetchSnasDataChart() {
    const { user } = yield select();
    const filter = yield select((state) => state.ui.analytics.stats.filter);
    const requests = user.snas.map((sna) => fork(fetchSnaDataChartSection, sna.uid, sna.label, sna.username, filter));
    yield all(requests);
}

function* fetchSnaDataChartSection(uid: string, label: string, username: string, filter: TimeRange) {
    try {
        const fetchSnaDataChartResponse = yield call(api.fetchSnaDataChart, uid, filter);
        yield put(
            fetchSnaDataChartSuccess({
                ...fetchSnaDataChartResponse.data,
                uid,
                label,
                username,
            }),
        );
    } catch (error) {
        yield put(fetchSnaDataChartFailure(error));
    }
}
