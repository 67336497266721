import {
    ON_ACCOUNT_DATA,
    FETCH_DATA,
    ON_ACCOUNT_ERROR,
    SELECT_PAGE,
    TOGGLE_MODAL,
    ON_CONNECT,
    RESET,
    ON_LOGIN_STATUS_LOADED,
} from 'constants/fbc';
import { createAction } from 'redux-actions';
import { SelectPageActionPayload } from 'reducers/fbc';
import { Pages } from 'types/Instaconnect';

export const fetchFbc = createAction(FETCH_DATA);
export const onAccountDataReceived = createAction(ON_ACCOUNT_DATA, (pages: Pages) => pages);
export const onSelect = createAction(SELECT_PAGE, (data: SelectPageActionPayload) => data);
export const onError = createAction(ON_ACCOUNT_ERROR, (error: string | unknown) => error);
export const toggleModal = createAction(TOGGLE_MODAL, (data: boolean) => data);
export const onConnect = createAction(ON_CONNECT);
export const reset = createAction(RESET);
export const setFbcLoginStatus = createAction(ON_LOGIN_STATUS_LOADED, (status: string) => status);

