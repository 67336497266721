import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';

import * as types from 'constants/sna';
import getLexique from 'locales';
import { SNAAPIData } from 'types/snas';
import { fetchStatsSharing } from 'actions/user';
import { mapSnas, mapSna } from 'mappers/snas';

import { showModale, hideModale } from './modale';

export const fetchSnasSuccess = createAction(types.FETCH_SNAS_SUCCESS, (data: SNAAPIData[]) => mapSnas.fromApi(data));
export const fetchSnasFailure = createAction(types.FETCH_SNAS_FAILURE, (error) => ({ error }));

export const fetchSnaSuccess = createAction(types.FETCH_SNA_SUCCESS, (data) => mapSna.fromApi(data));
export const fetchSnaFailure = createAction(types.FETCH_SNA_FAILURE, (error) => ({ error }));

export const removeSna = createAction(types.REMOVE_SNA, (data) => data);
export const removeSnaSuccess = createAction(types.REMOVE_SNA_SUCCESS, (data) => data);
export const removeSnaFailure = createAction(types.REMOVE_SNA_FAILURE, (error) => ({ error }));

export const confirmRemoveSna = (data) => async (dispatch) => {
    dispatch(
        showModale({
            body: 'RemoveSocialMedia',
            primaryAction: () => {
                dispatch(removeSna(data));
                dispatch(fetchStatsSharing());
                dispatch(hideModale());
            },
            secondaryAction: () => dispatch(hideModale()),
            data,
        }),
    );
};

export const displayRemoveToast = (network, locale) => {
    const lexique = getLexique(locale).components.toasts.removeSocialMedia;
    const lNetworks = getLexique(locale).networks;

    toast(lexique.text.replace('{{0}}', lNetworks[network]));

    // GA
    window.ga('send', 'event', {
        eventCategory: 'Account',
        eventAction: `Connect`,
        eventLabel: `${network} Disconnect`,
    });
};

export const displayAddToast = (network, locale) => {
    const lexique = getLexique(locale).components.toasts.addSocialMedia;
    const lNetworks = getLexique(locale).networks;

    toast(lexique.text.replace('{{0}}', lNetworks[network]));
};

export const loadSnaTopPosts = createAction(types.LOAD_SNA_TOP_POSTS);
export const fetchSnaTopPostsSuccess = createAction(types.FETCH_SNA_TOP_POSTS_SUCCESS);
export const fetchSnaTopPostsFailure = createAction(types.FETCH_SNA_TOP_POSTS_FAILURE, (error, snaId) => ({
    error,
    snaId,
}));
