export interface Routes {
    login: string;
    register: string;
    analytics: {
        index: string;
        audience: string;
        content: string;
        partner: string;
    };
    settings: string;
    profile: string;
    propositions: string;
    conversations: string;
    faq: string;
    onboarding: string;
    unconfirmedEmail: string;
}

export interface QueryParams {
  reconnectInstagram: string;
}

export const routes: Routes = {
    login: '/login',
    register: '/register',
    analytics: {
        index: '/statistics',
        audience: '/audience',
        content: '/content',
        partner: '/partenariat',
    },
    settings: `/settings`,
    profile: `/profile`,
    propositions: '/propositions',
    conversations: '/messaging',
    faq: '/stats-instagram-FAQ',
    onboarding: '/onboarding',
    unconfirmedEmail: '/unconfirmed-email',
};

export const queryParameters: QueryParams = {
  reconnectInstagram: 'reconnect-instagram',
};

export default routes;
