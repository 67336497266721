import React, { useEffect, memo, useCallback, useRef } from 'react';
import isEmpty from 'lodash.isempty';
import { Helmet } from 'react-helmet';

import InfoCircle from '@brandandcelebrities/icons/regular/InfoCircle';

import { onChangePage, loadPosts, fetchPostsTopHashtags, loadTopFlopPosts } from 'actions/analytics';
import { SocialStatsList } from 'components/templates';
import getLexique from 'locales';
import { AppState } from 'reducers';
import { useSelector, useDispatch } from 'utils/redux';

import desktopPostsSearch from 'assets/images/analytics/posts/desktop_posts_search.png';
import desktopPostsList from 'assets/images/analytics/posts/desktop_posts_list.png';

import SearchCard from './SearchCard';
import PostsList from './PostsList';
import Pagination from './Pagination';
import TotalChart from './TotalChart';
import AverageChart from './AverageChart';
import PlaceholderInfo from '../PlaceholderInfo';

import GlobalFilters from './GlobalFilters';
import TopHashtags from './TopHashtags';
import TopFlopPosts from './TopFlopPosts';

import './Posts.scss';

const Posts = () => {
    const dispatch = useDispatch();
    const scrollToRef = useRef(null);

    const changePage = useCallback(
        (page) => {
            dispatch(onChangePage(page));

            if (scrollToRef) {
                scrollToRef.current.scrollIntoView({
                    behavior: 'smooth',
                });
            }

            // Google Analytics
            window.ga('send', 'event', {
                eventCategory: 'Posts',
                eventAction: `Navigate`,
                eventLabel: `Page ${page}`,
            });
        },
        [dispatch],
    );

    const { lexique, total, snas, page, stats, hashtags, topFlopPosts, topHashtagLexique, device } = useSelector(
        (state: AppState) => ({
            lexique: getLexique(state.env.locale).containers.analyticsPage.posts,
            topHashtagLexique: getLexique(state.env.locale).containers.analyticsPage.posts.charts.topHashtags,
            total: state.posts.all.total,
            stats: state.posts.all.stats,
            page: state.ui.analytics.posts.page,
            snas: state.user.snas,
            hashtags: state.ui.analytics.hashtags,
            topFlopPosts: state.ui.analytics.topFlopPosts,
            device: state.env.device.type,
        }),
    );

    const Placeholders = memo(() => {
        if (!isEmpty(snas)) return null;
        const placeholders = [
            'mobile_posts_search',
            'post_empty_card_IN',
            'post_empty_card_IN',
            'post_empty_card_TW',
            'post_empty_card_YT',
            'post_empty_card_IN',
            'post_empty_card_TW',
            'post_empty_card_YT',
            'mobile_posts_pagination',
        ];
        if (device === 'mobile') {
            return (
                <>
                    <PlaceholderInfo text={lexique.placeholder} />
                    {placeholders.map((placeholder, index) => (
                        <img
                            key={`${placeholder}${index}`}
                            className="bc-analytics-posts__placeholder"
                            src={`/imgs/analytics/posts/${placeholder}.png`}
                            alt=""
                        />
                    ))}
                </>
            );
        }
        return (
            <>
                <PlaceholderInfo text={lexique.placeholder} />
                <img
                    className="bc-analytics-posts__placeholder"
                    src={desktopPostsSearch}
                    alt=""
                />
                <img
                    className="bc-analytics-posts__placeholder"
                    src={desktopPostsList}
                    alt=""
                />
            </>
        );
    });

    useEffect(() => {
        dispatch(loadPosts());
        dispatch(fetchPostsTopHashtags());
        dispatch(loadTopFlopPosts());
    }, [dispatch]);

    return (
        <div className="bc-analytics-posts">
            <Helmet>
                <title>{lexique.headTitle}</title>
            </Helmet>

            <div className="bc-analytics-stats__title">{lexique.title}</div>
            <SocialStatsList />
            <GlobalFilters />
            <TopHashtags hashtags={hashtags} lexique={topHashtagLexique} />
            <TopFlopPosts posts={topFlopPosts} />
            {!isEmpty(snas) && (
                <div className="bc-analytics-posts__all" ref={scrollToRef}>
                    <header className="bc-content-header">
                        <span className="bc-content-header_title">
                            <h3>{lexique.searchCard.title}</h3>
                        </span>
                    </header>

                    <SearchCard />
                    {total === 0 && (
                        <div className="bc-analytics-posts__empty-placeholder">
                            <div className="bc-analytics-posts-empty-placeholder__icon">
                                <InfoCircle />
                            </div>
                            <div className="bc-analytics-posts-empty-placeholder__text">
                                {lexique.emptySearchPlaceholder}
                            </div>
                        </div>
                    )}
                    {!isEmpty(stats) && total > 0 && (
                        <div className="bc-analytics-posts__graphs">
                            <div className="bc-analytics-posts__graph-container">
                                <TotalChart />
                            </div>
                            <div className="bc-analytics-posts__graph-container">
                                <AverageChart />
                            </div>
                        </div>
                    )}
                    <div className="bc-analytics-posts__posts-list-container">
                        <PostsList />
                    </div>
                    <div className="bc-analytics-posts__pagination-container">
                        <Pagination currentPage={page} totalItems={total} itemsPerPage={12} onChange={changePage} />
                    </div>
                </div>
            )}
            <Placeholders />
        </div>
    );
};

export default memo(Posts);
