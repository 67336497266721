import Cookies from 'universal-cookie';
import moment from 'moment';
import { TinyEmitter } from 'tiny-emitter';
import { PageApi } from 'types/Instaconnect';
import { conf } from 'config/env';
import { Networks } from 'types/snas.d';

const CLIENT_ID = conf.fbc.clientId;
const HOURS_BEFORE_CHECK = 24;
const COOKIE_NAME = '__bnc_facebook_refresh_tokens_at';

class FacebookBusinessConnect extends TinyEmitter {
  hasConnectedSnas: boolean;

  tokenWillExpire: boolean;

  status: string;

  accessToken: string;

  constructor(props) {
    super();
    this.hasConnectedSnas = props && props.hasConnectedSnas;
    this.tokenWillExpire = props && props.tokenWillExpire;
    this.status = '';

    if (window.fbAsyncInit) {
      if (window.FB) {
        this.attachEvents();
      }
      return null;
    }

    window.fbAsyncInit = () => this.loadSDKComplete();

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return true;
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.async = true;
      js.defer = true;
      js.crossOrigin = 'anonymous';
      js.src = 'https://connect.facebook.net/fr_FR/sdk.js';
      if (fjs) fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadSDKComplete = () => {
    console.info('loadSDKComplete', JSON.stringify(window.FB));
    const { FB } = window;

    if (!FB) return null;

    FB.init({
      appId: CLIENT_ID,
      cookie: true,
      xfbml: true,
      version: 'v21.0',
    });
    FB.getLoginStatus(this.getLoginStatus);
  };

  getLoginStatus = (response) => {
    console.info('getLoginStatus', response);
    this.emit(ON_STATUS_CHANGE, response.status);
    this.status = response.status;
    switch (response.status) {
      case 'connected':
        // eslint-disable-next-line
        const { accessToken, userID } = response.authResponse;
        console.info('getLoginStatus.connected', accessToken, userID);
        this.accessToken = accessToken;

        this.emit(ON_CONNECTED, { accessToken, userID });
        this.checkIfNeedToResendTokens();
        break;
      case 'not_authorized':
      case 'unknown':
      default:
        console.info(`getLoginStatus.${response.status}`, response.authResponse);
        this.emit(ON_DISCONNECTED, { reason: response.status });
        this.checkIfShouldAskToReconnect();
    }
  };

  checkIfShouldAskToReconnect = () => {
    // If not logged and, we have at least one connected sna and a flag that at least one token will expire => we ask for login again
    if (this.hasConnectedSnas && this.tokenWillExpire) {
      console.info('checkIfShouldAskToReconnect > YES');
      this.emit(SHOULD_RECONNECT);
      return true;
    }
    console.info('checkIfShouldAskToReconnect > NO');
  };

  checkIfNeedToResendTokens = () => {
    const cookie = new Cookies();
    const cookieValue = cookie.get(COOKIE_NAME);

    if (!cookieValue) {
      // Check for accounts
      console.info('checkIfNeedToResendTokens > YES');
      this.refreshTokens();
      return true;
    }

    const expireAt = moment(cookieValue).unix();
    const now = moment().unix();

    if (now >= expireAt) {
      // Check for accounts
      console.info('checkIfNeedToResendTokens > YES');
      this.refreshTokens();
      return true;
    }

    console.info('checkIfNeedToResendTokens > NO');
  };

  setNextRefreshCookie = () => {
    const to = moment().add(HOURS_BEFORE_CHECK, 'hours').format();
    console.info('setNextRefreshCookie', to);
    const cookie = new Cookies();
    cookie.set(COOKIE_NAME, to);
  };

  getInstagramData = ({ businessId }): Promise<PageApi> => {
    const { FB } = window;
    return new Promise((resolve) => {
      FB.api(`/${businessId}/?fields=name,username,followers_count,profile_picture_url,ig_id`, (response) => {
        console.info('getInstagramData', `/${businessId}`, response);
        resolve(response);
      });
    });
  };

  reinit = () => {
    this.doLogout();
    this.requestLogin();
  };

  checkPagesAndAccounts = (fromAuth) => {
    const { FB } = window;
    FB.api(
      '/me?fields=accounts{access_token,instagram_business_account,username,picture{url},fan_count,name,category},id',
      async (response) => {
        console.info('checkPagesAndAccounts /me', response);

        if (response.error) {
          console.info('checkPagesAndAccounts error', response.error);
          this.reinit();
          return true;
        }

        if (!response.accounts || (response.accounts && !response.accounts.data)) {
          if (fromAuth) {
            this.emit(ON_ACCOUNT_DATAS, {
              instagram: [],
              facebook: [],
              id: response.id,
              accessToken: this.accessToken,
            });
          } else {
            this.reinit();
          }
          return true;
        }

        const facebookPages = response.accounts.data.map((d) => ({
          label: Networks.facebook,
          picture: d.picture.data.url,
          uid: d.id,
          accessToken: d.access_token,
          name: d.name,
          communityCount: d.fan_count,
          category: d.category,
        }));

        if (!facebookPages?.length && !fromAuth) {
          this.reinit();
          return true;
        }

        console.info('checkPagesAndAccounts', response.accounts.data);

        const instagramTempPages = response.accounts.data
          .map((d) => {
            console.info('instagramTempPages', d);
            if (d?.instagram_business_account?.id) {
              return {
                label: Networks.instagram,
                accessToken: d.access_token,
                businessId: d.instagram_business_account.id,
                facebookPageId: d.id,
              };
            }
            return null;
          })
          .filter((f) => f);

        if (!instagramTempPages.length && !fromAuth) {
          this.reinit();
          return true;
        }

        const responsesInstagram: PageApi[] = await Promise.all(
          instagramTempPages.map((instagramTempPage) => this.getInstagramData(instagramTempPage)),
        );

        const instagramPages = instagramTempPages.map((i) => {
          const response = responsesInstagram.find((j) => j.id === i.businessId);
          return response
            ? {
                ...i,
                communityCount: response.followers_count,
                username: response.username,
                name: response.name,
                picture: response.profile_picture_url,
                uid: response.ig_id.toString(),
              }
            : { ...i };
        });

        console.info('facebookPages', facebookPages);
        console.info('instagramPages', instagramPages);

        this.emit(ON_ACCOUNT_DATAS, {
          instagram: instagramPages,
          facebook: facebookPages,
          id: response.id,
          accessToken: this.accessToken,
        });

        this.setNextRefreshCookie();
      },
    );
  };

  refreshTokens = (isForce?) => {
    const { FB } = window;
    FB.api('/me?fields=accounts{access_token,instagram_business_account{id,ig_id},id},id', async (response) => {
      console.info(
        'refreshTokens /me?fields=accounts{access_token,instagram_business_account{id,ig_id},id},id',
        response,
      );

      if (response.error) {
        if (isForce) {
          this.emit(ON_REFRESHED_TOKENS_ERROR, {
            status: this.status,
          });
          return true;
        }
        this.reinit();
        return true;
      }

      if (!response.accounts?.data) {
        this.emit(ON_REFRESHED_TOKENS, {
          tokens: [],
          id: response.id,
          accessToken: this.accessToken,
          instagramBusinessId: '',
        });
        return false;
      }

      const tokens = response.accounts.data.map((d) => ({
        accessToken: d.access_token,
        uid: d.instagram_business_account?.ig_id?.toString(),
        instagramBusinessId: d.instagram_business_account?.id,
      }));

      console.info('tokens', tokens);

      this.emit(ON_REFRESHED_TOKENS, {
        tokens,
        id: response.id,
        accessToken: this.accessToken,
      });

      this.setNextRefreshCookie();
    });
  };

  attachEvents = () => {
    const { FB } = window;
    FB.getLoginStatus(this.getLoginStatus);
  };

  doLogout = () => {
    console.info('doLogout');
    this.status = 'unknown';
  };

  requestLogin = () => {
    const { FB } = window;
    if (!FB) {
      // eslint-disable-next-line
      console.warn('Facebook SDK is not loaded');
      return null;
    }
    FB.login(this.onResponseLogin, {
      scope: 'catalog_management,pages_show_list,business_management,instagram_basic,instagram_manage_insights,pages_read_engagement',
    });
  };

  requestLogout = (callback) => {
    const { FB } = window;
    FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        FB.logout((response) => {
          if (callback) callback(response);
        });
      }
    });
  };

  onResponseLogin = (response) => {
    console.info('onResponseLogin', JSON.stringify(response));
    this.getLoginStatus(response);
    if (response.status === 'connected' && response.authResponse && response.authResponse.accessToken) {
      this.checkPagesAndAccounts(true);
    } else {
      this.emit(ON_ACCOUNT_DATAS, {
        instagram: [],
        facebook: [],
        id: response.id,
        accessToken: null,
      });
    }
  };

  requestDatas = () => {
    console.info('requestDatas');
    if (this.status === '') {
      // eslint-disable-next-line
      console.warn(
        `User's Facebook status is not defined, please use this function after event ON_STATUS_CHANGE`,
      );
    }

    // Force to redirect facebook login or params modale to fix bad selected instaconnect
    this.requestLogin();
  };
}

export const ON_STATUS_CHANGE = 'facebookBusinessConnect::onStatusChange';
export const ON_CONNECTED = 'facebookBusinessConnect::status::connected';
export const ON_DISCONNECTED = 'facebookBusinessConnect::status::notConnected';
export const SHOULD_RECONNECT = 'facebookBusinessConnect::shouldReconnection';
export const ON_REFRESHED_TOKENS = 'facebookBusinessConnect::onRefreshedTokens';
export const ON_REFRESHED_TOKENS_ERROR = 'facebookBusinessConnect::onRefreshedTokens::error';
export const ON_ACCOUNT_DATAS = 'facebookBusinessConnect::onAccountDatas';

/*
const info = (...obj) => {
    // eslint-disable-next-line
    if (DEBUG) console.info(...obj);
}
*/

export default FacebookBusinessConnect;
