import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as PROPOSITION_ANSWER_MOBILE from 'constants/propositionAnswerMobile';
import { AnyAction } from 'redux';

export interface PropositionAnswerMobileState {
    modal: {
        display: boolean;
    };
}

export interface PropositionPageState {
    propositionAnswerMobile: PropositionAnswerMobileState;
}

const initialState: PropositionPageState = {
    propositionAnswerMobile: {
        modal: {
            display: false,
        },
    },
};

const reducers = {
    [PROPOSITION_ANSWER_MOBILE.MODAL_OPEN]: (state: PropositionPageState): PropositionPageState =>
        update(state, {
            propositionAnswerMobile: {
                modal: { $merge: { display: true } },
            },
        }),
    [PROPOSITION_ANSWER_MOBILE.MODAL_CLOSE]: (state: PropositionPageState): PropositionPageState =>
        update(state, {
            propositionAnswerMobile: {
                modal: { $merge: { display: false } },
            },
        }),
};

export default createReducer<PropositionPageState, AnyAction>(initialState, reducers);
