import { createReducer } from 'redux-create-reducer';
import update from 'immutability-helper';

import * as MODALE from 'constants/modale';
import { AnyAction } from 'redux';

export interface ModaleState {
    title?: string | JSX.Element;
    primaryAction?: () => void;
    primaryTitle?: string;
    secondaryAction?: () => void;
    secondaryTitle?: string;
    onCloseAction?: () => void;
    show: boolean;
    body?: string;
    data?: object;
    isAlert?: boolean;
    disableBackdropClick?: boolean;
    disableEscapeKeyDown?: boolean;
    maxWidth?: string;
    iconTitle?: string;
    disableFullScreen?: boolean;
    modalStack: ModaleState[];
}

const initialState: ModaleState = {
    show: false,
    body: '',
    title: null,
    modalStack: [],
};

const reducers = {
  [MODALE.SHOW__MODALE]: (state: ModaleState, { payload }: AnyAction) =>
    update(state, {
      $merge: {
        show: true,
        ...payload,
      },
      ...(state.show &&
        state.body && {
          modalStack: {
            $push: [state],
          },
        }),
    }),
  [MODALE.HIDE__MODALE]: (state: ModaleState) =>
    update(state, {
      ...(state.modalStack.length === 0
        ? {
            $set: {
              ...initialState,
            },
          }
        : {
            $apply: () => {
              const previousState = state.modalStack[state.modalStack.length - 1];
              const newStack = [...state.modalStack];
              newStack.pop(); // remove last inserted element

              return {
                ...previousState,
                show: true,
                modalStack: newStack,
              };
            },
          }),
    }),
  [MODALE.UPDATE_MODALE]: (state: ModaleState, { payload }: AnyAction) =>
    update(state, {
      $merge: payload,
    }),
};

export default createReducer<ModaleState, AnyAction>(initialState, reducers);
