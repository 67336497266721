/* eslint-disable import/no-unresolved */
import { CANCELED, CONTACTED, REFUSED, SIGNED, VALIDATED } from 'constants/proposition';

export default {
    title: `Mes Propositions`,
    empty: {
        title: `Vous n’avez pas encore reçu de proposition.`,
        mobileTitle: `Pour augmenter vos chances d'être vu :`,
        body: `Afin d’augmenter votre visibilité sur notre moteur de recherche et de nous permettre de vous proposer les projets les plus à même de vous correspondre :`,
        bullet1: `1. Connectez vos réseaux sociaux.`,
        bullet2: `2. Renseignez votre profil.`,
        tagline: `On s’occupe du reste !`,
    },
    fields: {
        budget: {
            label: `Budget :`,
            empty: `A définir`,
        },
        date: `Date :`,
        fromTo: `{0} au {1}`,
        networks: `Réseaux :`,
    },
    states: {
        [CONTACTED]: `En attente de réponse`,
        [VALIDATED]: `Accepté`,
        [SIGNED]: `Signé`,
        [CANCELED]: `Annulé`,
        [REFUSED]: `Refusé`,
    },
};
