import PropositionAnswerCardLexique from 'locales/types/containers/propositionPage/propositionAnswerCard';

export const propositionAnswerCardLexique: PropositionAnswerCardLexique = {
    title: `Your answer:`,
    form: {
        textarea: {
            placeholder: `Do not hesitate to comment if you need additionnal informations`,
            error: `This field is required`,
        },
        title: `Interested ?`,
        buttons: {
            yes: `Yes`,
            no: `No`,
            discuss: `To be discussed`,
        },
    },
};

export default propositionAnswerCardLexique;
