/* eslint-disable import/no-extraneous-dependencies */
import React, { FC, Children, useMemo, useCallback, useEffect, useState, createRef } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';

import { Loader } from '@brandandcelebrities/kolkit';

import { AppState } from 'reducers';
import { DraftState, AllMessages, MESSAGE_TYPES, DEFAULT_PER_PAGE } from 'reducers/messaging';
import { getConversationInfos, getAllMessages, sendMessage, saveDraftMessage, mobileDisplay, loadOldMessages, addNewMessage } from 'actions/messaging';

import { ParamTypes } from 'types/ParamTypes';
import MessagingLexique from 'locales/types/containers/messagingPage';

import { editorToMarkdown } from 'utils/richTextEditor';
import { useSelector, useDispatch, useLexique } from 'utils/redux';
import { useLoading } from 'utils/hooks';
import usePrevious from 'utils/hooks/usePrevious';
import { useActionCable, CHANNELS } from 'utils/hooks/useActionCable';

import { breakpoint } from 'config/styles';

import { ChatContainer, List, BubbleMessage, Notification, Placeholder, SelectSubject } from 'containers/messagingPage/Components';
import Markdown from 'components/atoms/Markdown';

import * as mappers from 'mappers/messaging';

import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';

import styles from './Chat.module.scss';

interface Selector {
  hasConversations: boolean;
  isDesk: boolean;
  isMobile: boolean;
  device: string;
  messages: AllMessages;
  draft: DraftState | null;
  kolName: string;
  me: number | null;
  locale: string;
}

const DIFF_TIME_MINUTES = 5;

const Chat: FC<RouteComponentProps> = () => {

  const lexique = useLexique<MessagingLexique>('containers.messagingPage');
  const lexiqueAttachentError = useLexique('containers.messagingPage.attachmentError');

  const [messageRefs, setMessageRefs] = useState([]);
  const [scrollToMessage, setScrollToMessage] = useState(null);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [goTo, setGoTo] = useState({
    top: 0,
    behavior: 'smooth'
  });

  const { loading } = useLoading('getMessage');
  const { loading: sending } = useLoading('setMessage');
  const dispatch = useDispatch();

  const { conversationId } = useParams<ParamTypes>();

  const { hasConversations, isDesk, isMobile, device, messages, kolName, me, draft, locale } = useSelector<Selector>(
    ({ messaging, env, user }: AppState) => {
      return {
        isDesk: env?.viewport?.width > breakpoint?.desk,
        isMobile: env?.device?.isMobile,
        device: env.device.type,
        hasConversations: messaging?.hasConversations,
        messages: messaging?.currentConversation?.messages,
        kolName: user.pseudo || user.firstName,
        me: user.profileId,
        locale: env.locale?.split('-')?.[0],
        draft: messaging?.currentConversation?.draft,
      }
    }
  );

  const onNewMessageReceived = useCallback(
    async (newMessage) => {
      const messageMapped = mappers.newMessageWS.fromApi(newMessage);
      await dispatch(addNewMessage(messageMapped));
      setScrollToBottom(true);
    },
    [dispatch]
  );

  useActionCable({
    channel: CHANNELS.CONVERSATION,
    params: mappers.newMessageWS.toApi(conversationId),
    onDataReceived: onNewMessageReceived,
    skip: !conversationId
  });

  const prevDevice = usePrevious(device);

  useEffect(() => {
    if(device === 'desktop' && prevDevice === 'mobile') {
      dispatch(mobileDisplay(true))
    }
  }, [dispatch, device, prevDevice])

  useEffect(
    () => {
      const newRefs = (messages.rows || []).map( m => ({
        id: m.id,
        ref: createRef()
      }));
      setMessageRefs(newRefs);
    },
    [messages.rows]
  );

  useEffect(
    () => {
      if (messages.page === 0) {
        setScrollToBottom(!loading);
      }
    },
    [loading, messages.page]
  );

  useEffect(
    () => {
      setScrollToBottom(sending);
    },
    [sending]
  );

  useEffect(
    () => {
      if (conversationId) {
        !isDesk && dispatch(mobileDisplay(false))
        dispatch(getConversationInfos(conversationId));
        dispatch(getAllMessages({
          conversationId,
          page: 0,
          perPage: DEFAULT_PER_PAGE
        }));
      }
    },
    [conversationId, dispatch, isDesk]
  );

  useEffect(
    () => {
      const currentRef =  messageRefs?.[messages.perPage]?.ref?.current;
      if (messages.page > 0 && currentRef) {
        setGoTo({
          top: currentRef.offsetTop - 24,
          behavior: 'auto'
        });
      }
    },
    [messages.page, messages.perPage, messageRefs]
  );

  useEffect(
    () => {
      if (scrollToMessage) {
        const targetRef = messageRefs.find(m => m.id === scrollToMessage);
        const target = targetRef?.ref?.current;
        if (targetRef) {
          setGoTo({
            top: target.offsetTop - 24,
            behavior: 'smooth'
          });
          setScrollToMessage(null);
        }
      }
    },
    [scrollToMessage, messageRefs]
  );

  const handleLoadOldMessage = useCallback(
    (scrollTop) => {
      if (scrollTop === 0 && messages.hasMore) {
        dispatch(getAllMessages({
          conversationId,
          perPage: messages.perPage,
          page: messages.page + 1,
        }));
      }
    },
    [dispatch, conversationId, messages.hasMore, messages.perPage, messages.page]
  );

  const handleSelectSubject = useCallback(
    async (messageId) => {
      const targetRef = messageRefs.find(m => m.id === messageId);
      if (!targetRef) await dispatch(loadOldMessages(messageId));
      setScrollToMessage(messageId);
    },
    [messageRefs, dispatch]
  );

  const renderChatList = useMemo(
    () => {
      return Children.toArray(messages.rows?.map((item, index) => {
        const { id, content, createdAt, writer, attachments, messageType, subtype, subject, isThreadStart } = item;

        if (messageType === MESSAGE_TYPES.message) {
          const prevMessage = index ? messages.rows?.[index-1] : null;
          const isMe = String(me) === String(writer?.id);

          const isGrouped = prevMessage
            && (moment(item?.createdAt).diff(moment(prevMessage?.createdAt), 'minutes') <= DIFF_TIME_MINUTES)
            && prevMessage?.writer?.id === writer?.id;

          const cnSelect = cn(styles.selectSubject, index !== 0 && styles.isNotFirst);

          return (
            <>
              {isThreadStart && (
                <SelectSubject
                  messageId={id}
                  onSelect={handleSelectSubject}
                  label={subject}
                  className={cnSelect}
                  order={index}
                />
              )}
              <BubbleMessage
                ref={messageRefs[index]?.ref}
                message={<Markdown>{content}</Markdown>}
                timestamp={createdAt}
                me={isMe}
                avatar={writer?.pictureUrl}
                name={writer?.name}
                attachments={attachments}
                isGrouped={isGrouped}
              />
            </>
          );
        }
        if (messageType === MESSAGE_TYPES.notification) {
          return (
            <Notification
              subtype={subtype}
              content={content}
              lexique={lexique.chat.notification}
              timestamp={createdAt}
              writer={writer}
              kolName={kolName}
            />
          );
        }
        return null;
      }));
    },
    [messages.rows, lexique.chat, me, kolName, messageRefs, handleSelectSubject]
  );

  const handleSendMessage = useCallback(
    async (message) => {
      if (sending) return false;

      const dataToSave = {
        ...message,
        content: editorToMarkdown(message?.content)
      };
      const response = await dispatch(sendMessage({
        conversationId,
        message: dataToSave
      }));

      if (!response) {
        // eslint-disable-next-line no-alert
        window.alert(lexiqueAttachentError.global);
        return false;
      }
    },
    [sending, dispatch, conversationId, lexiqueAttachentError.global]
  );

  const handleSaveDraft = useCallback(
    async ({message, conversationId}) => {
      if (sending) return false;

      const attachmentsToDelete = (draft?.attachments || [])
        .filter(originAttachment => message?.attachments.findIndex(attachment => attachment.id && attachment.id === originAttachment.id) < 0)
        .map(attachment => ({ id: attachment.id, destroy: true }))

      const newAttachments = message?.attachments.filter(attachment => !attachment.id);

      const attachments = [
        ...attachmentsToDelete,
        ...newAttachments
      ];

      const dataToSave = {
        ...message,
        attachments
      };
      await dispatch(saveDraftMessage({
        conversationId,
        message: dataToSave
      }));
    },
    [dispatch, draft, sending]
  );

  if (!hasConversations) return (
    <ChatContainer>
      <Placeholder
        description={lexique?.chat?.noConversations}
        className={styles.placeholder}
        iconOptions={{
          label: 'comment',
          color: '#ff729f',
        }}
      />
    </ChatContainer>
  );

  return (
    <ChatContainer>
      {loading && <Loader full background="rgba(255, 255, 255, .8)"/>}
      <ChatHeader />
      <List
        startBottom={scrollToBottom}
        scroll={goTo}
        onScrollStop={handleLoadOldMessage}
      >
        {renderChatList}
        { sending && (
          <BubbleMessage
            showTimestamp={false}
            message={
              <Loader
                theme="navy"
                background="transparent"
                className={styles.sending}
              />
            }
          />
        )}
      </List>
      <ChatFooter
        conversationId={conversationId}
        onSaveDraft={handleSaveDraft}
        onSend={handleSendMessage}
        draftMessage={draft}
        locale={locale}
        withoutToolbar={isMobile}
      />
    </ChatContainer>
  )
};

Chat.displayName = 'Chat';

export default withRouter(Chat);
