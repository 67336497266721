import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

const options: ThemeOptions = {
    palette: {
        primary: {
            main: '#003057',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            input: {
                color: '#003057',
                fontSize: 12,
            },
            notchedOutline: {
                borderColor: '#CCD6E3',
            },
        },
    },
};

export default createMuiTheme(options);
