import { HeaderLexique } from 'locales/types/containers/shared/header';

const header: HeaderLexique = {
    navLinks: {
        audience: `Mon Audience`,
        content: `Mon contenu`,
        partner: `Ma visibilité`,
        discuss: `Conversations`,
    },
    feedback: {
        label: `Une question ?`,
        content: {
            subject: `Community : une question de `,
        },
    },
    subMenu: {
        settings: `Mon Compte`,
        profile: `Mon Profil`,
        logout: `Déconnexion`,
    },
};

export default header;
