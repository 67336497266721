import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash.isempty';
import _find from 'lodash.find';
import { Helmet } from 'react-helmet';

import { supportedSortedSnaKeys } from 'constants/networks';
import { loadCommunity, loadStatistics } from 'actions/analytics';
import SocialStatsList from 'components/templates/SocialStats/SocialStatsList/SocialStatsList';
import getLexique from 'locales';
import { SnaDataChartFetch } from 'types/snas';
import { TimeRange } from 'types/Filters';
import { AppState } from 'reducers';
import { SNAUserInfos } from 'reducers/user';

import mobileGraphFB from 'assets/images/analytics/stats/mobile_graph_FB.png';
import mobileGraphIN from 'assets/images/analytics/stats/mobile_graph_IN.png';
import mobileGraphTW from 'assets/images/analytics/stats/mobile_graph_TW.png';
import desktopGraphFB from 'assets/images/analytics/stats/desktop_graph_FB.png';
import desktopGraphIN from 'assets/images/analytics/stats/desktop_graph_IN.png';
import desktopGraphTW from 'assets/images/analytics/stats/desktop_graph_TW.png';
import desktopGraphYT from 'assets/images/analytics/stats/desktop_graph_YT.png';
import mobileCommunityEmptyCardAge from 'assets/images/analytics/stats/mobile_community_empty_card_age.png';
import mobileCommunityEmptyCardMap from 'assets/images/analytics/stats/mobile_community_empty_card_map.png';
import desktopCommunityEmptyCardAge from 'assets/images/analytics/stats/desktop_community_empty_card_age.png';
import desktopCommunityEmptyCardMap from 'assets/images/analytics/stats/desktop_community_empty_card_map.png';

import ChartCard from './ChartCard';
import PlaceholderInfo from '../PlaceholderInfo';
import AgeChart from './AgeChart';
import GeoChart from './GeoChart';

import './Stats.scss';

interface Props {
    loadStatistics: () => void;
    loadCommunity: () => void;
    lexiqueStatitics: any;
    lexiqueCommunity: any;
    snas: SNAUserInfos[];
    socialNetwork: SnaDataChartFetch[];
    filter: TimeRange;
    community: any;
    device: string;
}

class Stats extends PureComponent<Props> {
    componentDidMount() {
        this.props.loadStatistics();
        this.props.loadCommunity();
    }

    renderStatsPlaceholders = () => {
        const { snas, device } = this.props;

        if (!_isEmpty(snas)) return null;
        if (device === 'mobile') {
            return (
                <>
                    <img
                        className="bc-social-stats__chart-placeholder"
                        src={mobileGraphFB}
                        alt=""
                    />
                    <img
                        className="bc-social-stats__chart-placeholder"
                        src={mobileGraphIN}
                        alt=""
                    />
                    <img
                        className="bc-social-stats__chart-placeholder"
                        src={mobileGraphTW}
                        alt=""
                    />
                </>
            );
        }
        return (
            <>
                <img
                    className="bc-social-stats__chart-placeholder"
                    src={desktopGraphFB}
                    alt=""
                />
                <img
                    className="bc-social-stats__chart-placeholder"
                    src={desktopGraphIN}
                    alt=""
                />
                <img
                    className="bc-social-stats__chart-placeholder"
                    src={desktopGraphTW}
                    alt=""
                />
                <img
                    className="bc-social-stats__chart-placeholder"
                    src={desktopGraphYT}
                    alt=""
                />
            </>
        );
    };

    renderCommunityPlaceholders = () => {
        const { lexiqueCommunity, community, snas, device } = this.props;

        if (!_isEmpty(community) && _find(snas, { label: 'instagram' })) return null;

        if (device === 'mobile') {
            return (
                <>
                    {_isEmpty(_find(snas, { label: 'instagram' })) && (
                        <PlaceholderInfo text={lexiqueCommunity.placeholder} />
                    )}
                    <img
                        className="bc-analytics-community__placeholder"
                        src={mobileCommunityEmptyCardAge}
                        alt=""
                    />
                    <img
                        className="bc-analytics-community__placeholder"
                        src={mobileCommunityEmptyCardMap}
                        alt=""
                    />
                </>
            );
        }
        return (
            <>
                {_isEmpty(_find(snas, { label: 'instagram' })) && (
                    <PlaceholderInfo text={lexiqueCommunity.placeholder} />
                )}
                <img
                    className="bc-analytics-community__placeholder"
                    src={desktopCommunityEmptyCardAge}
                    alt=""
                />
                <img
                    className="bc-analytics-community__placeholder"
                    src={desktopCommunityEmptyCardMap}
                    alt=""
                />
            </>
        );
    };

    render() {
        const { lexiqueStatitics, lexiqueCommunity, snas, socialNetwork, filter, community } = this.props;
        return (
            <div className="bc-analytics-stats">
                <Helmet>
                    <title>{lexiqueStatitics.headTitle}</title>
                </Helmet>

                <div className="bc-analytics-stats__title">{lexiqueStatitics.title}</div>
                {_isEmpty(snas) && <PlaceholderInfo text={lexiqueStatitics.placeholder} />}
                <SocialStatsList />
                {!_isEmpty(socialNetwork) && (
                    <div className="bc-social-stats__charts-title">{lexiqueStatitics.charts.title}</div>
                )}
                <div className="bc-social-stats__charts">
                    {socialNetwork &&
                        socialNetwork.length > 0 &&
                        socialNetwork.map((aSocialNetwork) => (
                            <div key={aSocialNetwork.uid} className="bc-social-stats__chart-card-container">
                                <ChartCard filter={filter} socialNetwork={aSocialNetwork} />
                            </div>
                        ))}
                    {this.renderStatsPlaceholders()}
                </div>

                {!_isEmpty(community) && _find(snas, { label: 'instagram' }) && (
                    <>
                        <div className="bc-analytics-community__chart-container">
                            <div className="bc-analytics-community-chart-container__title">
                                {lexiqueCommunity.ageChart.title}
                            </div>
                            <div className="bc-analytics-community-chart-container__text">{lexiqueCommunity.text}</div>
                            <AgeChart />
                        </div>
                        <div className="bc-analytics-community__chart-container">
                            <div className="bc-analytics-community-chart-container__title">
                                {lexiqueCommunity.geoChart.title}
                            </div>
                            <div className="bc-analytics-community-chart-container__text">{lexiqueCommunity.text}</div>
                            <GeoChart />
                        </div>
                    </>
                )}
                {this.renderCommunityPlaceholders()}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexiqueStatitics: getLexique(state.env.locale).containers.analyticsPage.statistics, // @TODO type
    lexiqueCommunity: getLexique(state.env.locale).containers.analyticsPage.community, // @TODO type
    socialNetwork: state.ui.analytics.stats.socialNetwork.sort(
        (a, b) => supportedSortedSnaKeys.indexOf(a.label) - supportedSortedSnaKeys.indexOf(b.label),
    ),
    filter: TimeRange[state.ui.analytics.stats.filter],
    snas: state.user.snas,
    device: state.env.device.type,
    community: state.ui.analytics.community,
});

const mapDispatchToProps = (dispatch) => ({
    loadStatistics: () => dispatch(loadStatistics()),
    loadCommunity: () => dispatch(loadCommunity()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
