import React, { FC, useMemo, memo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Button2 } from '@brandandcelebrities/kolkit';

import { Networks } from 'constants/networks';
import { SocialName, EvolutionCard } from 'components/molecules';

import styles from './RecapItem.module.scss';

interface Props {
    title: string;
    content: string;
    username?: string;
    network?: Networks;
    evolution?: string;
    buttonLabel: string;
    navigateTo: string;
}

const RecapItem: FC<Props> = memo(
    ({ title, content, username, network, evolution, buttonLabel, navigateTo }: Props) => {
        const { value, suffix, isHashtag } = useMemo(() => {
            let value = content;
            let suffix = '';
            let isHashtag = false;
            if (content.endsWith('%')) {
                suffix = '%';
                value = content.slice(0, content.length - 2);
            } else if (content.startsWith('#')) {
                isHashtag = true;
            }
            return { value, suffix, isHashtag };
        }, [content]);

        const cnMain = useMemo(
            () =>
                classnames(styles.main, {
                    [styles.isHashtag]: isHashtag,
                }),
            [isHashtag],
        );

        return (
            <div className={styles.recapItem}>
                <div className={styles.title}>{title}</div>
                <div className={styles.content}>
                    <div className={cnMain}>
                        {value}
                        <span className={styles.suffix}>{suffix}</span>
                    </div>
                    {username && network && <SocialName name={username} network={network} />}
                    {evolution && <EvolutionCard evolution={evolution} />}
                </div>
                <Link to={navigateTo}>
                    <Button2
                        label={buttonLabel}
                        size="small"
                    />
                </Link>
            </div>
        );
    },
);

RecapItem.defaultProps = {
    username: undefined,
    network: undefined,
    evolution: undefined,
};

export default RecapItem;
