import React from 'react';

import styles from './StepperHeader.module.scss';

interface Props {
    header?: string;
    title?: string;
    className?: string;
}

const StepperHeader: React.FC<Props> = ({ header, title, className }: Props) => {
    return (
        <div className={className}>
            <h4 className={styles.header}>{header}</h4>
            <h2 className={styles.title}>{title}</h2>
        </div>
    );
};

StepperHeader.defaultProps = {
    header: '',
    title: '',
    className: undefined,
};

export default StepperHeader;
