import { FAQPageLexique } from 'locales/types/containers/faqPage';

export const partenariatsPageLexique: FAQPageLexique = {
    headTitle: 'FAQ Instagram - Kolsquare ',
    title: 'Sharing your Instagram statistics on Kolsquare',
    description:
        'Kolsquare allows you to share your Instagram statistics with brands, in real time, and automatically.\nNo need for screenshots or media-kit updates, your latest numbers are updated automatically!',
    faqs: [
        {
            question: 'Who is able to see my statistics?',
            answer: `Should you authorize Kolsquare to share your statistics in real time, brands using Kolsquare and the Kolsquare team will be the only ones allowed to access them. They are only shared on the Kolsquare for brands' platform, and via the Kolsquare Chrome extension.`,
        },
        {
            question: 'If I give my authorisation, which statistics are shared with brands using Kolsquare?',
            list: [
                'The number of likes, comments, impressions, reach, video views, saves and replies of your Instagram posts and stories;',
                'Socio-demographical data of your Instagram audience (age, gender and geographical distribution).',
            ],
        },
        {
            question: 'When and where can brands access these statistics?',
            answer:
                'Your statistics are only accessible on the Kolsquare for brands platform (paid version) and via the Kolsquare Chrome extension.',
            list: [
                'Your audience’s statistics are accessible every time your profile is opened on Kolsquare by a brand, or when an audience-based search is triggered on the Kolsquare search engine for brands.',
                'The statistics of your content are only available in the campaign reporting section of a brand who added you to it. Therefore, we don’t share the entirety of the statistics of your content to brands using our platform.',
            ],
        },
        {
            question: 'For how long is the authorization valid on Kolsquare?',
            answer:
                'Once you authorize Kolsquare to share your statistics with brands using the platform, the access will be effective for the following 30 days (New Facebook/Instagram term of use).',
        },
        {
            question: 'Can I revoke this access?',
            answer:
                'You can revoke the automatic sharing authorization of your Instagram statistics anytime  by visiting the page “My account” on Kolsquare. Once revoked, you can still change your mind and reactivate the automatic sharing.',
        },
    ],
    preCta: 'Any questions?',
    cta: 'Contact us',
    mailObject: 'Community: a question from {{1}} regarding statistics sharing',
};

export default partenariatsPageLexique;
