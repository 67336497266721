import { createReducer } from '@reduxjs/toolkit';

import * as UI from 'constants/ui';

const reducers = {
	[UI.LOADER]: (state, { payload: {id, show} }) => {
		const index = state.findIndex(item => item === id);
		!show
			? state.splice(index, 1)
			: state.push(id)
	},
};

export default createReducer([], reducers);
