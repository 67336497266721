export default {
    navLinks: {
        home: `Accueil`,
        audience: `Mon Audience`,
        content: `Mon contenu`,
        partner: `Ma visibilité`,
        discuss: `Conversations`,
        profile: `Mon profil`,
        settings: 'Mon compte',
        signout: `Se déconnecter`,
    },
};
