import React, { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { getInitials } from 'utils/names';

import styles from './Avatar.module.scss';

interface Props {
  avatar?: string;
  name: string;
  hide?: boolean;
}

const Avatar: FC<Props> = ({ avatar, name, hide }) => {

  const initials = useMemo(
      () => getInitials(name),
      [name]
  );

  const cnWrapper = cn(styles.wrapper, {[styles.hide]: hide});

  return (
    <div className={cnWrapper}>
        {avatar && <img src={avatar} alt="" />}
        <span className={styles.initials}>{initials}</span>
    </div>
  )
};

Avatar.displayName = 'Card';

Avatar.defaultProps = {
  name: '',
  hide: false,
}

export default memo(Avatar);
