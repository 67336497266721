export default {
    title: 'Mes projets',
    project: {
        date: {
            the: 'le',
            at: 'à',
        },
    },
    empty: {
        title: `Vous n’avez pas encore reçu de proposition.`,
        mobileTitle: `Pour augmenter vos chances d'être vu :`,
        body: `Afin d’augmenter votre visibilité sur notre moteur de recherche et de nous permettre de vous proposer les projets les plus à même de vous correspondre :`,
        bullet1: `1. Connectez vos réseaux sociaux.`,
        bullet2: `2. Renseignez votre profil.`,
        tagline: `On s’occupe du reste !`,
        error: `Une erreur s'est produite lors du chargement des projets...`,
        restablish: `Nous travaillons pour rétablir au plus vite...`,
    },
};
