export default {
    thankingStatsSharingWithoutRequest: {
        title: '🎉 Thank you {pseudo}!',
        text: `You are now sharing your statistics.
        \n\n Follow your statistics and audience evolution, your audience credibility score, and price calculator in your Kolsquare dashboard now!`,
        button: `My Kolsquare dashboard`,
    },
    thankingStatsSharingRequestFromBrand: {
        title: `🎉 Thank you {pseudo}!`,
        titleAlreadyActivated: `Hello {pseudo}`,
        text: `Your statistics are now available in the report of **{brandName}**. A 24hour delay may be necessary.
        \n\n Follow your statistics and audience evolution, your audience credibility score, and price calculator in your Kolsquare dashboard now!`,
        textMany: `Your statistics are now available in the report of **{brandName}** and **{length} brand(s)**. A 24hour delay may be necessary.
        \n\n Follow your statistics and audience evolution, your audience credibility score, and price calculator in your Kolsquare dashboard now! `,
        textAlreadyActivated: `You already activated the sharing of your statistics.
        \n\n Follow your statistics and audience evolution, your audience credibility score, and price calculator in your Kolsquare dashboard now!`,
        button: `My Kolsquare dashboard`,
    },
};
