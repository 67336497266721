import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import configureStore from '../store/configureStore';

export const store = configureStore({});

export const getDispatcher = (): AppDispatch => store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
