import ProjectsPageLexique from 'locales/types/containers/projectsPage';

export const projectPageLexique: ProjectsPageLexique = {
    headTitle: 'Messages - Kolsquare',
    back: `Back to messages`,
    mobilePlaceholder: `Write your message`,
    submit: {
        send: 'Send',
        sent: 'Sent!',
        sending: 'Sending...',
    },
    conversation: {
        brief: {
            agree: 'I am interested',
            disagree: 'I am not interested',
        },
        notification: {
            agreed: `You are interested by the offer of `,
            disagreed: `You are not interested by the offer of `,
            statsSharing: {
                title: `Help us complete our reporting.`,
                subTitle: `{kolName} we would like to access the statistics of your Instagram posts and stories via Kolsquare in order to complete our reporting and save time.`,
                linkDescription: `No need to share screenshots, you can safely give us access by following the link :`,
                linkLabel: `Share my statistics`,
            },
        },
    },
};

export default projectPageLexique;
