import React, { FC, useState, useCallback, memo } from 'react';
import cn from 'classnames';

import Divider from '@material-ui/core/Divider';
import { Button, Input } from '@brandandcelebrities/kolkit';

import { showModale, hideModale } from 'actions/modale';
import { updateEmail, updatePassword } from 'actions/profile';
import { deleteUser } from 'actions/user';

import { isEmail } from 'utils/email';
import { callGAEvent } from 'utils/googleAnalytics';
import { useSelector, useDispatch, useLexique } from 'utils/redux';

import { AppState } from 'reducers';

import { SectionsLayout } from 'components/templates';
import DeleteButton from 'components/atoms/Buttons/Button';

import picto from 'assets/images/profile.png';

import SettingsPageLexique from 'locales/types/containers/settingsPage/settingsPage';

import styles from './SettingsPage.module.scss';

interface Selector {
    email: string;
}
const SettingsPage: FC = () => {
    const lexique = useLexique<SettingsPageLexique>('containers.settingsPage.settingsPage');

    const dispatch = useDispatch();

    const { email } = useSelector(
        ({ user }: AppState): Selector => ({
            email: user.email,
        }),
    );

    const [password, setPassword] = useState<string>('');

    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [currentEmail, setEmail] = useState<string>(email || '');

    const [emailLoading, setEmailLoading] = useState<boolean>(false);
    const [emailResult, setEmailResult] = useState<string>('');

    const [passwordLoading, setPasswordLoading] = useState<boolean>(false);
    const [passwordResult, setPasswordResult] = useState<string>('');

    const toggleDeleteModal = useCallback(() => {
        dispatch(
            showModale({
                body: 'DeleteAccount',
                primaryAction: () => dispatch(hideModale()),
                secondaryAction: () => {
                    callGAEvent('Account', 'Delete');
                    dispatch(deleteUser());
                },
            }),
        );
    }, [dispatch]);

    const onChangeEmail = useCallback(({ value }) => {
        setEmail(value);
    }, []);
    const onChangePassword = useCallback(({ value }) => {
        setPassword(value);
    }, []);
    const onChangeNewPassword = useCallback(({ value }) => {
        setNewPassword(value);
    }, []);
    const onChangeConfirmPassword = useCallback(({ value }) => {
        setConfirmPassword(value);
    }, []);

    const onRequestChangeEmail = async (): Promise<void> => {
        setEmailLoading(true);
        const res = await dispatch(
            updateEmail({
                email: currentEmail,
            }),
        );

        if (res.status === 204) {
            setEmailResult('success');

            window.ga('send', 'event', {
                eventCategory: 'Account',
                eventAction: `Submit`,
                eventLabel: `Change_email`,
            });
        } else {
            window.ga('send', 'event', {
                eventCategory: 'Account',
                eventAction: `Error`,
                eventLabel: `Email_Already_In_Use`,
            });
            setEmailResult('error');
        }
        setEmailLoading(false);
    };

    const onRequestChangePassword = async (): Promise<void> => {
        setPasswordLoading(true);

        const res = await dispatch(
            updatePassword({
                password,
                newPassword,
                confirmPassword,
            }),
        );

        if (res && res.status === 204) {
            setPasswordResult('success');
            callGAEvent('Account', 'Submit', 'Password_Change');
        } else {
            callGAEvent('Account', 'Error', 'Password_invalid');
            setPasswordResult('error');
        }
        setPasswordLoading(false);
    };

    const cnErrorMessage = cn([styles.confirmationMessage, styles.messageError]);

    return (
        <SectionsLayout title={lexique.headTitle} picto={picto}>
            <section>
                <h2>{lexique.pageTitle}</h2>
                <div>
                    <h3>{lexique.subTitleEmail}</h3>
                    <Input
                        id="account-email"
                        label={lexique.email}
                        placeholder={lexique.email}
                        onChange={onChangeEmail}
                        value={currentEmail}
                        disabled={emailLoading}
                        fullWidth
                        size="big"
                    />
                    {emailResult === 'success' && (
                        <div className={styles.confirmationMessage}>{lexique.success.email}</div>
                    )}
                    {emailResult === 'error' && <div className={cnErrorMessage}>{lexique.error.email}</div>}
                    <div className={styles.submitButtonRight}>
                        <Button
                            id="account-email-submit"
                            disabled={email === '' || !isEmail(email)}
                            loading={emailLoading}
                            complete={emailResult === 'success'}
                            label={lexique.submit}
                            onClick={onRequestChangeEmail}
                        />
                    </div>
                </div>
                <div>
                    <Input
                        id="account-password"
                        placeholder={lexique.password}
                        type="password"
                        onChange={onChangePassword}
                        value={password}
                        disabled={passwordLoading}
                        fullWidth
                        size="big"
                    />

                    <Input
                        id="account-new-password"
                        className="mt15"
                        placeholder={lexique.newPassword}
                        type="password"
                        onChange={onChangeNewPassword}
                        value={newPassword}
                        disabled={passwordLoading}
                        fullWidth
                        size="big"
                    />
                    <Input
                        id="account-confirm-new-password"
                        className="mt15"
                        placeholder={lexique.confirmNewPassword}
                        type="password"
                        onChange={onChangeConfirmPassword}
                        value={confirmPassword}
                        disabled={passwordLoading}
                        fullWidth
                        size="big"
                    />
                    {passwordResult === 'success' && (
                        <div className={styles.confirmationMessage}>{lexique.success.password}</div>
                    )}
                    {passwordResult === 'error' && <div className={cnErrorMessage}>{lexique.error.password}</div>}
                    <div className={styles.submitButtonRight}>
                        <Button
                            id="account-new-password-submit"
                            disabled={
                                password === '' ||
                                newPassword === '' ||
                                confirmPassword === '' ||
                                newPassword !== confirmPassword
                            }
                            loading={passwordLoading}
                            complete={passwordResult === 'success'}
                            label={lexique.submit}
                            onClick={onRequestChangePassword}
                        />
                    </div>
                </div>
            </section>
            <Divider />
            <section>
                <h3>{lexique.subTitleAccountDeletion}</h3>
                <div className={styles.accountDelete}>
                    <DeleteButton className={styles.button} onClick={toggleDeleteModal} label={lexique.deleteAccount} />
                </div>
            </section>
        </SectionsLayout>
    );
};

export default memo(SettingsPage);
