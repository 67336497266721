import React, { memo } from 'react';
import cn from 'classnames';

import { formatPostContent } from 'utils/post';

import styles from './PostContent.module.scss';

interface Props {
    className?: string;
    content?: string;
}

const PostContent: React.FC<Props> = ({ className, content }) => {
    return (
        <p
            className={cn(styles.postContent, className)}
            dangerouslySetInnerHTML={{
                __html: formatPostContent(content),
            }}
        />
    );
};

export default memo(PostContent);
