import SocialStatsContainerLexique from 'locales/types/containers/shared/socialStatsContainer';

export const socialStatsContainerLexique: SocialStatsContainerLexique = {
    modal: {
        addInstagram: `Ajouter un compte instagram`,
        addInstagramBusiness: `Ajouter un compte Instagram Business`,
        addFacebook: `Ajouter un compte facebook`,
        addFacebookBusiness: `Ajouter un compte Facebook Business`,
        selectPages: {
            instagram: {
                title: `Connectez vos comptes Instagram Business`,
                text: `Choisissez les comptes Instagram Business que vous souhaitez importer`,
                noPages: `Il n'y a pas de compte business associé à ce profil.`,
                link: `Découvrez comment convertir votre profil Instagram en profil Business`,
            },
            facebook: {
                title: `Connectez vos comptes Facebook Business`,
                text: `Choisissez les comptes Facebook Business que vous souhaitez importer`,
                noPages: `Il n'y a pas de compte business associé à ce profil.`,
                link: ``,
            },
            next: 'Suivant',
        },
        or: `Ou`,
        title: {
            facebook: `Connectez votre fanpage Facebook`,
            twitter: `Connectez votre compte Twitter`,
            instagram: `Connectez votre compte Instagram`,
            youtube: `Connectez votre chaîne Youtube`,
            tiktok: `Connectez votre compte Tiktok`,
        },
        text: {
            facebook: `Copiez-collez l'url de votre fanpage`,
            twitter: `Copiez-collez l'url de votre compte`,
            instagram: `Copiez-collez l'url de votre compte`,
            tiktok: `Copiez-collez l'url de votre compte`,
            youtube: `Copiez-collez l'url de votre chaîne`,
        },
        form: {
            url: {
                facebook: {
                    placeholder: `www.facebook.com/brandandcelebrities`,
                    errorTypes: {
                        invalidUrl: `L'adresse de votre fanpage Facebook est invalide.`,
                        privateAccount: `Cette fanpage est privée`,
                        recordNotUnique: `Cette fanpage est déjà prise`,
                        unknownAccount: `Cette fanpage n'existe pas`,
                        errorUnknown: `Erreur serveur`,
                    },
                },
                instagram: {
                    placeholder: `www.instagram.com/kolsquare`,
                    errorTypes: {
                        invalidUrl: `L'adresse de votre compte Instagram est invalide.`,
                        privateAccount: `Ce compte est privé`,
                        recordNotUnique: `Ce compte est déjà pris`,
                        unknownAccount: `Ce compte n'existe pas`,
                        errorUnknown: `Erreur serveur`,
                    },
                },
                twitter: {
                    placeholder: `www.twitter.com/kolsquare`,
                    errorTypes: {
                        invalidUrl: `L'adresse de votre compte Twitter est invalide.`,
                        privateAccount: `Ce compte est privé`,
                        recordNotUnique: `Ce compte est déjà pris`,
                        unknownAccount: `Ce compte n'existe pas`,
                        errorUnknown: `Erreur serveur`,
                    },
                },
                youtube: {
                    placeholder: `www.youtube.com/channel/kolsquare`,
                    errorTypes: {
                        invalidUrl: `L'adresse de votre chaîne Youtube est invalide.`,
                        privateAccount: `Cette chaîne est privée`,
                        recordNotUnique: `Cette chaîne est déjà prise`,
                        unknownAccount: `Cette chaîne n'existe pas`,
                        errorUnknown: `Erreur serveur`,
                    },
                },
                tiktok: {
                    placeholder: `www.tiktok.com/@kolsquare`,
                    errorTypes: {
                        invalidUrl: `L'adresse de votre compte Tiktok est invalide.`,
                        privateAccount: `Ce compte est privé`,
                        recordNotUnique: `Ce compte est déjà pris`,
                        unknownAccount: `Ce compte n'existe pas`,
                        errorUnknown: `Erreur serveur`,
                    },
                },
            },
            send: `Valider`,
        },
    },
    addSNA: 'Ajouter un réseau social',
};

export default socialStatsContainerLexique;
