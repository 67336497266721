export default {
    0: {
        title: (name) => `Continuez comme ça ${name} !`,
        text: `Oups ! Il semblerait que votre profil soit suivi par plusieurs faux comptes. Un surplus d’abonnés suspects peut donner l’impression que vous achetez votre audience alors que ce n’est forcément pas le cas !`,
    },
    30: {
        title: (name) => `Continuez comme ça ${name} !`,
        text: `Il semblerait que des faux comptes persistent parmi vos abonnés et impactent votre score de crédibilité. Cela peut notamment nuire à votre image auprès des marques.`,
    },
    60: {
        title: (name) => `Félicitations ${name} !`,
        text: `Bonne nouvelle, votre audience semble avoir la pêche ! Il est cependant possible que certains faux comptes persistent parmi vos abonnés. Pas d’inquiétude, vous pouvez toujours y remédier.`,
    },
    70: {
        title: (name) => `Félicitations ${name} !`,
        text: `Votre communauté est un vrai modèle de vitalité et de bonne santé ! Elle est constituée en majorité de vrais abonnés et ça, c’est la classe !`,
    },
    90: {
        title: (name) => `Félicitations ${name} !`,
        text: `Vous êtes à l’Influence ce que Michael Jordan était au basket-ball ! Vous êtes parvenu(e)(s) à élever votre communauté au plus haut standard.`,
    },
    button: `Comment améliorer mon score ?`,
    score: 'Score de crédibilité de l’audience',
};
