import { createReducer } from 'redux-create-reducer';

import * as FBC from 'constants/fbc';

import { Action } from 'redux';

import {
    FBCDataState,
    Reducers,
    ResetErrorFunc,
    OnAccountDataFunc,
    FetchDataFunc,
    OnAccountErrorFunc,
    OnSelectPageFunc,
    ToggleModalFunc,
    OnLoginStatusLoaded
} from './fbc.reducer.d';

export const initialState: FBCDataState = {
    loginStatus: '',
    loading: false,
    error: '',
    modalOpened: false,
    pages: {
        instagram: [],
        facebook: [],
    },
    selectedPages: {
        instagram: [],
        facebook: [],
    },
};

export const onAccountData: OnAccountDataFunc = (state, { payload }) => {
    return {
        ...state,
        loading: false,
        error: '',
        pages: {
            ...state.pages,
            ...payload,
        },
    };
};

export const onError: OnAccountErrorFunc = (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload,
});

export const fetchData: FetchDataFunc = (state) => ({
    ...state,
    error: '',
    loading: true,
    pages: {
        instagram: [],
        facebook: [],
    },
    selectedPages: {
        instagram: [],
        facebook: [],
    },
});

export const selectPage: OnSelectPageFunc = (state, { payload }) => ({
    ...state,
    selectedPages: {
        ...state.selectedPages,
        [payload.network]: payload.pages,
    },
});

export const toggleModal: ToggleModalFunc = (state, { payload }) => ({
    ...state,
    modalOpened: payload,
});

export const reset: ResetErrorFunc = (state) => ({
    ...state,
    error: '',
    loading: false,
    pages: {
        instagram: [],
        facebook: [],
    },
    selectedPages: {
        instagram: [],
        facebook: [],
    },
});

export const setLoginStatus: OnLoginStatusLoaded = (state, { payload }) => ({
    ...state,
    loginStatus: payload
});

export const reducers: Reducers = {
    [FBC.FETCH_DATA]: fetchData,
    [FBC.ON_ACCOUNT_DATA]: onAccountData,
    [FBC.ON_ACCOUNT_ERROR]: onError,
    [FBC.SELECT_PAGE]: selectPage,
    [FBC.TOGGLE_MODAL]: toggleModal,
    [FBC.RESET]: reset,
    [FBC.ON_LOGIN_STATUS_LOADED]: setLoginStatus,
};

export default createReducer<FBCDataState, Action<Reducers>>(initialState, reducers);
