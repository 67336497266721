import React, { memo } from 'react';
import cn from 'classnames';
import { animated } from 'react-spring';

import styles from './TabMenu.module.scss';

const Tab = ({ children, active, disabled, ...rest }) => {
  const cnTab = cn(styles.tab, {
    [styles.active]: active,
    [styles.disabled]: disabled,
  });

  return (
    <animated.li
      {...rest}
      className={cnTab}
    >
      {children}
    </animated.li>
  )
};

export default memo(Tab);
