export const splitNameBySpaces = (fullName = ''): string[] => {
    return fullName.trim().split(/\s+/);
};

export const getInitials = (name) => {
    if (!name) return '';

    const names = splitNameBySpaces(name);
    if (names.length === 1) return names[0][0];
    return `${names[0][0]}${names[1][0]}`;
};

export const celebrityNameFormatter = obj => {
	if (!obj) return null;
	return obj.pseudo && obj.pseudo.trim() !== '' ? obj.pseudo : obj.fullName;
};