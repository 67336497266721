import React, { useEffect, FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { load } from 'actions/propositionPage';

import './PropositionPage.scss';
import getLexique from 'locales';
import { CONTACTED } from 'constants/proposition';
import { useDispatch, useSelector } from 'utils/redux';
import { AppState } from 'reducers';
import { Device } from 'reducers/env';
import { Proposition } from 'reducers/proposition';
import PropositionPageLexique from 'locales/types/containers/propositionPage/propositionPage';
import { ParamTypes } from 'types/ParamTypes';

import ContactCard from './ContactCard';
import PropositionAnswerMobile from './PropositionAnswerMobile';
import PropositionAnsweredMobile from './PropositionAnsweredMobile';
import PropositionAnswerCard from './PropositionAnswerCard';
import PropositionDetails from './PropositionDetails';
import PropositionDescription from './PropositionDescription';
import PropositionHeader from './PropositionHeader';

interface Selector {
    device: Device;
    lexique: PropositionPageLexique;
    proposition: Proposition;
}


const PropositionPage: FC = () => {
    const dispatch = useDispatch();
    const { propositionId } = useParams<ParamTypes>();
    
    useEffect(() => {
        dispatch(load(propositionId));
    }, [dispatch, propositionId]);

    const { lexique, device, proposition } = useSelector<Selector>((state: AppState) => ({
        lexique: getLexique(state.env.locale).containers.propositionPage.propositionPage,
        device: state.env.device,
        proposition: state.proposition.current,
    }));

    let content;
    if (device.isDesktop) {
        content = (
            <div className="bc-proposition-show">
                <Helmet>
                    <title>{lexique.headTitle}</title>
                </Helmet>
                <Link to="/" className="bc-proposotion-show__link-back">
                    {`< ${lexique.back}`}
                </Link>
                <div className="bc-proposition-show-container">
                    <div className="bc-proposition-show__section-left">
                        <div className="bc-proposition-body-card-container">
                            <PropositionHeader proposition={proposition} />
                            <div className="bc-proposition-description-card-container">
                                <PropositionDescription proposition={proposition} />
                            </div>
                            <div className="bc-proposition-answer-card-container">
                                <PropositionAnswerCard />
                            </div>
                        </div>
                    </div>
                    <div className="bc-proposition-show__section-right">
                        <PropositionDetails />
                        <div className="bc-contact-card-container">
                            <ContactCard proposition={proposition} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (device.isTablet) {
        content = (
            <div className="bc-proposition-show">
                <Helmet>
                    <title>{lexique.headTitle}</title>
                </Helmet>
                <Link to="/" className="bc-proposotion-show__link-back">
                    {`< ${lexique.back}`}
                </Link>
                <div className="bc-proposition-show-container">
                    <PropositionHeader proposition={proposition} />
                    <div className="bc-proposition-show__section-left">
                        <div className="bc-proposition-body-card-container">
                            <div className="bc-proposition-description-card-container">
                                <PropositionDescription proposition={proposition} />
                            </div>
                            <div className="bc-proposition-answer-card-container">
                                <PropositionAnswerCard proposition={proposition} />
                            </div>
                        </div>
                    </div>
                    <div className="bc-proposition-show__section-right">
                        <PropositionDetails />
                        <div className="bc-contact-card-container">
                            <ContactCard proposition={proposition} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="bc-proposition-show">
                <Helmet>
                    <title>{lexique.headTitle}</title>
                </Helmet>
                <Link to="/" className="bc-proposotion-show__link-back">
                    <div>{`< ${lexique.back}`}</div>
                </Link>
                <div className="bc-proposition-show-container">
                    <PropositionHeader proposition={proposition} />
                    <div className="bc-proposition-details-card-container">
                        <PropositionDetails />
                    </div>
                    {!!proposition && proposition.state === CONTACTED && proposition.answer_value === null && (
                        <div className="bc-proposition-answer-mobile-container">
                            <PropositionAnswerMobile />
                        </div>
                    )}
                    <div className="bc-proposition-description-card-container">
                        <PropositionDescription proposition={proposition} />
                    </div>
                    {!!proposition.state && !!proposition.answer_value && (
                        <div className="bc-proposition-answered-mobile-container">
                            <PropositionAnsweredMobile proposition={proposition} />
                        </div>
                    )}
                    <div className="bc-contact-card-container">
                        <ContactCard proposition={proposition} />
                    </div>
                </div>
            </div>
        );
    }

    return content;
};

export default PropositionPage;
