export default {
    headTitle: 'Ma Communauté - Kolsquare',
    placeholder: 'Vous devez ajouter votre compte Instagram pour obtenir des informations sur votre communauté.',
    text:
        "Les données présentées ci-dessous sont issues des Likers. Ainsi, il se peut qu'elles diffèrent des statistiques fournies directement sur Instagram qui sont issues des Followers.",
    ageChart: {
        title: 'Répartition des engagés par genre et par âge',
        legend: {
            male: 'Hommes',
            female: 'Femmes',
            years: ' ans',
        },
        placeholder: "Il n'y a pas de donnée.",
        bottomInfo: 'Source : Instagram',
    },
    geoChart: {
        title: 'Répartition des engagés par pays',
        tooltip: {
            country: 'Pays',
            followers: '',
        },
        placeholder: "Il n'y a pas de donnée.",
        bottomInfo: 'Source : Instagram',
    },
};
