import React, { Component } from 'react';
import { connect } from 'react-redux';
import classname from 'classnames';

import { Icon } from '@brandandcelebrities/kolkit';

import Tooltip from 'components/atoms/Tooltip';
import SocialPicto from 'components/atoms/SocialPicto';
import PostContent from 'components/atoms/PostContent';
import * as ICOS_INT from 'components/svgs/interactions';

import { datetimeFormatter, percentFormatter, simpleNumberFormatter } from 'utils';
import getLexique, { Locale } from 'locales';
import { Post as IPost } from 'reducers/posts';

import { AppState } from 'reducers';
import { PostLexique } from 'locales/types/containers/analyticsPage/posts';
import { Networks } from 'types/snas.d';

import './Post.scss';

interface Props {
    formats: any;
    lexique: PostLexique;
    data: IPost;
    locale: Locale;
}

interface State {
    tooltipDeployed: boolean;
}

class Post extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            tooltipDeployed: false,
        };
    }

    getStatsKeys = (social_network, type, variant) => {
        switch (social_network) {
            case 'facebook':
                return ['likes', 'comments', 'shares'];
            case 'instagram':
                if (variant.is_reel || variant.is_igtv) return [];
                if (type === 'video') {
                    return ['saved', 'videos_views', 'impressions', 'reach'];
                }
                return ['saved', 'impressions', 'reach'];
            case 'twitter':
                return ['likes', 'retweets'];
            case 'youtube':
                return ['likes', 'dislikes', 'comments', 'views'];
            case 'tiktok':
                return ['likes', 'comments', 'shares', 'plays'];
            default:
                return [];
        }
    };

    getDefaultInteractionIcon = (key) => {
        switch (key) {
            case 'likes':
                return ICOS_INT.ICO_THUMB_UP;
            case 'favorites':
                return ICOS_INT.ICO_HEART;
            case 'thumbsup':
                return ICOS_INT.ICO_THUMB_UP;
            case 'thumbsdown':
            case 'dislikes':
                return ICOS_INT.ICO_THUMB_DOWN;
            case 'stars':
                return ICOS_INT.ICO_STAR;
            case 'comments':
                return ICOS_INT.ICO_COMMENT;
            case 'shares':
                return ICOS_INT.ICO_SHARE_ALT;
            case 'retweets':
                return ICOS_INT.ICO_RETWEET;
            case 'views':
                return ICOS_INT.ICO_VIEW;
            case 'videos_views':
                return ICOS_INT.ICO_PLAY;
            case 'plays':
                return ICOS_INT.ICO_PLAY;
            case 'impressions':
                return ICOS_INT.ICO_EYE;
            case 'reach':
                return ICOS_INT.ICO_MEGAPHONE;
            case 'engagement_absolute':
                return ICOS_INT.ICO_ENGAGEMENT;
            case 'engagement_percent':
                return ICOS_INT.ICO_PERCENT;
            default:
                return null;
        }
    };

    getInteractionIcon = (social_network, key) => {
        switch (social_network) {
            case 'instagram':
                switch (key) {
                    case 'likes':
                        return ICOS_INT.ICO_THUMB_UP;
                    case 'comments':
                        return ICOS_INT.ICO_COMMENT;
                    case 'reach':
                        return <Icon className="bc-analytics-post-stats__detail-icon" label="bullseye" />;
                    case 'impressions':
                        return <Icon className="bc-analytics-post-stats__detail-icon" label="bullhorn" />;
                    case 'views':
                        return ICOS_INT.ICO_EYE;
                    case 'videos_views':
                        return ICOS_INT.ICO_EYE;
                    case 'saved':
                        return <Icon className="bc-analytics-post-stats__detail-icon" label="bookmark" />;
                    default:
                        return this.getDefaultInteractionIcon(key);
                }
            case 'facebook':
                switch (key) {
                    case 'likes':
                        return ICOS_INT.ICO_THUMB_UP;
                    case 'shares':
                        return ICOS_INT.ICO_SHARE_ALT;
                    case 'comments':
                        return ICOS_INT.ICO_COMMENT;
                    default:
                        return this.getDefaultInteractionIcon(key);
                }
            case 'twitter':
                switch (key) {
                    case 'likes':
                        return ICOS_INT.ICO_THUMB_UP;
                    case 'retweets':
                        return ICOS_INT.ICO_RETWEET;
                    default:
                        return this.getDefaultInteractionIcon(key);
                }
            case 'youtube':
                switch (key) {
                    case 'likes':
                        return ICOS_INT.ICO_THUMB_UP;
                    // case 'dislikes':
                    //   return ICOS_INT.ICO_THUMB_DOWN
                    case 'comments':
                        return ICOS_INT.ICO_COMMENT;
                    case 'views':
                        return ICOS_INT.ICO_VIEW;
                    // case 'favorites':
                    //   return ICOS_INT.ICO_HEART
                    default:
                        return this.getDefaultInteractionIcon(key);
                }
            default:
                return this.getDefaultInteractionIcon(key);
        }
    };

    toggleTooltip = () => {
        this.setState((prevState) => ({ tooltipDeployed: !prevState.tooltipDeployed }));
    };

    render() {
        const { formats, lexique, data, locale } = this.props;
        const { tooltipDeployed } = this.state;
        const engagement =
            data.social_network === Networks.instagram
                ? data.engagement_details.engagement
                : data.avg_engagement.absolute;
        return (
            <a rel="noopener noreferrer" href={data.content_url} target="_blank" className="bc-analytics-post-link">
                <div className="bc-analytics-post">
                    <div className="bc-analytics-post__image">
                        { data.is_reel && (
                            <Icon
                                className="bc-analytics-post__icon-type"
                                label="instagram-video"
                                theme="brand"
                                fill="white"
                                width="20"
                            />
                        )}
                        <img
                            className="bc-analytics-post__image-content"
                            src={data.picture_url}
                            alt=""
                        />
                    </div>
                    <div className="bc-analytics-post__content">
                        <div className="bc-analytics-post__top">
                            <div className="bc-analytics-post__date">
                                <div className="bc-analytics-post__day">
                                    {datetimeFormatter(data.published_at, locale, formats.longDate)}
                                </div>
                                <div className="bc-analytics-post__hour">
                                    {datetimeFormatter(data.published_at, locale, formats.hour)}
                                </div>
                            </div>
                            <div className="bc-analytics-post__social-network-shell">
                                <div className="bc-analytics-post-social-network__tooltip">
                                    <div
                                        className={classname({
                                            'bc-analytics-post-social-network-tooltip': true,
                                            'bc-analytics-post-social-network-tooltip--deployed': tooltipDeployed,
                                        })}
                                    >
                                        <div className="bc-analytics-post-social-network-tooltip__arrow" />
                                        <div className="bc-analytics-post-social-network-tooltip__content">
                                            {data.username || data.uid}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bc-analytics-post__social-network"
                                    onMouseEnter={this.toggleTooltip}
                                    onMouseLeave={this.toggleTooltip}
                                >
                                    <SocialPicto
                                        network={data.social_network}
                                        variant={{
                                            isStory: data.story,
                                            isIgtv: data.is_igtv,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <PostContent className="bc-analytics-post__text" content={data.content} />
                        <div className="bc-analytics-post__stats">
                            <div className="bc-analytics-post-stats__engagement">
                                <div className="bc-analytics-post-stats__engagement-label">{lexique.engagement}: </div>
                                <div className="bc-analytics-post-stats__engagement-rate">
                                    <span className="bc-analytics-post-stats__engagement-rate-value">
                                        {simpleNumberFormatter(engagement, formats.numbers)}
                                    </span>
                                    <span className="bc-analytics-post-stats__engagement-rate-percentage">
                                        {`(${percentFormatter(data.avg_engagement.percent)})`}
                                    </span>
                                </div>
                            </div>
                            <div className="bc-analytics-post-stats__details">
                                {this.getStatsKeys(data.social_network, data.type, {
                                    is_igtv: data.is_igtv,
                                    is_reel: data.is_reel,
                                }).map((k) => (
                                    <div key={k} className="bc-analytics-post-stats__detail">
                                        <Tooltip
                                            html={<div className="tooltip-small">{lexique.metrics[k]}</div>}
                                            position="top"
                                        >
                                            <div>
                                                {this.getInteractionIcon(data.social_network, k)}
                                                <div className="bc-analytics-post-stats__detail-metric">
                                                    {simpleNumberFormatter(data.engagement_details[k], formats.numbers)}
                                                </div>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    lexique: getLexique(state.env.locale).containers.analyticsPage.posts.post,
    formats: getLexique(state.env.locale).config,
    locale: state.env.locale,
});

export default connect(mapStateToProps, null)(Post);
