export default {
    title: 'Supprimer mon compte',
    question: 'Êtes-vous vraiment sûr(e) de vouloir supprimer votre compte ?',
    message:
        'La suppression de votre compte entraîne l’effacement total de toutes les informations que vous avez complétées, de l’historique de vos statistiques à tous les échanges que vous avez eus avec des marques. Votre profil disparaîtra de notre catalogue, ce qui signifie qu’il ne sera plus, ni identifiable, ni consultable, par les marques.',
    buttons: {
        yes: 'Je confirme la suppression définitive de mon compte',
        no: 'Non, annuler',
    },
    error: "Une erreur s'est produite. Veuillez reessayer.",
};
