export default {
    title: 'Delete my account',
    question: 'Are you sure you want to delete your account?',
    message:
        'Deleting your account will erase all the informations, from the history of your statistics to any conversations you had with brands. Your profile will be removed from our list of influencers, so it will no longer be findable and visible to brands.',
    buttons: {
        yes: 'Yes, delete my account',
        no: 'Cancel',
    },
    error: 'An unexpected error occured. Please retry.',
};
