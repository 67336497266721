import { all, fork } from 'redux-saga/effects';

import { watchAnalyticsPage } from './analyticsPage';
import { watchPropositionPage } from './propositionPage';

import { watchSocialStatsCard } from './socialStatsCard';
import { watchModal } from './modal';
import { watchReactRouter } from './reactRouter';
import { watchSna } from './sna';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        fork(watchAnalyticsPage),
        fork(watchPropositionPage),
        fork(watchModal),
        fork(watchSocialStatsCard),
        fork(watchReactRouter),
        fork(watchSna),
    ]);
}
