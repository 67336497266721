import React, { FC, memo, useCallback, useMemo } from 'react';

import { Radios } from '@brandandcelebrities/kolkit';
import InfoCircle from '@brandandcelebrities/icons/regular/InfoCircle';
import { Page } from 'types/Instaconnect';

import { Avatar } from 'components/molecules';

import styles from './PagesPicker.module.scss';
interface Props {
    lexique: {
        text: string;
        noPages: string;
        link: {
            label: string;
            url: string;
        };
    };
    pages: Page[];
    selected: string;
    onChange: (selected: string) => void;
}

const PagesPicker: FC<Props> = ({ lexique, pages, selected, onChange }) => {
    const handleChange = useCallback(
        ({ target }) => {
            onChange(target?.value);
        },
        [onChange],
    );

    const options = useMemo(
        () => {
            return pages.map( page => ({
                label: <Avatar name={page.displayName || page.name || page.username} avatar={page.picture} />,
                value: page.uid,
            }));
        },
        [pages]
    );

    if (pages?.length < 2) return null;

    return (
        <div className={styles.root}>
            <div className="bc-modal-content__text">
                <p>{lexique.text}</p>
            </div>
            <div className="bc-modal-content__btn-container bc-modal-content__btn-container--instagram">
                {pages.length === 0 && (
                    <div>
                        <div className="insta-business_no-page">
                            <div className="insta-business_no-page_square">
                                <div className="insta-business_no-page_icon">
                                    <InfoCircle />
                                </div>
                                <div className="insta-business_no-page_text">{lexique.noPages}</div>
                            </div>
                        </div>
                    </div>
                )}
                {pages.length > 0 && (
                    <Radios
                        options={options}
                        checked={selected}
                        direction="vertical"
                        onChange={handleChange}
                        className={styles.radios}
                        theme="KolLab"
                    />
                )}
            </div>
        </div>
    );
};

export default memo(PagesPicker);
