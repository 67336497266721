import PostsLexique from 'locales/types/containers/analyticsPage/posts';

export const postsLexique: PostsLexique = {
    headTitle: 'My Posts - Kolsquare',
    title: 'Content',
    placeholder: 'You must add at least one social account to search within your posts.',
    emptySearchPlaceholder: 'Sorry, there are no match for your search...',
    globalFilters: {
        label: 'Filter by:',
        socialNetworkFilter: {
            label: `Network`,
        },
        timeRangeFilter: {
            oneMonth: `1 month`,
            threeMonths: `3 months`,
            sixMonths: `6 months`,
            oneYear: `1 year`,
            all: `All the time`,
        },
    },
    searchCard: {
        title: 'All my posts',
        sortFilter: {
            options: {
                most_recent: 'Sort by date',
                most_engaging: 'Sort by engagements',
                most_liked: 'Sort by number of likes',
                most_relevant: 'Sort by relevance',
            },
        },
    },
    charts: {
        totalChart: {
            label: 'Number of posts',
        },
        averageChart: {
            label: 'Average engagements',
            tooltip: {
                engagement: 'engagements',
            },
            legends: {
                searchPosts: 'Posts matching your search',
                totalPosts: 'Total of posts',
            },
        },
        topHashtags: {
            title: 'Top Hashtag',
            description: 'List of hashtags you use that generate the most engagements (comments or likes).',
            engagementRate: 'Engagement rate',
            comments: 'Comments',
            likes: 'Likes',
            seeMore: 'See more',
            seeLess: 'See less',
        },
        topFlop: {
            top: {
                title: 'Top Posts',
                description: `The 3 posts that generated the most engagement`,
            },
            flop: {
                title: 'Flop Posts',
                description: `The 3 posts that generated the least engagement`,
            },
            metrics: {
                likes: 'Likes',
                comments: 'Comments',
                saved: 'Saves',
                views: 'Views',
                videos_views: 'Views',
                plays: 'Plays',
                impressions: 'Impressions',
                reach: 'Reach',
                shares: 'Shares',
                retweets: 'Retweets',
            },
            noResult: 'No result',
        },
    },
    post: {
        engagement: `Engagements`,
        metrics: {
            likes: `Likes`,
            thumbsup: `Likes`,
            thumbsdown: `Dislikes`,
            dislikes: `Dislikes`,
            retweets: `Retweets`,
            comments: `Comments`,
            shares: `Shares`,
            views: `Views`,
            saved: 'Saves',
            videos_views: `Views`,
            reach: `Reach`,
            impressions: `Impressions`,
            plays: 'Plays',
        },
    },
};

export default postsLexique;
