import React, { memo } from 'react';

import { Header } from 'components/atoms';
import { Placeholder } from 'components/molecules';
import getLexique from 'locales';
import { useSelector } from 'utils/redux';
import { TopFlopPosts as ITopFlopPosts } from 'reducers/ui/analytics';

import img from 'assets/images/proposition_list_empty.png';

import PostCard from '../PostCard';

import styles from './TopFlopPosts.module.scss';

interface Props {
    posts: ITopFlopPosts;
}

const TopFlopPosts: React.FC<Props> = ({ posts }) => {
    const { topPosts, flopPosts, error } = posts;
    const lexique = useSelector(({ env }) => getLexique(env.locale).containers.analyticsPage.posts.charts.topFlop);

    if (error) return null;

    return (
        <div className={styles.post}>
            <div className={styles.section} id="top-posts">
                <Header title={lexique.top.title} description={lexique.top.description} />
                <section className={styles.content}>
                    {topPosts?.length > 1 ? (
                        topPosts.map((post, index) => (
                            <PostCard data={post} rank={index + 1} key={`post-${post.id}`} position="top" />
                        ))
                    ) : (
                        <Placeholder img={img} tooltip="noResult" className={styles.placeholder} />
                    )}
                </section>
            </div>
            <div className={styles.section}>
                <Header title={lexique.flop.title} description={lexique.flop.description} />
                <section className={styles.content}>
                    {flopPosts?.length > 1 ? (
                        flopPosts.map((post, index) => (
                            <PostCard data={post} rank={index + 1} key={`post-${post.id}`} position="flop" />
                        ))
                    ) : (
                        <Placeholder img={img} tooltip="noResult" className={styles.placeholder} />
                    )}
                </section>
            </div>
        </div>
    );
};

export default memo(TopFlopPosts);
