import { Networks, supportedSortedSnaKeys, SOCIAL_NETWORKS } from 'constants/networks';
import { SnasState } from 'reducers/snas';
import { SNAObject, SnaDataChartFetch } from 'types/snas';
import { Stats } from './snas.d';

interface EmvBounds {
    min: number;
    max: number;
    nbFollowersMin: number;
    nbFollowersMax: number;
}

export const emvBounds: EmvBounds[] = [
    {
        nbFollowersMin: 0,
        nbFollowersMax: 1000,
        min: 80,
        max: 100,
    },
    {
        nbFollowersMin: 1000,
        nbFollowersMax: 10000,
        min: 400,
        max: 500,
    },
    {
        nbFollowersMin: 10000,
        nbFollowersMax: 30000,
        min: 480,
        max: 600,
    },
    {
        nbFollowersMin: 30000,
        nbFollowersMax: 80000,
        min: 960,
        max: 1200,
    },
    {
        nbFollowersMin: 80000,
        nbFollowersMax: 100000,
        min: 1200,
        max: 1500,
    },
    {
        nbFollowersMin: 100000,
        nbFollowersMax: 200000,
        min: 2400,
        max: 3000,
    },
    {
        nbFollowersMin: 200000,
        nbFollowersMax: 300000,
        min: 4800,
        max: 6000,
    },
    {
        nbFollowersMin: 300000,
        nbFollowersMax: 500000,
        min: 8000,
        max: 10000,
    },
    {
        nbFollowersMin: 500000,
        nbFollowersMax: 1000000,
        min: 16000,
        max: 20000,
    },
];

/*
 * get sna stats
 * @param object<Sna> sna: the sna object from the reducer (cf. reducer)
 * @param string network: the network concerned
 * @return object<Stats>: object composed of username, network, min and max
 */
export const getSnaStats = (sna: SNAObject, network: Networks, isInstagramStory = false): Stats => {
    const { username, stats } = sna;
    if (stats) {
      const { min_emv_per_story, max_emv_per_story, min_emv_per_post, max_emv_per_post, community_count } = stats;
      return {
        username,
        network,
        community_count,
        min: isInstagramStory ? min_emv_per_story : min_emv_per_post,
        max: isInstagramStory ? max_emv_per_story : max_emv_per_post,
      };
    }
    return {
      username,
      network,
      community_count: null,
      min: null,
      max: null,
    };
};

export const getSnaByUid = (snas: SnasState, network: Networks, uid: string): SNAObject =>
    snas[network].find((s) => s.uid === uid);

export const getSnaById = (snas: SnasState, network: Networks, id: number): SNAObject =>
    snas[network].find((s) => s.id === id);

/*
 * get snas stats
 * @param object<Snas>: "list" of snas from the reducer snas
 * @return array<Stats> snas
 */
export const getSnasStats = (snas: SnasState): Stats[] =>
    supportedSortedSnaKeys.reduce<Stats[]>((acc, network) => {
        const networkSnas = snas[network];
        const tmp = networkSnas.reduce<Stats[]>((acc2, sna) => {
            const stats = getSnaStats(sna, Networks[network]);
            acc2.push(stats);
            if (network === Networks.instagram) {
                acc2.push({
                    ...getSnaStats(sna, Networks.instagram_stories, true),
                });
            }
            return acc2;
        }, []);
        return [...acc, ...tmp];
    }, []);

/*
 * get sorted snas stats
 * @param object<Snas>: list of snas from the reducer snas
 * @return array<Stats> snas
 */
export const getSortedSnasStats = (snas: SnasState) => getSnasStats(snas);

/*
 * clean url
 */
export const formatSnaUrl = (url: string | null): string =>
    url && url !== '' ? `https://${url.replace(/(http:\/\/|https:\/\/|www\.)/gim, '').trim()}` : '';

export const isSnaUrlValid = (type: Networks, uri: string): boolean => {
    if (type === Networks.snapchat) return uri.length > 1;
    const url = formatSnaUrl(uri);

    if (type === Networks.website || type === Networks.blog || type === Networks.other_networks) {
        // const regexp = /^(http(?:s)?\:\/\/[a-zA-Z0-9]+(?:(?:\.|\-)[a-zA-Z0-9]+)+(?:\:\d+)?(?:\/[\w\-]+)*(?:\/?|\/\w+\.[a-zA-Z]{2,4}(?:\?[\w]+\=[\w\-]+)?)?(?:\&[\w]+\=[\w\-]+)*)$/gm;
        const regexp = /^(http(?:s)?:\/\/[a-zA-Z0-9]+(?:(?:\.|-)[a-zA-Z0-9]+)+(?::\d+)?(?:\/[\w-]+)*(?:\/?|\/\w+\.[a-zA-Z]{2,4}(?:\?[\w]+=[\w-]+)?)?(?:&[\w]+=[\w-]+)*)$/gm;
        return regexp.test(url);
    }

    const regexp = /(https:\/\/)+(facebook\.com|youtube\.com\/channel\/|twitter\.com|tiktok\.com|vm\.tiktok\.com|pinterest\.(com|fr|ch|be|it|es|co\.uk|de|ru)|21buttons\.com|twitch\.tv|instagram\.com)+[^\s]+[\w]/gim;
    if (!regexp.test(url)) return false;
    switch (type) {
        case Networks.facebook:
            return url.toLocaleLowerCase().indexOf('facebook.com/') > -1;
        case Networks.instagram:
            return url.toLocaleLowerCase().indexOf('instagram.com/') > -1;
        case Networks.twitter:
            return url.toLocaleLowerCase().indexOf('twitter.com/') > -1;
        case Networks.tiktok:
            return (
                url.toLocaleLowerCase().indexOf('tiktok.com/@') > -1 ||
                url.toLocaleLowerCase().indexOf('vm.tiktok.com/') > -1
            );
        case Networks.youtube:
            return url.toLocaleLowerCase().indexOf('youtube.com/channel/') > -1;
        case Networks.pinterest:
            return (/pinterest.(com|fr|ch|be|it|es|co\.uk|de|ru)\//).test(url?.toLocaleLowerCase());
        case Networks.twentyone_buttons:
            return url.toLocaleLowerCase().indexOf('21buttons.com/') > -1;
        case Networks.twitch:
            return url.toLocaleLowerCase().indexOf('twitch.tv/') > -1;
        default:
            return true;
    }
};

export const isSupportedSna = (type: Networks): boolean => SOCIAL_NETWORKS[type].supported;

export const pushSna = <T extends SNAObject | SnaDataChartFetch>(sna: T, array: T[], checkUrl = true): T[] => {
    const newArray = array.slice();
    // eslint-disable-next-line dot-notation
    const i = newArray.findIndex((sn) => sn.uid === sna.uid || (checkUrl && sn['url'] === sna['url']));
    if (i > -1) newArray[i] = sna;
    else newArray.push(sna);
    return newArray;
};

const isURL = (input: string): boolean => {
    try {
        const url = new URL(input);
        return !!url;
    } catch (_) {
        return false;
    }
};

export const constructFullUrl = (type: Networks, usernameOrUrl: string): string => {
    const urlIsValid = isURL(usernameOrUrl);
    switch (type) {
        case Networks.twitter:
            if (urlIsValid) return usernameOrUrl;
            return `https://twitter.com/${usernameOrUrl}`;
        case Networks.tiktok:
            const withoutQuery = (usernameOrUrl.split('?') || [])[0];
            if (urlIsValid) return withoutQuery;
            return `https://tiktok.com/${withoutQuery}`;
        case Networks.pinterest:
            if (urlIsValid) return usernameOrUrl;
            return `https://pinterest.fr/${usernameOrUrl}`;
        case Networks.twentyone_buttons:
            if (urlIsValid) return usernameOrUrl;
            return `https://21buttons.com/${usernameOrUrl}`;
        case Networks.twitch:
            if (urlIsValid) return usernameOrUrl;
            return `https://twitch.tv/${usernameOrUrl}`;
        default:
            return usernameOrUrl;
    }
};
