import React, { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'utils/redux';
import { AppState } from 'reducers';
import routes from 'config/routes';

const allowedRoutesOnTrialOver = [routes.settings, routes.faq, routes.unconfirmedEmail, routes.profile];

const OnboardingGuard: React.FC = () => {
    const { isEmailConfirmed, isTrialOver, isOnboarding, logged } = useSelector((state: AppState) => ({
        logged: state.env.logged,
        isEmailConfirmed: state.user.isEmailConfirmed,
        isTrialOver: state.user.isTrialOver,
        isOnboarding: !!state.onboarding.currentStep && state.onboarding.currentStep > 0,
    }));

    const location = useLocation();

    const toGoLink = useMemo(() => {
        if (!isOnboarding && location.pathname === routes.onboarding) return '/';
        if (isOnboarding && isTrialOver) return routes.onboarding; // to avoid infinite loop, onboarding has priority over "trial over" page
        if (isOnboarding && location.pathname !== routes.onboarding) return routes.onboarding;
        if (!isEmailConfirmed && isTrialOver && !allowedRoutesOnTrialOver.includes(location.pathname)) {
            return routes.unconfirmedEmail;
        }
        return null;
    }, [isOnboarding, isTrialOver, location, isEmailConfirmed]);

    if (!logged) return null;
    if (toGoLink) return <Redirect to={toGoLink} />;
    return null;
};

export default OnboardingGuard;
