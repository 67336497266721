import React, { memo, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const isNotNull = (v) => v && v.trim() !== '' && v.trim() !== '​\n';

interface Props {
    children: string;
    className?: string;
}

const Markdown = ({ children, className }: Props) => {
    const content = useMemo(() => isNotNull(children) ? children : '', [children]);

    let markdownParsed = content;
    const matchesUnderline = content.match(/\++.+?\++/g);
	if (matchesUnderline?.length) {
		matchesUnderline.forEach(mark => {
			markdownParsed = markdownParsed.replaceAll(mark, `~~${mark.replaceAll('++','').trim()}~~`)
		});
	}

    return (
        <div className={className}>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                    del: (props) => <ins>{props.children}</ins>,
                }}
            >
                {markdownParsed}
            </ReactMarkdown>
        </div>
    );
}

Markdown.defaultProps = {
    className: '',
};
export default memo(Markdown);
