import Cookies from 'universal-cookie';
import NF from 'simple-number-formatter';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';

import { Locale } from 'locales';
import { conf } from '../config/env';

export const getTokenFromCookies = (): string => {
    const cookies = new Cookies();
    if (conf.overrideEnv) return process.env.REACT_APP_COOKIE_TOKEN;

    return cookies.get(conf.cookieToken);
};

export const random = (max: number): number => Math.floor(Math.random() * max);
export const randomRange = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;
export const randomBool = (): boolean => random(2) === 1;
export const randomFromArray = (arr: Array<any>) => arr[random(arr.length)];

export const round = (n: number, p: number): number => {
    const factor = 10 ** p;
    const tempNumber = n * factor;
    const roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
};

export const ceil = (n: number, p: number): number => {
    const factor = 10 ** p;
    const tempNumber = n * factor;
    const roundedTempNumber = Math.ceil(tempNumber);
    return roundedTempNumber / factor;
};

export const floor = (n: number, p: number): number => {
    const factor = 10 ** p;
    const tempNumber = n * factor;
    const roundedTempNumber = Math.floor(tempNumber);
    return roundedTempNumber / factor;
};

export const isNumberStat = (n) => n !== null && n > 0;

export const numberPrice = (n: number): number => ceil(n, -3);

export interface LexiqueOfNums {
    b: string;
    k: string;
    m: string;
}

export const numberFormatter = (n: number, lexique: LexiqueOfNums, includeSpace = false): string => {
    if (n === null || Number.isNaN(n)) return '-';
    const s = includeSpace ? ' ' : '';
    // 100B+
    if (n >= 1e11) return `${(n / 1e9).toString().substring(0, 3)}${s}${lexique.b}`;
    // 1.00B+
    if (n >= 1e9) return `${(n / 1e9).toString().substring(0, 4)}${s}${lexique.b}`;
    // 100M+
    if (n >= 1e8) return `${(n / 1e6).toString().substring(0, 3)}${s}${lexique.m}`;
    // 1.00M+
    if (n >= 1e6) return `${(n / 1e6).toString().substring(0, 4)}${s}${lexique.m}`;
    // 100K+
    if (n >= 1e5) return `${(n / 1e3).toString().substring(0, 3)}${s}${lexique.k}`;
    // 1.00K+
    if (n >= 1e3) return `${(n / 1e3).toString().substring(0, 4)}${s}${lexique.k}`;

    // else
    if (Number.isInteger(n)) return n.toString();

    return NF(round(n, 1), '0.0');
};

export const thousandsDelimit = (n: number): string => {
    if (n === null || n === undefined || Number.isNaN(n)) return '-';
    return NF(n, '0,[00]', { thousandsDelimeter: ' ' });
};

export const simpleNumberFormatter = (n: number, format = '0.00'): string => {
    if (n === null || n === undefined || Number.isNaN(n)) return '-';
    if (Number.isInteger(n)) return n.toString();
    return NF(round(n, 1), format);
};

export const percentFormatter = (n: number, format = '0.00') => {
    if (n == null || Number.isNaN(n)) return '-';
    if (Number.isInteger(n)) return `${n}%`;
    if (n < 0.01 && n > -0.01) return '0%';
    return `${NF(round(n, 2), format)}%`;
};

export const percentFormatterSpace = (n: number, format = '0.00'): string => {
    if (n == null || Number.isNaN(n)) return '-';
    if (Number.isInteger(n)) return `${n} %`;
    if (n < 0.01 && n > -0.01) return '0 %';
    return `${NF(round(n, 2), format)} %`;
};

export const pointFormatter = (n: number, format = '0.00'): string => {
    if (n == null || Number.isNaN(n)) return '-';
    if (Number.isInteger(n)) return `${n} pt`;
    if (n < 0.01 && n > -0.01) return '0 pt';
    return `${NF(round(n, 2), format)} pt`;
};

export const percentFormatterCeil = (n: number, format = '0.00'): string => {
    if (n == null || Number.isNaN(n)) return '-';

    return `${NF(ceil(n, 2), format)}%`;
};

/*
export const dateFormatter = (d, locale) => new Date(d).toLocaleString(locale, { weekday: "long", year: "numeric", month:'long', day: 'numeric' });
*/

export const datetimeFormatter = (d, locale: Locale, format = 'LLLL') => {
    const m = moment(d);
    m.locale(locale.toLowerCase());
    return m.format(format);
};

// nl2br
export const nl2br = (str: string): string => String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
