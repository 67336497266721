import { useEffect, useContext, useRef, useCallback, useState, useMemo } from 'react';
import ActionCableContext from './ActionCableContext';
import useDeepComparaison from '../useDeepComparaison';

interface Params {
  channel: string;
  params?: { [x: string]: any };
  skip?: boolean;
  onDataReceived: (data?: any) => void;
  getInitialState?: boolean;
  onConnected?: () => void;
}

const useActionCable = ({ channel, params, onDataReceived, skip = false, getInitialState = false, onConnected }: Params) => {
  const cable = useContext(ActionCableContext);
  const cachedParams = useDeepComparaison(params || {});
  const refChannel = useRef(null);
  const [connected, setConnected] = useState(false);

  const paramsLog = useMemo(
    () => cachedParams ? `, params: ${JSON.stringify(cachedParams)}`:'',
    [cachedParams]
  );

  const getState = useCallback(
    () => {
      if (refChannel) {
        refChannel?.current?.perform('initial_state');
      }
    },
    [refChannel]
  );

  const unsubscribe = useCallback(
    () => {
      if (refChannel) {
        refChannel?.current?.unsubscribe();
        cable?.subscriptions?.remove({ channel });

        console.info(`[actionCable -> ${channel} unsubscribe ${paramsLog}]`);
      }
    },
    [refChannel, cable, channel, paramsLog]
  );

  useEffect(() => {
    if (cable && channel && !skip) {
      refChannel.current = cable.subscriptions.create(
        { channel, ...cachedParams },
        {
          received: onDataReceived,
          initialized: () => console.info(`[actionCable -> ${channel} initialized ${paramsLog}]`),
          connected: () => {
            console.info(`[actionCable -> ${channel} connected ${paramsLog}]`);
            setConnected(true);
            getInitialState && getState();
            if (onConnected) onConnected();
          },
          disconnected: () => {
            console.info(`[actionCable -> ${channel} disconnected ${paramsLog}]`);
            setConnected(false);
          },
          rejected: () => console.info(`[actionCable -> ${channel} rejected ${paramsLog}]`),
        },
      );
    }
    return () => {
      unsubscribe();
    }
  }, [
    refChannel,
    cable,
    cachedParams,
    skip,
    channel,
    onDataReceived,
    getInitialState,
    getState,
    onConnected,
    unsubscribe,
    paramsLog
  ]);

  return {
    connected,
    channel: refChannel.current,
    unsubscribe
  }

};

export default useActionCable;
