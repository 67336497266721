import { createAction } from 'redux-actions';
import api from 'api';
import { isSnaUrlValid, isSupportedSna } from 'utils/snas';
import { Networks } from 'constants/networks';
import { validAccount, recordNotUnique, privateAccount, unknownAccount } from 'utils/onboarding';
import { ValidationError } from 'containers/OnboardingPage/steps/StepNetworks/types.d';
import * as ONBOARDING from 'constants/onboarding';
import { OnboardingState } from 'reducers/onboarding';

import { fetchSnaSuccess, postSnaSuccess } from './socialStatsCard';

const ADD_SNA_STEP = 3;

export const updateOnboardingState = createAction<OnboardingState>(ONBOARDING.UPDATE_STATE);

export const saveSnaToStore = (snaInfos) => async (dispatch) => {
    dispatch(postSnaSuccess(snaInfos));
    const fetchSnaResponse = snaInfos.uid ? await api.fetchSna(snaInfos.uid) : { data: { id: null } };
    dispatch(
        fetchSnaSuccess({
            ...fetchSnaResponse.data,
            uid: snaInfos.uid,
            label: snaInfos.label,
            url: snaInfos.url,
        }),
    );
};

// TODO: convert to a redux action
export const saveSna = async (type: Networks, url: string): Promise<{ snaInfo?: any; error?: ValidationError }> => {
    if (!isSnaUrlValid(type, url)) {
        return { error: 'invalidUrl' };
    }
    if (!isSupportedSna(type)) {
        try {
            await api.postUnsupportedSnaRequest({ type, url });
            return { snaInfo: { uid: null, label: type, url } };
        } catch (err) {
            return { error: 'errorUnknown' };
        }
    }
    try {
        const snaInfoResponse = await api.fetchInfoSna(type, url, true);
        const snaInfo = snaInfoResponse?.data;
        if (validAccount(snaInfoResponse)) {
            try {
                await api.postSnaRequest(snaInfo, ADD_SNA_STEP);
                return { snaInfo };
            } catch (err) {
                if (recordNotUnique) return { error: 'recordNotUnique' };
                return { error: 'unknownAccount' };
            }
        }
        if (privateAccount(snaInfoResponse)) return { error: 'privateAccount' };
        if (unknownAccount(snaInfoResponse)) return { error: 'unknownAccount' };
        return { error: 'errorUnknown' };
    } catch (err) {
        return { error: 'errorUnknown' };
    }
};
