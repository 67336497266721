import { all, call, fork, put, take, takeEvery, select } from 'redux-saga/effects';

import * as ENV from 'constants/env';
import {
    fetchSnasSuccess,
    fetchSnasFailure,
    removeSnaSuccess,
    removeSnaFailure,
    displayRemoveToast,
    fetchSnaTopPostsSuccess,
    fetchSnaTopPostsFailure,
} from 'actions/sna';
import { fbc } from 'config/connect';
import * as types from 'constants/sna';
import { ActionMeta } from 'redux-actions';
import api from '../api';

export function* watchSna() {
    yield all([fork(watchAppInitSuccess), fork(watchRemoveSna), fork(watchLoadSnaTopPosts)]);
}

export function* watchAppInitSuccess() {
    yield take(ENV.FETCH_APP_INIT_SUCCESS);
    yield fork(fetchSnas);
}

export function* fetchSnas() {
    const { isEmailConfirmed, isTrialOver } = yield select((state) => state.user);
    if (!isEmailConfirmed && isTrialOver) return false;

    try {
        const { data } = yield call(api.fetchSnas);
        yield put(fetchSnasSuccess(data));
    } catch (error) {
        yield put(fetchSnasFailure(error));
    }
}

export function* watchRemoveSna() {
    let action = yield take([ENV.FETCH_APP_INIT_SUCCESS, types.REMOVE_SNA]);
    if (action.type === types.REMOVE_SNA) {
        yield take(ENV.FETCH_APP_INIT_SUCCESS);
    } else if (action.type === ENV.FETCH_APP_INIT_SUCCESS) {
        action = yield take(types.REMOVE_SNA);
    }

    yield fork(removeSna, action.payload);

    yield takeEvery(types.REMOVE_SNA, function* (action: ActionMeta<any, any>) {
        yield fork(removeSna, action.payload);
    });
}

export function* removeSna(payload) {
    try {
        if (payload.uid) {
            yield call(api.removeSna, payload.uid);
        } else {
            yield call(api.removeUnsupportedSna, payload.network);
        }
        yield put(removeSnaSuccess(payload));
        if (payload.network === 'instagram') {
            fbc.requestLogout((response) => {
                console.log('fbc deconnected: ', response)
            });
        }
        const locale = yield select((state) => state.env.locale);

        displayRemoveToast(payload.network, locale);
    } catch (err) {
        console.log(err);
        yield put(removeSnaFailure(err));
    }
}

export function* watchLoadSnaTopPosts() {
    yield takeEvery(types.LOAD_SNA_TOP_POSTS, function* (action: any) {
        yield fork(fetchSnaTopPosts, action.payload);
    });
}

export function* fetchSnaTopPosts(params) {
    try {
        const { timeRange, snaId, network } = params;
        const response = yield call(api.fetchSnaTopPosts, { timeRange, snaId });
        yield put(
            fetchSnaTopPostsSuccess({
                network,
                snaId,
                topPosts: response.data.top_posts,
            }),
        );
    } catch (error) {
        console.log(error);
        yield put(fetchSnaTopPostsFailure(error, params.snaId));
    }
}
