/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, memo, Children, useEffect, useCallback, useMemo, useRef } from 'react';
import _sortBy from 'lodash.sortby';
import _orderBy from 'lodash.orderby';

import { Loader } from '@brandandcelebrities/kolkit';

import routes from 'config/routes';
import { AppState } from 'reducers';
import { CurrentConversationState, ConversationFilters, ConversationState, ENUM_TABS, DraftState } from 'reducers/messaging';
import MessagingLexique from 'locales/types/containers/messagingPage';
import { history } from 'store/configureStore';

import { getConversations, getDraftMessages, mobileDisplay, updateFilters, updateConversationsListFromWS } from 'actions/messaging';
import { showModale } from 'actions/modale';

import { useDispatch, useSelector, useLexique } from 'utils/redux';
import useLoading from 'utils/hooks/useLoading';
import useDebounce from 'utils/hooks/useDebounce';
import { CHANNELS, useActionCable } from 'utils/hooks/useActionCable';

import { CardMessage, CardDraft, List, TabMenu, PanelHeader, Placeholder } from 'containers/messagingPage/Components';

const TABS = ['all', 'unread', 'draft'];

interface Selector {
  hasConversations: boolean;
  hasMore: boolean;
  conversations: ConversationState[];
  drafts: DraftState[];
  isMobile: boolean;
  currentConversation: CurrentConversationState;
  filters: ConversationFilters;
  allUnreadMessages: number;
  me: number;
}

const Inbox: FC = () => {
  const lexique = useLexique<MessagingLexique>('containers.messagingPage');
  const dispatch = useDispatch();
  const observer = useRef(null);

  const { loading } = useLoading('listConversations');
  const { hasConversations, conversations, drafts, isMobile, currentConversation, filters, hasMore, allUnreadMessages, me } = useSelector<Selector>(
    ({ env, messaging, user }: AppState) => {
      const filters = messaging.filters;
      return {
        isMobile: env.device.isMobile,
        hasConversations: messaging.hasConversations,
        conversations: messaging.conversations,
        drafts: _orderBy(messaging.draftMessages, ['updatedAt'], 'desc'),
        currentConversation: messaging.currentConversation,
        filters,
        hasMore: filters.tab === ENUM_TABS.draft ? messaging.hasDraftMore : messaging.hasMore,
        allUnreadMessages: messaging.allUnreadMessages,
        me: user.profileId
      }
    }
  );

  const debouncedFilters = useDebounce(filters, 200);

  useEffect(() => {
    if (debouncedFilters?.tab === ENUM_TABS.draft) {
      dispatch(getDraftMessages(debouncedFilters));
    } else {
      dispatch(getConversations(debouncedFilters));
    }
  }, [dispatch, debouncedFilters]);

  useEffect( // force to load last conversation on desktop
    () => {
      if (!loading && !isMobile && currentConversation.id === null) {
        const orderedConversations = conversations.length !== 0
          ? _sortBy(conversations, (c) => new Date(c.lastMessageAt)).reverse()
          : null

        const lastConversationId = orderedConversations ? orderedConversations[0].id : null;
        if (lastConversationId) history.push(`${routes.conversations}/${lastConversationId}`);
      }
    },
    [loading, isMobile, currentConversation.id]
  );

  const handleConversationsListFromWS = useCallback(
    data => {
      dispatch(updateConversationsListFromWS(data));
    },
    [dispatch]
  );

  useActionCable({
    channel: CHANNELS.INBOX,
    onDataReceived: handleConversationsListFromWS,
  });

  const loadMore = useCallback(
    () => {
      if(loading) return false;

      dispatch(updateFilters({
        page: filters.page + 1,
      }));
    },
    [dispatch, loading]
  );

  const lastProfileRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver( entries => {
        if (entries[0].isIntersecting && hasMore) {
          return loadMore()
        }
      })
      if (node) observer.current.observe(node)
    },
    [hasMore, observer, loadMore]
  );

  const goToMessages = useCallback(
    async id => {
      // if already selected and loaded
      if (!isMobile && currentConversation?.id?.toString() === id?.toString()) return false;

      isMobile && await dispatch(mobileDisplay(false))
      history.push(`${routes.conversations}/${id}`);
    },
    [dispatch, isMobile, currentConversation]
  );

  const handleDelete = useCallback(
    id => dispatch(
      showModale({
        body: 'ConfirmRemoveDraft',
        data: {id},
      })),
    [dispatch]
  );

  const handleChangeTab = useCallback(
    ({ value }) => {
      if (!hasConversations) return false;

      dispatch(updateFilters({
        page: 0,
        tab: value,
      }));
    },
    [dispatch, hasConversations]
  );

  const handleChangeKeyword = useCallback(
    ({value}) => {
      if (!hasConversations) return false;

      dispatch(updateFilters({
        page: 0,
        name: value,
      }));
    },
    [dispatch, hasConversations]
  );

  const tabsDataset = useMemo(
    () => {
      return TABS.map(tab => ({
        label: (tab === 'unread' && allUnreadMessages > 0) ? `${lexique[tab]?.title} (${allUnreadMessages})` : lexique[tab]?.title,
        value: tab,
        disabled: !hasConversations,
      }))
    },
    [lexique, allUnreadMessages, hasConversations]
  );

  const getActiveTab = useMemo(
    () => TABS.findIndex(id => id === filters.tab),
    [filters]
  );

  const getConversationList = useMemo(
    () => {
      if (conversations.length === 0) return (
        <Placeholder description={lexique[filters.tab].noResult} />
      )

      return Children.toArray(conversations ?.map( (item, index) => (
        <CardMessage
          ref={conversations?.length === index + 1 ? lastProfileRef : null}
          index={index}
          lastMessage={item?.lastMessage}
          draft={item?.draft}
          participants={item?.participants}
          hasBeenRead={item?.hasBeenRead}
          subject={item?.subject}
          date={item?.lastMessageAt}
          unreadMessages={item?.unreadMessages}
          active={currentConversation?.id?.toString() === item?.id?.toString()}
          onClick={() => goToMessages(item?.id)}
          lexique={lexique}
          me={me}
          animated
        />
      )));
    },
    [conversations, lexique, goToMessages, currentConversation, lastProfileRef]
  );

  const getDraftList = useMemo(
    () => {
      if (drafts.length === 0) return (
        <Placeholder description={lexique.draft.noResult} />
      )

      return Children.toArray(drafts?.map( (item, index) => (
        <CardDraft
          ref={drafts?.length === index + 1 ? lastProfileRef : null}
          index={index}
          content={item?.content}
          active={currentConversation?.id?.toString() === item?.conversationId?.toString()}
          participants={item?.participants}
          lexique={lexique}
          object=''
          date={item?.updatedAt}
          onClick={() => goToMessages(item?.conversationId)}
          onDelete={() => handleDelete(item?.id)}
          me={me}
          animated
        />
      )));
    },
    [drafts, lexique, goToMessages, currentConversation, lastProfileRef]
  );

  const renderList = useMemo(
    () => {
      if (!hasConversations) return (
        <Placeholder description={lexique.noConversations} />
      )

      return filters.tab === ENUM_TABS.draft
        ? getDraftList
        : getConversationList
    },
    [getDraftList, getConversationList, filters, lexique]
  );

  return (
    <>
      <PanelHeader
        title={lexique.panelHeader.title}
        placeholder={lexique.panelHeader.search}
        onChange={handleChangeKeyword}
        searchQuery={filters.name}
        disabled={!hasConversations}
      />
      <TabMenu
        onClick={handleChangeTab}
        dataset={tabsDataset}
        activeIndex={getActiveTab}
      />
      {loading && <Loader full background="rgba(255, 255, 255, .75)" /> }
      <List>
        {renderList}
      </List>
    </>
  )
};

Inbox.displayName = 'Inbox';

export default memo(Inbox);
