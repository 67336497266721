import { PlaceholderLexique } from 'locales/types/containers/homePage/homeSlide/placeholder';

const placeholder: PlaceholderLexique = {
    title: `Welcome to Community! 🎉🎉`,
    text: `Connect your Instagram account directly for accurate and relevant statistics about your community`,
    submit: 'Submit',
    button: {
        label: `Add my instagram account`,
        subtext: `(business only)`,
    },
};

export default placeholder;
