import { useRef } from 'react';
import _isEqual from 'lodash.isequal';

const deepCompareEquals = (prevVal, currentVal) => _isEqual(prevVal, currentVal);

const useDeepComparaison = (value) => {
  const ref = useRef<{ [x: string]: any }>();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export default useDeepComparaison;
