import React, { FC } from 'react';
import classname from 'classnames';
import _isEmpty from 'lodash.isempty';

import {
    MicroEndorsement,
    SponsoredEventPosts,
    SponsoredPosts,
    YtProductPlacement,
} from 'components/svgs/projectTypes';
import { CANCELED, CONTACTED, REFUSED, SIGNED, VALIDATED } from 'constants/proposition';

import './PropositionHeader.scss';
import { useLexique } from 'utils/redux';
import { Proposition } from 'reducers/proposition';
import PropositionHeaderLexique from 'locales/types/containers/propositionPage/propositionHeader';

interface Props {
    proposition: Proposition;
}

const ProjectTypes = {
    micro_endorsement: <MicroEndorsement />,
    youtube_product_placement: <YtProductPlacement />,
    sponsorised_posts: <SponsoredPosts />,
    sponsorised_posts_and_event: <SponsoredEventPosts />,
};

const PropositionHeader: FC<Props> = ({ proposition }) => {
    const lexique = useLexique<PropositionHeaderLexique>('containers.propositionPage.propositionHeader');

    return (
        <div className="bc-proposition-header-card">
            {proposition.state && (
                <div
                    className={classname({
                        'bc-proposition-header-card__status': true,
                        'bc-proposition-header-card__status--green': proposition.state === VALIDATED,
                        'bc-proposition-header-card__status--yellow': proposition.state === CONTACTED,
                        'bc-proposition-header-card__status--red':
                            proposition.state === REFUSED || proposition.state === CANCELED,
                        'bc-proposition-header-card__status--blue': proposition.state === SIGNED,
                    })}
                >
                    {lexique.state[proposition.state]}
                </div>
            )}
            <div
                className={classname({
                    'bc-proposition-header-card__body': true,
                    'bc-proposition-header-card__body--answered': !(
                        proposition.state === CONTACTED && proposition.answer_value === null
                    ),
                })}
            >
                <div className="bc-proposition-header-body__picto">
                    {proposition.project &&
                        !_isEmpty(proposition.project.project_type) &&
                        ProjectTypes[proposition.project.project_type]}
                </div>
                <div className="bc-proposition-header-body__content">
                    <div className="bc-proposition-header-content__title">
                        {proposition.project && proposition.project.name}
                    </div>
                    <div className="bc-proposition-header-content__brand">
                        {proposition.project && proposition.project.client_name}
                    </div>
                </div>
            </div>
            {proposition.state === CONTACTED && proposition.answer_value === null && (
                <div className="bc-proposition-header__answer">
                    <a href="#answer">{lexique.answer}</a>
                </div>
            )}
        </div>
    );
};

export default PropositionHeader;
