import PostsLexique from 'locales/types/containers/analyticsPage/posts';

export const postsLexique: PostsLexique = {
    headTitle: 'Mes Posts - Kolsquare',
    title: 'Contenu',
    placeholder: 'Vous devez ajouter au moins un réseau social pour rechercher parmi vos posts.',
    emptySearchPlaceholder: "Désolé, votre recherche n'a retourné aucun résultat",
    globalFilters: {
        label: 'Filtrer par :',
        socialNetworkFilter: {
            label: `Réseaux`,
        },
        timeRangeFilter: {
            oneMonth: `1 mois`,
            threeMonths: `3 mois`,
            sixMonths: `6 mois`,
            oneYear: `1 an`,
            all: `Tout le temps`,
        },
    },
    searchCard: {
        title: 'Tous mes posts',
        sortFilter: {
            options: {
                most_recent: 'Trier par date',
                most_engaging: 'Trier par engagements',
                most_liked: 'Trier par nombre de likes',
                most_relevant: 'Trier par pertinence',
            },
        },
    },
    charts: {
        totalChart: {
            label: 'Nombre de posts',
        },
        averageChart: {
            label: 'Engagements moyen',
            tooltip: {
                engagement: 'engagements',
            },
            legends: {
                searchPosts: 'Posts correspondant à votre recherche',
                totalPosts: 'Ensemble de vos posts',
            },
        },
        topHashtags: {
            title: 'Top Hashtag',
            description:
                "Liste des hashtags que vous utilisez qui génèrent le plus d'engagements (commentaires ou likes).",
            engagementRate: "Taux d'engagement",
            comments: 'Commentaires',
            likes: 'Likes',
            seeMore: 'Voir plus',
            seeLess: 'Voir moins',
        },
        topFlop: {
            top: {
                title: 'Top Posts',
                description: `Les 3 posts qui ont généré le plus d'engagements`,
            },
            flop: {
                title: 'Flop Posts',
                description: `Les 3 posts qui ont généré le moins d'engagements`,
            },
            metrics: {
                likes: 'Likes',
                comments: 'Commentaires',
                saved: 'Saves',
                views: 'Vues',
                videos_views: 'Vues',
                plays: 'Plays',
                impressions: 'Impressions',
                reach: 'Portée',
                shares: 'Partages',
                retweets: 'Retweets',
            },
            noResult: 'Aucun résultat',
        },
    },
    post: {
        engagement: `Engagements`,
        metrics: {
            likes: `Likes`,
            thumbsup: `Likes`,
            thumbsdown: `Dislikes`,
            dislikes: `Dislikes`,
            retweets: `Retweets`,
            saved: 'Saves',
            comments: `Commentaires`,
            shares: `Partages`,
            views: `Vues`,
            videos_views: `Vues`,
            reach: `Reach`,
            impressions: `Impressions`,
            plays: 'Plays',
        },
    },
};

export default postsLexique;
