import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import isEmail from 'validator/lib/isEmail';
import { Link } from 'react-router-dom';

import { StepWrapper, Input, Button, Checkbox, Loader } from '@brandandcelebrities/kolkit';

import { Locale } from 'locales';
import { submitRegister } from 'actions/user';
import routes from 'config/routes';
import { useLexique, useDispatch, useSelector } from 'utils/redux';
import { changeLocaleRequest } from 'actions/env';
import StepperHeader from 'components/molecules/StepperHeader';

import styles from './RegisterPage.module.scss';

const REQUIRED_FIELDS = ['email', 'password', 'acceptLegalTerms'];
const MIN_PASSWORD_SIZE = 12;

const RegisterPage: React.FC = () => {

  const lexique = useLexique('containers.registerPage');
  const dispatch = useDispatch();
  const [register, setRegister] = useState({
    email: '',
    password: '',
    acceptLegalTerms: false,
  });
  const [error, setError] = useState({
    email: '',
    password: '',
  });
  const [displayErrors, setDisplayErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const { query } = useSelector(({ router }) => ({
    query: router?.location.query,
  }));

  useEffect(
    () => {
      if (query?.email) {
        setRegister(s => ({
          ...s,
          email: decodeURIComponent(query.email)
        }));
      }

      if (query?.lang || query?.locale) {
        const lang = (query.lang || query.locale)?.indexOf('fr') > -1 ? Locale.fr_FR : Locale.en_GB;
        dispatch(changeLocaleRequest(lang));
      }
    },
    [query, dispatch]
  );

  const verifyField = useCallback(({ field, value }) => {
    if ((field === "email" || field === "password") && value.trim() === "") return "empty";
    if (field === "password" && value.length < MIN_PASSWORD_SIZE) return "passwordLength";
    if (field === "email" && !isEmail(value.trim())) return "invalidEmail";
    if (field === 'acceptLegalTerms' && !value)
      return 'hasNotAcceptedLegalTerms';
    return "";
  }, []);

  const handleChange = useCallback(
    ({name: field, value}: {name: string, value: string | boolean}) => {
      const _error = verifyField({ field, value });
      setRegister(s => ({
        ...s,
        [field]: value
      }));
      if (!displayErrors) return false;
      setError(s => ({
        ...s,
        [field]: _error
      }));
    },
    [verifyField, displayErrors]
  );

  const handleCheckbox = useCallback(
    () => {
      setRegister(s => ({
        ...s,
        acceptLegalTerms: !s.acceptLegalTerms
      }));
    },
    []
  );

  const verifyForm = useCallback(() => {
    const errors = REQUIRED_FIELDS.reduce((previous, field) => {
      const fieldError = verifyField({
        field,
        value: register[field],
      });
      return Object.assign(previous, fieldError && { [field]: fieldError });
    }, {} as {email: string, password: string});

    setError(errors);

    return Object.keys(errors).length > 0;
  }, [verifyField, register]);

  const onSubmitForm = useCallback(
    async () => {
      if (!displayErrors) setDisplayErrors(true);

      const hasError = verifyForm();
      if (hasError) {
        setDisplayErrors(true);
        return false;
      }

      setLoading(true);

      const data = {
        ...register,
        token: query?.token
      }
      const response = await dispatch(submitRegister(data));
      if (response === 'alreadyUsedEmail') {
        setError(s => ({
          ...s,
          'email': 'alreadyUsedEmail'
        }));
      }
      setLoading(false);
    },
    [dispatch, displayErrors, verifyForm, register, query]
  );

  const handleBlur = useMemo(() => setDisplayErrors(true), []);

  const hasError = useCallback(
    (input) => Boolean(displayErrors && error[input]),
    [error, displayErrors]
  );

  const isEmailValid = useMemo(
    () => !hasError('email') && register.email.trim() !== '',
    [hasError, register.email]
  );

  const isPasswordValid = useMemo(
    () => !hasError('password') && register.password.trim() !== '',
    [hasError, register.password]
  );

  const successEmailIcon = useMemo(
    () => register.email && isEmail(register.email.trim()) ? 'check':null,
    [register.email]
  );

  const successPasswordIcon = useMemo(
    () => register.password && register.password.length >= MIN_PASSWORD_SIZE  ? 'check':null,
    [register.password]
  );

  const isEmailDisabled = useMemo(
    () => query?.email && query?.email?.trim() !== '',
    [query]
  );

  return (
    <>
    <Helmet>
      <title>{lexique.headTitle}</title>
    </Helmet>
    <div className={styles.background}>
      <div className={styles.logo}>
        <span className={styles.imgPlaceholder} />
      </div>
      <StepWrapper className={styles.stepper} totalStep={8} step={1} width="100%">
        <div className={styles.contentWrapper}>
          {loading && <Loader full background="rgba(255, 255, 255, .8)" /> }
          <StepperHeader header={lexique.title} title={lexique.subtitle} />
          <div className={styles.description}>
            {lexique.description}
          </div>
          <div className={styles.fieldSet}>
            <Input
              name="email"
              fullWidth
              size="big"
              onChange={handleChange}
              value={register.email}
              placeholder={lexique.email}
              error={hasError('email')}
              errorMessage={lexique.error[error.email]}
              icon={successEmailIcon}
              valid={isEmailValid}
              onBlur={handleBlur}
              disabled={isEmailDisabled}
              forceError={hasError('email')}
            />
          </div>
          <div className={styles.fieldSet}>
            <Input
              name="password"
              type="password"
              fullWidth
              size="big"
              onChange={handleChange}
              value={register.password}
              placeholder={lexique.password.replace('{{min}}', MIN_PASSWORD_SIZE)}
              error={hasError('password')}
              errorMessage={lexique.error[error.password]?.replace('{{min}}', MIN_PASSWORD_SIZE)}
              icon={successPasswordIcon}
              valid={isPasswordValid}
              onBlur={handleBlur}
              forceError={hasError('password')}
            />
          </div>
          <Checkbox
            name="acceptLegalTerms"
            className={styles.checkbox}
            id="acceptLegalTerms"
            label={
              <p
                className={styles.acceptLegalTerms}
                dangerouslySetInnerHTML={{
                  __html: lexique.acceptLegalTerms,
                }}
              />
            }
            labelClassName={styles.labelCheck}
            checked={register.acceptLegalTerms}
            onChange={handleCheckbox}
          />
          {hasError('acceptLegalTerms') && (
            <div className={styles.error}>
              {lexique.error.hasNotAcceptedLegalTerms}
            </div>
          )}
          <Button
            label={lexique.submit}
            onClick={onSubmitForm}
            disabled={loading}
            fullWidth
            size="big"
            className={styles.button}
          />
          <Link to={routes.login}>
            <Button
              theme="navy"
              size="big"
              fullWidth
              className={styles.button}
              label={lexique.signIn}
            />
          </Link>
        </div>
      </StepWrapper>
    </div>
    </>
  );
};

export default memo(RegisterPage);
