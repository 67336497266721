import CF from 'currency-formatter';

export const ceil = (n, p) => {
    const factor = 10 ** p;
    const tmp = n * factor;
    const rounded = Math.ceil(tmp);
    return rounded / factor;
};

export const numberPrice = (n: number) => Math.round(ceil(n, 1));

export const formatPrice = (n: number, locale: string, currency: string): string =>
    n !== null ? CF.format(numberPrice(n), { locale, code: currency, precision: 0 }) : null;
