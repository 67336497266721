import React, { FC, useCallback, memo } from 'react';
import cn from 'classnames';

import { useDispatch, useLexique } from 'utils/redux';

import { openModal } from 'actions/socialStatsCard';
import SocialPicto from 'components/atoms/SocialPicto';
import { Networks } from 'types/snas';

import SocialStatsHomePageLexique from 'locales/types/components/socialStats/socialStatsCardEmpty';

import styles from './SocialStatsCard.module.scss';

interface Props {
    type: Networks;
}

const SocialStatsCardEmpty: FC<Props> = ({ type }) => {
    const dispatch = useDispatch();
    const lexique = useLexique<SocialStatsHomePageLexique>('components.socialStatsCards.socialStatsCardEmpty');

    const onClickButton = useCallback(() => dispatch(openModal(type)), [type, dispatch]);

    const cnContainer = cn(styles.container, styles.empty, styles[type]);

    return (
        <button type="button" className={cnContainer} onClick={onClickButton}>
            <div className={styles.picto}>
                <SocialPicto network={type} fill="#fff" />
            </div>
            <p className={styles.cardText}>{lexique.text[type]}</p>
        </button>
    );
};

export default memo(SocialStatsCardEmpty);
