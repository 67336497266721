import React, { FC, memo, useMemo, useCallback } from 'react';
import cn from 'classnames';

import { Button } from '@brandandcelebrities/kolkit';

import { useSelector, useLexique, useDispatch } from 'utils/redux';
import { unsupportedSocialNetwork } from 'constants/networks';
import SocialName from 'components/molecules/SocialName';
import { UnSupportedSnaListLexique } from 'locales/types/containers/shared/UnSupportedSnaListLexique';
import { SnasState } from 'reducers/snas';
import { showModale } from 'actions/modale';

import styles from './SocialStatsUnsupported.module.scss';

interface State {
    snas: SnasState;
}

const SocialStatsUnsupported: FC = () => {
    const lexique: UnSupportedSnaListLexique = useLexique<UnSupportedSnaListLexique>(
        'containers.shared.unSupportedSnaList',
    );
    const { snas }: State = useSelector(({ snas }): State => ({ snas }));
    const dispatch = useDispatch();

    const profileUnsupportedSnas = useMemo(() => {
        return Object.values(unsupportedSocialNetwork).reduce((prev, { id: network }) => {
            const snasOfNetwork = snas[network];
            const sna = snasOfNetwork[0];

            if (!sna || (sna && sna.url.length === 0)) return prev;

            const cnSna = cn(styles.item, styles[network]);
            return [
                ...prev,
                <div className={cnSna} key={`${network}`}>
                    <SocialName name={sna.url} network={network} />
                </div>,
            ];
        }, []);
    }, [snas]);

    const onClickEditButton = useCallback(
        () =>
            dispatch(
                showModale({
                    body: 'SnaForm',
                }),
            ),
        [dispatch],
    );

    if (profileUnsupportedSnas.length === 0) {
        const cnContainer = cn(styles.container, styles.empty);
        return (
            <div className={cnContainer}>
                <div className={styles.buttonWrapper}>
                    <Button icon="plus" theme="navy" onClick={onClickEditButton} className={styles.button}>
                        {lexique.add}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>{lexique.title}</h3>
            <p className={styles.subTitle}>{lexique.subTitle}</p>
            <div className={styles.wrapper}>{profileUnsupportedSnas}</div>
            <div className={styles.buttonWrapper}>
                <Button icon="pen" theme="navy" onClick={onClickEditButton} className={styles.button}>
                    {lexique.edit}
                </Button>
            </div>
        </div>
    );
};

export default memo(SocialStatsUnsupported);
