import { OnboardingPageLexique } from 'locales/types/containers/onboardingPage';

const onboardingPageLexique: OnboardingPageLexique = {
    header: 'Créer mon profil',
    steps: {
        infos: {
            title: 'Remplis ton profil pour gagner en visibilité',
            placeholders: {
                firstName: 'Prénom *',
                lastName: 'Nom *',
                stageName: 'Pseudo *',
                gender: 'Genre',
                birthdate: 'Date de naissance *',
                nationality: 'Nationalité *',
                livingAddress: 'Ville de résidence *',
            },
            errors: {
                requiredNameOrPseudo: 'Remplis ton pseudo sur les réseaux et/ou ton nom complet',
                required: 'Remplis ton pseudo sur les réseaux et/ou ton nom complet',
            },
        },
        activity: {
            title: 'Parle-nous de ton activité',
            labels: {
                activities: 'Activités (3 max)',
                influenceThemes: 'Thèmes d’influence (5 max)',
                website: 'Site web officiel (optionnel)',
            },
            placeholders: {
                activities: 'Sélectionner...',
                influenceThemes: 'Sélectionner...',
                website: 'http://',
            },
        },
        networksInstagramOnly: {
            title: 'Associe ton Instagram',
            helpText: 'Connecte ton instagram pour partager des statistiques avec {brandname}',
            helpTextMany:
                'Connecte ton instagram pour partager des statistiques avec {brandname} et {length} marque(s)',
        },
        networks: {
            title: 'Associe tes réseaux d’influence',
            titleInstaconnected: 'Top ! Est-ce que ton influence s’étend sur un autre réseau ?',
            helpText: 'Ajoutes-en au moins un pour commencer à recevoir des offres.',
            skipAndCreate: 'Ignorer et Valider mon profil',
            footerText: 'Tu pourras toujours en ajouter d’autres par la suite',
            cancel: 'Annuler',
            instagram: {
                label: 'Via ton compte Facebook',
                errors: {
                    invalidUrl: `L'adresse de votre compte Instagram est invalide.`,
                    privateAccount: `Ce compte est privé`,
                    recordNotUnique: `Ce compte est déjà pris`,
                    unknownAccount: `Ce compte n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            youtube: {
                label: 'Via ton compte Google',
                inputTitle: 'URL de la chaîne',
                inputPlaceholder: 'https://www.youtube.com/channel/kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre chaîne Youtube est invalide.`,
                    privateAccount: `Cette chaîne est privée`,
                    recordNotUnique: `Cette chaîne est déjà prise`,
                    unknownAccount: `Cette chaîne n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            facebook: {
                inputTitle: 'URL de la page',
                inputPlaceholder: 'https://www.facebook.com/Kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre fanpage Facebook est invalide.`,
                    privateAccount: `Cette fanpage est privée`,
                    recordNotUnique: `Cette fanpage est déjà prise`,
                    unknownAccount: `Cette fanpage n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            tiktok: {
                inputTitle: `Nom d'utilisateur`,
                inputPlaceholder: '@Kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre compte Tiktok est invalide.`,
                    privateAccount: `Ce compte est privé`,
                    recordNotUnique: `Ce compte est déjà pris`,
                    unknownAccount: `Ce compte n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            twitter: {
                inputTitle: `Nom d'utilisateur`,
                inputPlaceholder: 'Kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre compte Twitter est invalide.`,
                    privateAccount: `Ce compte est privé`,
                    recordNotUnique: `Ce compte est déjà pris`,
                    unknownAccount: `Ce compte n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            pinterest: {
                inputTitle: `URL`,
                inputPlaceholder: 'https://www.pinterest.fr/kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre compte Pinterest est invalide.`,
                    privateAccount: `Ce compte est privé`,
                    recordNotUnique: `Ce compte est déjà pris`,
                    unknownAccount: `Ce compte n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            snapchat: {
                inputTitle: `Nom d'utilisateur`,
                inputPlaceholder: 'kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre compte Snapchat est invalide.`,
                    privateAccount: `Ce compte est privé`,
                    recordNotUnique: `Ce compte est déjà pris`,
                    unknownAccount: `Ce compte n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            twentyone_buttons: {
                inputTitle: `URL`,
                inputPlaceholder: 'https://www.21buttons.com/kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre compte 21 buttons est invalide.`,
                    privateAccount: `Ce compte est privé`,
                    recordNotUnique: `Ce compte est déjà pris`,
                    unknownAccount: `Ce compte n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            twitch: {
                inputTitle: `URL`,
                inputPlaceholder: 'https://www.twitch.tv/kolsquare',
                errors: {
                    invalidUrl: `L'adresse de votre chaîne Twitch est invalide.`,
                    privateAccount: `Cette chaîne est privée`,
                    recordNotUnique: `Cette chaîne est déjà prise`,
                    unknownAccount: `Cette chaîne n'existe pas`,
                    errorUnknown: `Erreur serveur`,
                },
            },
            status: {
                set: 'Associé',
                unset: 'Associer',
                editing: 'OK',
                validating: 'OK',
            },
            see: {
                more: 'Afficher plus',
                less: 'Afficher moins',
            },
        },
        instaConnect: {
            backButton: 'Retour',
            header: 'Instagram connect',
            title: 'Quelques étapes sont nécessaires',
            text: 'Vérifions ensemble que tout est en place  👀',
            titleFinished: 'Okay! Tout est en place pour associer ton Instagram 🎉',
            positiveButton: 'Oui',
            negativeButton: 'Non, je ne sais pas',
            instaAccountType: {
                question: 'As-tu un profil Insta Créateur ou Pro ?',
                modal: {
                    title: 'Comment changer ton type de compte',
                    text:
                        'Instagram a renforcé son processus de connexion et demande un compte pro ou créateur pour utiliser Kolsquare. Dans les paramètres de ton compte, va sur “Passer à un compte professionnel” et suis les étapes.',
                    imgPath: '/imgs/onboarding/instaconnect-01.png',
                    primaryActionLabel: 'Ouvrir Instagram',
                },
            },
            fbAccountType: {
                question: 'As-tu une page Facebook Business et en mode Administrateur ?',
                modal: {
                    title: 'Comment créer une page Facebook Business',
                    text:
                        'Instagram utilise Facebook Pay pour les paiements. Il te faut une page pour recevoir de l’argent.',
                    imgPath: '/imgs/onboarding/instaconnect-02.png',
                    primaryActionLabel: 'Créer ma page Facebook',
                },
            },
            linkedAccount: {
                question: 'Ta page Facebook est-elle reliée à ton compte Instagram (pro ou créateur) ?',
                modal: {
                    title: 'Comment relier ta page Facebook à ton compte Instagram',
                    imgPath: '/imgs/onboarding/instaconnect-03.png',
                    primaryActionLabel: 'Relier mon compte sur Facebook',
                },
            },
            modalSecondaryActionLabel: 'C’est fait !',
            fbConnect: 'Connecter mon compte via FB Connect',
            fbConnectWarning: '⚠️  Active bien toutes les autorisations, Kolsquare en aura besoin pour fonctionner.',
            error: {
                title: 'Hmm... Il semble y avoir une erreur 🤔',
                text: 'Nous n’avons pas pu connecter ton compte Instagram à Kolsquare. Vérifie que tu as bien rempli toutes les étapes, et retente l’association. ',
                instagramNotFound: "Le compte Instagram qui a été sélectionné ne correspond pas au compte Instagram déjà ajouté. Veuillez recommencer et sélectionner le bon compte Instagram en cliquant sur “Modifier les paramètres” après l'étape de Login.",
                retry: 'Réessayer',
                goBack: 'Connecter un autre compte',
            },
            submit: 'Enregistrer',
        },
        instaStatsSharing: {
            shareButton: `Activer le partage`,
            skipButton: `Partager mes statistiques plus tard`,
        },
    },
    next: 'Continuer',
    validate: 'Valider mon profil',
};

export default onboardingPageLexique;
