export default {
    headTitle: 'My Account - Kolsquare',
    pageTitle: `My account`,
    subTitleEmail: `Login credentials`,
    subTitleAccountDeletion: `Account deletion`,
    email: 'E-mail',
    submit: `Submit`,
    password: `Password`,
    newPassword: `New password`,
    confirmNewPassword: `Confirm new password`,
    titleSNA: `Your social networks`,
    disconnect: {
        button: `Disconnect`,
        instagram: `Connect your Instagram account to get your audience profile as well as advanced statistics`,
    },
    success: {
        email: `A confirmation e-mail was sent to this address`,
        password: `Your password has been changed.`,
    },
    error: {
        email: `Sorry, this email is already taken.`,
        password: `Sorry, your password is incorrect`,
    },
    deleteAccount: 'Delete my account',
};
