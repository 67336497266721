import React, { FC, memo } from 'react';

import { useLexique } from 'utils/redux';
import { InstagramButton } from 'components/atoms';

import InstaConnectLexique from 'locales/types/components/instaconnect';

import './Placeholder.scss';

interface Props {
    onInstaConnectButtonClick: () => void;
}

const Placeholder: FC<Props> = ({ onInstaConnectButtonClick }) => {
    const lexique = useLexique<any>('containers.homePage.homeSlide.placeholder');
    const instaConnectLexique = useLexique<InstaConnectLexique>('components.instaconnect');
    return (
        <div className="bc-home-slide__placeholder">
            <div className="bc-home-slide__placeholder__content">
                <div className="bc-home-slide__placeholder__content__title">{lexique.title}</div>
                <div className="bc-home-slide__placeholder__content__text">{lexique.text}</div>
                <div className="bc-home-slide__placeholder__content__button">
                    <InstagramButton
                        onClick={onInstaConnectButtonClick}
                        lexique={instaConnectLexique.instagramButton}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(Placeholder);
