import React, { memo } from 'react';
import classname from 'classnames';
import { ReactComponent as Stars } from 'assets/images/svg/quality-score-stars.svg';

import './quality-score.scss';

interface Props {
    qualityScore?: number;
    radiusSize?: number;
}

const QualityScore: React.SFC<Props> = ({ qualityScore, radiusSize }) => {
    const size = radiusSize;
    const modifiedQualityScore = qualityScore || 0;
    const withStars = qualityScore >= 60;

    const radius = size / 2;
    const sizeSvg = size + 10;
    const center = sizeSvg / 2;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - modifiedQualityScore / 100);

    return (
        <div className="bc-quality-score" style={{ width: sizeSvg, height: sizeSvg }}>
            {withStars && (
                <div className="bc-quality-score__drawing">
                    <Stars />
                </div>
            )}
            <div className="bc-quality-score__inner">
                <p className="bc-quality-score__inner__score">
                    {qualityScore !== null ? `${Math.floor(qualityScore)}` : '-'}
                </p>
                <span>/100</span>
            </div>
            <svg style={{ transform: 'rotate(-90deg)' }} width={sizeSvg} height={sizeSvg}>
                <circle
                    className="bc-quality-score__bg-circle"
                    cx={center}
                    cy={center}
                    r={radius}
                    fill="none"
                    stroke="#DDDDDD"
                />
                <circle
                    className={classname('bc-quality-score__circle', {
                        'bc-quality-score__circle--pink': modifiedQualityScore >= 90,
                        'bc-quality-score__circle--green': modifiedQualityScore >= 60 && modifiedQualityScore < 90,
                        'bc-quality-score__circle--orange': modifiedQualityScore >= 30 && modifiedQualityScore < 60,
                        'bc-quality-score__circle--red': modifiedQualityScore < 30,
                    })}
                    cx={center}
                    cy={center}
                    r={radius}
                    fill="none"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </svg>
        </div>
    );
};

QualityScore.defaultProps = {
    radiusSize: 35,
    qualityScore: null,
};

export default memo(QualityScore);
