import { useSelector as useRedux, shallowEqual, useDispatch as useReduxDispatch } from 'react-redux';
import _isEqual from 'lodash.isequal';
import _get from 'lodash.get';
import { AnyAction, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import getLexique from 'locales';

import { AppState } from '../reducers';

/*
 * Overload to add thunk support to Redux's dispatch() function.
 * Useful for react-redux or any other library which could use this type.
 */
export interface Dispatch {
    <TReturnType, TState, TExtraThunkArg>(
        action: ThunkAction<TReturnType, TState, TExtraThunkArg, AnyAction> | Action<any>,
    ): TReturnType;
}

const useSelector = <Selection>(selector: (state: AppState) => Selection) => useRedux(selector, shallowEqual);
const useDeepSelector = <Selector extends Record<string, any>>(selector): Selector => useRedux(selector, _isEqual);
const useDispatch = () => useReduxDispatch<Dispatch>();

/* hook that enables us to quickly find lexique */
const useLexique = <Lexique extends Record<string, any>>(key: string): Lexique => {
    const { lexique } = useDeepSelector((state: AppState) => ({
        lexique: getLexique(state.env.locale),
    }));
    return _get(lexique, key, {});
};

interface KeyMap {
    [key: string]: string;
}

/* same hook but for multiple lexiques, useful to avoid repetition and get performant selector */
const useLexiques = <Lexique extends Record<string, any>>(keys: KeyMap): Lexique => {
    const { lexique } = useDeepSelector((state: AppState) => ({
        lexique: getLexique(state.env.locale),
    }));
    return Object.keys(keys).reduce<Lexique>(
        (acc, key) => ({ ...acc, [key]: _get(lexique, keys[key], {}) }),
        {} as any,
    );
};
export { useDispatch, useLexique, useLexiques, useSelector, useDeepSelector };
