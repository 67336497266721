import React, { FC, memo, useCallback } from 'react';

import { onChangeSocialNetworksFilter, onChangeTimeRangeFilter } from 'actions/analytics';

import './GlobalFilters.scss';
import { TimeRange } from 'types/Filters';
import { TimeRangeSelector } from 'components/molecules';
import { useDispatch, useLexique, useSelector } from 'utils/redux';
import { supportedSortedSnaKeys } from 'constants/networks';
import SelectSocialNetwork from '../SelectSocialNetwork';

export const GlobalFilters: FC = () => {
    const dispatch = useDispatch();
    const lexique = useLexique<any>('containers.analyticsPage.posts.globalFilters');

    const changeTimeRangeFilter = useCallback(
        (timeRange: TimeRange) => {
            dispatch(onChangeTimeRangeFilter(timeRange));

            // Google Analytics
            window.ga('send', 'event', {
                eventCategory: 'Posts',
                eventAction: `Filter`,
                eventLabel: timeRange,
            });
        },
        [dispatch],
    );
    const changeSocialNetworksFilter = (socialNetwork) => {
        dispatch(onChangeSocialNetworksFilter(socialNetwork));

        // Google Analytics
        if (socialNetwork.selected) {
            window.ga('send', 'event', {
                eventCategory: 'Posts',
                eventAction: `Filter`,
                eventLabel: `Add ${socialNetwork.label}`,
            });
        } else {
            window.ga('send', 'event', {
                eventCategory: 'Posts',
                eventAction: `Filter`,
                eventLabel: `Remove ${socialNetwork.label}`,
            });
        }
    };

    const { timeRangeFilter, socialNetworksFilter } = useSelector(({ ui }) => ({
        timeRangeFilter: ui.analytics.posts.globalFilters.timeRangeFilter,
        socialNetworksFilter: Object.values(ui.analytics.posts.globalFilters.socialNetworksFilter).sort(
            (a: any, b: any) => supportedSortedSnaKeys.indexOf(a.label) - supportedSortedSnaKeys.indexOf(b.label),
        ),
    }));

    return (
        <div className="bc-analytics-stats__global-filters">
            <div className="bc-analytics-stats-global-filters__label">{lexique.label}</div>
            <SelectSocialNetwork
                placeholder={lexique.socialNetworkFilter.label}
                dataset={socialNetworksFilter}
                onClickItem={changeSocialNetworksFilter}
            />
            <div className="bc-analytics-stats-global-filters__time-filter">
                <TimeRangeSelector
                    id="timeRangeFilter"
                    timeRange={timeRangeFilter}
                    onTimeRangeChange={changeTimeRangeFilter}
                />
            </div>
        </div>
    );
};

export default memo(GlobalFilters);
