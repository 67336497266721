import React, { Children, memo, useMemo, useState } from 'react';
import cn from 'classnames';
import { Li, Truncate } from '@brandandcelebrities/kolkit';

import DropdownButton from 'components/atoms/DropdownButton';
import MessagingLexique from 'locales/types/containers/messagingPage';
import configZIndex from 'config/zIndex';
import { useSelector, useLexique } from 'utils/redux';

import styles from './SelectSubject.module.scss';

const SelectSubject = ({ label, messageId, onSelect, order, ...rest }) => {
  const lexique = useLexique<MessagingLexique>('containers.messagingPage');

  const [open, setOpen] = useState(false);
  const subjectThreads = useSelector( ({ messaging }) => messaging.currentConversation?.subjectThreads);

  const options = useMemo(
    () => (subjectThreads || []).map(s => ({
      label: s.subject,
      selected: messageId === s.id,
      active: true,
      disabled: false,
      onClick: () => onSelect(s.id)
    })),
    [subjectThreads, messageId, onSelect]
  );

  const getStyle = useMemo(
    () => ({
      zIndex: open ? configZIndex.portal : configZIndex.default + order
    }),
    [open, order]
  );

  if (subjectThreads?.length === 0) return null;

  return (
    <div style={getStyle} {...rest}>
      <DropdownButton
        onOpen={setOpen}
        label={<Truncate>{label}</Truncate>}
        subHeader={lexique.chat.goTo}
        className={styles.dropdown}
        subHeaderClassName={styles.subHeaderClassName}
        size="big"
        theme="ghost"
      >
        {Children.toArray(options.map( option => {
          if (!option.active) return null;

          const cnLi = cn(styles.item, {
            [styles.selected]: option.selected
          });
          return (
            <Li
              onClick={option.onClick}
              disabled={option.disabled}
              className={cnLi}
            >
              <Truncate>{option.label}</Truncate>
            </Li>
          )})) as []
        }
      </DropdownButton>
    </div>
  )
};

SelectSubject.defaultName = 'SelectSubject';

export default memo(SelectSubject);
