import analyticsPage from './analyticsPage';
import homePage from './homePage';
import loginPage from './loginPage';
import registerPage from './registerPage';
import settingsPage from './settingsPage';
import profilePage from './profilePage/profilePage';
import propositionPage from './propositionPage';
import projectsPage from './projectsPage/projectsPage';
import partenariatsPage from './partenariatsPage';
import faqPage from './faqPage';
import shared from './shared';
import onboardingPage from './onboardingPage';
import unconfirmedEmailPage from './unconfirmedEmailPage';
import messagingPage from './messagingPage';

export default {
    analyticsPage,
    homePage,
    loginPage,
    registerPage,
    settingsPage,
    profilePage,
    propositionPage,
    projectsPage,
    partenariatsPage,
    shared,
    faqPage,
    onboardingPage,
    unconfirmedEmailPage,
    messagingPage,
};
