import { createReducer } from 'redux-create-reducer';

import * as PROFILE from 'constants/profile';
import * as SOCIAL_STATS_CARD from 'constants/socialStatsCard';
import * as SNA from 'constants/sna';
import * as ENV from 'constants/env';
import * as FBC from 'constants/fbc';

import { Action } from 'redux';
import { SOCIAL_NETWORKS, Networks, unsupportedSocialNetwork } from 'constants/networks';
import { SNAObject, SNAMappedData } from 'types/snas';
import { pushSna } from 'utils/snas';
import {
    FetchSNASAction,
    Reducers,
    SnasState,
    RemoveSNAAction,
    FetchSNAAction,
    FetchAppInitAction,
    OnConnectAction,
    FetchSNATopPostsAction,
    ProfileSaveAction,
} from './snas.reducer.d';

const emptySna = (sn): SNAObject => ({
    id: null,
    url: '',
    uid: null,
    username: null,
    label: sn,
    stats: null,
    supported: SOCIAL_NETWORKS[sn].supported,
    filled: false,
});

export const initialState: SnasState = Object.keys(SOCIAL_NETWORKS).reduce((acc, sn) => {
    return {
        ...acc,
        [sn]: [],
    };
}, {});

const mergePayloads = (current: SNAMappedData, payload: SNAMappedData) => {
    return {
        ...Object.keys(current).reduce((acc, data) => {
            if (payload[data]) acc[data] = payload[data];
            return acc;
        }, current),
    };
};

const getUpdatedData = (state: SnasState, payload: SNAMappedData, update = true): SNAObject => {
    const network: Networks = payload.label;
    const current = state[network].find(
        (sn) =>
            (payload.uid && sn.uid === payload.uid) ||
            (sn.id && sn.id === payload.id) ||
            (payload.url && sn.url === payload.url),
    );
    const data = update && !!current ? mergePayloads(current, payload) : mergePayloads(emptySna(network), payload);

    return {
        id: data.id,
        uid: data.uid,
        username: data.username,
        supported: SOCIAL_NETWORKS[data.label].supported,
        url: data.url,
        label: network,
        filled: SOCIAL_NETWORKS[data.label].supported ? !!data.uid : !!data.url,
        stats: data.stats || null,
    };
};

const onConnect = (state: SnasState, { payload }: OnConnectAction) => ({
    ...state,
    [payload.label]: pushSna(
        {
            ...getUpdatedData(state, payload),
            filled: true,
            stats: {
                avg_engagement_per_post: null,
                avg_engagement_rate: null,
                avg_engagement_rate_per_post: null,
                community_count: null,
                community_progression_rate: null,
                engagement_details: null,
                engagement_rate_progression: null,
                total_posts: null,
                posts_per_week: null,
                emv: null,
                min_emv_per_post: null,
                max_emv_per_post: null,
                emv_per_story: null,
                min_emv_per_story: null,
                max_emv_per_story: null,
            },
            valid_token: true,
        },
        state[payload.label],
    ),
});

const fetchSnas = (state: SnasState, { payload }: FetchSNASAction): SnasState =>
    payload.reduce<SnasState>(
        (acc, sna) => ({
            ...acc,
            [sna.label]: pushSna(getUpdatedData(acc, sna), state[sna.label]),
        }),
        state,
    );

const fetchSna = (state: SnasState, { payload }: FetchSNAAction): SnasState => ({
    ...state,
    [payload.label]: pushSna(getUpdatedData(state, payload), state[payload.label]),
});

const removeSna = (state: SnasState, { payload: { uid, network } }: RemoveSNAAction): SnasState => ({
    ...state,
    [network]: state[network].filter((f) => {
        return SOCIAL_NETWORKS[network].supported ? f.uid !== uid : false;
    }),
});

const saveWebsiteOnProfileSave = (
    state: SnasState,
    {
        payload: {
            profile: { website },
        },
    }: ProfileSaveAction,
): SnasState => ({
    ...state,
    website: [
        state.website && state.website[0]
            ? { ...state.website[0], url: website }
            : {
                  id: null,
                  uid: null,
                  username: null,
                  supported: false,
                  url: website,
                  label: Networks.website,
                  filled: true,
                  stats: null,
              },
    ],
});

const onFetchAppInit = (state: SnasState, { payload: { response } }: FetchAppInitAction) =>
    unsupportedSocialNetwork.reduce<SnasState>((acc, { id }) => {
        if (acc[id] && acc[id][0]) {
            acc[id][0].url = response[id] || '';
        } else {
            acc[id] = [
                getUpdatedData(state, {
                    id: null,
                    url: response[id] || '',
                    label: id,
                }),
            ];
        }

        return acc;
    }, state);

const fetchSnaTopPosts = (
    state: SnasState,
    { payload: { network, snaId, topPosts } }: FetchSNATopPostsAction,
): SnasState => {
    return {
        ...state,
        [network]: state[network].map((sna) => {
            if (sna.id === snaId) {
                return {
                    ...sna,
                    top_posts: topPosts,
                };
            }
            return sna;
        }),
    };
};

// @TODO change constants, use new ones. This is temporary because no time to update sagas...

export const reducers: Reducers = {
    [SOCIAL_STATS_CARD.FETCH_STATS_SNA_SUCCESS]: fetchSna,
    [SNA.FETCH_SNAS_SUCCESS]: fetchSnas,
    [SNA.REMOVE_SNA_SUCCESS]: removeSna,
    [SNA.FETCH_SNA_TOP_POSTS_SUCCESS]: fetchSnaTopPosts,
    [PROFILE.SAVE]: saveWebsiteOnProfileSave,
    [FBC.ON_CONNECT]: onConnect,
    [ENV.FETCH_APP_INIT_SUCCESS]: onFetchAppInit,
};

export default createReducer<SnasState, Action<Reducers>>(initialState, reducers);
