export default {
    headTitle: 'Mon Compte - Kolsquare',
    pageTitle: `Mon compte`,
    subTitleEmail: `Paramètres de connexion`,
    subTitleAccountDeletion: `Suppression de compte`,
    email: 'E-mail',
    submit: `Valider`,
    password: `Mot de passe`,
    newPassword: `Nouveau mot de passe`,
    confirmNewPassword: `Confirmer nouveau mot de passe`,
    titleSNA: `Vos réseaux sociaux`,
    disconnect: {
        button: `Déconnexion`,
        instagram: `Connectez-vous avec Instagram pour accéder au profil de votre audience ainsi qu'a des statistiques avancées`,
    },
    success: {
        email: `Un mail de confirmation vous a été envoyé.`,
        password: `Mot de passe changé avec succès.`,
    },
    error: {
        email: `Désolé, cet email est déjà utilisé.`,
        password: `Désolé, votre mot de passe est incorrect`,
    },
    deleteAccount: `Supprimer mon compte`,
};
