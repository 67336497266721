import React, { FC, memo, useMemo } from 'react';
import moment from 'moment';

import { useLexique, useSelector } from 'utils/redux';

interface Props {
  timestamp: string;
  format?: string;
  showTime?: boolean;
  rest?: any;
  className?: string;
}

const Timeago: FC<Props> = ({ timestamp, format, showTime, ...rest }) => {
  const lexique = useLexique('config');
  const locale = useSelector(({ env }) => env.locale );

  const REFERENCE = moment();

  const isNow = useMemo(
    () => REFERENCE.diff(timestamp, 's') < 59,
    [REFERENCE, timestamp]
  );

  const isToday = useMemo(
    () => {
      const today = REFERENCE.startOf('day');
      return moment(timestamp).isSame(today, 'd');
    },
    [REFERENCE, timestamp]
  );

  const isYesterday = useMemo(
    () => {
      const yesterday = REFERENCE.subtract(1, 'days').startOf('day');
      return moment(timestamp).isSame(yesterday, 'd');
    },
    [REFERENCE, timestamp]
  );

  const timeago = useMemo(
    () => {
      moment.locale(locale.toLowerCase());
      const time = showTime ? moment(timestamp).format('H:mm') : '';

      if (isNow) return lexique?.now?.replace('{{time}}', time);
      if (isToday) return lexique?.today?.replace('{{time}}', time);
      if (isYesterday) return lexique?.yesterday?.replace('{{time}}', time);

      return moment(timestamp).format(showTime ? `${format} H:mm` : format);
    },
    [timestamp, lexique, locale, isNow, isToday, isYesterday, showTime, format]
  );

  return (
    <span {...rest}>
      {timeago}
    </span>
  )
};

Timeago.displayName = 'Timeago';

Timeago.defaultProps = {
  format: 'DD MMM',
  showTime: false
}

export default memo(Timeago);
