import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import ButtonBase from '@material-ui/core/ButtonBase';
import { Icon, Tooltip } from '@brandandcelebrities/kolkit';

import SocialName from 'components/molecules/SocialName';
import PostContent from 'components/atoms/PostContent';

import getLexique from 'locales';

import { datetimeFormatter, percentFormatter, thousandsDelimit } from 'utils';
import { getInteractionIcon } from 'utils/post';
import { getSnaById } from 'utils/snas';
import { useSelector } from 'utils/redux';

import { Networks } from 'types/snas.d';
import { FetchPosts } from 'types/fetchApi';

import styles from './PostCard.module.scss';

const getStatsKeys = (network: Networks, type, variant?: { is_reel?: boolean; is_igtv?: boolean }): string[] => {
    switch (network) {
        case Networks.facebook:
            return ['comments', 'likes'];
        case Networks.instagram:
            if (variant.is_reel || variant.is_igtv) return [];
            if (type === 'video') {
                return ['saved', 'videos_views', 'impressions', 'reach'];
            }
            return ['saved', 'impressions', 'reach'];
        case Networks.youtube:
            return ['views', 'comments', 'likes'];
        case Networks.twitter:
            return ['likes', 'retweets'];
        case Networks.tiktok:
            return ['likes', 'comments', 'shares', 'plays'];
        default:
            return [];
    }
};

interface Props {
    data: FetchPosts;
    rank: number;
    position: 'top' | 'flop';
}

const PostCard: React.FC<Props> = ({ data, rank, position }) => {
    const {
        published_at,
        avg_engagement,
        engagement_details,
        type,
        social_network,
        picture_url,
        content,
        username,
        content_url,
        sna_id,
        is_igtv,
        is_reel,
    } = data;

    const { lMetrics, lConfig, locale, snaUrl } = useSelector(({ env, snas }) => {
        const sna = getSnaById(snas, social_network, sna_id);
        return {
            lMetrics: getLexique(env.locale).containers.analyticsPage.posts.charts.topFlop.metrics,
            lConfig: getLexique(env.locale).config,
            locale: env.locale,
            snaUrl: sna && sna.url ? sna.url : '',
        };
    });

    const statsKeys = useMemo(() => getStatsKeys(social_network, type, { is_reel, is_igtv }), [
        social_network,
        type,
        is_reel,
        is_igtv,
    ]);

    const cnEngagement = cn(styles.engagementRate, styles[position]);

    return (
        <div className={styles.card}>
            <div className={styles.rankDate}>
                <div className={styles.rank}>{`#${rank}`}</div>
                <div className={styles.day}>{datetimeFormatter(published_at, locale, lConfig.postDate)},</div>
                <div className={styles.hour}>{datetimeFormatter(published_at, locale, lConfig.hour)}</div>
            </div>

            <ButtonBase
                component="a"
                href={snaUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialName}
            >
                <SocialName
                    name={username}
                    network={social_network}
                    variant={{
                        isIgtv: is_igtv,
                        isReel: is_reel,
                    }}
                />
            </ButtonBase>

            <div className={styles.engagement}>
                <div className={cnEngagement}>
                    <span className={styles.engagementRateValue}>{thousandsDelimit(avg_engagement.absolute)}</span>
                    <span className={styles.engagementRatePercent}>{percentFormatter(avg_engagement.percent)}</span>
                </div>
                <div className={styles.stats}>
                    {statsKeys.map((k) => (
                        <div key={k} className={styles.stat}>
                            <div className={styles.statInteraction}>
                                <Tooltip content={lMetrics[k]}>
                                    <span>{getInteractionIcon(social_network, k)}</span>
                                </Tooltip>
                            </div>
                            <div className={styles.statMetric}>{thousandsDelimit(engagement_details[k])}</div>
                        </div>
                    ))}
                </div>
            </div>
            <ButtonBase
                component="a"
                href={content_url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
            >
                <div className={styles.body}>
                    {picture_url && picture_url.length > 0 && (
                        <div className={styles.image}>
                            { is_reel && (
                                <Icon
                                    className={styles.imageIcon}
                                    label="instagram-video"
                                    theme="brand"
                                    fill="white"
                                    width="20"
                                />
                            )}
                            <img src={picture_url} alt="" />
                        </div>
                    )}
                    <PostContent className={styles.text} content={content} />
                </div>
            </ButtonBase>
        </div>
    );
};

export default memo(PostCard);
