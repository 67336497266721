import React, { useState, useCallback, useMemo } from 'react';
import { HeightTransition } from '@brandandcelebrities/kolkit';

import Arrow, { Direction } from 'components/atoms/Arrow';
import { Networks } from 'constants/networks';
import { useLexique } from 'utils/redux';

import NetworkItem from './NetworkItem';

import { NetworkAssociation } from '../types.d';

import styles from './SocialNetworksList.module.scss';

const SNA_HEIGHT = 73;

const SOCIAL_NETWORKS = [
    Networks.instagram,
    Networks.youtube,
    Networks.tiktok,
    Networks.facebook,
    Networks.twitter,
    Networks.snapchat,
    Networks.twitch,
    Networks.twentyone_buttons,
    Networks.pinterest,
];

interface Props {
    className?: string;
    networkAssociation: NetworkAssociation;
    onSave: (network: Networks, networkUrl?: string) => void;
    onOpen: (network: Networks) => void;
    onCancel: (network: Networks) => void;
}

const SocialNetworksList: React.FC<Props> = ({ className, networkAssociation, onSave, onOpen, onCancel }) => {
    const [showingMore, setShowingMore] = useState(false);
    const lexique = useLexique(`containers.onboardingPage.steps.networks`);

    const handleSeeMore = useCallback(() => {
        setShowingMore((prev) => !prev);
    }, []);

    const minHeight = useMemo(() => {
        let height = SNA_HEIGHT;
        if (networkAssociation[Networks.instagram]?.state === 'set') {
            height += SNA_HEIGHT * 2;
            height += networkAssociation[Networks.youtube]?.state === 'editing' ? SNA_HEIGHT : 0;
            height += networkAssociation[Networks.tiktok]?.state === 'editing' ? SNA_HEIGHT : 0;
        }
        return height;
    }, [networkAssociation]);

    return (
        <ul className={className}>
            <HeightTransition on={showingMore} minHeight={minHeight}>
                <>
                    {SOCIAL_NETWORKS.map((network) => (
                        <NetworkItem
                            key={network}
                            network={network}
                            state={networkAssociation[network]?.state}
                            error={networkAssociation[network]?.error}
                            ctaTheme={network === Networks.instagram ? 'default' : 'navy'}
                            onCancel={onCancel}
                            onOpen={onOpen}
                            onSave={onSave}
                        />
                    ))}
                </>
            </HeightTransition>
            <div className={styles.row} onClick={handleSeeMore} role="presentation">
                <div className={styles.body}>
                    <p className={styles.network}>{lexique.see[showingMore ? 'less' : 'more']}</p>
                </div>
                <Arrow className={styles.arrow} direction={showingMore ? Direction.toTop : Direction.toBottom} />
            </div>
        </ul>
    );
};

export default SocialNetworksList;
