import { useEffect, useState, useCallback, useMemo } from 'react';
import _throttle from 'lodash.throttle';

const useSticky = () => {
    const [sticky, setSticky] = useState(false);

    const onScroll = useCallback(() => {
        if (window.scrollY > 0 && !sticky) return setSticky(true);
        if (window.scrollY <= 0 && sticky) return setSticky(false);
        return null;
    }, [sticky]);

    const throttledScroll = useMemo(() => _throttle(onScroll, 100), [onScroll]);

    useEffect(() => {
        document.addEventListener('scroll', throttledScroll);
        return () => document.removeEventListener('scroll', throttledScroll);
    }, [throttledScroll]);

    return sticky;
};

export default useSticky;
