import contactCard from './contactCard';
import propositionAnswerCard from './propositionAnswerCard';
import propositionAnsweredMobile from './propositionAnsweredMobile';
import propositionDescription from './propositionDescription';
import propositionDetails from './propositionDetails';
import propositionHeader from './propositionHeader';
import propositionPage from './propositionPage';

export default {
    contactCard,
    propositionAnswerCard,
    propositionAnsweredMobile,
    propositionDescription,
    propositionDetails,
    propositionHeader,
    propositionPage,
};
