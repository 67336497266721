import React, { FC, useCallback, memo } from 'react';
import cn from 'classnames';
import { showModale } from 'actions/modale';
import { useLexique, useDispatch } from 'utils/redux';
import { AddButton } from 'components/atoms';

import styles from './SocialStatsCard.module.scss';

interface Lexique {
    addSNA: string;
}

const SocialStatsCardAdd: FC = () => {
    const dispatch = useDispatch();
    const lexique = useLexique<Lexique>('containers.shared.socialStatsContainer');
    const onClick = useCallback(() => {
        dispatch(
            showModale({
                body: 'SnaForm',
            }),
        );
    }, [dispatch]);

    const cnContainer = cn(styles.container, styles.addButton);
    return (
        <div className={cnContainer}>
            <AddButton onClick={onClick} label={lexique.addSNA} />
        </div>
    );
};

export default memo(SocialStatsCardAdd);
