import PropositionDetailsLexique from 'locales/types/containers/propositionPage/propositionDetails';

const propositionDetails: PropositionDetailsLexique = {
    title: `Détails de la proposition`,
    budget: {
        label: `Budget :`,
        empty: `A définir`,
    },
    date: {
        label: `Date :`,
        to: `au`,
    },
    socialNetworks: `Réseaux :`,
};

export default propositionDetails;
