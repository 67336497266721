export const FETCH_SNAS_SUCCESS = 'sna/FETCH_SNAS_SUCCESS';
export const FETCH_SNAS_FAILURE = 'sna/FETCH_SNAS_FAILURE';

export const FETCH_SNA_SUCCESS = 'sna/FETCH_SNA_SUCCESS';
export const FETCH_SNA_FAILURE = 'sna/FETCH_SNA_FAILURE';

export const REMOVE_SNA = 'sna/REMOVE_SNA';
export const REMOVE_SNA_SUCCESS = 'sna/REMOVE_SNA_SUCCESS';
export const REMOVE_SNA_FAILURE = 'sna/REMOVE_SNA_FAILURE';

export const FETCH_ON_CONNECT = 'sna/FETCH_ON_CONNECT';

export const LOAD_SNA_TOP_POSTS = 'sna/LOAD_SNA_TOP_POSTS';
export const FETCH_SNA_TOP_POSTS_SUCCESS = 'sna/FETCH_SNA_TOP_POSTS_SUCCESS';
export const FETCH_SNA_TOP_POSTS_FAILURE = 'sna/FETCH_SNA_TOP_POSTS_FAILURE';
