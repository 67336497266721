import React, { FC, memo } from 'react';

import { ReactComponent as Facebook } from 'assets/images/svg/facebook.svg';
import { ReactComponent as Instagram } from 'assets/images/svg/instagram.svg';
import { ReactComponent as Twitter } from 'assets/images/svg/twitter.svg';
import { ReactComponent as Youtube } from 'assets/images/svg/youtube.svg';
import { ReactComponent as InstagramStories } from 'assets/images/svg/insta-stories.svg';
import { ReactComponent as IGTV } from 'assets/images/svg/igtv.svg';
import { ReactComponent as Tiktok } from 'assets/images/svg/tiktok.svg';
import { ReactComponent as Snapchat } from 'assets/images/svg/Snapchat.svg';
import { ReactComponent as Pinterest } from 'assets/images/svg/pinterest.svg';
import { ReactComponent as Twitch } from 'assets/images/svg/twitch.svg';
import { ReactComponent as TwentyOneButtons } from 'assets/images/svg/21buttons.svg';

import { ReactComponent as FacebookOnboarding } from 'assets/images/svg/onboarding-networks/facebook.svg';
import { ReactComponent as InstagramOnboarding } from 'assets/images/svg/onboarding-networks/instagram.svg';
import { ReactComponent as TwitterOnboarding } from 'assets/images/svg/onboarding-networks/twitter.svg';
import { ReactComponent as YoutubeOnboarding } from 'assets/images/svg/onboarding-networks/youtube.svg';
import { ReactComponent as TiktokOnboarding } from 'assets/images/svg/onboarding-networks/tiktok.svg';
import { ReactComponent as SnapchatOnboarding } from 'assets/images/svg/onboarding-networks/snapchat.svg';
import { ReactComponent as PinterestOnboarding } from 'assets/images/svg/onboarding-networks/pinterest.svg';
import { ReactComponent as TwitchOnboarding } from 'assets/images/svg/onboarding-networks/twitch.svg';
import { ReactComponent as TwentyOneButtonsOnboarding } from 'assets/images/svg/onboarding-networks/21buttons.svg';

import { Networks, SOCIAL_NETWORKS } from 'constants/networks';
import { useLexique } from 'utils/redux';
import { TooltipLexique } from 'locales/types/components/tooltip';
import { NetworkVariant } from 'types/snas';
import Tooltip from '../Tooltip';

interface Props {
    network: Networks;
    fill?: string;
    variant?: NetworkVariant;
    isForOnboarding?: boolean;
}

const IGTV_COLOR = 'url(#igtvGradientColor)';

const SocialPicto: FC<Props> = ({ network, fill, variant, isForOnboarding }) => {
    const lexique = useLexique<TooltipLexique>('components.tooltips');

    switch (network) {
        case Networks.facebook: {
            return isForOnboarding ? (
                <FacebookOnboarding fill={fill || SOCIAL_NETWORKS.facebook.color} />
            ) : (
                <Facebook fill={fill || SOCIAL_NETWORKS.facebook.color} />
            );
        }
        case Networks.twitter: {
            return isForOnboarding ? (
                <TwitterOnboarding fill={fill || SOCIAL_NETWORKS.twitter.color} />
            ) : (
                <Twitter fill={fill || SOCIAL_NETWORKS.twitter.color} />
            );
        }
        case Networks.youtube: {
            return isForOnboarding ? (
                <YoutubeOnboarding fill={fill || SOCIAL_NETWORKS.youtube.color} />
            ) : (
                <Youtube fill={fill || SOCIAL_NETWORKS.youtube.color} />
            );
        }
        case Networks.tiktok: {
            return isForOnboarding ? (
                <TiktokOnboarding fill={fill || SOCIAL_NETWORKS.tiktok.color} />
            ) : (
                <Tiktok fill={fill || SOCIAL_NETWORKS.tiktok.color} />
            );
        }
        case Networks.snapchat: {
            return isForOnboarding ? (
                <SnapchatOnboarding fill={fill || SOCIAL_NETWORKS.snapchat.color} />
            ) : (
                <Snapchat fill={fill || SOCIAL_NETWORKS.snapchat.color} />
            );
        }
        case Networks.pinterest: {
            return isForOnboarding ? (
                <PinterestOnboarding fill={fill || SOCIAL_NETWORKS.pinterest.color} />
            ) : (
                <Pinterest fill={fill || SOCIAL_NETWORKS.pinterest.color} />
            );
        }
        case Networks.twentyone_buttons: {
            return isForOnboarding ? (
                <TwentyOneButtonsOnboarding fill={fill || SOCIAL_NETWORKS.twentyone_buttons.color} />
            ) : (
                <TwentyOneButtons fill={fill || SOCIAL_NETWORKS.twentyone_buttons.color} />
            );
        }
        case Networks.twitch: {
            return isForOnboarding ? (
                <TwitchOnboarding fill={fill || SOCIAL_NETWORKS.twitch.color} />
            ) : (
                <Twitch fill={fill || SOCIAL_NETWORKS.twitch.color} />
            );
        }
        case Networks.instagram: {
            if (variant.isStory) return <InstagramStories fill={fill || SOCIAL_NETWORKS.instagram.color} />;
            if (variant.isIgtv) return <IGTV fill={fill || IGTV_COLOR} />;
            return isForOnboarding ? (
                <InstagramOnboarding fill={fill || SOCIAL_NETWORKS.instagram.color} />
            ) : (
                <Instagram fill={fill || SOCIAL_NETWORKS.instagram.color} />
            );
        }
        case Networks.instagram_stories: {
            return (
                <Tooltip html={<div className="tooltip-small">{lexique.instagram_stories}</div>} position="top">
                    <InstagramStories fill={fill || SOCIAL_NETWORKS.instagram.color} />
                </Tooltip>
            );
        }
        default:
            return null;
    }
};

SocialPicto.defaultProps = {
    fill: null,
    variant: { isIgtv: false, isStory: false },
    isForOnboarding: false,
};

export default memo(SocialPicto);
