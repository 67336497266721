export default {
    modal: {
        addInstagram: `Reconnect Instagram`,
        addInstagramBusiness: `Reconnect Instagram Business`,
        title: {
            facebook: `Reconnect your Facebook fanpage`,
            twitter: `Reconnect your Twitter account`,
            instagram: `Reconnect your Instagram Business account`,
            youtube: `Reconnect your Youtube channel`,
        },
        text: `We need your authorisation in order to display your posts and related statistics.`,
        form: {
            send: `Save`,
        },
    },
};
