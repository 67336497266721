import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { interpolateLab } from 'd3-interpolate';
import isEmpty from 'lodash.isempty';
import zip from 'lodash.zip';
import clone from 'lodash.clone';
import zipObject from 'lodash.zipobject';
import classNames from 'classnames';

import SocialPicto from 'components/atoms/SocialPicto';
import { onChangeTimeFilter } from 'actions/analytics';
import getLexique from 'locales';

import './ChartCard.scss';
import { Viewport } from 'reducers/env';
import { TimeRange } from 'types/Filters';
import { SOCIAL_NETWORKS, Networks } from 'constants/networks';
import Chart from '../Chart';

const TIME_RANGES = Object.keys(TimeRange).map((t) => t);

interface Props {
    lexique: any; // @TODO type
    filter: any;
    socialNetwork?: {
        label: string;
        uid: string;
        username: string;
        data: {
            dates: string[];
            content: Array<number | null>;
            community: Array<number | null>;
            engagement: Array<number | null>;
        };
    };
    locale: string;
    formats: any;
    device: string;
    viewport: Viewport;
    onChangeTimeFilter: (timeRanges: TimeRange) => void;
}
const fillEmptyValues = (input) => {
    if (isEmpty(input)) return input;
    const data = clone(input);

    let index = 0;
    do {
        let firstPoint = null;
        let lastPoint = null;

        // Initialize the data to null if the first value(s) is/are null/undefined
        if (!data[index]) {
            while (index < data.length && !data[index]) {
                data[index] = null;
                index++;
            }
        }

        // Complete the missing values (holes) in the graph
        while (index < data.length && data[index] !== null) {
            index++;
        }
        if (index === data.length) return data;
        firstPoint = index - 1;

        while (index < data.length && data[index] === null) {
            index++;
        }
        if (index === data.length) return data;
        lastPoint = index;

        if (firstPoint !== 0) {
            const step = Math.floor((data[lastPoint] - data[firstPoint]) / (lastPoint - firstPoint)); // We round it down to avoid to have a float when it should be an integer
            for (let emptyPoint = firstPoint + 1; emptyPoint < lastPoint; emptyPoint++) {
                data[emptyPoint] = data[emptyPoint - 1] + step;
            }
        }
    } while (index < data.length);

    return data;
};

class ChartCard extends PureComponent<Props> {
    render() {
        const { lexique, filter, socialNetwork, locale, formats, device, viewport } = this.props;

        if (isEmpty(socialNetwork)) return null;
        const data = isEmpty(socialNetwork.data)
            ? null
            : zip(
                  socialNetwork.data.dates,
                  fillEmptyValues(socialNetwork.data.community),
                  fillEmptyValues(socialNetwork.data.engagement),
                  socialNetwork.data.content,
              ).map((sn) => zipObject(['date', 'followers', 'engagements', 'publications'], sn));
        return (
            <div className="bc-analytics-stats__chart-card">
                <div className="bc-analytics-stats-chart-card__header">
                    <div className="bc-analytics-stats-chart-card__title">
                        <div className="bc-analytics-stats-chart-card__title-picto">
                            <SocialPicto network={socialNetwork.label as Networks} />
                        </div>
                        <div className="bc-analytics-stats-chart-card__title-infos">
                            <div className="bc-analytics-stats-chart-card__title-network">{socialNetwork.label}</div>
                            <div className="bc-analytics-stats-chart-card__title-pseudo">
                                {socialNetwork.username || socialNetwork.uid}
                            </div>
                        </div>
                    </div>
                    <div className="bc-analytics-stats-chart-card__filters">
                        {TIME_RANGES.map(
                            (timeRange) =>
                                timeRange !== TimeRange.all && (
                                    <button
                                        type="button"
                                        key={timeRange}
                                        className={classNames({
                                            'bc-analytics-stats-chart-card__filter': true,
                                            'bc-analytics-stats-chart-card__filter--selected': timeRange === filter,
                                        })}
                                        onClick={() => this.props.onChangeTimeFilter(TimeRange[timeRange])}
                                    >
                                        {lexique.filter[timeRange]}
                                    </button>
                                ),
                        )}
                    </div>
                </div>
                <div className="bc-analytics-stats-chart-card__body">
                    {!isEmpty(socialNetwork.data) && !isEmpty(socialNetwork.data.dates) && (
                        <>
                            <div className="bc-analytics-stats-chart-card__chart">
                                <Chart
                                    data={data}
                                    color={SOCIAL_NETWORKS[socialNetwork.label].color}
                                    mobile={device === 'mobile'}
                                    viewportWidth={viewport.width}
                                    margin={{
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                    }}
                                    locale={locale}
                                    formats={formats}
                                    lexique={lexique.chart}
                                />
                            </div>
                            <div className="bc-analytics-stats-chart-card__legends">
                                <div className="bc-analytics-stats-chart-card__legend">
                                    <svg
                                        className="bc-analytics-stats-chart-card__legend-line"
                                        viewBox="0 0 500 500"
                                        preserveAspectRatio="none"
                                    >
                                        <rect
                                            x={0}
                                            y={0}
                                            width={500}
                                            height={500}
                                            fill={SOCIAL_NETWORKS[socialNetwork.label].color}
                                        />
                                    </svg>
                                    <div className="bc-analytics-stats-chart-card__legend-text">
                                        {lexique.legends.community}
                                    </div>
                                </div>
                                <div className="bc-analytics-stats-chart-card__legend">
                                    <svg
                                        className="bc-analytics-stats-chart-card__legend-line"
                                        viewBox="0 0 500 500"
                                        preserveAspectRatio="none"
                                    >
                                        <rect
                                            x={0}
                                            y={0}
                                            width={500}
                                            height={500}
                                            fill={interpolateLab(
                                                'white',
                                                SOCIAL_NETWORKS[socialNetwork.label].color,
                                            )(0.3)}
                                        />
                                    </svg>
                                    <div className="bc-analytics-stats-chart-card__legend-text">
                                        {lexique.legends.engagements}
                                    </div>
                                </div>
                                <div className="bc-analytics-stats-chart-card__legend">
                                    <svg
                                        className="bc-analytics-stats-chart-card__legend-area"
                                        viewBox="0 0 500 500"
                                        preserveAspectRatio="none"
                                    >
                                        <polygon points="500,0 500,500 0,500" fill="#d4d4d4" />
                                    </svg>
                                    <div className="bc-analytics-stats-chart-card__legend-text">
                                        {lexique.legends.publications}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {(isEmpty(socialNetwork.data) ||
                        (!isEmpty(socialNetwork.data) && isEmpty(socialNetwork.data.dates))) && (
                        <div className="bc-analytics-stats-chart-card__placeholder">{lexique.noData}</div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    device: state.env.device.type,
    viewport: state.env.viewport,
    formats: getLexique(state.env.locale).config,
    locale: state.env.locale,
    lexique: getLexique(state.env.locale).containers.analyticsPage.statistics.charts.chartCard,
});

const mapDispatchToProps = (dispatch) => ({
    onChangeTimeFilter: (timeRange: TimeRange) => {
        dispatch(onChangeTimeFilter(timeRange));

        // Google Analyics
        window.ga('send', 'event', {
            eventCategory: 'Statistics',
            eventAction: `Filter`,
            eventLabel: timeRange,
        });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartCard);
