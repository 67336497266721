import UnconfirmedEmailPageLexique from 'locales/types/containers/unconfirmedEmailPage';

const unconfirmedEmailPageLexique: UnconfirmedEmailPageLexique = {
    title: 'Vérifie ton adresse email',
    description:
        'Une petite minute ! Nous n’avons pas pu vérifier ton email. Kolsquare a besoin de cette vérification pour assurer la sécurité de la plateforme.',
    linkSent: 'Clique sur le lien envoyé à l’adresse :',
    emailNotReceived: 'Tu n’as rien reçu ?',
    resendEmail: 'Renvoyer l’email',
    changeEmail: 'Corriger mon adresse email',
    deleteAccount: 'Supprimer mon compte',
};

export default unconfirmedEmailPageLexique;
