import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';

import { Icon, Text, Input, Button } from '@brandandcelebrities/kolkit';

import { useLexique } from 'utils/redux';
import { ChatLexique } from 'locales/types/containers/messagingPage';

import styles from './LinkDropdown.module.scss';

const LinkDropdown = ({ onChange, currentState, translations, expanded, onExpandEvent, doCollapse }) => {
  const lexiqueChat = useLexique<ChatLexique>('containers.messagingPage.chat');

  const [selectionText, setSelectionText] = useState('')
  const [link, setLink] = useState({
    target: '',
    targetOption: '_self',
  });

  useEffect(
    () => {
      setSelectionText(currentState?.selectionText);
      setLink({
        target: currentState?.link?.target || currentState?.selectionText,
        targetOption: currentState?.link?.targetOption,
      });
    },
    [currentState]
  );

  const stopPropagation = useCallback(
    (event) => {
      event.stopPropagation();
    },
    []
  );

  const handleTitleChange = useCallback(
    ({ value }) => {
      setSelectionText(value);
    },
    []
  );

  const handleLinkChange = useCallback(
    ({ value }) => {
      setLink(s => ({
        ...s,
        target: value
      }));
    },
    []
  );

  // const handleCheck = useCallback(
  //   () => {
  //     setLink(s => ({
  //       ...s,
  //       targetOption: (!s.targetOption || s.targetOption === '_self') ? '_blank' : '_self',
  //     }));
  //   },
  //   []
  // );

  const handleSave = useCallback(
    () => {
      onChange('link', selectionText, link?.target, link?.targetOption);
    },
    [selectionText, link, onChange]
  );

  const removeLink = useCallback(
    () => {
      onChange('unlink');
    },
    [onChange]
  );

  const isDisabled = useMemo(
    () => !selectionText || !link?.target,
    [selectionText, link]
  );

  return (
    <div className={styles.wrapper}>
      <Icon
        isButton
        label="link"
        onClick={onExpandEvent}
        disabled={!selectionText}
        title={lexiqueChat.link}
      />
      <Icon
        isButton
        label="unlink"
        onClick={removeLink}
        disabled={!currentState?.link?.target}
        title={lexiqueChat.unLink}
      />

      {expanded && (
        <div className={styles.dropdown} onClick={stopPropagation} role="dialog">
          <Text resetMargin tag="span" className={styles.label}>{translations['components.controls.link.linkTitle']}</Text>
          <Input
            onChange={handleTitleChange}
            value={selectionText}
            className={styles.input}
            fullWidth
          />
          <Text resetMargin tag="span" className={styles.label}>{translations['components.controls.link.linkTarget']}</Text>
          <Input
            onChange={handleLinkChange}
            value={link?.target}
            className={styles.input}
            fullWidth
          />
          {/* Commented waiting the solution to change mardown 
            <Checkbox
              label={translations['components.controls.link.linkTargetOption']}
              id="label"
              name="linkTargetOption"
              checked={link?.targetOption === '_blank'}
              onChange={handleCheck}
            />
          */}
          <div className={styles.actions}>
            <Button
              theme="navy"
              onClick={doCollapse}
              label={translations['generic.cancel']}
            />
            <Button
              disabled={isDisabled}
              onClick={handleSave}
              label={translations['generic.add']}
            />
          </div>
        </div>
      )}
    </div>
  )
}

LinkDropdown.defaultName = 'LinkDropdown';

export default memo(LinkDropdown);