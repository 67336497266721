const resolveTimeout = (fn, timeout) => {
  let timer: ReturnType<typeof setTimeout>;
  const handleCb = cb => typeof cb === 'function' ? cb() : cb;
  const promise = new Promise(resolve => {
    if (timeout) clearTimeout(timer);
    timer = setTimeout(
      () => resolve(handleCb(fn)),
      timeout,
    );
  });

  Promise
    .race([promise])
    .then(() => {
      clearTimeout(timer);
    });

  return true;
};

export default resolveTimeout;