import PropositionDescriptionLexique from 'locales/types/containers/propositionPage/propositionDescription';

const propositionDescription: PropositionDescriptionLexique = {
    title: `Project description`,
    fields: {
        description: `Context and plan:`,
        performance: `Mission:`,
        broadcastMedia: {
            label: `The brand will publish the content on:`,
            facebook: `Facebook`,
            instagram: `Instagram`,
            twitter: `Twitter`,
            youtube: `YouTube`,
            website: `Its own official website`,
        },
        rightsDuration: `Rights duration:`,
        numberOfPosts: `Number of posts on your own social networks:`,
        media: {
            label: `Type of content:`,
            video: `Video`,
            photo: `Picture`,
            text: `Text`,
        },
        suggestion: `Type of post, hashtag or mention:`,
        amplifyBroadcast: {
            label: `Amplification:`,
            yes: `The posts will be amplify through digital media buy`,
        },
        relayBroadcast: {
            label: `Shares:`,
            yes: `The brand will share your posts accross their own social networks`,
        },
    },
};

export default propositionDescription;
