import { CANCELED, CONTACTED, REFUSED, SIGNED, VALIDATED } from 'constants/proposition';
import PropositionHeaderLexique from 'locales/types/containers/propositionPage/propositionHeader';

export const headerLexique: PropositionHeaderLexique = {
    state: {
        [CONTACTED]: `Pending`,
        [VALIDATED]: `Accepted`,
        [SIGNED]: `Signed`,
        [CANCELED]: `Canceled`,
        [REFUSED]: `Declined`,
    },
    answer: `Reply`,
};

export default headerLexique;
