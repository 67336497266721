import React, { FC, useCallback, memo, useEffect, useState, useMemo } from 'react';
import moment from 'moment';

import { Loader, Checkbox, Icon } from '@brandandcelebrities/kolkit';

import { ON_ACCOUNT_DATAS, ON_DISCONNECTED } from 'utils/fbc/FacebookBusinessConnect';
import { callGAEvent } from 'utils/googleAnalytics';
import { ConnectButton } from 'components/molecules';
import { Modal } from 'components/atoms';
import Markdown from 'components/atoms/Markdown';

import { saveAutoUserToken } from 'actions/profile';
import { setCheckNoMoreInsta } from 'actions/user';
import { showModale } from 'actions/modale';
import { fbc } from 'config/connect';

import { useDispatch, useSelector, useLexique } from 'utils/redux';
import { FBCDataState } from 'reducers/fbc';
import { AppState } from 'reducers';
import { fetchFbc, reset, onError, onAccountDataReceived } from 'actions/fbc';
import { Pages } from 'types/Instaconnect';
import InstaConnectLexique from 'locales/types/components/instaconnect';
import { ConfigLexique } from 'locales/types';

import styles from './RenewingInstaConnect.module.scss';

export interface Props {
  onClose?: (...args) => void;
  modalOpened?: boolean;
}

const RenewingInstaConnect: FC<Props> = ({ onClose, modalOpened }) => {
  const dispatch = useDispatch();
  const instaConnectLexique = useLexique<InstaConnectLexique>('components.instaconnect');
  const configLexique = useLexique<ConfigLexique>('config');

  const { pages, loading, error: fbcError }: FBCDataState = useSelector<FBCDataState>( ({ fbc }: AppState) => fbc);
  const { snas, statsSharing } = useSelector( ({ user }) => ({
    snas: user.snas,
    statsSharing: user.statsSharing,
  }));

  const [noMoreInsta, setNoMoreInsta] = useState(false);
  const [instagramNotFound, setInstagramNotFound] = useState(false);

  const handleCloseModal = useCallback(() => {
    if (noMoreInsta) {
      callGAEvent('Renew_token', 'dont_show_anymore');
      dispatch(setCheckNoMoreInsta(true));
    }
    onClose();
  }, [onClose, noMoreInsta, dispatch]);

  const getTitle = useMemo(
    () => {
      const snaInstagram = snas.find(sna => sna.label === 'instagram');
      if (statsSharing?.tokenExpiresAt) {
        return instaConnectLexique.modal.renewing.title
          .replace('{{username}}', snaInstagram?.username)
          .replace('{{expiredAt}}', moment(statsSharing?.tokenExpiresAt).format(configLexique.altDate))
      }
      return instaConnectLexique.modal.renewing.hasExpiredTitle
        .replace('{{username}}', snaInstagram?.username)
    },
    [snas, statsSharing, instaConnectLexique, configLexique]
  );

  useEffect(() => {
    if (!modalOpened) dispatch(reset());
  }, [modalOpened, dispatch]);

  const saveNewToken = useCallback(
    async (pages: Pages) => {
      const instagramBusiness = pages?.instagram.find(data => data?.businessId === statsSharing?.instagramBusinessId);
      if (!instagramBusiness) {
        await dispatch(onAccountDataReceived(pages));
        setInstagramNotFound(true);
        return false;
      }

      const tokenData = {
        accessToken: instagramBusiness.accessToken,
        uid: instagramBusiness.uid,
        instagramBusinessId: instagramBusiness.businessId,
        triggerMode: 'manual'
      }
      await dispatch(saveAutoUserToken(tokenData));
      handleCloseModal();
      dispatch(showModale({ body: 'FbcTokenSuccess'}));
    },
    [dispatch, handleCloseModal, statsSharing],
  );

  const fail = useCallback(
    (e) => {
      dispatch(onError(e.reason));
    },
    [dispatch],
  );

  const getFacebookPages = useCallback(async () => {
    await dispatch(fetchFbc());
    fbc.once(ON_ACCOUNT_DATAS, saveNewToken);
    fbc.once(ON_DISCONNECTED, fail);
    fbc.requestDatas();
  }, [dispatch, saveNewToken, fail]);

  const handleCheckNoMoreInsta = useCallback(() => setNoMoreInsta(s => !s), []);

  const renderError = useMemo(
    () => {
      if (fbcError || instagramNotFound) {
        const message = fbcError
          ? instaConnectLexique.errorConnect
          : instagramNotFound
            ? instaConnectLexique.instagramNotFound
            : '';
        return (
          <div className={styles.error}>
            <Icon label="exclamation-circle" fill="#777474" />
            <span className={styles.message}>{message}</span>
          </div>
        )
      }

      return null;
    },
    [fbcError, instagramNotFound, instaConnectLexique]
  )

  return (
    <Modal
      onClose={handleCloseModal}
      display={modalOpened}
    >
      <div className={styles.instaconnect}>
        <div className={styles.title}>
          <Markdown>{getTitle}</Markdown>
        </div>
        { renderError }

        <div className={styles.button}>
          {(!pages?.instagram?.length || (pages?.instagram?.length && instagramNotFound)) && !loading && (
            <ConnectButton
              onClickConnect={getFacebookPages}
              label={instaConnectLexique.modal.renewing.addInstagramBusiness}
            />
          )}
          {loading && <Loader full />}
        </div>

        <div className={styles.gnee}>
          <a
            href={instaConnectLexique.selectPages.instagram.link.url}
            target="_blank"
            className={styles.link}
            rel="noopener noreferrer"
          >
            {instaConnectLexique.selectPages.instagram.link.label}
          </a>
        </div>

        <div className={styles.checkbox}>
          <Checkbox
            label={instaConnectLexique.modal.renewing.noMoreShowRenewing}
            id="noMoreShowRenewing"
            name="noMoreShowRenewing"
            checked={noMoreInsta}
            onChange={handleCheckNoMoreInsta}
          />
        </div>
      </div>
    </Modal>
  );
};

RenewingInstaConnect.defaultProps = {
  onClose: () => {},
  modalOpened: false,
};

export default memo(RenewingInstaConnect);
