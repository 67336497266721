import React, { FC, memo } from 'react';
import cn from 'classnames';

import SocialPicto from 'components/atoms/SocialPicto';
import { Networks } from 'constants/networks';
import { NetworkVariant } from 'types/snas';
import './socialName.scss';

interface Props {
    name: string;
    network: Networks;
    variant?: NetworkVariant;
}

const SocialName: FC<Props> = memo(({ name, network, variant }) => {
    const cnLabel = cn('social-name__label', network, {
        isIgtv: variant?.isIgtv,
        isReel: variant?.isReel,
        isStory: variant?.isStory,
    });
    return (
        <p className="social-name">
            <SocialPicto network={network} variant={variant} />
            <span className={cnLabel}>{name}</span>
        </p>
    );
});

export default SocialName;
