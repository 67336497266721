import InstaConnectLexique from 'locales/types/components/instaconnect';

const instaconnect: InstaConnectLexique = {
    selectPages: {
        instagram: {
            title: `Connect your Instagram Business accounts`,
            text: `Choose which Instagram Business Profiles to import`,
            noPages: `There are no business or creator accounts related to this profile.`,
            link: {
                label: `I can't connect my Instagram account`,
                url: 'https://www.facebook.com/help/502981923235522',
            },
        },
        facebook: {
            title: `Connect your Facebook Business accounts`,
            text: `Choose which Facebook Business Profiles to import`,
            noPages: `There are no business or creator accounts related to this profile.`,
            link: ``,
        },
    },
    instagramButton: {
        label: `Register your Instagram account`,
        subtext: `(Business or Creator only)`,
    },
    consentInstagramButton: {
        label: `Connect my Instagram account`,
        subtext: `(Business or Creator linked to a Facebook page only)`,
    },
    errorConnect: `There are no business or creator accounts related to this profile.`,
    instagramNotFound: `The Instagram account that was selected does not match the Instagram account that was previously added. Please start over and select the correct Instagram account by clicking "Change Settings" after the Login step.`,
    submit: `Next`,
    modal: {
        warning: 'If you do not have a Business or creator account, go directly to the next step.',
        consentWarning: `Kolsquare is an Official Instagram Partner.`,
        new: {
            title: 'Connect your Instagram account via Facebook',
            content: 'To add your **business** or **creator** Instagram account, you have to connect your account to a **Facebook Page**.',
            addInstagramBusiness: 'Add my Instagram account via Facebook',
            reconnectInstagramBusiness: 'Reconnect your Instagram Business account',
        },
        renewing: {
            title: 'The connection with the Instagram account **{{username}}** will expire on {{expiredAt}}!',
            hasExpiredTitle: 'The connection with the Instagram account **{{username}}** has expired!',
            content: "Please note, you must have linked a **Facebook page** to your Instagram **Business** or **Creator** account.",
            addInstagramBusiness: `Renew the connection`,
            noMoreShowRenewing: `Do not show anymore`,
        }
    },
    congratulations: {
        title: 'Congratulations ## !',
        text:
            'Your Instagram account has been added to Kolsquare. You will have access to your statistics in less than 24 hours.',
    },
};

export default instaconnect;
