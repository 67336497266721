import _isEmpty from 'lodash.isempty';
import React, { FC } from 'react';
import nl2br from 'react-nl2br';

import './PropositionDescription.scss';
import { useLexique } from 'utils/redux';
import { Proposition } from 'reducers/proposition';
import PropositionDescriptionLexique from 'locales/types/containers/propositionPage/propositionDescription';

interface Props {
    proposition: Proposition;
}

const PropositionDescription: FC<Props> = ({ proposition }) => {
    const lexique = useLexique<PropositionDescriptionLexique>('containers.propositionPage.propositionDescription');

    return (
        <div className="bc-proposition-description-card">
            <div className="bc-proposition-description-card__title">{lexique.title}</div>
            {proposition.project && proposition.project.description && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.description}</h4>
                    <div className="bc-proposition-description-card__text">
                        {nl2br(proposition.project.description)}
                    </div>
                </div>
            )}
            {proposition.performance && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.performance}</h4>
                    <div className="bc-proposition-description-card__text">{nl2br(proposition.performance)}</div>
                </div>
            )}
            {proposition.project && !_isEmpty(proposition.project.broadcast_media) && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.broadcastMedia.label}</h4>
                    <p className="bc-proposition-description-card__text">
                        {proposition.project.broadcast_media.map((bm) => lexique.fields.broadcastMedia[bm]).join(' / ')}
                    </p>
                </div>
            )}
            {proposition.project && proposition.project.rights_duration && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.rightsDuration}</h4>
                    <p className="bc-proposition-description-card__text">
                        {proposition.project && proposition.project.rights_duration}
                    </p>
                </div>
            )}
            {proposition.number_of_posts && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.numberOfPosts}</h4>
                    <p className="bc-proposition-description-card__text">{proposition.number_of_posts}</p>
                </div>
            )}
            {proposition.project && !_isEmpty(proposition.project.media) && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.media.label}</h4>
                    <p className="bc-proposition-description-card__text">
                        {proposition.project.media.map((bm) => lexique.fields.media[bm]).join(' / ')}
                    </p>
                </div>
            )}
            {proposition.project && proposition.project.suggestion && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.suggestion}</h4>
                    <div className="bc-proposition-description-card__text">{nl2br(proposition.project.suggestion)}</div>
                </div>
            )}
            {proposition.project && proposition.project.amplify_broadcast === 'yes' && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">
                        {lexique.fields.amplifyBroadcast.label}
                    </h4>
                    <p className="bc-proposition-description-card__text">{lexique.fields.amplifyBroadcast.yes}</p>
                </div>
            )}
            {proposition.project && proposition.project.relay_broadcast === 'yes' && (
                <div className="bc-proposition-description-card__description">
                    <h4 className="bc-proposition-description-card__subtitle">{lexique.fields.relayBroadcast.label}</h4>
                    <p className="bc-proposition-description-card__text">{lexique.fields.relayBroadcast.yes}</p>
                </div>
            )}
        </div>
    );
};

export default PropositionDescription;
