import fbcTokenSuccess from './fbcTokenSuccess';
import snaForm from './snaForm';
import blockingMaintenance from './blockingMaintenance';
import blockingUpgrade from './blockingUpgrade';
import blockingUpdatedCGU from './blockingUpdatedCGU';
import credibilityScore from './credibilityScore';
import deleteAccount from './deleteAccount';
import removeSocialMedia from './removeSocialMedia';
import requestStats from './requestStats';

export default {
  fbcTokenSuccess,
  snaForm,
  blockingMaintenance,
  blockingUpgrade,
  blockingUpdatedCGU,
  credibilityScore,
  deleteAccount,
  removeSocialMedia,
  requestStats,
};
