import links from '../links';

const register = {
  headTitle: 'Sign up - Kolsquare',
  title: "Join kolsquare",
  subtitle: "Create your profile",
  description: "Promote your profile to our partner brands",

  email: "Email",
  password: "Password (Min. {{min}} characters)",
  subscribeNewsletter: "I wish to receive my Kolsquare monthly repo",
  submit: "Register",
  signIn: "Login",
  acceptLegalTerms: `I accept Kolsquare's <a href="${links.legalTerms}" target="_blank" rel="noopener noreferrer">General Terms</a>.`,
  error: {
    hasNotAcceptedLegalTerms: "Please agree to the Terms and Conditions",
    invalidEmail: "Invalid email",
    empty: "This field is required",
    passwordLength: "Min. {{min}} characters",
    alreadyUsedEmail: "This email is already registered, try to connect",
    maintenance: "Our service is under maintenance... Please try again in a few minutes",
    unknown: "A critical error occured.... Please try again in a few minutes"
  }
}

export default register;