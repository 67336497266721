import React, { useMemo, useEffect, memo } from 'react';
import routes from 'config/routes';
import { getTopHashTagOneMonth } from 'actions/home';
import { supportedSocialNetwork } from 'constants/networks';
import { RecapBlockLexique } from 'locales/types/containers/shared/RecapBlockLexique';
import { AppState } from 'reducers';
import { SnasState } from 'reducers/snas';
import { Hashtag } from 'reducers/ui/analytics';
import { SNAObject } from 'types/snas';
import { pointFormatter, percentFormatterSpace } from 'utils';
import { useSelector, useDispatch, useLexique } from 'utils/redux';

import RecapItem from 'components/molecules/RecapItem';

import styles from './RecapBlock.module.scss';

interface Selector {
    snas: SnasState;
    topHashTag: Hashtag;
}

const RecapBlock = () => {
    const lexique = useLexique<RecapBlockLexique>('containers.shared.recapBlock');
    const dispatch = useDispatch();

    const { snas, topHashTag } = useSelector<Selector>(({ snas, ui }: AppState): Selector => ({
        snas,
        topHashTag: ui.homepage.topHashTag,
    }),
    );

    // engagment rate data
    const { highestCommunitySna, avgEngagement, evolution } = useMemo(() => {
        let highestCommunitySna: SNAObject = {
            stats: { community_count: 0 },
        } as SNAObject;
        supportedSocialNetwork.forEach(({ id }) => {
            if (snas[id] && snas[id].length) {
                snas[id].forEach((sna: SNAObject) => {
                    if ((sna?.stats?.community_count || 0) > highestCommunitySna.stats.community_count) {
                        highestCommunitySna = sna;
                    }
                });
            }
        });
        const totalEngagement =
            highestCommunitySna && highestCommunitySna.stats && highestCommunitySna.stats.engagement_details
                ? Object.keys(highestCommunitySna.stats.engagement_details).reduce(
                      (acc, value) => acc + highestCommunitySna.stats.engagement_details[value] || 0,
                      0,
                  )
                : null;
        const avgEngagement = totalEngagement
            ? 100 * (totalEngagement / highestCommunitySna.stats.community_count)
            : null;

        return {
            highestCommunitySna,
            avgEngagement: avgEngagement ? percentFormatterSpace(avgEngagement) : null,
            evolution: highestCommunitySna.stats.engagement_rate_progression
                ? pointFormatter(highestCommunitySna.stats.engagement_rate_progression)
                : null,
        };
    }, [snas]);

    // nbVisit & hashtag data
    useEffect(() => {
        dispatch(getTopHashTagOneMonth());
    }, [dispatch]);

    // if one of them is undefined, don't render block
    if (!topHashTag || !avgEngagement) return null;

    // render
    return (
        <>
            <div className={styles.recap}>
                <RecapItem
                    title={lexique.engagment.title}
                    buttonLabel={lexique.engagment.button}
                    content={avgEngagement}
                    evolution={evolution}
                    network={highestCommunitySna.label}
                    username={highestCommunitySna.username}
                    navigateTo={`${routes.analytics.index}${routes.analytics.audience}`}
                />
                <RecapItem
                    title={lexique.mostEngagingHashtag.title}
                    buttonLabel={lexique.mostEngagingHashtag.button}
                    content={`#${topHashTag.key}`}
                    navigateTo={`${routes.analytics.index}${routes.analytics.content}#top-hashtags`}
                />
            </div>
            <p className={styles.legend}>{lexique.legend}</p>
        </>
    );
};

export default memo(RecapBlock);
