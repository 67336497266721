import React, { memo } from 'react';

import UpgradeModal from './UpgradeModal';

import './modale.scss';

const Modales = memo(() => (
    <>
        <UpgradeModal />
    </>
));

export default Modales;
