import React, { useCallback } from 'react';
import { Button } from '@brandandcelebrities/kolkit';
import { Redirect, NavLink } from 'react-router-dom';
import { useSelector, useDispatch, useLexique } from 'utils/redux';
import UnconfirmedEmailPageLexique from 'locales/types/containers/unconfirmedEmailPage';
import { AppState } from 'reducers';
import { UserState } from 'reducers/user';
import { callGAEvent } from 'utils/googleAnalytics';
import { showModale, hideModale } from 'actions/modale';
import { resendConfirmationEmail, deleteUser } from 'actions/user';
import routes from 'config/routes';
import styles from './UnconfirmedEmailPage.module.scss';

const UnconfirmedEmailPage: React.FC = () => {
    const dispatch = useDispatch();
    const { isEmailConfirmed, isTrialOver, email } = useSelector<UserState>((state: AppState) => state.user);
    const lexique = useLexique<UnconfirmedEmailPageLexique>('containers.unconfirmedEmailPage');
    const resendEmail = useCallback(() => {
        dispatch(resendConfirmationEmail());
    }, [dispatch]);

    const toggleDeleteModal = useCallback(() => {
        dispatch(
            showModale({
                body: 'DeleteAccount',
                primaryAction: () => dispatch(hideModale()),
                secondaryAction: () => {
                    callGAEvent('Account', 'Delete');
                    dispatch(deleteUser());
                },
            }),
        );
    }, [dispatch]);

    if (isEmailConfirmed || !isTrialOver) return <Redirect to="/" />;
    return (
        <div className={styles.page}>
            <div className={styles.card}>
                <h1 className={styles.title}>{lexique.title}</h1>
                <p className={styles.description}>{lexique.description}</p>
                <div className={styles.linkSentCard}>
                    <span>{lexique.linkSent}</span>
                    <span className={styles.linkSentCardEmail}>{email}</span>
                </div>
                <div className={styles.emailNotReceived}>{lexique.emailNotReceived}</div>
                <div className={styles.ctas}>
                    <Button className={styles.cta} fullWidth size="big" onClick={resendEmail}>
                        {lexique.resendEmail}
                    </Button>
                    <NavLink to={routes.settings}>
                        <Button fullWidth size="big" theme="navy">
                            {lexique.changeEmail}
                        </Button>
                    </NavLink>
                </div>
                <Button theme="reset" onClick={toggleDeleteModal} className={styles.deleteAccount}>
                    {lexique.deleteAccount}
                </Button>
            </div>
        </div>
    );
};

export default UnconfirmedEmailPage;
