export default {
    headTitle: 'Mes Statistiques - Kolsquare',
    title: 'Audience',
    placeholder: 'Vous devez ajouter au moins un réseau social pour accéder à vos statistiques.',
    charts: {
        title: `Indicateurs de performance`,
        chartCard: {
            filter: {
                oneMonth: `1 mois`,
                threeMonths: `3 mois`,
                sixMonths: `6 mois`,
                oneYear: `1 an`,
                all: 'Tout',
            },
            chart: {
                legends: {
                    community: 'Communauté :',
                    engagements: 'Engagements :',
                    publications: 'Publications :',
                },
            },
            legends: {
                community: 'Communauté',
                engagements: 'Engagements moyen (1 mois)',
                publications: 'Publications',
            },
            noData: 'Il n’y a pas de donnée pour la période sélectionnée.',
        },
    },
};
