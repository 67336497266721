import { ApiPromise, APIResponseData } from './api.d';
import { logCall } from './index';
import { FetchConversations, FetchConversationInfos, FetchMessage, FetchDraftMessage, FetchDraftMessages, FetchAllMessages } from 'types/fetchApi';

import { randomBool, randomFromArray, randomRange } from 'utils';
import { DEFAULT_PER_PAGE } from 'reducers/messaging';

const PROJECT_NAMES = ['Atlas', 'Le retour du roi', 'Babylone', 'Netflix', 'Ocs', 'Paramount'];
const NAMES = ['Prince', 'Jickael Mackson', 'Jean-michel dit Jeanmi', 'Mitch Buchannon', 'Nacho de La Noche', 'Alfred', 'Marcelo', 'Michel Gallager', 'Gandalf le bleu', 'Mash Boutchek', 'Maverik Zecho', 'Robin Hoood', 'Serge Benamou', 'Mob Barley', 'Meddy Fercury'];
const CONTENT = [
  'The path of the righteous man is beset on all sides by the iniquities of the selfish and the tyranny of evil men. Blessed is he who, in the name of charity and good will, shepherds the weak through the valley of darkness, for he is truly his brother\'s keeper and the finder of lost children. And I will strike down upon thee with great vengeance and furious anger those who would attempt to poison and destroy My brothers. And you will know My name is the Lord when I lay My vengeance upon thee.',
  'Your bones don\'t break, mine do. That\'s clear. Your cells react to bacteria and viruses differently than mine. You don\'t get sick, I do.',
  'Hey, look at me when I\'m talking to you, motherfucker. You listen: we go in there, and that nigga Winston or anybody else is in there, you the first motherfucker to get shot. You understand?',
  'Look, just because I don\'t be givin\' no man a foot massage don\'t make it right for Marsellus to throw Antwone into a glass motherfuckin\' house, fuckin\' up the way the nigger talks. Motherfucker do that shit to me, he better paralyze my ass, \'cause I\'ll kill the motherfucker, know what I\'m sayin\'?'
];

const PROFILE_TYPES = ['contact', 'profile'];

const fakeIds = (max = 25) => Array(randomRange(1,max))
  .fill(null)
  .map(() => randomRange(1,1000000));

const fakeProfile = () => ({
  id: randomRange(100,10000),
  name: randomFromArray(NAMES),
  pseudo: randomBool() ? randomFromArray(NAMES): null,
  full_name: randomFromArray(NAMES),
  type: randomFromArray(PROFILE_TYPES),
  picture_url: `https://i.pravatar.cc/240?u=${randomRange(1,100)}`,
  is_active: randomBool(),
});

const fakeAttachments = () => randomBool()
  ? Array(randomRange(0, 3)).fill(null).map(() => ({
      id: randomRange(0,100),
      size: randomRange(0,10000),
      filename: `Paillettes ${randomRange(0, 500)}`,
      url: `https://cdn.fstoppers.com/styles/med-16-9/s3/lead/2017/08/iceland-unique-compositions-landscape-photography.jpg`
    }))
  : [];

const fakeDate = () => `2021-01-${randomRange(10,27)}T${randomRange(10,23)}:${randomRange(10,23)}:${randomRange(10,23)}`;

const fakeMessage = (id?) => ({
  id: id || randomRange(1,1000000),
  created_at: fakeDate(),
  content: randomFromArray(CONTENT),
  subject:'Proposition de partenariat avec KOLQUARE',
  is_a_thread_start: randomBool(),
  message_type: 'message',
  subtype: null,
  has_gmail_sync: randomBool(),
  writer: fakeProfile(),
  attachments: fakeAttachments()
});

const fakeDraftMessage = (id?) => ({
  id: id || randomRange(1,1000000),
  conversation_id: randomRange(1,1000000),
  created_at: fakeDate(),
  updated_at: fakeDate(),
  participants: fakeIds(randomRange(2,10)).map(fakeProfile),
  content: randomFromArray(CONTENT),
  attachments: fakeAttachments(),
});

const fakeDraftMessages = () => {
  const total = randomRange(1,50);
  return {
    total,
    rows: fakeIds(total).map(id => fakeDraftMessage(id)),
  }
}

const fakeConversationInfos = (id) => {
  const lastMessageAt = fakeDate();
  const messageIds = fakeIds(20);
  return {
    id: id || randomRange(1, 10000),
    last_message_at: lastMessageAt,
    draft_message: null,
    last_message: {
      id: randomRange(1,1000000),
      created_at: lastMessageAt,
      content: randomFromArray(CONTENT),
      has_gmail_sync: randomBool(),
      message_type: 'message',
      writer: fakeProfile(),
      attachments: fakeAttachments()
    },
    participants: fakeIds(randomRange(2,10)).map(fakeProfile),
    subject: 'Proposition de partenariat avec KOLQUARE',
    threads: messageIds.map(id => ({
      id,
      created_at: fakeDate(),
      subject: 'Proposition de partenariat avec KOLQUARE',
    })),
    company_name: 'KOLQUARE',
    has_been_read: randomBool(),
    project_name: randomFromArray(PROJECT_NAMES),
    project_id: randomRange(1,1000000),
    unread_messages_count: randomRange(0,10)
  }
}

const fakeAllMessages = () => ({
  total: randomRange(1, 50),
  rows: fakeIds(DEFAULT_PER_PAGE).map(fakeMessage)
});

const fakeConversations = () => {
  const total = randomRange(1,50);

  return {
    total,
    rows: fakeIds(total).map(fakeConversationInfos),
  }
};

export const fetchConversations = (parameters) => {
  return new Promise<APIResponseData<FetchConversations>>((resolve) => {
      logCall('GET', 'conversations', parameters);

      const data = fakeConversations() as FetchConversations;
      const response = {
        status: 200,
        data,
      };
      let timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => resolve(response), 500);
  });
};

export const getConversationInfos = (conversationId: number) => {
  return new Promise<APIResponseData<FetchConversationInfos>>((resolve) => {
      logCall('GET', `conversations/${conversationId}`, {});

      const data = fakeConversationInfos(conversationId);
      const response = {
        status: 200,
        data,
      };
      let timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => resolve(response), 500);
  });
};

export const getAllMessages = (params) => {
  return new Promise<APIResponseData<FetchAllMessages>>( (resolve) => {
    logCall('GET', `conversations/${params.id}/messages`, params);
    const response = {
      status: 200,
      data: fakeAllMessages(), 
    };
    let timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => resolve(response), 500);

  })
}

export const postMessage = (conversationId, message) => {
  return new Promise<APIResponseData<FetchMessage>>((resolve) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      logCall('POST', `conversations/${conversationId}/messages`, message);

      return resolve({
        data: fakeMessage(),
      });
    }, 2000);
  });
}

export const getDraftMessages = (parameters) => {
  return new Promise<APIResponseData<FetchDraftMessages>>((resolve) => {
      logCall('GET', `draft_messages`, parameters);

      const data = fakeDraftMessages();
      const response = {
        status: 200,
        data,
      };
      let timeout;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => resolve(response), 500);
  });
};

export const postDraftMessage = (message) => {
  return new Promise<APIResponseData<FetchDraftMessage>>((resolve) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      logCall('POST', `draft_messages`, message);

      return resolve({
        data: fakeDraftMessage(),
      });
    }, 2000);
  })
}

export const putDraftMessage = (id, message) => {
  return new Promise<APIResponseData<FetchDraftMessage>>((resolve) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      logCall('PUT', `draft_messages/${id}`, message);

      return resolve({
        data: fakeDraftMessage(),
      });
    }, 2000);
  })
}

export const deleteDraftMessage = (id): ApiPromise<void> => {
  return new Promise( (resolve) => {
    let timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      logCall('DELETE', `draft_messages/${id}`, {});

      return resolve();
    }, 2000);
  })
}
